export const FILTERMODAL = 'FILTERMODAL';
export const ACTIVEFILTER = 'ACTIVEFILTER';
export const DRAWER = 'DRAWER';

export const LISTENERS = {
    USER: 'USER',
    USER_DATA: 'USER_DATA'
};

// CENTRALISED
export const USER = {
    UPDATE_AUTH: 'UPDATE_AUTH',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    UPDATE_DATA: 'UPDATE_DATA',
    UPDATE_BRANDING: 'UPDATE_BRANDING',
    UPDATE_FORMS: 'UPDATE_FORMS',
    LOGOUT: 'LOGOUT'
};
