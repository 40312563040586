import React from "react";
import PropTypes from "prop-types";

const DashedLine = ({
    dashLength,
    dashThickness,
    dashGap,
    dashColor,
    dashStyle,
    dashWidth,
}) => {
    const dashedLineStyle = {
        borderTop: `${dashThickness}px ${dashStyle} ${dashColor}`,
        height: dashLength,
        margin: `${dashGap}px 0`,
        width: dashWidth,
    };

    return <div style={dashedLineStyle}></div>;
};

DashedLine.propTypes = {
    dashLength: PropTypes.number.isRequired,
    dashThickness: PropTypes.number.isRequired,
    dashGap: PropTypes.number.isRequired,
    dashColor: PropTypes.string.isRequired,
    dashStyle: PropTypes.string,
};

DashedLine.defaultProps = {
    dashStyle: "dashed",
};

export default DashedLine;
