import React, { Component } from 'react';
import { isValid, parse } from 'postcode'; // 2022-10-23 (JJ): Adding postcode field
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import { translate } from '../../../Common/CulverdocsTranslate';
import { Form } from 'react-bootstrap';
import Font from '../../../Constant/font';
import Size from '../../../Constant/size';
import Colours from '../../../Constant/colour';
import Stylesheet from 'reactjs-stylesheet';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import { child, get, push, ref, remove, set, update, equalTo, orderByChild, query } from 'firebase/database';
import { auth, database, storage } from '../../../firebase';

class CulverdocsPostcode extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
        return this.props.itemValue !== prevProps.itemValue || this.props.visible !== prevProps.visible || this.props.item.value !== prevProps.item.value || this.props.caption !== prevProps.caption;
    }

    render() {
        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption}`);

        if (!this.props.visible) {
            return null;
        }
        const styles = getStyle(this.props.brandData);
        return (
            <div>
                <div style={{ flexDirection: 'row' }}>
                    <CulverdocsFieldCaption
                        brandData={this.props.brandData}
                        caption={this.props.item.caption}
                        userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                        mandatory={this.props.item.mandatory}
                        editable={this.props.editable}
                        badge={this.props.item.badgeMessage}
                        badgeColour={this.props.item.badgeColour}
                        hasValue={this.props.item.value ? true : false}
                    />

                    {/* <FontAwesomeIcon icon={['fad', 'times']} size={11}/>  */}
                    {this.props.item && this.props.item.errortext && (
                        <p
                            style={styles.fieldCaptionBadgeError}
                            numberoflines={1}
                        >
                            {translate(this.props.item.errortext)}
                        </p>
                    )}

                    {/* <FontAwesomeIcon icon={['far', 'check']} size={11}/>  */}
                    {this.props.item && this.props.item.successtext && (
                        <p
                            style={styles.fieldCaptionBadge}
                            numberoflines={1}
                        >
                            {translate(this.props.item.successtext)}
                        </p>
                    )}
                </div>

                {this.props.editable && (
                    // <TextInput
                    //     style={styles.fieldText}
                    //     defaultValue={this.props.item.value} // 2023-02-11 (JJ): Fix for Postcode Fields when Shared via Email and returned does not contain the original value
                    //     autoCapitalize={'characters'}
                    //     autoCorrect={false}
                    //     returnKeyType={'done'}
                    //     onEndEditing={async (text) => {
                    //         var postcode = text.nativeEvent.text;
                    //         postcode = postcode.replace(/\s/g, '');
                    //         this.props.item.value = postcode; //Set the item value to the new str.

                    //         //Clear all of the item keys, as we'll update them through API / fallback.
                    //         this.props.item.badgeMessage = null;
                    //         this.props.item.badgeColour = null;
                    //         this.props.item.latitude = null;
                    //         this.props.item.longitude = null;

                    //         // First validate the postcode using postcode component, this is the initial check/purpose for this field type to validate the input.
                    //         if (!isValid(this.props.item.value)) {
                    //             // There is an issue with the provided Postcode, it does not match the expected regex/format.
                    //             this.props.item.badgeMessage = translate('Postcode is not valid.');
                    //             this.props.item.badgeColour = Colours.RED;
                    //             this.props.item.value = null;
                    //             this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                    //             this.forceUpdate();
                    //         } else {
                    //             this.props.item.badgeMessage = null;
                    //             this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                    //             this.forceUpdate();
                    //         }

                    //         // If lookup us selected, try to query the API with the postcode if the user is online
                    //         if (this.props.item.postcodelookupcoordinates && this.props.item.value && !this.props.item.badgeMessage) {
                    //             try {
                    //                 let response = await this.fetchWithTimeout(`https://api.postcodes.io/postcodes/${this.props.item.value}`, 1000);
                    //                 if (response.status == 200) {
                    //                     // We got a successful
                    //                     this.props.item.badgeMessage = `${response.result.nuts}`;
                    //                     this.props.item.badgeColour = Colours.GREEN;
                    //                     this.props.item.latitude = response.result.latitude;
                    //                     this.props.item.longitude = response.result.longitude;
                    //                     this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                    //                     this.forceUpdate();
                    //                 } else {
                    //                     // There was a problem with the provided postcode, postcodes.io returned an error so clear the value.
                    //                     this.props.item.badgeMessage = translate('Postcode is not valid.'); //`${item.value} is invalid, please try again.`;
                    //                     this.props.item.badgeColour = Colours.RED;
                    //                     this.props.item.successtext = null;
                    //                     this.props.item.value = null;
                    //                     this.props.item.latitude = null;
                    //                     this.props.item.longitude = null;
                    //                     this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                    //                     this.forceUpdate();
                    //                 }
                    //             } catch (error) {
                    //                 //This postcode isn't valid, either the postcodes.io returned a 404 or comms failiure.
                    //                 //Caught an error but no status, fallback to communication failure.
                    //                 // There was an error calling the API, or it is offline. Fallback to Outcodes List if it has been defined.
                    //                 if (this.props.item.value && this.props.item.postcodefallbackoutcodelistname) {
                    //                     const { outcode } = parse(this.props.item.value);
                    //                     if (outcode) {
                    //                         var list = await database().ref(`accounts/${userProfile.accountid}/list/${this.props.item.postcodefallbackoutcodelistname}/values`).orderByChild('value').equalTo(outcode).once('value');
                    //                         var list_val = list.val();
                    //                         if (!list_val) {
                    //                             this.props.item.badgeMessage = translate('Outcode is not valid.'); //`Cannot find ${outcode}, please try again.`;
                    //                             this.props.item.badgeColour = Colours.RED;
                    //                             this.props.item.successtext = null;
                    //                             this.props.item.value = null;
                    //                             this.props.item.latitude = null;
                    //                             this.props.item.longitude = null;
                    //                             this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                    //                             this.forceUpdate();
                    //                         } else {
                    //                             var list_entry = list_val[Object.keys(list_val)[0]]; //Get the first ID regardless of ID - this is the GUID for standard lists.
                    //                         }

                    //                         if (list_entry) {
                    //                             this.props.item.badgeMessage = null;
                    //                             this.props.item.badgeColour = null;
                    //                             this.props.item.latitude = list_entry.Latitude;
                    //                             this.props.item.longitude = list_entry.Longitude;
                    //                             this.props.item.successtext = `${list_entry.value}`;
                    //                         } else {
                    //                             this.props.item.badgeMessage = translate('Outcode is not valid.'); //`No Outcode entry for ${outcode}`;
                    //                             this.props.item.badgeColour = Colours.RED;
                    //                         }
                    //                     } else {
                    //                         this.props.item.badgeMessage = translate('Outcode is not valid.'); //`No Outcode in ${item.value}`;
                    //                         this.props.item.badgeColour = Colours.RED;
                    //                     }
                    //                     this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                    //                     this.forceUpdate();
                    //                 }
                    //             }
                    //         }
                    //     }}
                    //     onChangeText={(text) => {
                    //         this.props.item.value = text;
                    //         if (text === '') {
                    //             this.props.item.value = null;
                    //         }

                    //         this.forceUpdate();
                    //     }}
                    // />
                    <Form.Control
                        style={styles.fieldText}
                        defaultValue={this.props.item.value ? this.props.item.value : ''}
                        onChange={(text) => {
                            this.props.item.value = text.target.value.toUpperCase();
                            if (text === '') {
                                this.props.item.value = null;
                            }

                            this.forceUpdate();
                        }}
                        onBlur={async (text) => {
                            var postcode = text.nativeEvent.target.value;
                            postcode = postcode.replace(/\s/g, '');
                            this.props.item.value = postcode; //Set the item value to the new str.

                            //Clear all of the item keys, as we'll update them through API / fallback.
                            this.props.item.badgeMessage = null;
                            this.props.item.badgeColour = null;
                            this.props.item.latitude = null;
                            this.props.item.longitude = null;

                            // First validate the postcode using postcode component, this is the initial check/purpose for this field type to validate the input.
                            if (!isValid(this.props.item.value)) {
                                // There is an issue with the provided Postcode, it does not match the expected regex/format.
                                this.props.item.badgeMessage = translate('Postcode is not valid.');
                                this.props.item.badgeColour = Colours.RED;
                                this.props.item.value = null;
                                this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                                this.forceUpdate();
                            } else {
                                this.props.item.badgeMessage = null;
                                this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                                this.forceUpdate();
                            }

                            // If lookup us selected, try to query the API with the postcode if the user is online
                            if (this.props.item.postcodelookupcoordinates && this.props.item.value && !this.props.item.badgeMessage) {
                                try {
                                    let response = await this.fetchWithTimeout(`https://api.postcodes.io/postcodes/${this.props.item.value}`, 1000);
                                    if (response.status == 200) {
                                        // We got a successful
                                        this.props.item.badgeMessage = `${response.result.nuts}`;
                                        this.props.item.badgeColour = Colours.GREEN;
                                        this.props.item.latitude = response.result.latitude;
                                        this.props.item.longitude = response.result.longitude;
                                        this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                                        this.forceUpdate();
                                    } else {
                                        // There was a problem with the provided postcode, postcodes.io returned an error so clear the value.
                                        this.props.item.badgeMessage = translate('Postcode is not valid.'); //`${item.value} is invalid, please try again.`;
                                        this.props.item.badgeColour = Colours.RED;
                                        this.props.item.successtext = null;
                                        this.props.item.value = null;
                                        this.props.item.latitude = null;
                                        this.props.item.longitude = null;
                                        this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                                        this.forceUpdate();
                                    }
                                } catch (error) {
                                    //This postcode isn't valid, either the postcodes.io returned a 404 or comms failiure.
                                    //Caught an error but no status, fallback to communication failure.
                                    // There was an error calling the API, or it is offline. Fallback to Outcodes List if it has been defined.
                                    if (this.props.item.value && this.props.item.postcodefallbackoutcodelistname) {
                                        const { outcode } = parse(this.props.item.value);
                                        if (outcode) {
                                            let databaseRef = ref(database, `accounts/${this.props.userProfile.accountid}/list/${this.props.item.postcodefallbackoutcodelistname}/values`);

                                            var list = get(child(query(databaseRef, orderByChild('value'), equalTo(outcode)))).then((res) => res);
                                            var list_val = list.val();
                                            // var list = await database().ref(`accounts/${this.props.userProfile.accountid}/list/${this.props.item.postcodefallbackoutcodelistname}/values`).orderByChild('value').equalTo(outcode).once('value');
                                            // var list_val = list.val();

                                            if (!list_val) {
                                                this.props.item.badgeMessage = translate('Outcode is not valid.'); //`Cannot find ${outcode}, please try again.`;
                                                this.props.item.badgeColour = Colours.RED;
                                                this.props.item.successtext = null;
                                                this.props.item.value = null;
                                                this.props.item.latitude = null;
                                                this.props.item.longitude = null;
                                                this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                                                this.forceUpdate();
                                            } else {
                                                var list_entry = list_val[Object.keys(list_val)[0]]; //Get the first ID regardless of ID - this is the GUID for standard lists.
                                            }

                                            if (list_entry) {
                                                this.props.item.badgeMessage = null;
                                                this.props.item.badgeColour = null;
                                                this.props.item.latitude = list_entry.Latitude;
                                                this.props.item.longitude = list_entry.Longitude;
                                                this.props.item.successtext = `${list_entry.value}`;
                                            } else {
                                                this.props.item.badgeMessage = translate('Outcode is not valid.'); //`No Outcode entry for ${outcode}`;
                                                this.props.item.badgeColour = Colours.RED;
                                            }
                                        } else {
                                            this.props.item.badgeMessage = translate('Outcode is not valid.'); //`No Outcode in ${item.value}`;
                                            this.props.item.badgeColour = Colours.RED;
                                        }
                                        this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                                        this.forceUpdate();
                                    }
                                }
                            }
                        }}
                    />
                )}

                {/* Non-editable */}
                {!this.props.editable && (
                    <div style={styles.fieldText}>
                        <p style={styles.fieldNonEditable}>{this.props.item.value}</p>
                    </div>
                )}
            </div>
        );
    }

    async fetchWithTimeout(url, timeout) {
        let controller = new AbortController();
        setTimeout(() => controller.abort(), timeout); // abort after 3 seconds
        const resp = await fetch(url, { signal: controller.signal });
        const json = await resp.json();
        // Only throw an error if the response is an error and there's no response body. Postcodes.io will return 404 with error text.
        if (!resp.ok && !json) {
            throw new Error(resp.status);
        }
        return json;
    }
}
// const mapStateToProps = (state) => {
//     return {
//         brandData: state.user.brand,
//         userProfile: state.user.profile,
//         userId: state.user.userId
//     };
// };
export default CulverdocsPostcode;
const getStyle = (brandData) => {
    return Stylesheet.create({
        // Common Styles - Mandatory & Caption
        // mandatoryimg: {
        //     alignContent: 'center',
        //     alignSelf: 'center',
        //     top:2,
        //     marginRight: 0,
        //     marginLeft: 0,
        //     tintColor: Colours.RED
        // },
        // fieldcaption: {
        //     fontFamily: Font.family.bold,
        //     fontSize: Font.size.medium,
        //     color: Colours.BLACK
        // },
        fieldNonEditable: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            // backgroundColor: Colours.WHITE,
            color: Colours.GREY
        },
        // fieldNonEditableView : {
        //     borderBottomWidth: 1,
        //     borderBottomColor: brandData?.primarycolour,
        //     backgroundColor: Colours.WHITE,
        //     marginBottom: 10,
        //     paddingTop:10, //for iOS
        //     paddingBottom:10, //for iOS
        //     paddingLeft:5, //for iOS
        // },
        fieldCaptionBadge: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.small,
            color: Colours.BLACK,
            marginLeft: 5,
            paddingLeft: 5,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: lightenDarkenColor(brandData?.primarycolour, 50),
            borderRadius: 5,
            borderWidth: 1,
            borderColor: lightenDarkenColor(brandData?.primarycolour, 50),
            padding: 2,
            paddingLeft: 5,
            paddingRight: 5,
            height: 20,
            maxWidth: 150 // 2022-10-26 (JJ): Set maxWidth on fieldcaptions to limit content length (postcode changes)
        },

        // Field Specific
        fieldText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
            // borderBottomWidth: 1,
            // borderColor: brandData?.primarycolour,
            backgroundColor: Colours.WHITE,
            marginBottom: 10,
            paddingTop: 10, //for iOS
            paddingBottom: 10, //for iOS
            paddingLeft: 5, //for iOS
            height: 45,
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            cursor: 'pointer',
            borderRadius: 5,
            elevation: 5
        },

        fieldCaptionBadgeError: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.small,
            color: Colours.BLACK,
            marginLeft: 5,
            paddingLeft: 5,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colours.RED,
            borderRadius: 5,
            borderWidth: 1,
            borderColor: Colours.RED,
            padding: 2,
            paddingLeft: 5,
            paddingRight: 5,
            height: 20,
            maxWidth: 150 // 2022-10-26 (JJ): Set maxWidth on fieldcaptions to limit content length (postcode changes)
        }
    });
};
