import React, { Component } from 'react';
import Font from '../../../Constant/font';
import Colours from '../../../Constant/colour';
import { translate } from '../../../Common/CulverdocsTranslate';
import Stylesheet from 'reactjs-stylesheet';

export default class CulverdocsHeading extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        return this.props.visible !== prevProps.visible || this.props.item.value !== prevProps.item.value || this.props.caption !== prevProps.caption;
    }

    render() {
        const styles = getStyle(this.props.brandData);

        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption}`);
        if (!this.props.visible) {
            return null;
        }

        var textcustomstyle = [];
        var headingcustomstyle = [];

        if (this.props.item.captionalignment) {
            switch (this.props.item.captionalignment) {
                case 'Left':
                    textcustomstyle.push({ textAlign: 'left' });
                    break;
                case 'Centre':
                    textcustomstyle.push({ textAlign: 'center' });
                    break;
                case 'Right':
                    textcustomstyle.push({ textAlign: 'right' });
                    break;
            }
        }

        if (this.props.item.stylecolour) {
            headingcustomstyle.push({
                backgroundColor: this.props.item.stylecolour
            });
        }
        if (this.props.item.stylewidth && this.props.item.stylewidth == 11) {
            headingcustomstyle.push({ width: '90%' });
        }
        if (this.props.item.stylefontcolour) {
            textcustomstyle.push({ color: this.props.item.stylefontcolour });
        }

        return (
            <div>
                <div style={styles.headingView}>
                    <p style={styles.headingText}>{translate(this.props.caption)}</p>
                </div>
            </div>
        );
    }
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        headingView: {
            display: 'flex',
            // justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            paddingLeft: 10,
            paddingRight: 10,
            width: '100%',
            backgroundColor: brandData.primarycolour,
            minHeight: 40,
            marginBottom: 15
        },
        headingText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.large,
            color: Colours.WHITE,
            padding: 5,
            margin: 0
        }
    });
};
