import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import '../../styles.css';
import { CulverdocsButton } from '../../Component/CulverdocsButton';
import Colours from '../../Constant/colour';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, database } from '../../firebase';
import { setDispatch, startFirebaseListeners } from '../../Firebase/FirebaseListenerControl';
import { useDispatch } from 'react-redux';
import { getLocalStorage, removeAllLocalStorage, setLocalStorage } from '../../Helper/localStorage';
import { ShowLoader } from '../../Common/Loader';
import { child, get, onValue, ref } from 'firebase/database';
import { validateRTDeviceID } from '../../Firebase/validateRTDeviceID';
import { translate } from '../../Common/CulverdocsTranslate';
import { releaseRTDeviceID } from '../../Firebase/releaseRTDeviceID';
import { updateDeviceInfo } from '../../Firebase/updateDeviceInfo';
function NotFound() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showLogin, setShowLogin] = useState(false);
    const [preventNavigate, setPreventNavigate] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        setTimeout(() => {
            setShowLogin(true);
        }, 3000);
    }, []);
    return (
        <>
            <div
                className="main_container"
                style={{
                    height: '100vh',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    flex: 1,
                    minHeight: '100vh'
                }}
            >
                <Container
                    fluid
                    style={{
                        //  flex: 1,
                        alignSelf: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <Row
                        style={{
                            alignSelf: 'center',
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <Col>
                            <Image
                                src={require('../../Assets/Images/logo_white.png')}
                                style={{ flex: 1, width: window.innerWidth / 3 }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ color: 'white', marginBottom: 20 }}>1.0.0</Col>
                    </Row>
                    <Row>
                        {showLogin && (
                            <CulverdocsButton
                                title="Go To Login"
                                //  icon={'envelope'}
                                extraStyle={{
                                    backgroundColor: Colours.THEME,
                                    borderColor: Colours.THEME
                                }}
                                onClick={() => navigate('/')}
                            />
                        )}
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default NotFound;
