// LIBRARIES
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Stylesheet from 'reactjs-stylesheet';
import { returnCollapsibleRepeaterChildCaption } from './Helper/returnCollapsibleRepeaterChildCaption';
import { listFilterTextConditions } from './Helper/listFilterTextConditions'; // 2024-04-19 (JJ): Improvements to Select / List Filters - listFilterContainsAdvancedFilter to support: OR conditions, >, <, .., DATE()
//import { returnFieldVisibilityCheck } from './Helper/returnFieldVisibility'; // 2024-07-13 (JJ): Update to returnFieldVisibility - separated into helper with HOC to support DATE() and internalPlaceholders

// FIELDS - // 2023-04-22 (JJ): Implementation of CreateFormScreenV2 and Component fields

//import { connect } from "react-redux";

// import { CulverdocsModalOptions } from './Modals/CulverdocsModalOptions';
// import { CulverdocsModalValidation } from './Modals/CulverdocsModalValidation';
// import { CulverdocsModalConfirmSend } from './Modals/CulverdocsModalConfirmSend';

// import { CulverdocsFieldRender } from "./FormFields/_CulverdocsFieldRender";
// import CulverdocsRepeater from "./FormFields/CulverdocsRepeater";

// 2024-04-26 (JJ): Calculation reimplementation to only trigger when related fields are updated via calculationDatanames
import { evaluate } from 'mathjs';
import Colours from '../../Constant/colour';
import Font from '../../Constant/font';
import Size from '../../Constant/size';
import { translate } from '../../Common/CulverdocsTranslate';
import { auth, database, storage } from '../../firebase';
import { lightenDarkenColor } from '../../Helper/lightenDarkenColor';
import { getLocalStorage } from '../../Helper/localStorage';
import { child, equalTo, get, orderByChild, push, query, ref, remove, set, update } from 'firebase/database';
import { CulverdocsScreenHeader } from '../../Component/CulverdocsScreenHeader';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CulverdocsFieldRender } from './FormFields/_CulverdocsFiledRender';
import { getDownloadURL } from 'firebase/storage';
import { ref as sRef } from 'firebase/storage';
import { history } from '../../Common/history';
import { CulverdocsModalValidation } from './Modals/CulverdocsModalValidation';
import { CulverdocsModalConfirmSend } from './Modals/CulverdocsModalConfirmSend';
import { CulverdocsModalOptions } from './Modals/CulverdocsModalOptions';
import CulverdocsRepeater from './FormFields/CulverdocsRepeater';
import { CulverdocsScreenHeader2 } from '../../Component/CulverdocsScreenHeader2';
import { Sidebar } from 'react-pro-sidebar';
import { Col, Container, Row } from 'react-bootstrap';

//====CLASS DECLARATION====//
class CreateFormScreen extends Component {
    //--------->>>State Initilization----------->>>

    state = {
        fieldsUpdated: false, //Used to set if a field has been updated since opening the form, if so, update timestamp and upload when exiting.
        showCulverdocsModalValidation: false, //Field Validation Modal
        showCulverdocsModalConfirmSubmit: false, //Submit Modal (Confirm)
        showCulverdocsModalOptions: false, //Options form to Share / Delete etc.
        sendButtonText: 'Send', //Default Send Button Text
        sendButtonIcon: 'cloud-upload', // Default Send Button Icon
        sendButtonDisabled: false, //Disable the send button for GPS Required (awaiting location)
        form: null, //Form
        formState: null, //Form State
        formFields: null, //Form Fields
        formEvents: null, //Form Events
        recordId: null,
        brandData: {},
        userProfile: {},
        userId: ''
    };

    currentPosition = 0; // 2023-12-19 (JJ): NFE - Support for AutoScroll property on Choice, Checkbox & Select.

    //------------>>>LifeCycle Methods------------->>>

    async componentDidMount() {
        this.unblock = history.block((location, action) => {
            console.log('pop', location);
            if (action === 'POP') {
                // Prevent navigation
                //alert('hey');
                return 'Are you sure you want to go back? Your changes may not be saved.';
            }
        });
        console.log(this.props.location.state);
        if (!this.props.location.state) {
            this.navigate(true);
            return false;
        }
        let currentUser = auth.currentUser;

        if (!currentUser) {
            return false;
        }
        let brandDataValue = getLocalStorage('brandData');
        let userProfilesValue = getLocalStorage('userProfiles');
        this.setState({
            brandData: brandDataValue,
            userProfile: userProfilesValue,
            userId: currentUser.uid
        });
        await this.prepareFormState(brandDataValue, userProfilesValue, currentUser.uid);
        // this.focusListener = this.props.navigation.addListener(
        //     "focus",
        //     async () => {
        //         await this.prepareFormState(); //This is called when the page first loads or re-loaded from navigation, and called via kiosk mode on completion of a form.
        //         // Enable BackHandler to update firebase record if required.
        //         // this.backHandler = BackHandler.addEventListener(
        //         //     "hardwareBackPress",
        //         //     () => {
        //         //         this.writeFirebaseUpdateDraftRecord(true);
        //         //         return true; //The back event is being handled by the system, return true to avoid it triggering twice.
        //         //     }
        //         // );
        //     }
        // );

        // this.blurListener = this.props.navigation.addListener(
        //     "blur",
        //     async () => {
        //         // if (this.backHandler) {
        //         //     this.backHandler.remove();
        //         // }
        //     }
        // );
    }

    // Function to handle navigation and clearing states when the app needs to return home or go back depending on the state
    navigate = (goBack) => {
        console.log('CreateFormScreen - navigate() - called with goBack:', goBack ? 'Yes - should go back 1 screen' : 'No - should go to homescreen');

        goBack && this.props.navigate('/home');
        // this.resetStates();
        // if (this.props.location.state.openedFromTaskView) {
        //     //Always go back when openining from TaskView as we want to return to the List View we came from
        //     this.props.navigation.goBack();
        // } else {
        //     // Optional when opening from outside of TM, either opening from NewFormScreen or from HomeScreen
        //     if (goBack) {
        //         this.props.navigation.goBack();
        //     } else {
        //         this.props.navigation.navigate(
        //             this.state.userProfile.homescreen
        //         );
        //     }
        // }
    };

    resetStates = () => {
        // Scroll to the top of the page to reset for next form.
        if (this.scroll) {
            this.scroll.scrollTo({ y: 0, animated: false });
        }

        this.setState({
            fieldsUpdated: false, //Used to set if a field has been updated since opening the form, if so, update timestamp and upload when exiting.
            showCulverdocsModalValidation: false, //Field Validation Modal
            showCulverdocsModalConfirmSubmit: false, //Submit Modal (Confirm)
            showCulverdocsModalOptions: false, //Options form to Share / Delete etc.
            sendButtonText: this.props.location.state.form.buttontext ? this.props.location.state.form.buttontext : 'Send', //Default Send Button Text
            sendButtonIcon: this.props.location.state.form.buttonicon ? this.props.location.state.form.buttonicon : 'cloud-upload', // Default Send Button Icon
            sendButtonDisabled: false, //Disable the send button for GPS Required (awaiting location)
            form: null, //Form
            formState: null, //Form State
            formFields: null, //Form Fields
            formEvents: null, //Form Events
            recordId: null
        });
    };

    componentWillUnmount() {
        //Remove the backhander when unmounting to avoid duplicate backs
        // 2023-07-25 (JJ): NFE - Internal / BackHandler fix, only remove if defined due to undefined issue during development.
        if (this.backHandler) {
            this.backHandler.remove();
        }
    }

    async prepareFormState(brandData, userProfile, uid) {
        console.log(`CreateFormScreenV2 - Starting prepareFormState() for recordId: ${this.props.location.state.recordId} | userId: ${this.state.userId} | formId : ${this.props.location.state.form.formid} - `);

        // 2023-08-10 (JJ): NFE - Set the Key State at the start of prepareFormState() as this is used by default fields
        this.setState({
            recordId: this.props.location.state.recordId ? this.props.location.state.recordId : null
        });

        // Get any presets for this form, load now before processing default data
        console.log('presetvalue', `users/${uid}/profile/presets/${this.props.location.state.form.formid}`);
        var presetvalue = await get(child(ref(database), `users/${uid}/profile/presets/${this.props.location.state.form.formid}`)).then((snapshot) => snapshot);
        if (presetvalue) {
            presetvalue = presetvalue.val();
            this.setState({ presettable: presetvalue }); // 2023-10-07 (JJ): NFE - Fix for Presets which were disabled, now correctly stamps state to handle in setFieldDefaultValues
        }

        //Starting the load, get a copy of the fields from params - set the default values. Only set is value is blank (for edits/draft)
        let formFieldsCopy = JSON.parse(JSON.stringify(this.props.location.state.form.fields));

        // 2023-05-16 (JJ): Only run field default values if this is a new form as they will have already been set, repeaters have been converted into objects.
        // 2023-07-23 (JJ): NFE - Fix for requests supporting default values & repeaters which had an issue with foreach with objects.
        // 2024-01-16 (JJ): Implementing promises for NFE Default Values to await repeater item Select List Default Values which needs to query the DB.
        let promises = [];
        formFieldsCopy.forEach(async (field, index) => {
            field.index = index;
            // console.log(
            //     "please look this point",
            //     this.props.location.state.form.fields
            // );
            //Only set on the field if it isnt a repeater.
            if (field.type !== 'repeater') {
                // 2023-08-10 (JJ): NFE - Write Default Values to the DB if there is a key and the value has changed (i.e. draft records with blank fields which are defaulted by the client)
                if (this.props.location.state.recordId) {
                    let originalFieldValue = field.value;

                    // 2024-01-16 (JJ): Implementing promises for NFE Default Values to await repeater item Select List Default Values which needs to query the DB.
                    // 2024-01-16 (JJ): Update to various setFieldDefaultValues references - pass the parent item to set to support Populate From List
                    promises.push(
                        this.setFieldDefaultValues(field, formFieldsCopy).then(() => {
                            if (field.value !== originalFieldValue) {
                                this.onFieldChanged(field); // Field has been updated, stamp the value in the DB if we have a key, otherwise this will be written with the draft record.
                            }
                        })
                    );
                } else {
                    // 2024-01-16 (JJ): Implementing promises for NFE Default Values to await repeater item Select List Default Values which needs to query the DB.
                    // 2024-01-16 (JJ): Update to various setFieldDefaultValues references - pass the parent item to set to support Populate From List
                    promises.push(this.setFieldDefaultValues(field, formFieldsCopy));
                }
            }

            // 2023-07-23 (JJ): NFE - Fix for repeaters in requests which had an issue with foreach with objects.
            if (field.type == 'repeater') {
                // 2023-07-28 (JJ): NFE - Final fix for default values to map through object entries instead of forEach
                Object.entries(field.fields).map(async ([mapIndex, repfields]) => {
                    let fieldIndex = 0;
                    if (repfields) {
                        await repfields.forEach(async (repfield) => {
                            repfield.index = fieldIndex;

                            // 2023-08-10 (JJ): NFE - Write Default Values to the DB if there is a key and the value has changed (i.e. draft records with blank fields which are defaulted by the client)
                            if (this.props.location.state.recordId) {
                                let originalFieldValue = repfield.value;
                                // 2024-01-16 (JJ): Implementing promises for NFE Default Values to await repeater item Select List Default Values which needs to query the DB.
                                // 2024-01-16 (JJ): Update to various setFieldDefaultValues references - pass the parent item to set to support Populate From List
                                promises.push(
                                    this.setFieldDefaultValues(repfield, repfields).then(() => {
                                        if (repfield.value !== originalFieldValue) {
                                            this.onFieldChanged(repfield, field, mapIndex); // Field has been updated, stamp the value in the DB if we have a key, otherwise this will be written with the draft record.
                                        }
                                    })
                                );
                            } else {
                                // 2024-01-16 (JJ): Implementing promises for NFE Default Values to await repeater item Select List Default Values which needs to query the DB.
                                // 2024-01-16 (JJ): Update to various setFieldDefaultValues references - pass the parent item to set to support Populate From List
                                promises.push(this.setFieldDefaultValues(repfield, repfields));
                            }
                            fieldIndex++;
                        });
                    }
                });
            }
        });

        // 2024-01-16 (JJ): Implementing promises for NFE Default Values to await repeater item Select List Default Values which needs to query the DB.
        await Promise.all(promises);
        // 2024-04-29 (JJ): Improved Calculations on first load - run processFieldCalculation() after all default values have been set and run when creating new repeater children
        this.state.formFields = formFieldsCopy;
        let calculationFields = formFieldsCopy.filter((field) => field.type == 'calculation' && field.calculationtype == 'App' && field.calculationexpression);
        calculationFields.forEach((field, index) => {
            this.processFieldCalculation(field);
        });

        let repeaters = formFieldsCopy.filter((field) => field.type == 'repeater');
        repeaters.forEach(async (repeater, index) => {
            Object.entries(repeater.fields).map(([mapIndex, repfields]) => {
                // 2024-05-12 (JJ): Fix for unhandled promises with repeater children being deleted on new calculation field approach.
                if (Array.isArray(repfields)) {
                    let calculationInrepeaterChildFields = repfields.filter((field) => field && field.type && field.calculationtype && field.type == 'calculation' && field.calculationtype == 'App' && field.calculationexpression);
                    calculationInrepeaterChildFields.forEach((field, index) => {
                        this.processFieldCalculation(field, repeater, mapIndex);
                    });
                }
            });
        });

        //Get the events and add to it if we're new - dont re-start events/created time if the form is recovered
        let formEventsCopy = {};
        if (this.props.location.state.mode == 'new' && !this.props.location.state.recovered) {
            formEventsCopy = [
                {
                    type: 'created',
                    datetime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                }
            ];
        } else {
            formEventsCopy = JSON.parse(JSON.stringify(this.props.location.state.form.events));
        }

        // 2023-08-10 (JJ): NFE - Reinstating GPS capture
        if (this.props.location.state.form.gps) {
            // Set GPS Timeout before getting current position.
            var gpsTimeout = this.props.location.state.form.gpstimeout ? { timeout: this.props.location.state.form.gpstimeout * 1000 } : {};
            if (!this.props.location.state.form.gpstimeout) {
                gpsTimeout = { timeout: 60000 }; //fI we're iOS and the Timeout hasnt been set, update to 60secs as it is required.
            }

            // await Geolocation.getCurrentPosition(
            //     (location) => {
            //         this.setState({
            //             gps: `${location.coords.latitude},${location.coords.longitude}`,
            //         });
            //     },
            //     (error) => {
            //         // See error code charts below.
            //         this.setState({
            //             gpsError: `Error ${error.code} - ${error.message}`,
            //         });
            //     },
            //     { enableHighAccuracy: true, ...gpsTimeout }
            // );
            // 2024-05-16 (RB): Todo FFS
        }

        // Everything is prepared, set the state to load.

        this.setState({
            // Update formFields and formEvents
            formFields: formFieldsCopy,
            formEvents: formEventsCopy,
            formState: this.props.location.state.form.state,

            // Allow the form to be shared via email after being sent (i.e. resend) and also after saving as draft etc (previously was only on New)
            sendFormToEmailAddr: this.props.location.state.form.sendto ? this.props.location.state.form.sendto : '',
            sendFormToEmailCCMe: this.props.location.state.form.sendtocc ? this.props.location.state.form.sendtocc : false
        });
        console.log('presetvalue formFieldsCopy', formFieldsCopy, this.state.formFields);

        // v1.3.8 - Now pass to navigation params
        // this.props.navigation.setParams({
        //     formFields: this.state.formFields, //Pass formFields into navigation Params so we can reference the fields.
        //     allowDelete:
        //         this.state.formState &&
        //         this.state.formState !== "new" &&
        //         !this.props.location.state.form.donotallowdelete,
        // });

        console.log(`prepareFormState() - Finished preparing for recordId: ${this.props.location.state.recordId}`);
    }

    // 2024-01-16 (JJ): Update to various setFieldDefaultValues references - pass the parent item to set to support Populate From List
    setFieldDefaultValues = async (field, parentFields) => {
        // 2024-04-26 (JJ): Calculation reimplementation to only trigger when related fields are updated via calculationDatanames
        if (field.type == 'calculation' && field.calculationtype == 'App' && field.calculationexpression) {
            // Extract the datanames in the calculation with regex.
            field.calculationDatanames = [];
            for (const match of field.calculationexpression.matchAll(/\[(.*?)\]/g)) {
                if (!field.calculationDatanames.includes(match[1])) {
                    field.calculationDatanames.push(match[1]);
                }
            }
        }

        //This will loop through the fields in the item to set the default values. Used during init and when adding a repeater index.
        if (field.defaultvalue && !field.value) {
            // Standard Default Value fields (text) - Support for variable field in CreateFormScreen
            // 2023-12-28 (JJ): New field type "email" with email address validation, error message, default and smart lock
            // 2023-12-28 (JJ): New field type "slider"
            if (field.type == 'text' || field.type == 'textblock' || field.type == 'integer' || field.type == 'yesno' || field.type == 'variable' || field.type == 'email' || field.type == 'slider') {
                field.value = field.defaultvalue;
            }

            // 2023-07-08 (JJ): Support internal placeholders in Text Default Value - [Account.Name], [Device.Name], [Device.Email]
            // 2023-07-11 (JJ): Support internal placeholders in variable fields in addition to text, using same default value string
            // 2023-12-28 (JJ): New field type "email" with email address validation, error message, default and smart lock
            if (field.type == 'text' || field.type == 'variable' || field.type == 'email') {
                var regexpr = '(\\[.*?\\])'; //Get everything between []
                var regex = new RegExp(regexpr, 'g');
                let results;

                //console.log('this.state.userProfile.internalPlaceholders', this.state.userProfile.internalPlaceholders);

                while ((results = regex.exec(field.value)) !== null) {
                    let result = results[0];
                    result = result.replace('[', '').replace(']', '');
                    // console.log('enna mina dika', result, this.state.userProfile);
                    // 2024-02-07 (JJ): Update Default Value Placeholders to replace with blank if it doesnt exist in _internalPlaceholders
                    field.value = field.value.replace(`[${result}]`, this.state.userProfile?.internalPlaceholders[result] ? this.state.userProfile.internalPlaceholders[result] : '');
                }
            }

            // 2023-07-08 (JJ): Adding Default Value to Select (Options ONLY)
            if (field.type == 'select' && field.listtype == 'values') {
                field.value = field.defaultvalue;
                field.selected = [field.defaultvalue];
                field.listitem = [field.defaultvalue];
            }

            // 2024-01-16 (JJ): Support for Select List Default Values - both NFE and OFE (as values support was implemented in OFE)
            if (field.type == 'select' && field.listtype == 'list' && field.listname) {
                let debRef = ref(database);
                let path = `accounts/${this.state.userProfile.accountid}/list/${field.listname}/values/${field.defaultvalue}/`;
                let listOption = await get(child(debRef, path));
                if (listOption) {
                    listOption = listOption.val();
                    if (listOption && listOption.value) {
                        field.value = listOption.value;
                        field.selected = [listOption.value];
                        field.listitem = listOption; // 2023-08-10 (JJ): NFE - Fix for Smart Lock on Select fields correctly stamping listitem array with all list properties

                        // 2024-01-16 (JJ): Support Populate From List on setFieldDefaultValues for Select List Default Values and new repeater items
                        if (field.listtofieldvalue) {
                            let parentData = parentFields ? parentFields : this.state.formFields;
                            field.listtofieldvalue.forEach((val, index) => {
                                //val.Name = the List Field Name (e.g. Hair Colour) - val.Value = the Field ID that needs setting.
                                if (val.Name && val.Value) {
                                    let destinationField = parentData.find((child) => child.id === val.Value);
                                    if (destinationField) {
                                        if (destinationField.type == 'documentviewer') {
                                            if (field.listitem[val.Name + '_id']) {
                                                destinationField.documentviewerfilename = field.listitem[val.Name];
                                                destinationField.documentviewerfileid = field.listitem[val.Name + '_id'];
                                            }
                                        } else {
                                            let newVal = field.listitem[val.Name] ? field.listitem[val.Name] : '';
                                            destinationField.value = newVal;
                                        }
                                    }
                                }
                            });
                        }
                    }
                }
            }

            //Conversion - date
            if (field.type == 'date') {
                if (field.defaultvalue == 'TODAY') {
                    //Todays Date
                    var datetimeformat = field.datetimeformat ? field.datetimeformat : 'date'; //Use the format if it is set, otherwise default to date.
                    if (datetimeformat == 'date') {
                        field.value = moment(new Date()).format('YYYY-MM-DD');
                    }
                    if (datetimeformat == 'time') {
                        field.value = moment(new Date()).format('HH:mm');
                    }
                    if (datetimeformat == 'datetime') {
                        field.value = moment(new Date()).format('YYYY-MM-DD HH:mm');
                    }
                }
            }

            // 2022-07-24 (JJ): Adding Checkbox field type - default value to enabled
            if (field.type == 'checkbox' && field.defaultvalue == 'true') {
                field.value = field.optionvalue[1]; //Default to the true value.
            }
        }

        // 2022-09-15 (JJ): Update checkbox handling, default to false option value if it isn't defaulted.
        // 2022-10-29 (JJ): Fix to checkbox default value being set to false if there's no default value, now only sets if there is no previous value
        if (field.type == 'checkbox' && !field.defaultvalue && !field.value) {
            field.value = field.optionvalue[0]; //False value
        }

        // v1.1.9 - Force presettable values, set to non-editable.
        // 2023-01-06 (JJ): Handle presetable fields if it has been set in the array, regardless of the field property (field.presettable) being set.
        if (this.state.presettable) {
            //Set the default value and make non editable if it isnt null and has a value
            if (this.state.presettable[field.id] !== undefined && this.state.presettable[field.id] !== null && this.state.presettable[field.id] !== '') {
                field.value = this.state.presettable[field.id];
                field.noneditable = true; //Set the field to non-editable if it has been preset!
            }
        }

        // 2023-07-09 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Short Answer, Number, Choice, Select)
        // if (field.lockfieldtype && !field.value) {
        //     let formFieldKey = `${this.props.location.state.form.formid}:${field.id}`;
        //     let asyncData = getLocalStorage('LockedValues');

        //     //await AsyncStorage.getItem("LockedValues").then(
        //     //     (res) => JSON.parse(res)
        //     // ); // 2024-05-16 (RB): Todo ffs

        //     let lockedValue = asyncData && asyncData[formFieldKey];
        //     if (lockedValue) {
        //         // Standard Lock Types - Short Answer, Choice or Number (without increment)
        //         if (field.lockfieldtype == 'value' && field.type !== 'select') {
        //             field.value = lockedValue;
        //         }

        //         // Select Lock - retrieve the Option ID from the list
        //         if (field.lockfieldtype == 'value' && field.type == 'select') {
        //             // Handle Values - don't retrieve from the database.
        //             if (field.listtype == 'list') {
        //                 // List Values - the value is the Option ID to retrieve
        //                 let listOption = get(child(ref(database), `accounts/${this.state.userProfile.accountid}/list/${field.listname}/values/${lockedValue}/`)).then((snapshot) => snapshot.val());
        //                 if (listOption) {
        //                     listOption = listOption.val();
        //                     if (listOption && listOption.value) {
        //                         field.value = listOption.value;
        //                         field.selected = [listOption.value];
        //                         field.listitem = listOption; // 2023-08-10 (JJ): NFE - Fix for Smart Lock on Select fields correctly stamping listitem array with all list properties
        //                     } else {
        //                         // There's an issue retrieveing the listoption, might have been deleted. Exit out so we dont set locked flag
        //                         // Lets not delete the lock here, as if we retrieve the ID afterwards, the app will start to use it again.
        //                         // Allow the user to re-set it with a new ID if they want as it will override the previous.
        //                         return;
        //                     }
        //                 }
        //             } else {
        //                 // Option values, stamp the simple value
        //                 field.value = lockedValue;
        //                 field.selected = [lockedValue];
        //                 field.listitem = [lockedValue];
        //             }
        //         }

        //         // Numeric Lock - Incremental lock
        //         if (field.lockfieldtype == 'increment') {
        //             field.value = String(parseInt(lockedValue) + 1);
        //             let asyncData;
        //             // await AsyncStorage.getItem(
        //             //     "LockedValues"
        //             // ).then((res) => JSON.parse(res));
        //             //   asyncData[formFieldKey] = field.value; //Push the updated number back to lockedValues and save

        //             this.updateLockedValues(asyncData);
        //         }

        //         field.locked = true;
        //         field.forceUpdate = true; // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
        //     }
        // }
        if (field.lockfieldtype && !field.value) {
            // 2024-06-09 (JJ): Improvements to Lock Fields / Smart Lock - potential crashing so rework for JSON parsing and try/catch blocks
            try {
                console.log('lockedValue id', field.id);
                let lockedValue = await this.getLockedValue(field.id);
                console.log('lockedValue data', lockedValue);
                if (lockedValue) {
                    // Standard Lock Types - Short Answer, Choice or Number (without increment)
                    if (field.lockfieldtype == 'value' && field.type !== 'select') {
                        field.value = lockedValue;
                    }

                    // Select Lock - retrieve the Option ID from the list
                    if (field.lockfieldtype == 'value' && field.type == 'select') {
                        // Handle Values - don't retrieve from the database.
                        if (field.listtype == 'list') {
                            // List Values - the value is the Option ID to retrieve
                            // let listOption = await database().ref(`accounts/${this.props.userProfile.accountid}/list/${field.listname}/values/${lockedValue}/`).once('value');
                            console.log('get data for lock', `accounts/${this.state.userProfile.accountid}/list/${field.listname}/values/${lockedValue}/`);
                            //let listOptionRef = ref(`accounts/${this.state.userProfile.accountid}/list/${field.listname}/values/${lockedValue}/`);
                            let listOption = await get(child(ref(database), `accounts/${this.state.userProfile.accountid}/list/${field.listname}/values/${lockedValue}/`)).then((snap) => snap);
                            if (listOption) {
                                listOption = listOption.val();
                                if (listOption && listOption.value) {
                                    field.value = listOption.value;
                                    field.selected = [listOption.value];
                                    field.listitem = listOption; // 2023-08-10 (JJ): NFE - Fix for Smart Lock on Select fields correctly stamping listitem array with all list properties
                                } else {
                                    // There's an issue retrieveing the listoption, might have been deleted. Exit out so we dont set locked flag
                                    // Lets not delete the lock here, as if we retrieve the ID afterwards, the app will start to use it again.
                                    // Allow the user to re-set it with a new ID if they want as it will override the previous.
                                    return;
                                }
                            }
                        } else {
                            // Option values, stamp the simple value
                            field.value = lockedValue;
                            field.selected = [lockedValue];
                            field.listitem = [lockedValue];
                        }
                    }

                    // Numeric Lock - Incremental lock
                    if (field.lockfieldtype == 'increment') {
                        // 2024-06-07 (JJ): Improvements / fix for Lock Fields incorrectly unlocking/overwriting
                        field.value = String(parseInt(lockedValue) + 1);
                        await this.updateLockedValue(field.id, field.value);
                    }

                    field.locked = true;
                }
            } catch (error) {
                console.error('setFieldDefaultValues() - lockedValues ERROR:', error);
            }
        }
    };

    // ------------ START RENDER ------------------------- \\
    handleHeaderRight = () => {
        // alert("pressed");
        // 2024-05-12 (JJ): Reimplement Kiosk Mode in v1.7.5 (userProfile.kioskMode / userProfile.kioskformid)
        if (!this.props.location.state.form.kiosk) {
            this.setState({ showCulverdocsModalOptions: true });
        }
        // this.setState({ showCulverdocsModalOptions: true });
    };
    render() {
        const styles = getStyle(this.state.brandData);
        if (!this.props.location.state) {
            return <Link to="/" />; // Redirect to the root screen
        }
        //  const { navigate } = this.props;

        // 2023-06-25 (JJ): NFE Styling update to add top padding to first field if it is not a heading
        // 2023-06-26 (JJ): Fix for first field padding, checks inside FieldRenderer instead of render() due to Send button issue
        // 2023-07-23 (JJ): NFE - Update styling issues with fields showing spaces when they weren't visibile, changed to use filtered data.
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <div
                    style={styles.mainContainer}
                    className="content-area"
                >
                    <CulverdocsScreenHeader2
                        brandData={this.state.brandData}
                        // collapsed={collapsed}
                        // onCollapsedClick={handleToggle}
                        //  handleLogoutToggle={handleLogoutToggle}
                        //  showLogoutModal={showLogoutModal}
                        // handleLogouthide={handleLogouthide}
                        ScreenName={'createform'}
                        title={this.props.location.state.form.name}
                        RightIconType={'fad'}
                        RightIcon={this.props.location.state.mode == 'view' ? 'eye' : 'circle-ellipsis'}
                        LeftIcon={'circle-chevron-left'}
                        //  userProfile={userProfile}
                        //userid={uidState}
                        onLeftIconClick={() => (this.props.location.state.mode == 'view' ? this.navigate(true) : this.writeFirebaseUpdateDraftRecord())}
                        onRightIconClick={() => this.handleHeaderRight()}
                    />
                    <div style={{ padding: 50, paddingBottom: 0 }}>
                        {/* <div
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        maxWidth: '100%', 
                        overflowX: 'hidden',
                        flex: 1,
                        backgroundColor: Colours.LIGHTGREY
                    }}
                > */}
                        {/* // 2023-11-19 (JJ): NFE - Performance Updates, removing loader/ActivityIndicator due to re-render issues. */}
                        {/* {!this.state.formFields && (
                    <ActivityIndicator
                        size="large"
                        color={this.state.brandData?.primarycolour}
                        style={{ flex: 1 }}
                    />      
                )} */}

                        {/* {this.state.formFields && (
                    <FlatList
                        scrollEnabled={false}
                        data={this.state.formFields.filter((field) =>
                            this.returnFieldVisibility(field) 
                        )}
                        extraData={this.state}
                        // 2023-07-27 (JJ): NFE - Updating FieldRenderer and RepeaterChild to use listKey and keyExtractor with field index for performance
                        listKey={(item, index) => index.toString()}
                        keyExtractor={(item, index) =>
                            item.dataname + item.index.toString()
                        } // 2023-08-02 (JJ): NFE - Update keyExtractor on CreateFormScreenV2 render() to item.dataname + item.index.toString() due to multiple hidden fields causing update issues.
                        renderItem={({ item, index }) => {
                            if (item.type !== "repeater") {
                                return (
                                     
                                    <div>
                                        <p>CulverdocsRepeater</p>
                                    </div>
                                );
                            } else {
                                return (
                                    <div>
                                        <p>CulverdocsRepeater</p>
                                    </div>
                                   
                                );
                            }
                        }}
                    ></FlatList>
                )} */}
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignContent: 'flex-start', overflow: 'auto', height: '80vh' }}>
                            {/* <div style={{ display: 'flex', height: 70, justifyContent: 'space-between', alignContent: 'center' }}>
                            <FontAwesomeIcon
                                icon={['fad', 'circle-chevron-left']}
                                color={this.state.brandData.primarycolour}
                                style={{ height: 30, width: 30 }}
                                onClick={() => this.writeFirebaseUpdateDraftRecord(true)}
                            />
                            <p style={{ flex: 1, fontSize: Font.size.xxlarge, textAlign: 'center', textDecorationLine: 'underline' }}>{this.props.location.state.form.name}</p>
                            <FontAwesomeIcon
                                icon={this.props.location.state.mode == 'view' ? ['fad', 'eye'] : ['fad', 'circle-ellipsis']}
                                color={this.state.brandData.primarycolour}
                                style={{ height: 30, width: 30 }}
                                onClick={this.handleHeaderRight}
                            />
                        </div> */}
                            {this.state.formFields &&
                                this.state.formFields
                                    .filter((field) => this.returnFieldVisibility(field))
                                    .map((item, index) => {
                                        if (item.type !== 'repeater') {
                                            return (
                                                <div
                                                    style={{
                                                        marginTop: 5
                                                    }}
                                                >
                                                    <CulverdocsFieldRender
                                                        brandData={this.state.brandData}
                                                        userProfile={this.state.userProfile}
                                                        userId={this.state.userId}
                                                        item={item}
                                                        pItem={null}
                                                        formFields={this.state.formFields}
                                                        returnPlaceholderCaption={this.returnPlaceholderCaption}
                                                        returnFieldVisibility={this.returnFieldVisibility}
                                                        returnFieldEditable={this.returnFieldEditable}
                                                        downloadMediaFromStorage={this.downloadMediaFromStorage}
                                                        getKeyForCurrentRecord={this.getKeyForCurrentRecord}
                                                        onFieldChanged={this.onFieldChanged}
                                                        onFieldNewChild={this.onFieldNewChild}
                                                        onFieldDeleteChild={this.onFieldDeleteChild}
                                                        returnFieldFromID={this.returnFieldFromID}
                                                        returnFieldValueFromID={this.returnFieldValueFromID}
                                                        returnListValues={this.returnListValues}
                                                        // checkGPSPermissions={
                                                        //     this.checkGPSPermissions
                                                        // }
                                                        onFieldUpdateValueIndex={this.onFieldUpdateValueIndex}
                                                        returnValueFromDataname={this.returnValueFromDataname}
                                                        toggleFieldLock={this.toggleFieldLock} // 2023-07-09 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Short Answer, Number, Choice, Select)
                                                        setFieldDataFromID={this.setFieldDataFromID}
                                                    />
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div
                                                    style={{
                                                        marginTop: 10
                                                    }}
                                                >
                                                    <CulverdocsRepeater
                                                        brandData={this.state.brandData}
                                                        pItem={item}
                                                        navigationMode={this.props.location.state.mode}
                                                        formFields={this.state.formFields}
                                                        returnPlaceholderCaption={this.returnPlaceholderCaption}
                                                        returnFieldVisibility={this.returnFieldVisibility}
                                                        returnFieldEditable={this.returnFieldEditable}
                                                        downloadMediaFromStorage={this.downloadMediaFromStorage}
                                                        getKeyForCurrentRecord={this.getKeyForCurrentRecord} // 2023-12-29 (JJ): Implementing new field "document" / file upload in NFE.
                                                        onFieldChanged={this.onFieldChanged}
                                                        onFieldNewChild={this.onFieldNewChild}
                                                        onFieldDeleteChild={this.onFieldDeleteChild}
                                                        returnFieldFromID={this.returnFieldFromID}
                                                        returnFieldValueFromID={this.returnFieldValueFromID}
                                                        returnListValues={this.returnListValues}
                                                        checkGPSPermissions={this.checkGPSPermissions}
                                                        setFieldDefaultValues={this.setFieldDefaultValues}
                                                        onFieldUpdateValueIndex={this.onFieldUpdateValueIndex} // 2024-01-09 (JJ): Global update onFieldSoftDeletePhoto() to onFieldUpdateValueIndex() as this is used for Files, Photo Updates, etc.
                                                        returnValueFromDataname={this.returnValueFromDataname}
                                                        toggleFieldLock={this.toggleFieldLock}
                                                        formid={this.props.location.state.form.formid} // 2023-07-09 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Short Answer, Number, Choice, Select)
                                                        setFieldDataFromID={this.setFieldDataFromID}
                                                        returnCollapsibleRepeaterChildCaption={returnCollapsibleRepeaterChildCaption}

                                                        // returnCollapsibleRepeaterChildCaption={
                                                        //    returnCollapsibleRepeaterChildCaption
                                                        // }
                                                        // 2024-05-16 (RB): Todo FFS
                                                    />
                                                </div>
                                            );
                                        }
                                    })}

                            {/* Bottom Button */}
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-end', cursor: 'pointer' }}>
                                {this.props.location.state.mode !== 'view' && this.state.formFields && !this.state.designerMode && (
                                    <div
                                        onClick={() => this.validateForm()}
                                        style={{
                                            ...(this.state.sendButtonDisabled ? styles.btnSendButtonDisabled : styles.btnSendButton),
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                        disabled={this.state.sendButtonDisabled || this.state.sendButtonState == 'Uploading'}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: '8px' // This adds a small space between the icon and text if needed
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fad', this.state.sendButtonIcon]}
                                                style={{ width: 30, height: 30, display: 'block' }} // display block removes any inline padding or margins
                                                color={Colours.WHITE}
                                            />
                                            <p
                                                style={{
                                                    ...styles.sendButtonText,
                                                    margin: 0,
                                                    padding: 0,
                                                    lineHeight: 1 // ensures the text height is minimal, helping with vertical centering
                                                }}
                                            >
                                                {translate(this.state.sendButtonText)}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* Discard Modal */}
                            {/* // 2023-06-25 (JJ): Restrict deletion of Started records as these haven't been synced to SQL yet, must be draft first. */}
                            {/* <CulverdocsModalOptions
                                showCulverdocsModalOptions={this.state.showCulverdocsModalOptions}
                                // 2024-08-28 (RB): CreateFormScreen now allows deletion of "started" records - improved handling on agent to save draft and delete.
                                showDelete={this.state.formState && this.state.formState !== 'new' && !this.props.location.state.form.donotallowdelete}
                                showShareViaEmail={this.props.location.state.form.allowdelegate}
                                onDeleteForm={this.writeFirebaseDeleteForm}
                                onCloseModal={() => {
                                    this.setState({ showCulverdocsModalOptions: false });
                                }}
                                onFormShareViaEmail={this.writeFirebaseShareForm}
                                onGoBack={this.writeFirebaseUpdateDraftRecord}
                                // 2023-10-07 (JJ): NFE - Support Shared via Email Address Source, now retrieves the field and/or select listitem to default the email address in the modal via callback
                                returnShareViaEmailAddress={this.returnShareViaEmailAddress}
                                brandData={this.state.brandData}
                            /> */}
                            <CulverdocsModalValidation
                                showCulverdocsModalValidation={this.state.showCulverdocsModalValidation}
                                validationCount={this.state.validationCount}
                                validationField={this.state.validationField}
                                brandData={this.state.brandData}
                                onDiscard={() => {
                                    this.setState({ showCulverdocsModalValidation: false });
                                }}
                            />
                            {/* // 2023-06-09 (JJ): Supporting Confirm Send Modal */}
                            <CulverdocsModalConfirmSend
                                showCulverdocsModalConfirmSubmit={this.state.showCulverdocsModalConfirmSubmit}
                                onFormSubmit={() => {
                                    this.onFormSubmit();
                                }}
                                onDiscard={() => {
                                    this.setState({
                                        showCulverdocsModalConfirmSubmit: false
                                    });
                                }}
                                brandData={this.state.brandData}
                            />
                            {/* {this.renderSubmitModal()} */}
                            {/* {this.renderSendModal()} */}
                        </div>
                    </div>
                </div>
                <div>
                    <Sidebar
                        collapsed={true}
                        collapsedWidth={0}
                        backgroundColor={this.state.brandData.primarycolour}
                        style={{ width: 400, paddingLeft: 0, paddingRight: 0, marginLeft: 20, marginRight: 0, marginTop: 30, border: '5px solid ' + this.state.brandData.primarycolour, borderRadius: '5px' }}
                    >
                        <Container
                            fluid
                            style={{
                                paddingLeft: 10,
                                paddingRight: 10
                            }}
                        >
                            <Row
                                style={{
                                    paddingLeft: 0,
                                    paddingRight: 0
                                }}
                            >
                                <Col
                                    lg={12}
                                    style={{ display: 'flex', paddingLeft: 0, paddingRight: 0 }}
                                >
                                    <CulverdocsModalOptions
                                        showCulverdocsModalOptions={true}
                                        // 2024-08-28 (RB): CreateFormScreen now allows deletion of "started" records - improved handling on agent to save draft and delete.
                                        showDelete={this.state.formState && this.state.formState !== 'new' && !this.props.location.state.form.donotallowdelete}
                                        showShareViaEmail={this.props.location.state.form.allowdelegate}
                                        onDeleteForm={this.writeFirebaseDeleteForm}
                                        onCloseModal={() => {
                                            this.setState({ showCulverdocsModalOptions: false });
                                        }}
                                        onFormShareViaEmail={this.writeFirebaseShareForm}
                                        onGoBack={this.writeFirebaseUpdateDraftRecord}
                                        // 2023-10-07 (JJ): NFE - Support Shared via Email Address Source, now retrieves the field and/or select listitem to default the email address in the modal via callback
                                        returnShareViaEmailAddress={this.returnShareViaEmailAddress}
                                        brandData={this.state.brandData}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Sidebar>
                </div>
            </div>
        );
    }

    // 2023-10-07 (JJ): NFE - Support Shared via Email Address Source, now retrieves the field and/or select listitem to default the email address in the modal
    returnShareViaEmailAddress = () => {
        if (this.props.location.state.form.allowdelegate && this.props.location.state.form.emailsource && this.props.location.state.form.emailsource !== '' && this.state.formFields) {
            let emailSource = this.props.location.state.form.emailsource + ''; //Convert to string if it is saved as int
            emailSource = emailSource.split('.'); // emailSource[0] = Field ID | emailSource[1] = List Option Property / Custom Field (optional)
            let field = this.state.formFields.find((child) => child.id == emailSource[0]);
            if (emailSource[0] && emailSource[0] !== '' && this.state.formFields) {
                if (field && field.value) {
                    if (field.type == 'select' && emailSource[1] && emailSource[1] !== '') {
                        //The selected field is a select, and we have a option property / custom field set, lets get that from the selected value.
                        if (field.listitem && field.listitem[emailSource[1]] && field.listitem[emailSource[1]] !== '') {
                            return field.listitem[emailSource[1]].toLowerCase();
                        }
                    } else {
                        return field.value.toLowerCase();
                    }
                }
            }
        }
    };

    //  -------------------------- LIST SUPPORT ------------------------ \\
    returnListValues = async (item, pItem, rIndex, customFilters) => {
        if ((item.type == 'select' && item.listtype == 'list') || (item.type == 'pincode' && item.listname !== '') || (item.type == 'repeater' && item.listname !== '')) {
            //If a listfilter has been set, take the first filter and apply it at DB/request level to reduce the dataset for further/advanced filtering.

            let listFilter = customFilters ? customFilters : item.listfilter;
            var fieldFilter = [];

            // 2024-04-19 (JJ): Improvements to Select / List Filters - listFilterContainsAdvancedFilter to support: OR conditions, >, <, .., DATE(), !=
            const listFilterAdvancedFilterPattern = /[,\[\]<>]|(\.\.)|DATE|!=/;
            const listFilterContainsAdvancedFilter = listFilter && listFilter[0].Type == 'text' && listFilterAdvancedFilterPattern.test(listFilter[0].Value);

            if (listFilter && !listFilterContainsAdvancedFilter) {
                //If the type is field, get the field dataname from ID to query the field value
                if (listFilter[0].Type == 'field') {
                    fieldFilter = await this.returnFieldValueFromID(listFilter[0].Value, item, pItem, rIndex);
                }

                //Set the Name and Value from the listfilter when hardcoded with text.
                if (listFilter[0].Type == 'text') {
                    fieldFilter = {
                        fieldval: listFilter[0].Value,
                        fieldname: listFilter[0].Name
                    };
                }

                if (!fieldFilter.fieldval) {
                    return {
                        success: false,
                        title: translate('Missing Selection'),
                        message: translate('These options are based on your selection, please provide the required field first: ') + translate(fieldFilter.fieldname)
                    };
                } else {
                    var list = [];
                    // var list = await database() // 2024-05-16 (RB): Todo ffs
                    //     .ref(
                    //         `accounts/${this.state.userProfile.accountid}/list/${item.listname}/values`
                    //     )
                    //     .orderByChild(listFilter[0].Name)
                    //     .equalTo(fieldFilter.fieldval)
                    //     .once("value");
                    // list = list.val();
                    // let databaseRef = ref(database, `accounts/${this.props.userProfile.accountid}/list/${this.props.item.postcodefallbackoutcodelistname}/values`);

                    // var list = get(child(query(databaseRef, orderByChild('value'), equalTo(outcode)))).then((res) => res);
                    // var list_val = list.val();
                    let listRef = ref(database, `accounts/${this.state.userProfile.accountid}/list/${item.listname}/values`);
                    console.log('path', `accounts/${this.state.userProfile.accountid}/list/${item.listname}/values`);
                    var listData = await get(child(query(listRef, orderByChild(listFilter[0].Name), equalTo(fieldFilter.fieldval))))
                        .then((res) => res)
                        .catch((err) => console.log('datahas error', err));
                    console.log('Data check on list data1', listData);
                    list = listData.val();
                }
            } else {
                //No filters, just get the data from list.
                // var list = await database() // 2024-05-16 (RB): Todo ffs
                //     .ref(
                //         `accounts/${this.state.userProfile.accountid}/list/${item.listname}/values`
                //     )
                //     .once("value");
                // list = list.val();
                //   var list = [];
                list = await get(child(ref(database), `accounts/${this.state.userProfile.accountid}/list/${item.listname}/values`)).then((snapshot) => snapshot.val());
            }

            // v1.2.0 - fix to check list and length before
            if (!list || list.length == 0) {
                return {
                    success: false,
                    title: translate('No List Options'),
                    message: translate('This list does not contain any options. Please update your list and try again.')
                };
            } else {
                //Support for non list numeric keys, will allow us to push any IDs into path, sorted into new array for missing/null keys. Fix for numeric but non-sequential list IDs where a list is Defined.
                var lstoptions = [];
                list = Object.values(list);
                list.forEach((lis) => {
                    if (lis && lis.value) {
                        lstoptions.push(lis);
                    }
                });
                list = lstoptions;
            }

            // 2023-08-01 (JJ): NFE - Update for returnListValues to fix filtering issues which resulted in returning unfiltered lists
            list.sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1));

            //Apply further filtering based on secondary filters. We have already filtered on the first key through Firebase, only need to client-side filter any additional.
            if (list && listFilter) {
                //Take a copy so we dont splice the FB data before deleting the first entry as it has been filtered by DB.
                var lstfilter = JSON.parse(JSON.stringify(listFilter));

                // 2024-04-19 (JJ): Improvements to Select / List Filters - listFilterContainsAdvancedFilter to support: OR conditions, >, <, .., DATE(), !== etc.
                if (!listFilterContainsAdvancedFilter) {
                    //Delete the first entry, we've already dealt with it!
                    lstfilter.splice(0, 1); // 2023-08-01 (JJ): NFE - Update for returnListValues to fix filtering issues which resulted in returning unfiltered lists
                }

                list = this.filterListOptions(list, lstfilter, item, pItem, rIndex);

                // Check if the list contains options after filtering additional values, or return error state if no values exist.
                if (!list || list.length == 0) {
                    return {
                        success: false,
                        title: translate('No List Options'),
                        message: translate('This list does not contain any options. Please update your list and try again.')
                    };
                }
            }

            // Handling Sort By list property - user defined ascending or descending. Default the options if these don't exist from legacy field setups.
            item.listsortby = !item.listsortby ? 'value' : item.listsortby;
            item.listsortbyorder = !item.listsortbyorder ? 'asc' : item.listsortbyorder;

            // Support for List Sorting by _distance - using Latitude & Longitude values.
            if (item.listsortby == '_distance') {
                var latlongfrom = {}; //This will contain latitude and longitude keys

                // Distance calculations are handled by source & destination Latitude & Longitude values passed through XX function
                if (item.listsortbydistancesource == '_gps' && this.state.gps) {
                    latlongfrom.latitude = this.state.gps.split(',')[0];
                    latlongfrom.longitude = this.state.gps.split(',')[1];
                } else {
                    // Distance is set from a field id, get the latitude & longitude from source field - either postcode or location.
                    let source_field = this.returnFieldFromID(item.listsortbydistancesource, pItem, rIndex);
                    if (source_field && source_field.latitude && source_field.longitude) {
                        latlongfrom.latitude = source_field.latitude;
                        latlongfrom.longitude = source_field.longitude;
                    }
                }

                // Now loop through the list entries and calculate the distance.
                list.forEach(async (entry) => {
                    entry._distance = this.calculateDistance(latlongfrom.latitude, latlongfrom.longitude, entry.Latitude, entry.Longitude, 'mi', 0);
                    if (!isNaN(entry._distance)) {
                        entry.value = `${entry.value} (${entry._distance}mi)`;
                    }
                });
            }

            // Handling Sort By list property - user defined ascending or descending
            list.sort((a, b) => {
                if (!a[item.listsortby] || !b[item.listsortby]) {
                    return false;
                }

                // Lowercase for initial conversion
                a = a[item.listsortby].toLowerCase();
                b = b[item.listsortby].toLowerCase();

                // Convert to float if it's a number, otherwise return the text string for sorting
                if (!isNaN(a) && !isNaN(b)) {
                    // The values are numbers, convert to float then minus from each other.
                    a = parseFloat(a);
                    b = parseFloat(b);

                    if (item.listsortbyorder == 'asc') {
                        return a - b;
                    } else {
                        return b - a;
                    }
                } else {
                    // This is name/alphabetical sorting
                    if (item.listsortbyorder == 'asc') {
                        return a < b ? -1 : 1;
                    } else {
                        return a > b ? -1 : 1;
                    }
                }
            });

            // Completed all List related processes, return the list options.
            return { success: true, options: list };
        }

        if (item.listtype == 'values') {
            //Filter the list or return the list if there's no filter.
            if (item.listfilter) {
                item.optionvalue = this.filterListOptions(item.optionvalue, item.listfilter, item, pItem);
            }
            return { success: true, options: item.optionvalue };
        }
    };

    filterListOptions = (list, listfilter, item, pItem, rIndex) => {
        if (listfilter == undefined) {
            listfilter = {};
        }

        let dataToCheck = {};
        if (pItem && pItem.type && pItem.type == 'repeater') {
            dataToCheck = pItem.fields[rIndex].concat(this.state.formFields); // Concat the Root/Form Fields after the repeater fields to fallback to the root after checking local.
        } else {
            dataToCheck = this.state.formFields;
        }

        if (Array.isArray(listfilter)) {
            list = list.filter((listitem) => {
                let showListOption = true;
                listfilter.forEach((element) => {
                    if (element.Type == 'field') {
                        dataToCheck.forEach((field) => {
                            if (field.id == element.Value) {
                                showListOption = showListOption && listitem[element.Name] == field.value;
                            }
                        });
                    }

                    if (element.Type == 'text') {
                        // 2024-04-19 (JJ): Improvements to Select / List Filters - listFilterContainsAdvancedFilter to support: OR conditions, >, <, .., DATE()
                        //showListOption = []; // 2024-05-16 (RB): Todo FFS
                        showListOption && listFilterTextConditions(element.Value, listitem[element.Name], this.returnValueFromDataname, item, pItem, rIndex, this.state.userProfile.internalPlaceholders);
                    }
                });
                return showListOption;
            });
        }
        return list;
    };

    calculateDistance = (lat1, lon1, lat2, lon2, unit, dp) => {
        var radlat1 = (Math.PI * lat1) / 180;
        var radlat2 = (Math.PI * lat2) / 180;
        var radlon1 = (Math.PI * lon1) / 180;
        var radlon2 = (Math.PI * lon2) / 180;
        var theta = lon1 - lon2;
        var radtheta = (Math.PI * theta) / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == 'K') {
            dist = dist * 1.609344;
        }
        if (unit == 'N') {
            dist = dist * 0.8684;
        }
        if (unit == 'mi') {
            dist = dist * 0.8684 * 1.151;
        }
        dist = dist.toFixed(dp);
        return dist;
    };

    //  -------------------------- FIELD / FIREBASE UPDATES ------------------------ \\
    // 2023-12-19 (JJ): NFE - Support for AutoScroll property on Choice, Checkbox & Select.

    onFieldChanged = async (item, pItem, rIndex, scrollAfterSet) => {
        //onFieldChanged - This is used to confirm a field value has changed, update DB.

        // 2024-04-14 (JJ): Improvement to CulverdocsCalculation rendering on new forms, don't write/create a new record on initial load if the recordId doesn't already exist.
        // This way, the calculations can run on render, without writing to FB until the record is created, at which point any future writes will update as normal.
        if (item.type == 'calculation' && !this.state.recordId) {
            return;
        }

        if (!this.state.recordId) {
            // There is no key - need to write to the Firebase DB and store the key for future writes.
            await this.writeFirebaseCreateDraftRecord();
        }

        // Confirm we have an item.index and fieldDataUpdate (object) has been provided.
        if (isNaN(item.index)) {
            // 2023-10-14 (JJ): NFE - Show the user an error if there is an issue updating the field if the index doesn't exist
            alert(translate('Error saving'), translate('There was a problem saving this field. Please try again, if the problem continues, contact support.'));
            return;
        }

        // We should now have a key, from load or via insert above, pass the item and update into update function
        if (this.state.userId && this.state.recordId) {
            // Write the "item" back in to firebase, this will include any properties/values which have been updated.
            if (pItem) {
                // 2023-11-05 (JJ): NFE - Show the user an error if there is an issue updating a repeater field if the pItem.index or rIndex doesn't exist
                if (isNaN(pItem.index) || isNaN(rIndex)) {
                    alert(translate('Error saving'), translate('There was a problem saving this repeater field. Please try again, if the problem continues, contact support.'));
                    return;
                }

                // This is a repeater item, update based on pItem and rIndex
                // database()
                //     .ref(
                //         `/users/${this.state.userId}/data/${this.state.recordId}/fields/${pItem.index}/fields/${rIndex}/${item.index}`
                //     )
                //     .update({ ...item }); //Spread the item object to avoid setting to immutable

                let databaseRef = ref(database, `/users/${this.state.userId}/data/${this.state.recordId}/fields/${pItem.index}/fields/${rIndex}/${item.index}`);

                update(databaseRef, { ...item });
            } else {
                console.log(`Updating`, `/users/${this.state.userId}/data/${this.state.recordId}/fields/${item.index}`, 'with', { ...item });

                let databaseRef = ref(database, `/users/${this.state.userId}/data/${this.state.recordId}/fields/${item.index}`);

                update(databaseRef, { ...item });

                // database()
                //     .ref(
                //         `/users/${this.state.userId}/data/${this.state.recordId}/fields/${item.index}`
                //     )
                //     .update({ ...item }); //Spread the item object to avoid setting to immutable
            }

            // Console confirmation
            console.log(`OK - Finished onFieldChanged() for ${this.state.recordId} - Field Index: ${item.index} - Value: ${item}`);
        }

        // 2023-12-19 (JJ): NFE - Support for AutoScroll property on Choice, Checkbox & Select.
        // This is done at the end of setting to keep it generic (for future fields) and avoid lag if it scrolls whilst setting the value
        if (scrollAfterSet) {
            this.scroll.scrollTo({
                y: this.currentPosition + scrollAfterSet,
                animated: true
            });
        }

        // 2024-04-26 (JJ): Calculation reimplementation to only trigger when related fields are updated via calculationDatanames
        // First check for any fields at root level
        let calculationFields = this.state.formFields?.filter((field) => field.type == 'calculation' && field.calculationDatanames && (field.calculationDatanames.includes(item.dataname) || field.calculationDatanames.includes(`${pItem?.dataname}.${item.dataname}`)));
        if (calculationFields) {
            calculationFields.forEach((field) => {
                console.log(`onFieldChanged() - calculationFields based on ${item.dataname} has found:`, field.dataname);
                this.processFieldCalculation(field);
            });
        }

        // If we are inside a repeater, also check for any calculations within that repeater child to update
        if (pItem) {
            calculationFields = pItem.fields[rIndex].filter((field) => field.type == 'calculation' && field.calculationDatanames && (field.calculationDatanames.includes(item.dataname) || field.calculationDatanames.includes(`${pItem?.dataname}.${item.dataname}`)));
            if (calculationFields) {
                calculationFields.forEach((field) => {
                    console.log(`onFieldChanged() - calculationFields based on REPEATER ${pItem.dataname}.${item.dataname} has found:`, field.dataname);
                    this.processFieldCalculation(field, pItem, rIndex);
                });
            }
        }
        // 2024-04-26 (JJ): CreateFormScreen performance updates - removed forceUpdate() and fieldsUpdated() references and replaced with setState to respect shouldComponentUpdate
        this.setState({
            formFields: this.state.formFields,
            fieldsUpdated: true
        });
    };

    onFieldNewChild = async (item, pItem, rIndex, childIndex, child) => {
        //onFieldChanged - This is used to confirm a field value has changed, update DB.
        console.log(`onFieldNewChild - ${item.type} Item Index: ${item.index} - childIndex: ${childIndex} - child: ${child}`);

        if (isNaN(item.index) || !child) {
            console.log(`onFieldNewChild() - Missing parameters. Exiting.`);
            return;
        }

        if (!this.state.recordId) {
            // There is no key - need to write to the Firebase DB and store the key for future writes.
            await this.writeFirebaseCreateDraftRecord();
        }

        // We should now have a key, from load or via insert above, pass the item and update into update function
        if (this.state.userId && this.state.recordId) {
            // Write the "item" back in to firebase, this will include any properties/values which have been updated.
            if (item.type == 'repeater') {
                // Adding a new repeater child
                // database()
                //     .ref(
                //         `/users/${this.state.userId}/data/${this.state.recordId}/fields/${item.index}/fields/${childIndex}`
                //     )
                //     .update({ ...child }); //Spread the item object to avoid setting to immutable

                // 2023-11-08 (JJ): NFE - Fix for Generated Repeaters saved as draft where there is only a single child - onFieldNewChild() write generated key on repeater field
                if (item.generatefromlist && !item.generated) {
                    // database()
                    //     .ref(
                    //         `/users/${this.state.userId}/data/${this.state.recordId}/fields/${item.index}/`
                    //     )
                    //     .update({ generated: true });
                }
            } else {
                // This is a field update
                if (pItem) {
                    // The field has a parent (pItem) - apply to the rIndex
                    // database()
                    //     .ref(
                    //         `/users/${this.state.userId}/data/${this.state.recordId}/fields/${pItem.index}/fields/${rIndex}/${item.index}/value/${childIndex}`
                    //     )
                    //     .update({ ...child }); //Spread the item object to avoid setting to immutable

                    let databaseRef = ref(database, `/users/${this.state.userId}/data/${this.state.recordId}/fields/${pItem.index}/fields/${rIndex}/${item.index}/value/${childIndex}`);

                    update(databaseRef, { ...child });
                } else {
                    // Standard field (outside of repeater), apply to value
                    // database()
                    //     .ref(
                    //         `/users/${this.state.userId}/data/${this.state.recordId}/fields/${item.index}/value/${childIndex}`
                    //     )
                    //     .update({ ...child }); //Spread the item object to avoid setting to immutable
                    let databaseRef = ref(database, `/users/${this.state.userId}/data/${this.state.recordId}/fields/${item.index}/value/${childIndex}`);

                    update(databaseRef, { ...child });
                }
            }

            // Now update the timestamp on the header
            // database().ref(`/users/${this.state.userId}/data/${this.state.recordId}`).update({ timestamp: new Date().getTime() });
            let databaseTimeStamp = ref(database, `/users/${this.state.userId}/data/${this.state.recordId}`);

            update(databaseTimeStamp, { timestamp: new Date().getTime() });

            // Console confirmation
            console.log(`OK - Finished onFieldNewChild() for ${this.state.recordId} - Field Index: ${item.index} - Child Index: ${childIndex} - Value: ${child}`);
        }

        // 2024-04-29 (JJ): Improved Calculations on first load - run processFieldCalculation() after all default values have been set and run when creating new repeater children
        if (item.type == 'repeater') {
            // let calculationFields = item.fields[childIndex].filter((field) => field.type == 'calculation' && field.calculationDatanames);
            let calculationFields = item.fields[childIndex].filter((field) => field.type == 'calculation' && field.calculationDatanames);
            if (calculationFields) {
                calculationFields.forEach(async (field) => {
                    this.processFieldCalculation(field, item, childIndex);
                });
            }
        }

        // 2024-04-26 (JJ): CreateFormScreen performance updates - removed forceUpdate() and fieldsUpdated() references and replaced with setState to respect shouldComponentUpdate
        this.setState({
            formFields: this.state.formFields,
            fieldsUpdated: true
        });
    };

    //  2023-07-11 (JJ): Update to onFieldDeleteChild to support deletions for photos inside repeaters - where an image is deleted before saving as draft etc.
    onFieldDeleteChild = async (item, pItem, rIndex, childIndex) => {
        //onFieldChanged - This is used to confirm a field value has changed, update DB.

        if (isNaN(item.index) || !childIndex) {
            console.log(`onFieldDeleteChild() - Missing parameters. Exiting.`);
            return;
        }

        if (!this.state.recordId) {
            return; //This is a delete, do not insert, if we're trying to delete without a key something has gone wrong.
        }

        // We should now have a key, from load or via insert above, pass the item and update into update function
        if (this.state.userId && this.state.recordId) {
            // Write the "item" back in to firebase, this will include any properties/values which have been updated.
            if (item.type == 'repeater') {
                let databaseRef = ref(database, `/users/${this.state.userId}/data/${this.state.recordId}/fields/${item.index}/fields/${childIndex}`);

                remove(databaseRef);
                // database()
                //     .ref(
                //         `/users/${this.state.userId}/data/${this.state.recordId}/fields/${item.index}/fields/${childIndex}`
                //     )
                //     .remove();
            } else {
                // 2023-07-11 (JJ): Update to onFieldDeleteChild to support deletions for photos inside repeaters - where an image is deleted before saving as draft etc.
                if (pItem) {
                    let databaseRef = ref(database, `/users/${this.state.userId}/data/${this.state.recordId}/fields/${pItem.index}/fields/${rIndex}/${item.index}/value/${childIndex}`);

                    set(databaseRef, null);
                    // The field has a parent (pItem) - apply to the rIndex
                    //database().ref(`/users/${this.state.userId}/data/${this.state.recordId}/fields/${pItem.index}/fields/${rIndex}/${item.index}/value/${childIndex}`).set(null); //Spread the item object to avoid setting to immutable
                } else {
                    // Standard field (outside of repeater), apply to value
                    let databaseRef = ref(database, `/users/${this.state.userId}/data/${this.state.recordId}/fields/${item.index}/value/${childIndex}`);

                    set(databaseRef, null);
                    //   database().ref(`/users/${this.state.userId}/data/${this.state.recordId}/fields/${item.index}/value/${childIndex}`).set(null); //Spread the item object to avoid setting to immutable
                }
            }

            // Now update the timestamp on the header
            // database().ref(`/users/${this.state.userId}/data/${this.state.recordId}`).update({ timestamp: new Date().getTime() });
        }

        // 2024-04-26 (JJ): Calculation reimplementation to only trigger when related fields are updated via calculationDatanames
        // If the repeater child is deleted, re-calculate any fields which relate to the repeater (e.g. summed repeater fields)
        let calculationFields = this.state.formFields.filter((field) => field.type == 'calculation' && field.calculationDatanames && field.calculationDatanames.some((name) => name.startsWith(`${item.dataname}.`)));
        if (calculationFields) {
            calculationFields.forEach((field) => {
                this.processFieldCalculation(field);
            });
        }

        // 2024-04-26 (JJ): CreateFormScreen performance updates - removed forceUpdate() and fieldsUpdated() references and replaced with setState to respect shouldComponentUpdate
        this.setState({
            formFields: this.state.formFields,
            fieldsUpdated: true
        });
    };

    // 2024-01-09 (JJ): Global update onFieldSoftDeletePhoto() to onFieldUpdateValueIndex() as this is used for Files, Photo Updates, etc.
    onFieldUpdateValueIndex = async (item, pItem, rIndex, childIndex, child) => {
        //onFieldChanged - This is used to confirm a field value has changed, update DB.
        console.log(`onFieldUpdateValueIndex - ${item.index} - ${item.type} - ChildIndex: ${childIndex}`);

        if (isNaN(item.index) || !childIndex) {
            return;
        }

        if (!this.state.recordId) {
            return; //This is a delete, do not insert, if we're trying to delete without a key something has gone wrong.
        }

        // We should now have a key, from load or via insert above, pass the item and update into update function
        if (this.state.userId && this.state.recordId) {
            if (pItem) {
                // The field has a parent (pItem) - apply to the rIndex
                // database()
                //     .ref(
                //        `/users/${this.state.userId}/data/${this.state.recordId}/fields/${pItem.index}/fields/${rIndex}/${item.index}/value/${childIndex}`
                //     )
                //    .update({ ...child }); //Spread the item object to avoid setting to immutable

                const databaseRef = ref(database, `/users/${this.state.userId}/data/${this.state.recordId}/fields/${pItem.index}/fields/${rIndex}/${item.index}/value/${childIndex}`);
                update(databaseRef, { ...child });
            } else {
                // Standard field (outside of repeater), apply to value
                //  database()
                //   .ref(
                //       `/users/${this.state.userId}/data/${this.state.recordId}/fields/${item.index}/value/${childIndex}`
                //   )
                //    .update({ ...child }); //Spread the item object to avoid setting to immutable
                const databaseRef = ref(database, `/users/${this.state.userId}/data/${this.state.recordId}/fields/${item.index}/value/${childIndex}`);
                update(databaseRef, { ...child });
            }
        }

        // 2024-04-29 (JJ): Fix to onFieldsUpdated() removed but legacy refernces on Photo fields
        this.setState({
            formFields: this.state.formFields,
            fieldsUpdated: true
        }); //Push update to process deletion of the photo
    };

    async writeFirebaseCreateDraftRecord() {
        // Write into /data/XXX where XXX = Timestamp so it is unique for the current record. We'll push into data on completion via Firebase Function.

        if (this.state.recordId) {
            return; //Guarding - make sure we haven't written to the DB yet.
        }

        console.log(`writeFirebaseCreateDraftRecord() - Starting for userId ${this.state.userId}`);

        // console.log({
        //             ...this.props.location.state.form,
        //             state: "started", //options: saving, draft, uploading, uploaded, processing, complete //v1.1.6 - "saving" is new type for uploading data.
        //             fields: JSON.parse(JSON.stringify(this.state.formFields)),
        //             events: JSON.parse(JSON.stringify(this.state.formEvents)),
        //             gps: this.state.gps,
        //             timestamp: new Date().getTime(),
        //         })

        // return;

        if (this.state.userId) {
            // Write to the /data/ location using the timestamp, using copy of formFields and formEvents to avoid immutable update.
            // This will be used for all future updates.
            // 2023-06-24 (JJ): NFE Support for new state "started" to avoid new form writeback to agent
            // 2023-06-24 (JJ): NFE updates to create the initial record as "started" to avoid draft creation/processing
            // var pushRef = database()
            //     .ref(`/users/${this.state.userId}/data/`)
            //     .push({
            //         ...this.props.location.state.form,
            //         state: "started", //options: saving, draft, uploading, uploaded, processing, complete //v1.1.6 - "saving" is new type for uploading data.
            //         fields: JSON.parse(JSON.stringify(this.state.formFields)),
            //         events: JSON.parse(JSON.stringify(this.state.formEvents)),
            //         gps: this.state.gps,
            //         timestamp: new Date().getTime(),
            //     });

            const pushRef = ref(database, `/users/${this.state.userId}/data/`);
            const newPostRef = push(pushRef);
            set(newPostRef, {
                ...this.props.location.state.form,
                state: 'started', //options: saving, draft, uploading, uploaded, processing, complete //v1.1.6 - "saving" is new type for uploading data.
                fields: JSON.parse(JSON.stringify(this.state.formFields)),
                events: JSON.parse(JSON.stringify(this.state.formEvents)),
                // gps: this.state.gps,
                timestamp: new Date().getTime()
            });

            this.setState({ recordId: newPostRef.key, formState: 'started' });
            console.log(`writeFirebaseCreateDraftRecord() - Written new starting record to /data/${newPostRef.key}/`);
        }
    }

    writeFirebaseUpdateDraftRecord = () => {
        console.log('writeFirebaseUpdateDraftRecord() Start');

        // 2024-05-12 (JJ): Reimplement Kiosk Mode in v1.7.5 (userProfile.kioskMode / userProfile.kioskformid)
        if (this.props.location.state.form.kiosk) {
            return;
        }

        // We might have called this to go back, exit even if we don't have a key, just dont need to update.
        if (!this.state.userId || this.props.location.state.mode == 'view') {
            this.navigate(false);
            return; //Guarding - make sure we have a key first
        }

        // Check that the form has been updated since loading, otherwise no need to stamp and go back to the previous screen (i.e. NewFormScreen)
        if (!this.state.recordId || !this.state.fieldsUpdated) {
            this.navigate(true);
            return;
        }

        // Finished guarding, update the timestamp/state;
        console.log('writeFirebaseUpdateDraftRecord() - Updating record with state type: ', this.state.formState);

        // Write new Draft event.
        this.state.formEvents.push({
            type: 'draft',
            datetime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }); //Push a save draft

        // Update the timestamp to reposition the record in the view, and update state for draft to push any changes.
        // 2023-06-24 (JJ): NFE updates to create the initial record as "started" to avoid draft creation/processing
        if (this.state.formState == 'draft' || this.state.formState == 'started') {
            // database()
            //     .ref(`/users/${this.state.userId}/data/${this.state.recordId}`)
            //     .update({
            //         timestamp: new Date().getTime(),
            //         events: JSON.parse(JSON.stringify(this.state.formEvents)), // 2024-01-10 (JJ): writeFirebaseUpdateDraftRecord() - clone to avoid making formEvents immutable
            //         state: "saving",
            //     });
            let databaseRef = ref(database, `/users/${this.state.userId}/data/${this.state.recordId}`);

            update(databaseRef, {
                timestamp: new Date().getTime(),
                events: JSON.parse(JSON.stringify(this.state.formEvents)), // 2024-01-10 (JJ): writeFirebaseUpdateDraftRecord() - clone to avoid making formEvents immutable
                state: 'saving'
            });
        } else {
            // database()
            //     .ref(`/users/${this.state.userId}/data/${this.state.recordId}`)
            //     .update({
            //         timestamp: new Date().getTime(),
            //     });
            let databaseRef = ref(database, `/users/${this.state.userId}/data/${this.state.recordId}`);

            update(databaseRef, {
                timestamp: new Date().getTime()
            });
        }

        this.navigate(true);
    };

    writeFirebaseDeleteForm = () => {
        console.log('writeFirebaseDeleteForm() Start');
        if (!this.state.userId || !this.state.recordId || this.props.location.state.mode == 'view') {
            // 2023-09-05 (JJ): Support new parameter "openedFromTaskView" from NFE, to support back instead of navigate at the end of the form
            this.navigate(false);
            return; //Guarding - make sure we have a key first
        }

        // Update state to deleting for agent to delete record.
        // database()
        //     .ref(`/users/${this.state.userId}/data/${this.state.recordId}`)
        //     .update({ timestamp: new Date().getTime(), state: "deleting" });

        let databaseRef = ref(database, `/users/${this.state.userId}/data/${this.state.recordId}`);

        update(databaseRef, {
            timestamp: new Date().getTime(),
            state: 'deleting'
        });

        // Now go back to main menu.
        this.navigate(true);
    };

    writeFirebaseShareForm = async (email, cc) => {
        console.log('writeFirebaseShareForm() starting with: ', email, cc);

        // Confirm we have an email address
        if (!email) {
            console.log(`writeFirebaseShareForm() - Missing email address. Exiting.`);
            return;
        }

        if (!this.state.recordId) {
            // There is no key - need to write to the Firebase DB and store the key for future writes.
            await this.writeFirebaseCreateDraftRecord();
        }

        // Push new event before sending
        this.state.formEvents.push({
            type: 'sending',
            datetime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        });

        // We should now have a key if the record hadn't already been created, lets set to sending
        if (this.state.userId && this.state.recordId) {
            // Push update to firebase to mark as sending
            // database()
            //     .ref(`/users/${this.state.userId}/data/${this.state.recordId}`)
            //     .update({
            //         timestamp: new Date().getTime(),
            //         events: this.state.formEvents,
            //         sendto: email,
            //         sendtocc: cc,
            //         state: "sending",
            //     });
            let databaseRef = ref(database, `/users/${this.state.userId}/data/${this.state.recordId}`);

            update(databaseRef, {
                timestamp: new Date().getTime(),
                events: this.state.formEvents,
                sendto: email,
                // sendtocc: cc,
                state: 'sending'
            });
            // Go back home
            this.navigate(true);
        }
    };

    //  --------------------------------- CALCULATE FUNCTIONS ------------------------------- \\
    // 2024-04-26 (JJ): Calculation reimplementation to only trigger when related fields are updated via calculationDatanames
    async processFieldCalculation(field, pItem, rIndex) {
        var regexpr = '(\\[.*?\\])'; //Get everything between []
        var regex = new RegExp(regexpr, 'g');
        var calcexpr = field.calculationexpression;

        // Loop through calculation expression to find all placeholders and parse them.
        let results;
        while ((results = regex.exec(field.calculationexpression)) !== null) {
            let result = results[0];
            result = result.replace('[', '').replace(']', '');

            let newval;
            if (result.includes('.')) {
                newval = this.returnSumFromDatanameInsideRepeater(result); //This will sum the values in each child matching the dataname.
            } else {
                newval = this.returnValueFromDataname(result, field, pItem, rIndex); // 2023-08-01 (JJ): NFE - Update calculation returnValueFromDataname to support repeaters
            }
            calcexpr = calcexpr.replace(`[${result}]`, newval);
        }

        var calculated_value;
        try {
            calculated_value = evaluate(calcexpr);
            if (typeof calculated_value === 'object' || isNaN(calculated_value) || !isFinite(calculated_value)) {
                calculated_value = '';
            }
        } catch (exception) {
            console.log('processFieldCalculation() - EXCEPTION!');
        }

        if (calculated_value !== field.value) {
            field.value = calculated_value;
            console.log(`Updating calculation: ${field.dataname} (${pItem?.dataname} ${rIndex}) to: ${calculated_value}`);
            this.onFieldChanged(field, pItem, rIndex);
        }
    }

    //  --------------------------------- VALIDATE & SEND FUNCTIONS ------------------------------- \\
    async validateForm() {
        //this.onFormSubmit();
        // this.setState({ showCulverdocsModalConfirmSubmit: true });
        // return;

        // alert("hey");
        //TODO: Create a validateField function to reduce code duplication
        var validationFlag = false;
        var validationFields = [];
        var validationCount = 0;

        // 2022-07-07 (JJ): Adding Error field type
        let errorExists = await this.checkErrorExists();

        console.log(`checkErrorExists() result:`, errorExists);

        if (errorExists['exists']) {
            // If any errors exist, skip standard checks and return an error.
            // We check this first as there's less fields to check, and we dont want the user to complete all fields only to find the error.
            validationFlag = true;
            validationCount = -1; //-1 will indicate an error (to save another state being created)
            validationFields.push(`${errorExists['caption']}`);
        } else {
            // Run the standard checks.
            await this.state.formFields.forEach(async (field) => {
                // 2023-07-11 (JJ): Fix for Photo storage in array, min photos handling and field validation rework.
                if (field.type !== 'repeater') {
                    if (!this.validateField(field)) {
                        validationFlag = true;
                        validationCount++;
                        validationFields.push(`${field.caption}`);
                    }
                }

                //Loop through all of the child fields if it is a repeater.
                if (field.type == 'repeater') {
                    var pItem = field;
                    var repeaterNumber = 0; //Store this for the message

                    // 2023-07-27 (JJ): NFE - Fix for validateForm() not checking repeater visibility before child fields
                    if (this.returnFieldVisibility(field)) {
                        // 2023-10-07 (JJ): NFE - Generated Repeaters now respect the Required flag, to check the lines have been generated (hard restriction to stop processing without generation even if no values exist)
                        if (pItem.mandatory && pItem.generatefromlist && !pItem.generated) {
                            validationFlag = true;
                            validationCount++;
                            validationFields.push(`${pItem.caption}: ${pItem.generatecaption}`);
                        }

                        // 2023-10-07 (JJ): NFE - Fix for Generated Repeaters in ungenerated state triggering validation errors
                        if (!pItem.generatefromlist || (pItem.generatefromlist && pItem.generated)) {
                            await Object.entries(field.fields).map(async ([mapIndex, repfields]) => {
                                repeaterNumber++;
                                // 2023-07-26 (JJ): NFE - Fix for validating repeater fields, includes pItem and mapIndex for visibility checks
                                // 2023-07-31 (JJ): NFE - Fix for ValidateForm and CheckErrorExists to skip repeater children which are null / deleted
                                if (repfields) {
                                    await repfields.forEach(async (repfield) => {
                                        // 2023-07-11 (JJ): Fix for Photo storage in array, min photos handling and field validation rework.
                                        if (!this.validateField(repfield, pItem, mapIndex)) {
                                            validationFlag = true;
                                            validationCount++;

                                            // 2023-09-24 (JJ): NFE - Support Collapsible Repeater Child Caption in validateForm()
                                            if (pItem.repeatercollapsiblechildcaption) {
                                                let repeaterCaption = [];
                                                // this.returnCollapsibleRepeaterChildCaption(
                                                //     pItem,
                                                //     mapIndex
                                                // );
                                                // 2024-05-16 (RB): Todo FFS
                                                validationFields.push(`${repeaterCaption}, ${repfield.caption}`);
                                            } else {
                                                validationFields.push(`${pItem.caption} (${repeaterNumber}), ${repfield.caption}`);
                                            }
                                        }
                                    });
                                }
                            });
                        }

                        // 2023-10-07 (JJ): NFE - Adding support for Repeater Min/Max - Disable add button if the maxvalue is reached, and validation support for minvalue
                        // 2023-10-08 (JJ): NFE - Do not enforce Repeater Min/Max if the repeater is non-editable or Generated From List, as this cannot be controlled by the user.
                        if (pItem.minvalue && pItem.minvalue > 0 && pItem.fields && repeaterNumber < pItem.minvalue && !pItem.noneditable && !pItem.generatefromlist) {
                            validationFlag = true;
                            validationCount++;
                            validationFields.push(`${pItem.caption}: ${pItem.minvalue} required`);
                        }
                    }
                }
            });
        }

        if (validationFlag) {
            this.setState({
                showCulverdocsModalValidation: true,
                validationField: validationFields,
                validationCount: validationCount
            });
        } else {
            this.setState({ showCulverdocsModalValidation: false });

            if (this.props.location.state.form.sendconfirmation) {
                this.setState({ showCulverdocsModalConfirmSubmit: true }); // 2023-06-09 (JJ): Supporting Confirm Send Modal
            } else {
                this.onFormSubmit();

                // 2024-05-12 (JJ): Reimplement Kiosk Mode in v1.7.5 (userProfile.kioskMode / userProfile.kioskformid)
                if (this.props.location.state.form.kiosk) {
                    this.resetStates();
                    this.prepareFormState();
                }
            }
        }
    }

    // 2023-07-11 (JJ): Fix for Photo storage in array, min photos handling and field validation rework.
    validateField = (field, pItem, mapIndex) => {
        // This function will return: true = OK | false = There is an issue with the field

        // 2023-12-28 (JJ): New field type "email" with email address validation, error message, default and smart lock
        if (field.type == 'email' && field.invalidEmailAddress) {
            return false;
        }

        if (!field.mandatory) {
            return true; //Check mandatory first before getting the field visibility (for performance)
        }

        // 2023-07-26 (JJ): NFE - Fix for validating repeater fields, includes pItem and mapIndex for visibility checks
        if (!this.returnFieldVisibility(field, pItem, mapIndex)) {
            return true; //Dont check if it's not visible
        }

        // 2023-12-29 (JJ): Implementing new field "document" / file upload in NFE.
        // 2023-12-30 (JJ): New field type "video" using react-native-image-picker (for max duration) and react-native-compressor (pre-upload compression)
        if (field.type == 'photo' || field.type == 'document' || field.type == 'video') {
            // Photo fields are handled differently due to checking for keys. First check we have a value
            if (field.minvalue && !Object.keys(field.value).length) {
                return false;
            }

            // First check if the total is under the minimum, before we check for soft deleted.
            if (Object.keys(field.value).length && Object.keys(field.value).length < field.minvalue) {
                return false;
            }

            // Lastly, if all is OK at this point, check for active photos that haven't been soft deleted
            let activeItems = 0;
            Object.entries(field.value).map(([photoIndex, photo]) => {
                if (photo && !photo['deleted'] && (photo['photo'] || photo['fbstorage'])) {
                    activeItems++; // 2022-01-03 (JJ): Manage photoCount variable instead of .length() which may include deleted photos.
                }
            });

            if (activeItems < field.minvalue) {
                return false;
            }
        } else {
            // Standard field type, check value (or fbstorage - for illustration, etc)
            if (!field.value && !field.fbstorage) {
                return false;
            }
        }

        return true; //Finished all checks, return true if we haven't already returned
    };

    async checkErrorExists() {
        // This function will find any error fields and check the visibility, disable the button if so.
        if (!this.state.formFields) {
            return false;
        }

        let exists = false;
        let caption = '';
        await this.state.formFields.forEach(async (field) => {
            if (field.type == 'error' && this.returnFieldVisibility(field)) {
                exists = true;
                // 2022-07-26 (JJ): Support placeholders in caption, heading & error
                caption = this.returnPlaceholderCaption(field);
                // caption = field.caption;
            }

            // Don't check repeaters if there's a root error.
            if (!exists) {
                //Loop through all of the child fields if it is a repeater.
                if (field.type == 'repeater') {
                    var pItem = field;
                    await Object.entries(field.fields)
                        .sort()
                        .map(async ([mapIndex, repfields]) => {
                            // 2023-07-31 (JJ): NFE - Fix for ValidateForm and CheckErrorExists to skip repeater children which are null / deleted
                            if (repfields) {
                                if (this.returnFieldVisibility(pItem)) {
                                    await repfields.forEach(async (field) => {
                                        if (field.type == 'error' && this.returnFieldVisibility(field, pItem, mapIndex)) {
                                            exists = true;
                                            // 2022-07-26 (JJ): Support placeholders in caption, heading & error
                                            caption = this.returnPlaceholderCaption(field, pItem, mapIndex);
                                        }
                                    });
                                }
                            }
                        });
                }
            }
        });

        return { exists: exists, caption: caption };
    }

    async onFormSubmit() {
        // console.log("onFormSubmit");

        //return;

        if (!this.state.recordId) {
            await this.writeFirebaseCreateDraftRecord();
        }

        if (!this.state.userId || !this.state.recordId || this.props.location.state.mode == 'view') {
            return; //Guarding - make sure we have a key first
        }

        // this.setState({ isProcessingToSend: true, showSubmitModal:false }); // Mark this as processing to send, this will stop auto-downloading when the data is being stripped.

        //Add a "submitted" event to the events array
        this.state.formEvents.push({
            type: 'submitted',
            datetime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }); //Push an event

        //Start the loader.
        // this.setState({ showLoader: true });

        console.log(`onFormSubmit() - Submitting Record`);

        // Update the timestamp to reposition the record in the view, and update state for draft to push any changes.
        // database()
        //     .ref(`/users/${this.state.userId}/data/${this.state.recordId}`)
        //     .update({
        //         timestamp: new Date().getTime(),
        //         state: "uploading",
        //         events: this.state.formEvents,
        //         gps: this.state.gps,
        //     });
        let databaseRef = ref(database, `/users/${this.state.userId}/data/${this.state.recordId}`);

        update(databaseRef, {
            timestamp: new Date().getTime(),
            state: 'uploading',
            events: this.state.formEvents
            // gps: this.state.gps,
        });

        // 2023-09-05 (JJ): Support new parameter "openedFromTaskView" from NFE, to support back instead of navigate at the end of the form
        // JJ NOTE: IGNORE ALL TASK VIEW FUNCTIONALITY FOR NOW
        // if (this.props.location.state.openedFromTaskView) {
        //     // 2023-09-24 (JJ): Fix for setItemProcessingState - only call if it has been passed in to props, error with new Form type tile
        //     if (this.props.location.state.setItemProcessingState) {
        //         this.props.location.state.setItemProcessingState(); // 2023-09-24 (JJ): call setItemProcessingState() when opening the form with openedFromTaskView - set the icon as processing
        //     }
        // }

        // 2024-05-12 (JJ): Reimplement Kiosk Mode in v1.7.5 (userProfile.kioskMode / userProfile.kioskformid)
        if (!this.props.location.state.form.kiosk) {
            this.props.navigate('/home');
            //  this.navigate(false);
            return;
        }
        console.log('this is reached');
    }

    //  --------------------------------- MEDIA FUNCTIONS ------------------------------- \\
    downloadMediaFromStorage = async (media, type) => {
        if (media.fbstorage && !media.firebaseStorageProcessing) {
            //Mark as downloading to show the loading spinner - stamp this before await netinfo as it will cause a delay/multiple load attempts.
            media.firebaseStorageProcessing = true;

            // 2021-12-30 (JJ): New state of isConnected, assume false and will set when opening the form (for auto downloading media) + warn on manual download/click
            let isConnected = true; //(await NetInfo.fetch()).isConnected; // 2024-05-16 (RB): Todo FFS
            if (!isConnected) {
                alert(translate('Unable to download'), translate('You are unable to view this as you are currently offline. Please check your connection and try again.'));
                return;
            }
            console.log('data', media.fbstorage, this.state.userId);
            let filepath = `/users/${this.state.userId}/${media.fbstorage}`; // 2021-12-29 (JJ): Build the path based on the storage folder.
            let mediaRef = sRef(storage, filepath);

            try {
                let downloadedUrl = await getDownloadURL(mediaRef).then((url) => {
                    return url;
                });
                console.log('url data', downloadedUrl);
                let downloaded = await fetch(downloadedUrl)
                    .then((response) => response.text())
                    .then((data) => {
                        return data;
                    })
                    .catch((error) => console.error('Error fetching base64 data:', error));
                if (downloaded) {
                    const base64 = downloaded; //await RNFS.readFile(tmpFilename);

                    // Clear the firebaseStorageProcessing flag now it has been downloaded.
                    media.firebaseStorageProcessing = false;

                    // Set the value to the downloaded base64
                    switch (type) {
                        case 'photo':
                            media.photo = base64;
                            break;
                        case 'signature':
                            media.value = base64;
                            break;
                        case 'image':
                            media.image = base64;
                            break;
                        case 'canvas':
                            media.value = base64;
                            break; // 2022-12-10 (JJ): Adding Canvas / Illustration field
                    }

                    //   await RNFS.unlink(tmpFilename); // Now tidy up, delete the temp file.
                }
            } catch {
                return;
            }
        }
    };

    // 2023-12-29 (JJ): Implementing new field "document" / file upload in NFE.
    getKeyForCurrentRecord = async () => {
        if (!this.state.recordId && !this.state.designerMode) {
            // There is no key - need to write to the Firebase DB and store the key for future writes.
            await this.writeFirebaseCreateDraftRecord();
        }

        return this.state.recordId;
    };

    //  -------------------------- SUPPORTING / HELPER FUNCTIONS ------------------------ \\
    returnFieldFromID = async (fieldid, pItem, rIndex) => {
        let datatoCheck = {};
        if (pItem && pItem && pItem.type && pItem.type == 'repeater') {
            datatoCheck = pItem.fields[rIndex].concat(this.state.formFields); // Concat the Root/Form Fields after the repeater fields to fallback to the root after checking local.
        } else {
            datatoCheck = this.state.formFields;
        }
        return datatoCheck.find((child) => child.id === fieldid);
    };

    returnFieldValueFromID = async (fieldid, item, pItem, rIndex) => {
        var field = await this.returnFieldFromID(fieldid, pItem, rIndex);
        return { fieldval: field.value, fieldname: field.caption };
    };

    setFieldDataFromID = async (fieldID, pItem, rIndex, values) => {
        var field = await this.returnFieldFromID(fieldID, pItem, rIndex);
        for (var key in values) {
            field[key] = values[key];
        }
        this.onFieldChanged(field, pItem, rIndex); //Update the database when updating the field value

        // 2024-04-26 (JJ): CreateFormScreen performance updates - removed forceUpdate() and fieldsUpdated() references and replaced with setState to respect shouldComponentUpdate
        this.setState({ formFields: this.state.formFields });
    };

    returnSumFromDatanameInsideRepeater = (dataname) => {
        console.log(`returnSumFromDatanameInsideRepeater: ${dataname}`);

        // dataname includes "." so must be split out, then grab all children from the repeater.
        if (!dataname.includes('.')) {
            return '';
        }

        if (!this.state.formFields) {
            return '';
        }

        dataname = dataname.split('.');
        let datatoCheck = [];
        let repeater = this.state.formFields.find((child) => child.dataname === dataname[0]);
        // 2024-05-12 (JJ): Fix for unhandled promises with repeater children being deleted on new calculation field approach.
        if (repeater) {
            if (Array.isArray(repeater.fields)) {
                repeater.fields.forEach((fieldGroup) => {
                    if (fieldGroup !== null) {
                        datatoCheck.push(...fieldGroup); //Push all children into the data to filter on.
                    }
                });
            }
        }

        let fields = datatoCheck.filter((child) => child.dataname === dataname[1]); //Find all fields inside the repeater with this dataname

        let result = 0;
        fields.forEach((field) => {
            if (field && field.value) {
                result += parseFloat(field.value);
            }
        });

        return result ? result : '';
    };

    returnPlaceholderCaption = (item, pItem, rIndex) => {
        if (!this.state.formFields) {
            return '';
        }

        // Copy of returnFieldVisibility() functionality to define the scope.
        if (pItem && pItem.type && pItem.type == 'repeater') {
            var visiblefieldscope = pItem.fields[rIndex].concat(this.state.formFields); // Concat the Root/Form Fields after the repeater fields to fallback to the root after checking local.
        } else {
            var visiblefieldscope = this.state.formFields;
        }

        var caption = item.caption; //Copy of the field caption before processing.

        var regexpr = '(\\[.*?\\])'; //Get everything between []
        var regex = new RegExp(regexpr, 'g');
        let results;

        while ((results = regex.exec(caption)) !== null) {
            let result = results[0];
            result = result.replace('[', '').replace(']', '');
            let newval = this.returnValueFromDataname(result, item, pItem, rIndex);
            caption = caption.replace(`[${result}]`, newval);
        }

        return caption;
    };

    returnPlaceholderValue = (item, pItem, rIndex) => {
        if (!this.state.formFields) {
            return '';
        }

        // Copy of returnFieldVisibility() functionality to define the scope.
        if (pItem && pItem.type && pItem.type == 'repeater') {
            var visiblefieldscope = pItem.fields[rIndex].concat(this.state.formFields); // Concat the Root/Form Fields after the repeater fields to fallback to the root after checking local.
        } else {
            var visiblefieldscope = this.state.formFields;
        }

        var value = item.value; //Copy of the field value before processing.

        var regexpr = '(\\[.*?\\])'; //Get everything between []
        var regex = new RegExp(regexpr, 'g');
        let results;

        while ((results = regex.exec(value)) !== null) {
            let result = results[0];
            result = result.replace('[', '').replace(']', '');
            let newval = this.returnValueFromDataname(result, item, pItem, rIndex);
            value = value.replace(`[${result}]`, newval);
        }

        return value;
    };

    returnValueFromDataname = (dataname, item, pItem, rIndex) => {
        if (!this.state) {
            return '';
        }

        if (!this.state.formFields) {
            return '';
        }

        let datatoCheck = {};
        if (pItem && pItem.type && pItem.type == 'repeater') {
            datatoCheck = pItem.fields[rIndex].concat(this.state.formFields); // Concat the Root/Form Fields after the repeater fields to fallback to the root after checking local.
        } else {
            datatoCheck = this.state.formFields;
        }

        let fields = datatoCheck.find((child) => child.dataname === dataname);
        if (fields && fields['value']) {
            return fields['value'];
        } else {
            return '';
        }
    };

    checkGPSPermissions = async () => {
        // if (Platform.OS == "android") {
        //     try {
        //         const granted = await PermissionsAndroid.request(
        //             PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION
        //         );
        //         if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        //             // this.setState({gpspermission:true});
        //         } else {
        //             // this.setState({gpspermission:false});
        //         }
        //     } catch (err) {
        //         // this.setState({gpspermission:false});
        //     }
        // }
        // if (Platform.OS == "ios") {
        //     await Geolocation.requestAuthorization("whenInUse"); //Get authorisation from iOS to request GPS, wait for auth before loading the form.
        // }
    };

    returnFieldVisibility = (item, pItem, rIndex) => {
        // 2023-10-07 (JJ): NFE - Fix to stop rendering empty images which don't have base64 or fbstorage
        if (item.type == 'image' && !item.image && !item.fbstorage) {
            return false;
        }

        // 2023-04-09 (JJ): Add DocumentViewer checks into renderFieldVisibility so this is not shown unless there is a file or embedded base64
        if (item.type == 'documentviewer' && !item.documentviewerfileid && !item.documentviewerbase64) {
            return false; //Do not show the file if there's no ID and Base64, either one is required to display the field.
        }

        // 2023-10-07 (JJ): NFE - Fix to stop rendering variable fields via returnFieldVisibility which were causing issues with form loading
        if (item.type == 'variable') {
            return false;
        }

        // 2023-10-13 (JJ): NFE - Fix for large number of JS calculation fields causing issues with clearing loading state due to condition in _CulverdocsFieldRender
        if (item.type == 'calculation' && item.calculationtype !== 'App') {
            return false;
        }

        // 2021-08-13 (JJ): Update returnFieldVisibility() to handle root fields from inside repeater and correctly handle Multi-Filters.
        //visiblefieldscope is the scope/level that we're checking for conditionality.
        //If this is called from a standard field, the parent/root fields will be returned.
        //If this is called from within a repeater, we will return that repeater section PLUS the root, so we can check outside of the repeater child.
        if (pItem && pItem.type && pItem.type == 'repeater') {
            var visiblefieldscope = pItem.fields[rIndex].concat(this.state.formFields); // Concat the Root/Form Fields after the repeater fields to fallback to the root after checking local.
        } else {
            var visiblefieldscope = this.state.formFields;
        }

        let visible = false;

        if (item.visiblecondition) {
            var visvconditioncopy = JSON.parse(JSON.stringify(item.visiblecondition)); //Take a copy so we dont update immutable data.
            visvconditioncopy.forEach((condition) => {
                condition.result = false; //Set the default to false.

                // Find the field instead of looping through the array.
                let field = visiblefieldscope.find((child) => child.id === condition.fieldid);
                if (field.id == condition.fieldid) {
                    var conditionarray = condition.value.split(','); //Split the condition values in case there's an OR filter.
                    //Lets loop through each of the conditions
                    conditionarray.forEach((conditionalvalue) => {
                        // 2022-07-06 (JJ): Support for filtering with datanames i.e. >[value] - this replaces the text with the field value and runs standard filters
                        if (conditionalvalue.includes('[')) {
                            var fieldname = conditionalvalue.split('[')[1].split(']')[0]; //Opted for this approach to keep simple instead of regex, only 1 field value per filter.
                            var fieldvalue = this.returnValueFromDataname(fieldname, item, pItem, rIndex);
                            conditionalvalue = conditionalvalue.replace(`[${fieldname}]`, fieldvalue);
                        }

                        //Exact Match
                        if (conditionalvalue == field.value) {
                            condition.result = true;
                        }

                        // Wildcard - * = any value in the field
                        if (conditionalvalue == '*' && field.value) {
                            condition.result = true; //Condition is * so there should be a value.
                        }

                        // 2021-05-31 (JJ): Support for blank filter condition ''.
                        if (conditionalvalue == "''" && !field.value) {
                            condition.result = true; //Condition is '' so there should not be a value.
                        }

                        // 2021-05-31 (JJ): Support for range x..y in filters.
                        if (conditionalvalue.includes('..')) {
                            var condvalarr = conditionalvalue.split('..');
                            var fieldval = parseFloat(field.value);
                            var min = parseFloat(condvalarr[0]);
                            var max = parseFloat(condvalarr[1]);
                            if (field.value && fieldval >= min && fieldval <= max) {
                                condition.result = true;
                            }
                        }

                        // 2021-05-30 (JJ): Fix for conditional visibility to include < and > conditions.
                        var firstchar = conditionalvalue.substring(0, 1);
                        if (firstchar == '<' || firstchar == '>') {
                            var fieldval = parseFloat(field.value);
                            var checkcond = parseFloat(conditionalvalue.substring(1));
                            if (firstchar == '>' && field.value && fieldval > checkcond) {
                                condition.result = true;
                            }
                            if (firstchar == '<' && field.value && fieldval < checkcond) {
                                condition.result = true;
                            }
                        }

                        // 2022-07-27 (JJ): Support for not equals condition !=
                        if (conditionalvalue.substring(0, 2) == '!=') {
                            var checkcond = conditionalvalue.substring(2);
                            if (field.value && field.value !== checkcond) {
                                condition.result = true;
                            }
                        }

                        // 2023-08-19 (JJ): NFE - Support Multiselect filtering, where the target field is select with multiselect values, check contains instead of value.
                        if (!condition.result && field.type == 'select' && field.listmultiselect && field.selected && field.selected.includes(conditionalvalue)) {
                            condition.result = true;
                        }
                    });
                }
            });

            // Now loop through the conditions and check them all.
            visible = true; //Show the field unless any of the conditions failed. This is to support multi-filters.
            visvconditioncopy.forEach((condition) => {
                if (!condition.result) {
                    visible = false;
                }
            });
        } else {
            visible = true;
        }

        // We have completed checking all fields, return the visibility rule.
        return visible;
    };

    returnFieldEditable = (item) => {
        if (this.props.location.state.mode == 'view' || item.noneditable) {
            return false;
        } else {
            return true;
        }
    };

    // ------------ LOCK FUNCTIONS ------------ \\

    toggleFieldLock = async (field, state) => {
        // 2024-08-02 (JJ): toggleFieldLock() restricted in CreateFormScreen view mode - cannot enable/disable when viewing completed records
        if (this.props.location.state.mode == 'view') {
            return;
        }

        // field = toggled field | state = true (locked), false (unlocked)
        try {
            console.log(`toggleFieldLock() - Lock (${state}): ${field.dataname} to: ${field.value}`);

            if (state) {
                if (field.type == 'select' && field.listtype == 'list' && field.listitem && field.listitem.id) {
                    this.updateLockedValue(field.id, field.listitem.id);
                } else {
                    this.updateLockedValue(field.id, field.value);
                }
            } else {
                this.updateLockedValue(field.id, null);
            }

            field.locked = state;
            this.setState({ formFields: this.state.formFields });
        } catch (error) {
            console.error('toggleFieldLock() - ERROR:', error);
        }
    };

    updateLockedValue = async (fieldId, value) => {
        let databaseRef = ref(database, `/users/${this.state.userId}/smartlock/${this.props.location.state.form.formid}/`);
        update(databaseRef, { [fieldId]: value });
    };

    getLockedValue = async (fieldId) => {
        //  var lockedValue = await database().ref(`/users/${this.state.userId}/smartlock/${this.props.location.state.form.formid}/${fieldId}`).once('value');
        console.log('get lock `/users/${this.state.userId}/smartlock/${this.props.location.state.form.formid}/${fieldId}`', `/users/${this.state.userId}/smartlock/${this.props.location.state.form.formid}/${fieldId}`);
        let lockedValue = await get(child(ref(database), `/users/${this.state.userId}/smartlock/${this.props.location.state.form.formid}/${fieldId}`)).then((snapshot) => snapshot.val());
        console.log('get lock value', lockedValue);
        if (lockedValue) {
            return lockedValue;
        }
    };
}
const withRouter = (Component) => {
    return (props) => {
        const location = useLocation();
        const navigate = useNavigate();
        return (
            <Component
                {...props}
                location={location}
                navigate={navigate}
            />
        );
    };
};
export default withRouter(CreateFormScreen);

// ====STYLES DECLARATION======//
const getStyle = (brandData) => {
    return Stylesheet.create({
        mainContainer: { position: 'relative', marginLeft: 30, alignSelf: 'center', marginRight: 30, backgroundColor: Colours.WHITE, paddingBottom: 40 },

        container: {
            flex: 1,
            backgroundColor: Colours.LIGHTGREY
        },
        headerStyle: {
            height: Size.headingHeight,
            backgroundColor: brandData?.primarycolour
        },
        backIconStyle: {
            marginHorizontal: Size.doubleBaseMargin
        },

        // Send Button
        btnSendButton: {
            display: 'flex',
            // flex: 0.1,
            borderRadius: 5,
            height: 55,
            maxHeight: 50,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: brandData?.primarycolour
        },
        btnSendButtonDisabled: {
            display: 'flex',
            flex: 1,
            height: 55,
            borderRadius: 5,
            maxHeight: 50,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            backgroundColor: lightenDarkenColor(brandData.primarycolour, 50)
        },
        sendButtonText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.large,
            color: Colours.WHITE,
            marginLeft: 5
        }
    });
};
