import React, { Component } from 'react';
import Font from '../../../Constant/font';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
export default class CulverdocsSlider extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        return this.props.itemValue !== prevProps.itemValue || this.props.visible !== prevProps.visible || this.props.caption !== prevProps.caption;
    }

    // 2024-01-18 (JJ): Slider update to remove Default Value - now sets to Value or MinValue if there's no value.
    state = {
        itemValue: 0
    };

    componentDidMount() {
        if (this.props.item.value && parseInt(this.props.item.value) > 0) {
            this.setState({ itemValue: parseInt(this.props.item.value) });
        } else {
            if (this.props.item.minvalue) {
                this.setState({
                    itemValue: parseInt(this.props.item.minvalue)
                });
            }
        }
    }

    render() {
        const styles = getStyle(this.props.brandData);

        if (!this.props.visible) {
            return null;
        }
        return (
            <div>
                <CulverdocsFieldCaption
                    brandData={this.props.brandData}
                    caption={`${this.props.item.caption}`}
                    userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                    badge={this.props.item.value ? this.props.item.value : '0'}
                    mandatory={this.props.item.mandatory}
                    editable={this.props.editable}
                    hasValue={this.props.item.value ? true : false}
                />

                <div
                    style={{
                        ...styles.fieldSlider,
                        ...(!this.props.editable && { cursor: 'not-allowed' })
                    }}
                >
                    <p style={styles.textMinMax}>{parseInt(this.props.item.minvalue)}</p>

                    <Slider
                        style={{
                            ...(!this.props.editable && { cursor: 'not-allowed' }),
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            height: 40,
                            marginRight: 20,
                            marginLeft: 20,
                            backgroundColor: 'transparent'
                            //  marginTop: 20,
                        }}
                        min={parseInt(this.props.item.minvalue)}
                        max={parseInt(this.props.item.maxvalue)}
                        step={this.props.item.sliderstep ? parseInt(this.props.item.sliderstep) : 1}
                        value={this.props.item.value}
                        onChange={(value) => {
                            this.props.item.value = value;
                            this.forceUpdate();
                        }}
                        styles={{
                            track: {
                                backgroundColor: this.props.brandData?.primarycolour,
                                height: 8,
                                ...(!this.props.editable && { cursor: 'not-allowed' })
                            },
                            handle: {
                                // cursor: 'pointer',
                                borderColor: this.props.brandData?.primarycolour,
                                marginLeft: -6,
                                marginTop: -8,
                                height: 20,
                                width: 20,
                                backgroundColor: this.props.brandData?.primarycolour,
                                opacity: 1
                            },
                            rail: {
                                backgroundColor: lightenDarkenColor(this.props.brandData?.primarycolour, 80),
                                height: 5,
                                ...(!this.props.editable && { cursor: 'not-allowed' })
                            },
                            dot: {
                                borderColor: this.props.brandData?.primarycolour,
                                backgroundColor: this.props.brandData?.primarycolour,
                                height: 20
                            },
                            activeDot: {
                                borderColor: this.props.brandData?.primarycolour,
                                backgroundColor: this.props.brandData?.primarycolour,
                                height: 20
                            }
                        }}
                        disabled={!this.props.editable}
                        onChangeComplete={() => {
                            this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                        }}
                    />
                    <p style={styles.textMinMax}>{parseInt(this.props.item.maxvalue)}</p>
                </div>
            </div>
        );
    }
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        // Field Specific
        fieldSlider: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'center',
            paddingRight: 10,
            paddingLeft: 10,
            alignItems: 'center',
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
            backgroundColor: Colours.WHITE,
            marginBottom: 10, // 2023-07-29 (JJ): NFE - Reducing Number field to marginBottom 10 to standardise padding
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            borderRadius: 4,
            elevation: 3,
            height: 45,
            paddingTop: 20
        },
        textMinMax: {
            color: Colours.GREY,
            fontFamily: Font.family.regular,
            fontSize: Font.size.small,
            top: -1
        }
    });
};
