export const lightenDarkenColor = (color, amount) => {
    // Ensure the color is in the correct format (e.g., '#RRGGBB')
    color = String(color).replace(/[^0-9a-f]/gi, "");

    // Convert the color to RGB
    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);

    // Calculate the new RGB values
    const adjust = (amount < 0 ? -1 : 1) * Math.abs(amount);
    const adjustedR = Math.min(255, Math.max(0, r + adjust));
    const adjustedG = Math.min(255, Math.max(0, g + adjust));
    const adjustedB = Math.min(255, Math.max(0, b + adjust));

    // Convert the adjusted RGB values back to hex and return the color
    return `#${adjustedR.toString(16)}${adjustedG.toString(
        16
    )}${adjustedB.toString(16)}`;
};
