import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { v4 as uuidv4 } from 'uuid';
import { getLocalStorage, setLocalStorage } from '../Helper/localStorage';

export async function releaseRTDeviceID() {
    try {
        const deviceId = getLocalStorage('deviceId');
        const releaseRTDeviceID = httpsCallable(functions, 'releaseRTDeviceIDEUWest1');
        const response = await releaseRTDeviceID({ deviceid: deviceId });

        return response.data.success;
    } catch (error) {
        console.error('Error releasing device ID:', error);
        return false;
    }
}
