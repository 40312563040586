// ------------------------------------------------------------------------------------------------------------------------------------------------------
// TRANSLATION UPDATE NOTES:
// The standard app translations are stored in the below function returnLanguageTranslations()
// The below should be run in the terminal inside the project root folder. This will extract the generated list of translations where hardcoded translate() strings are used:

//       find src/ -type f -name "*.js" -exec grep -oE "translate\(['\"][^'\"]*['\"]\)" {} + | sed -E "s/translate\(['\"]//; s/['\"]\)//" > translations_raw.txt && cat translations_raw.txt | cut -d ':' -f 2- | sort -u > translations_unique.txt && rm translations_raw.txt

// This will process and should be left with a single file "translations_unique.txt" containing a sorted and de-duped list of all hardcoded strings in the app.
// This list should then be C&Pd into the Google Sheet (Translations) which handles the Google Translations and builds the language strings:

//       https://docs.google.com/spreadsheets/d/1qP_IircHIGSsR5uS-0Oq9GrglH5ztDfR2Dpr3AGEodQ/edit#gid=0

// C&P the concatenated strings from the "Output" Sheet back into the returnLanguageTranslations() function.
// Delete the "trasnslations_unique.txt" file as there's no need to commit it, as the data is stored in here (CulverdocsTranslate.js)
// ------------------------------------------------------------------------------------------------------------------------------------------------------

// TODO: Need to implement moment.lang() to support moment string translations on the homescreen where it shows the Date and "3 months ago" text

// These are the hardcoded/internal translations which aren't wrapped with translate(), but should be translated as part of the standard product.
// translate('My Forms') - Task Management Default
// translate('Started') - Task Management Default
// translate('Savomg') - Task Management Default
// translate('Drafts') - Task Management Default
// translate('Requested') - Task Management Default
// translate('Rejected') - Task Management Default
// translate('Returned') - Task Management Default
// translate('Sending') - Task Management Default
// translate('Shared') - Task Management Default
// translate('Uploading') - Task Management Default
// translate('Processing') - Task Management Default
// translate('Complete') - Task Management Default

// This is the global translateData which stores the translations we've loaded.
let translateData = null;
let loadedLanguage = 'en';

export function initialiseTranslations() {
    // First check if the device is using a supported language
    if (supportedLanguage()) {
        let language = getNativeDeviceLanguage();
        translateData = returnLanguageTranslations(language);
        loadedLanguage = language;
    } else {
        console.log(`initialiseTranslations() - Device Language is not supported, using Default/English`);
    }
}

export const addTranslations = (data) => {
    translateData = { ...translateData, ...data };
};

export function translate(string) {
    if (!translateData) {
        return string;
    }

    if (translateData[string]) {
        return translateData[string];
    } else {
        return string;
    }
}

export function supportedLanguage() {
    let supportedLanguages = ['es', 'fr', 'it', 'de', 'pl'];
    let language = getNativeDeviceLanguage();
    return supportedLanguages.includes(language);
}

export function getFriendlyLoadedLanguage() {
    switch (loadedLanguage) {
        case 'es':
            return 'Español (es)';
            break;
        case 'fr':
            return 'Français (fr)';
            break;
        case 'it':
            return 'Italiano (it)';
            break;
        case 'de':
            return 'Deutsch (de)';
            break;
        case 'pl':
            return 'Polski (pl)';
            break;
        default:
            return 'en';
            break;
    }
}

// Helper Functions are called in SplashScreen
export function getNativeDeviceLanguage() {
    // var deviceLanguage =
    //     Platform.OS === 'ios'
    //         ? NativeModules.SettingsManager.settings.AppleLocale || NativeModules.SettingsManager.settings.AppleLanguages[0] //iOS 13
    //         : NativeModules.I18nManager.localeIdentifier;

    // // Return the device language as-is, we will check if it is supported later.
    // return deviceLanguage.split('_')[0];
    var deviceLanguage = navigator.language || navigator.userLanguage;

    // Return the device language as-is, we will check if it is supported later.
    return deviceLanguage.split('-')[0];
}

function returnLanguageTranslations(language) {
    var translations = [];
    translations['es'] = {
        'Account in use': 'Cuenta en uso',
        'An error exists on the form and cannot be sent. Please check the form to resolve the below error and try again': 'Existe un error en el formulario y no se puede enviar. Por favor revise el formulario para resolver el siguiente error e inténtelo nuevamente',
        'An error has occured, please try again.': 'Se ha producido un error, inténtelo de nuevo.',
        Apply: 'Aplicar',
        Cancel: 'Cancelar',
        Clear: 'Claro',
        'Close Form': 'Cerrar formulario',
        Complete: 'Completo',
        'Confidential Form': 'Formulario confidencial',
        'Delete Form': 'Eliminar formulario',
        Deleting: 'Eliminando',
        Documents: 'Documentos',
        Done: 'Hecho',
        'Download Failed': 'Descarga fracasó',
        Drafts: 'Borradores',
        'Email Address': 'Dirección de correo electrónico',
        'Email Address is required': 'Se requiere Dirección de correo electrónico',
        'Enter your PIN to continue': 'Introduce tu PIN para continuar',
        Error: 'Error',
        'Error saving': 'Error al guardar',
        'Execute Query': 'Ejecutar la solicitud',
        'Existing Illustration': 'Ilustración existente',
        'Express Login': 'Inicio de sesión exprés',
        Filter: 'Filtrar',
        'Forgot your login details? Contact your system administrator.': '¿Ha olvidado sus datos de acceso? Póngase en contacto con el administrador de su sistema.',
        Forms: 'Formularios',
        Home: 'Hogar',
        'Incorrect PIN Code': 'Código PIN incorrecto',
        'Invalid Email Address': 'Dirección de correo electrónico no válida',
        'items in this view': 'elementos en esta vista',
        'Large File': 'Archivo grande',
        'Logging Out...': 'Saliendo de tu cuenta...',
        'Login Attempts Blocked': 'Intentos de inicio de sesión bloqueados',
        'Login Details Incorrect': 'Detalles de inicio de sesión incorrectos',
        'Login with Email': 'Iniciar sesión con correo electrónico',
        Logout: 'Cerrar sesión',
        'Missing Form Target': 'Objetivo de formulario faltante',
        'Missing Information': 'Información faltante',
        'Missing Required Field(s)': 'Faltan campos obligatorios',
        'Missing Selection': 'Selección faltante',
        'My Forms': 'Mis formularios',
        'New Form': 'Nueva forma',
        No: 'No',
        'No Connection': 'Sin conexión',
        'No forms to display': 'No hay formularios para mostrar',
        'No List Options': 'Sin opciones de lista',
        OK: 'DE ACUERDO',
        Options: 'Opciones',
        'Outcode is not valid.': 'El código de salida no es válido.',
        Password: 'Contraseña',
        'Password is required': 'se requiere contraseña',
        'Password is too short': 'La contraseña es demasiado corta',
        Photo: 'Foto',
        'Please confirm all information has been provided before sending this form. Are you sure?': 'Confirme que se haya proporcionado toda la información antes de enviar este formulario. ¿Está seguro?',
        'Please wait...': 'Espere por favor...',
        'Postcode is not valid.': 'El código postal no es válido.',
        'Press here to return to Clocking screen': 'Presione aquí para regresar a la pantalla de Marcado',
        Processing: 'Procesando',
        'Recovered - Unsaved form': 'Recuperado - Formulario no guardado',
        Rejected: 'Rechazado',
        Requested: 'Solicitado',
        Returned: 'Devuelto',
        'Returned from': 'Regresado de',
        'Saved as Draft': 'Guardado como borrador',
        'Saving as Draft': 'Guardar como borrador',
        Savomg: 'Savomg',
        Search: 'Buscar',
        Send: 'Enviar',
        'Send a copy to me': 'Envíame una copia',
        'Send Form': 'Enviar el formulario',
        'Send Now': 'Enviar ahora',
        Sending: 'Enviando',
        'Sending to': 'Enviando a',
        'Sending to': 'Enviando a',
        'Sent to': 'Enviado a',
        'Share Form via Email': 'Compartir formulario por correo electrónico',
        'Share via Email': 'Compartir via correo electrónico',
        Shared: 'Compartido',
        'Something went wrong': 'Algo salió mal',
        Started: 'Comenzó',
        'There are no': 'No existen',
        'There are no Documents within this search. Please try again or clear the search': 'No hay documentos dentro de esta búsqueda. Inténtalo de nuevo o borra la búsqueda.',
        'There was a problem downloading the selected file, please check your connection and try again.': 'Hubo un problema al descargar el archivo seleccionado, verifique su conexión e inténtelo nuevamente.',
        'There was a problem saving this field. Please try again, if the problem continues, contact support.': 'Hubo un problema al guardar este campo. Inténtelo de nuevo, si el problema continúa, comuníquese con el soporte.',
        'There was a problem saving this repeater field. Please try again, if the problem continues, contact support.': 'Hubo un problema al guardar este campo repetidor. Inténtelo de nuevo, si el problema continúa, comuníquese con el soporte.',
        'There was an error encountered processing this photo. Please try again.': 'Se produjo un error al procesar esta foto. Inténtalo de nuevo.',
        'There was an error processing this video. Please try again.': 'Se produjo un error al procesar este video. Inténtalo de nuevo.',
        'These options are based on your selection, please provide the required field first:': 'Estas opciones se basan en su selección; proporcione primero el campo obligatorio:',
        'This Form is Confidential': 'Este formulario es confidencial',
        'This list does not contain any options. Please update your list and try again.': 'Esta lista no contiene ninguna opción. Actualice su lista e inténtelo de nuevo.',
        'This option has not been configured with a Form Target. Please check the setup or contact your administrator.': 'Esta opción no se ha configurado con un destino de formulario. Verifique la configuración o comuníquese con su administrador.',
        'This QR Code does not contain the expected Culverdocs login details, you may have scanned an incorrect QR Code.': 'Este código QR no contiene los detalles de inicio de sesión esperados de Culverdocs; es posible que haya escaneado un código QR incorrecto.',
        'This will permanently delete the form, are you sure?': 'Esto eliminará permanentemente el formulario, ¿estás seguro?',
        Title: 'Título',
        'Title Search': 'Buscar título',
        Types: 'Tipos',
        'Unable to download': 'No se puede descargar',
        'Unable to open this form as it cannot be retrieved.': 'No se puede abrir este formulario porque no se puede recuperar.',
        'Unable to save your Illustration, please try again.': 'No se puede guardar tu ilustración, inténtalo de nuevo.',
        'Unable to upload': 'No se puede cargar',
        Uploading: 'Subiendo',
        'Welcome to': 'Bienvenido a',
        'Welcome to Culverdocs, enter your details below to continue': 'Bienvenido a Culverdocs, ingrese sus datos a continuación para continuar',
        'Welcome to Culverdocs, scan your QR code to continue': 'Bienvenido a Culverdocs, escanea tu código QR para continuar',
        'Would you like to share this form via email? The recipient cannot change any information already provided by you. Enter the email address below to continue.': '¿Le gustaría compartir este formulario por correo electrónico? El destinatario no puede cambiar ninguna información que usted ya haya proporcionado. Ingrese la dirección de correo electrónico a continuación para continuar.',
        'You are unable to send this form as there are incomplete fields': 'No puedes enviar este formulario porque hay campos incompletos',
        'You are unable to upload files as you are currently offline. Please check your connection and try again.': 'No puedes cargar archivos porque actualmente no estás conectado. Por favor, compruebe la conexión y vuelva a intentarlo.',
        'You are unable to upload this video this as you are currently offline. Please check your connection and try again.': 'No puedes subir este video porque actualmente no estás conectado. Por favor, compruebe la conexión y vuelva a intentarlo.',
        'You are unable to view this as you are currently offline. Please check your connection and try again.': 'No puedes ver esto porque actualmente estás desconectado. Por favor, compruebe la conexión y vuelva a intentarlo.',
        'You cannot login as the account is already in use on another device. Please contact your system administrator.': 'No puede iniciar sesión porque la cuenta ya está en uso en otro dispositivo. Por favor, póngase en contacto con el administrador del sistema.',
        'You cannot upload this file as it exceeds the maximum file size:': 'No puede cargar este archivo porque excede el tamaño máximo de archivo:',
        'You do not have any Documents to view': 'No tienes ningún documento para ver',
        'You do not have any forms within this filter. Please try again or clear your filtered list': 'No tienes ningún formulario dentro de este filtro. Inténtelo de nuevo o borre su lista filtrada.',
        'You have already updated this photo, would you like to open the Orignal or the Current version?': 'Ya has actualizado esta foto, ¿te gustaría abrir la versión original o actual?',
        'You must complete all required fields before creating a new repeater.': 'Debe completar todos los campos obligatorios antes de crear un nuevo repetidor.',
        'You will no longer be able to access completed or new forms. Are you sure?': 'Ya no podrá acceder a formularios nuevos o completados. ¿Está seguro?'
    };
    translations['pl'] = {
        'Account in use': 'Konto w użyciu',
        'An error exists on the form and cannot be sent. Please check the form to resolve the below error and try again': 'W formularzu występuje błąd i nie można go wysłać. Sprawdź formularz, aby rozwiązać poniższy błąd i spróbuj ponownie',
        'An error has occured, please try again.': 'Wystąpił błąd. Spróbuj ponownie.',
        Apply: 'Stosować',
        Cancel: 'Anulować',
        Clear: 'Jasne',
        'Close Form': 'Zamknij formularz',
        Complete: 'Kompletny',
        'Confidential Form': 'Formularz poufny',
        'Delete Form': 'Usuń formularz',
        Deleting: 'Usuwanie',
        Documents: 'Dokumenty',
        Done: 'Zrobione',
        'Download Failed': 'Pobieranie nie udane',
        Drafts: 'Warcaby',
        'Email Address': 'Adres e-mail',
        'Email Address is required': 'Adres e-mail jest wymagany',
        'Enter your PIN to continue': 'Wprowadź swój PIN, aby kontynuować',
        Error: 'Błąd',
        'Error saving': 'Błąd podczas zapisywania',
        'Execute Query': 'Wykonaj zapytanie',
        'Existing Illustration': 'Istniejąca ilustracja',
        'Express Login': 'Ekspresowe logowanie',
        Filter: 'Filtr',
        'Forgot your login details? Contact your system administrator.': 'Nie pamiętasz danych logowania? Skontaktuj się z administratorem systemu.',
        Forms: 'Formularze',
        Home: 'Dom',
        'Incorrect PIN Code': 'Nieprawidłowy kod PIN',
        'Invalid Email Address': 'Niepoprawny adres email',
        'items in this view': 'elementy w tym widoku',
        'Large File': 'Duży plik',
        'Logging Out...': 'Wylogowuję się...',
        'Login Attempts Blocked': 'Próby logowania zablokowane',
        'Login Details Incorrect': 'Dane logowania są nieprawidłowe',
        'Login with Email': 'Zaloguj się za pomocą e-maila',
        Logout: 'Wyloguj',
        'Missing Form Target': 'Brakujący cel formularza',
        'Missing Information': 'Brakująca informacja',
        'Missing Required Field(s)': 'Brak wymaganych pól',
        'Missing Selection': 'Brak zaznaczenia',
        'My Forms': 'Moje formularze',
        'New Form': 'Nowa forma',
        No: 'NIE',
        'No Connection': 'Brak połączenia',
        'No forms to display': 'Brak formularzy do wyświetlenia',
        'No List Options': 'Brak opcji listy',
        OK: 'OK',
        Options: 'Opcje',
        'Outcode is not valid.': 'Kod zewnętrzny jest nieprawidłowy.',
        Password: 'Hasło',
        'Password is required': 'Wymagane jest hasło',
        'Password is too short': 'Hasło jest za krótkie',
        Photo: 'Zdjęcie',
        'Please confirm all information has been provided before sending this form. Are you sure?': 'Przed wysłaniem formularza proszę potwierdzić, że wszystkie informacje zostały podane. Jesteś pewny?',
        'Please wait...': 'Proszę czekać...',
        'Postcode is not valid.': 'Kod pocztowy jest nieprawidłowy.',
        'Press here to return to Clocking screen': 'Naciśnij tutaj, aby powrócić do ekranu zegara',
        Processing: 'Przetwarzanie',
        'Recovered - Unsaved form': 'Odzyskano – niezapisany formularz',
        Rejected: 'Odrzucony',
        Requested: 'Wymagany',
        Returned: 'Zwrócony',
        'Returned from': 'Powrócił z',
        'Saved as Draft': 'Zapisano jako wersję roboczą',
        'Saving as Draft': 'Zapisywanie jako wersja robocza',
        Savomg: 'Savomg',
        Search: 'Szukaj',
        Send: 'Wysłać',
        'Send a copy to me': 'Wyślij kopię do mnie',
        'Send Form': 'Wysłać z',
        'Send Now': 'Wyślij teraz',
        Sending: 'Wysyłanie',
        'Sending to': 'Wysyłam do',
        'Sending to': 'Wysyłam do',
        'Sent to': 'Wysłane do',
        'Share Form via Email': 'Udostępnij formularz e-mailem',
        'Share via Email': 'Udostępnij poprzez e-mail',
        Shared: 'Wspólny',
        'Something went wrong': 'Coś poszło nie tak',
        Started: 'Rozpoczęty',
        'There are no': 'Nie ma',
        'There are no Documents within this search. Please try again or clear the search': 'W tym wyszukiwaniu nie ma żadnych dokumentów. Spróbuj ponownie lub wyczyść wyszukiwanie',
        'There was a problem downloading the selected file, please check your connection and try again.': 'Wystąpił problem podczas pobierania wybranego pliku. Sprawdź połączenie i spróbuj ponownie.',
        'There was a problem saving this field. Please try again, if the problem continues, contact support.': 'Wystąpił problem z zapisaniem tego pola. Spróbuj ponownie. Jeśli problem będzie się powtarzał, skontaktuj się z pomocą techniczną.',
        'There was a problem saving this repeater field. Please try again, if the problem continues, contact support.': 'Wystąpił problem z zapisaniem tego pola wzmacniaka. Spróbuj ponownie. Jeśli problem będzie się powtarzał, skontaktuj się z pomocą techniczną.',
        'There was an error encountered processing this photo. Please try again.': 'Podczas przetwarzania tego zdjęcia wystąpił błąd. Proszę spróbuj ponownie.',
        'There was an error processing this video. Please try again.': 'Wystąpił błąd podczas przetwarzania tego filmu. Proszę spróbuj ponownie.',
        'These options are based on your selection, please provide the required field first:': 'Opcje te zależą od Twojego wyboru. Najpierw podaj wymagane pole:',
        'This Form is Confidential': 'Ten formularz jest poufny',
        'This list does not contain any options. Please update your list and try again.': 'Ta lista nie zawiera żadnych opcji. Zaktualizuj swoją listę i spróbuj ponownie.',
        'This option has not been configured with a Form Target. Please check the setup or contact your administrator.': 'Ta opcja nie została skonfigurowana z elementem docelowym formularza. Sprawdź konfigurację lub skontaktuj się z administratorem.',
        'This QR Code does not contain the expected Culverdocs login details, you may have scanned an incorrect QR Code.': 'Ten kod QR nie zawiera oczekiwanych danych logowania do Culverdocs. Być może zeskanowałeś nieprawidłowy kod QR.',
        'This will permanently delete the form, are you sure?': 'Spowoduje to trwałe usunięcie formularza, jesteś pewien?',
        Title: 'Tytuł',
        'Title Search': 'Wyszukiwanie tytułu',
        Types: 'Typy',
        'Unable to download': 'Niemożliwe do ściągnięcia',
        'Unable to open this form as it cannot be retrieved.': 'Nie można otworzyć tego formularza, ponieważ nie można go pobrać.',
        'Unable to save your Illustration, please try again.': 'Nie można zapisać ilustracji. Spróbuj ponownie.',
        'Unable to upload': 'Nie można przesłać',
        Uploading: 'Przesyłanie',
        'Welcome to': 'Witamy w',
        'Welcome to Culverdocs, enter your details below to continue': 'Witamy w Culverdocs. Aby kontynuować, wprowadź swoje dane poniżej',
        'Welcome to Culverdocs, scan your QR code to continue': 'Witamy w Culverdocs. Zeskanuj kod QR, aby kontynuować',
        'Would you like to share this form via email? The recipient cannot change any information already provided by you. Enter the email address below to continue.': 'Czy chcesz udostępnić ten formularz e-mailem? Odbiorca nie może zmienić żadnych informacji już podanych przez Ciebie. Aby kontynuować, wpisz poniżej adres e-mail.',
        'You are unable to send this form as there are incomplete fields': 'Nie możesz wysłać tego formularza ze względu na niekompletne pola',
        'You are unable to upload files as you are currently offline. Please check your connection and try again.': 'Nie możesz przesyłać plików, ponieważ jesteś obecnie offline. Proszę, sprawdź swoje połączenie i spróbuj ponownie.',
        'You are unable to upload this video this as you are currently offline. Please check your connection and try again.': 'Nie możesz przesłać tego filmu, ponieważ jesteś obecnie offline. Proszę, sprawdź swoje połączenie i spróbuj ponownie.',
        'You are unable to view this as you are currently offline. Please check your connection and try again.': 'Nie możesz tego wyświetlić, ponieważ jesteś obecnie offline. Proszę, sprawdź swoje połączenie i spróbuj ponownie.',
        'You cannot login as the account is already in use on another device. Please contact your system administrator.': 'Nie możesz się zalogować, ponieważ konto jest już używane na innym urządzeniu. Skontaktuj się z administratorem systemu.',
        'You cannot upload this file as it exceeds the maximum file size:': 'Nie możesz przesłać tego pliku, ponieważ przekracza on maksymalny rozmiar pliku:',
        'You do not have any Documents to view': 'Nie masz żadnych dokumentów do wyświetlenia',
        'You do not have any forms within this filter. Please try again or clear your filtered list': 'Nie masz żadnych formularzy w ramach tego filtra. Spróbuj ponownie lub wyczyść przefiltrowaną listę',
        'You have already updated this photo, would you like to open the Orignal or the Current version?': 'Zaktualizowałeś już to zdjęcie. Czy chcesz otworzyć wersję oryginalną czy aktualną?',
        'You must complete all required fields before creating a new repeater.': 'Przed utworzeniem nowego wzmacniaka musisz wypełnić wszystkie wymagane pola.',
        'You will no longer be able to access completed or new forms. Are you sure?': 'Nie będziesz już mieć dostępu do wypełnionych lub nowych formularzy. Jesteś pewny?'
    };
    translations['de'] = {
        'Account in use': 'Konto wird verwendet',
        'An error exists on the form and cannot be sent. Please check the form to resolve the below error and try again': 'Im Formular liegt ein Fehler vor und kann nicht gesendet werden. Bitte überprüfen Sie das Formular, um den folgenden Fehler zu beheben, und versuchen Sie es erneut',
        'An error has occured, please try again.': 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
        Apply: 'Anwenden',
        Cancel: 'Stornieren',
        Clear: 'Klar',
        'Close Form': 'Formular schließen',
        Complete: 'Vollständig',
        'Confidential Form': 'Vertrauliches Formular',
        'Delete Form': 'Formular löschen',
        Deleting: 'Löschen',
        Documents: 'Unterlagen',
        Done: 'Erledigt',
        'Download Failed': 'Herunterladen fehlgeschlagen',
        Drafts: 'Entwürfe',
        'Email Address': 'E-Mail-Adresse',
        'Email Address is required': 'E-Mailadresse wird benötigt',
        'Enter your PIN to continue': 'Geben Sie Ihre PIN ein, um fortzufahren',
        Error: 'Fehler',
        'Error saving': 'Fehler beim Speichern',
        'Execute Query': 'Abfrage ausführen',
        'Existing Illustration': 'Vorhandene Illustration',
        'Express Login': 'Express-Login',
        Filter: 'Filter',
        'Forgot your login details? Contact your system administrator.': 'Anmeldedaten vergessen? Kontaktieren Sie Ihren Systemadministrator.',
        Forms: 'Formen',
        Home: 'Heim',
        'Incorrect PIN Code': 'Falscher PIN-Code',
        'Invalid Email Address': 'Ungültige E-Mail-Adresse',
        'items in this view': 'Elemente in dieser Ansicht',
        'Large File': 'Große Datei',
        'Logging Out...': 'Ausloggen...',
        'Login Attempts Blocked': 'Anmeldeversuche blockiert',
        'Login Details Incorrect': 'Anmeldedaten falsch',
        'Login with Email': 'Melden Sie sich mit E-Mail an',
        Logout: 'Ausloggen',
        'Missing Form Target': 'Fehlendes Formularziel',
        'Missing Information': 'Fehlende Information',
        'Missing Required Field(s)': 'Fehlende Pflichtfelder',
        'Missing Selection': 'Fehlende Auswahl',
        'My Forms': 'Meine Formulare',
        'New Form': 'Neue Form',
        No: 'NEIN',
        'No Connection': 'Keine Verbindung',
        'No forms to display': 'Keine Formulare zum Anzeigen vorhanden',
        'No List Options': 'Keine Listenoptionen',
        OK: 'OK',
        Options: 'Optionen',
        'Outcode is not valid.': 'Der Outcode ist ungültig.',
        Password: 'Passwort',
        'Password is required': 'Passwort wird benötigt',
        'Password is too short': 'Das Passwort ist zu kurz',
        Photo: 'Foto',
        'Please confirm all information has been provided before sending this form. Are you sure?': 'Bitte bestätigen Sie, dass alle Informationen angegeben wurden, bevor Sie dieses Formular absenden. Bist du sicher?',
        'Please wait...': 'Bitte warten...',
        'Postcode is not valid.': 'Die Postleitzahl ist ungültig.',
        'Press here to return to Clocking screen': 'Drücken Sie hier, um zum Uhrzeitbildschirm zurückzukehren',
        Processing: 'wird bearbeitet',
        'Recovered - Unsaved form': 'Wiederhergestellt – nicht gespeichertes Formular',
        Rejected: 'Abgelehnt',
        Requested: 'Angefordert',
        Returned: 'Ist zurückgekommen',
        'Returned from': 'Zurückgekehrt von',
        'Saved as Draft': 'Als Entwurf gespeichert',
        'Saving as Draft': 'Als Entwurf speichern',
        Savomg: 'Savomg',
        Search: 'Suchen',
        Send: 'Schicken',
        'Send a copy to me': 'Schicken Sie mir eine Kopie',
        'Send Form': 'Sende Formular',
        'Send Now': 'Schick jetzt',
        Sending: 'Senden',
        'Sending to': 'Senden an',
        'Sending to': 'Senden an',
        'Sent to': 'Gesendet an',
        'Share Form via Email': 'Formular per E-Mail teilen',
        'Share via Email': 'Per E-Mail teilen',
        Shared: 'Geteilt',
        'Something went wrong': 'Etwas ist schief gelaufen',
        Started: 'Gestartet',
        'There are no': 'Es gibt keine',
        'There are no Documents within this search. Please try again or clear the search': 'Diese Suche enthält keine Dokumente. Bitte versuchen Sie es erneut oder löschen Sie die Suche',
        'There was a problem downloading the selected file, please check your connection and try again.': 'Beim Herunterladen der ausgewählten Datei ist ein Problem aufgetreten. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.',
        'There was a problem saving this field. Please try again, if the problem continues, contact support.': 'Beim Speichern dieses Feldes ist ein Problem aufgetreten. Bitte versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich an den Support.',
        'There was a problem saving this repeater field. Please try again, if the problem continues, contact support.': 'Beim Speichern dieses Repeater-Feldes ist ein Problem aufgetreten. Bitte versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich an den Support.',
        'There was an error encountered processing this photo. Please try again.': 'Bei der Verarbeitung dieses Fotos ist ein Fehler aufgetreten. Bitte versuche es erneut.',
        'There was an error processing this video. Please try again.': 'Bei der Verarbeitung dieses Videos ist ein Fehler aufgetreten. Bitte versuche es erneut.',
        'These options are based on your selection, please provide the required field first:': 'Diese Optionen basieren auf Ihrer Auswahl. Bitte füllen Sie zuerst das erforderliche Feld aus:',
        'This Form is Confidential': 'Dieses Formular ist vertraulich',
        'This list does not contain any options. Please update your list and try again.': 'Diese Liste enthält keine Optionen. Bitte aktualisieren Sie Ihre Liste und versuchen Sie es erneut.',
        'This option has not been configured with a Form Target. Please check the setup or contact your administrator.': 'Diese Option wurde nicht mit einem Formularziel konfiguriert. Bitte überprüfen Sie die Einrichtung oder wenden Sie sich an Ihren Administrator.',
        'This QR Code does not contain the expected Culverdocs login details, you may have scanned an incorrect QR Code.': 'Dieser QR-Code enthält nicht die erwarteten Culverdocs-Anmeldedaten. Möglicherweise haben Sie einen falschen QR-Code gescannt.',
        'This will permanently delete the form, are you sure?': 'Dadurch wird das Formular dauerhaft gelöscht. Sind Sie sicher?',
        Title: 'Titel',
        'Title Search': 'Titelsuche',
        Types: 'Typen',
        'Unable to download': 'Download nicht möglich',
        'Unable to open this form as it cannot be retrieved.': 'Dieses Formular kann nicht geöffnet werden, da es nicht abgerufen werden kann.',
        'Unable to save your Illustration, please try again.': 'Ihre Illustration konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.',
        'Unable to upload': 'Hochladen nicht möglich',
        Uploading: 'Hochladen',
        'Welcome to': 'Willkommen zu',
        'Welcome to Culverdocs, enter your details below to continue': 'Willkommen bei Culverdocs. Geben Sie unten Ihre Daten ein, um fortzufahren',
        'Welcome to Culverdocs, scan your QR code to continue': 'Willkommen bei Culverdocs. Scannen Sie Ihren QR-Code, um fortzufahren',
        'Would you like to share this form via email? The recipient cannot change any information already provided by you. Enter the email address below to continue.': 'Möchten Sie dieses Formular per E-Mail teilen? Der Empfänger kann die von Ihnen bereits gemachten Angaben nicht ändern. Geben Sie unten die E-Mail-Adresse ein, um fortzufahren.',
        'You are unable to send this form as there are incomplete fields': 'Sie können dieses Formular nicht senden, da die Felder unvollständig sind',
        'You are unable to upload files as you are currently offline. Please check your connection and try again.': 'Sie können keine Dateien hochladen, da Sie derzeit offline sind. Bitte überprüfe deine Verbindung und versuche es erneut.',
        'You are unable to upload this video this as you are currently offline. Please check your connection and try again.': 'Sie können dieses Video nicht hochladen, da Sie derzeit offline sind. Bitte überprüfe deine Verbindung und versuche es erneut.',
        'You are unable to view this as you are currently offline. Please check your connection and try again.': 'Sie können dies nicht sehen, da Sie derzeit offline sind. Bitte überprüfe deine Verbindung und versuche es erneut.',
        'You cannot login as the account is already in use on another device. Please contact your system administrator.': 'Sie können sich nicht anmelden, da das Konto bereits auf einem anderen Gerät verwendet wird. Bitte kontaktieren Sie Ihren Systemadministrator.',
        'You cannot upload this file as it exceeds the maximum file size:': 'Sie können diese Datei nicht hochladen, da sie die maximale Dateigröße überschreitet:',
        'You do not have any Documents to view': 'Sie haben keine Dokumente zum Anzeigen',
        'You do not have any forms within this filter. Please try again or clear your filtered list': 'In diesem Filter sind keine Formulare vorhanden. Bitte versuchen Sie es erneut oder löschen Sie Ihre gefilterte Liste',
        'You have already updated this photo, would you like to open the Orignal or the Current version?': 'Sie haben dieses Foto bereits aktualisiert. Möchten Sie das Original oder die aktuelle Version öffnen?',
        'You must complete all required fields before creating a new repeater.': 'Sie müssen alle erforderlichen Felder ausfüllen, bevor Sie einen neuen Repeater erstellen.',
        'You will no longer be able to access completed or new forms. Are you sure?': 'Sie haben dann keinen Zugriff mehr auf ausgefüllte oder neue Formulare. Bist du sicher?'
    };
    translations['it'] = {
        'Account in use': 'Conto in uso',
        'An error exists on the form and cannot be sent. Please check the form to resolve the below error and try again': "È presente un errore nel modulo e non può essere inviato. Controlla il modulo per risolvere l'errore riportato di seguito e riprova",
        'An error has occured, please try again.': 'Si è verificato un errore, riprova.',
        Apply: 'Fare domanda a',
        Cancel: 'Annulla',
        Clear: 'Chiaro',
        'Close Form': 'Chiudi modulo',
        Complete: 'Completare',
        'Confidential Form': 'Modulo riservato',
        'Delete Form': 'Elimina modulo',
        Deleting: 'Eliminazione',
        Documents: 'Documenti',
        Done: 'Fatto',
        'Download Failed': 'Scaricamento fallito',
        Drafts: 'bozze',
        'Email Address': 'Indirizzo e-mail',
        'Email Address is required': "L'indirizzo e-mail è obbligatorio",
        'Enter your PIN to continue': 'Inserisci il tuo PIN per continuare',
        Error: 'Errore',
        'Error saving': 'Errore durante il salvataggio',
        'Execute Query': 'Esegui interrogazione',
        'Existing Illustration': 'Illustrazione esistente',
        'Express Login': 'Accesso rapido',
        Filter: 'Filtro',
        'Forgot your login details? Contact your system administrator.': "Hai dimenticato i tuoi dati di accesso? Contatta l'amministratore di sistema.",
        Forms: 'Forme',
        Home: 'Casa',
        'Incorrect PIN Code': 'Codice PIN errato',
        'Invalid Email Address': 'Indirizzo email non valido',
        'items in this view': 'elementi in questa visualizzazione',
        'Large File': 'File di grandi dimensioni',
        'Logging Out...': 'Disconnessione...',
        'Login Attempts Blocked': 'Tentativi di accesso bloccati',
        'Login Details Incorrect': 'Dettagli di accesso errati',
        'Login with Email': "Accedi con l'e-mail",
        Logout: 'Disconnettersi',
        'Missing Form Target': 'Destinazione modulo mancante',
        'Missing Information': 'Informazione mancante',
        'Missing Required Field(s)': 'Campi obbligatori mancanti',
        'Missing Selection': 'Selezione mancante',
        'My Forms': 'I miei moduli',
        'New Form': 'Nuova forma',
        No: 'NO',
        'No Connection': 'Nessuna connessione',
        'No forms to display': 'Nessun modulo da visualizzare',
        'No List Options': 'Nessuna opzione di elenco',
        OK: 'OK',
        Options: 'Opzioni',
        'Outcode is not valid.': 'Il codice esterno non è valido.',
        Password: "Parola d'ordine",
        'Password is required': "E 'richiesta la password",
        'Password is too short': 'La password è troppo corta',
        Photo: 'Foto',
        'Please confirm all information has been provided before sending this form. Are you sure?': 'Si prega di confermare che tutte le informazioni sono state fornite prima di inviare questo modulo. Sei sicuro?',
        'Please wait...': 'Attendere prego...',
        'Postcode is not valid.': 'Il codice postale non è valido.',
        'Press here to return to Clocking screen': 'Premere qui per tornare alla schermata Orologio',
        Processing: 'in lavorazione',
        'Recovered - Unsaved form': 'Recuperato: modulo non salvato',
        Rejected: 'Respinto',
        Requested: 'Richiesto',
        Returned: 'Restituito',
        'Returned from': 'Ritornato da',
        'Saved as Draft': 'Salvato come bozza',
        'Saving as Draft': 'Salvataggio come bozza',
        Savomg: 'Savomg',
        Search: 'Ricerca',
        Send: 'Inviare',
        'Send a copy to me': 'Mandamene una copia',
        'Send Form': 'Invia modulo',
        'Send Now': 'Spedisci ora',
        Sending: 'Invio',
        'Sending to': 'Invio a',
        'Sending to': 'Invio a',
        'Sent to': 'Inviato a',
        'Share Form via Email': 'Condividi il modulo tramite e-mail',
        'Share via Email': 'Condividi tramite e-mail',
        Shared: 'Condiviso',
        'Something went wrong': 'Qualcosa è andato storto',
        Started: 'Iniziato',
        'There are no': 'Non ci sono',
        'There are no Documents within this search. Please try again or clear the search': 'Non ci sono documenti in questa ricerca. Riprova o cancella la ricerca',
        'There was a problem downloading the selected file, please check your connection and try again.': 'Si è verificato un problema durante il download del file selezionato, controlla la connessione e riprova.',
        'There was a problem saving this field. Please try again, if the problem continues, contact support.': "Si è verificato un problema durante il salvataggio di questo campo. Riprova, se il problema persiste contatta l'assistenza.",
        'There was a problem saving this repeater field. Please try again, if the problem continues, contact support.': "Si è verificato un problema durante il salvataggio di questo campo ripetitore. Riprova, se il problema persiste contatta l'assistenza.",
        'There was an error encountered processing this photo. Please try again.': "Si è verificato un errore durante l'elaborazione di questa foto. Per favore riprova.",
        'There was an error processing this video. Please try again.': "Si è verificato un errore durante l'elaborazione di questo video. Per favore riprova.",
        'These options are based on your selection, please provide the required field first:': 'Queste opzioni si basano sulla tua selezione, fornisci prima il campo obbligatorio:',
        'This Form is Confidential': 'Questo modulo è confidenziale',
        'This list does not contain any options. Please update your list and try again.': "Questo elenco non contiene alcuna opzione. Aggiorna l'elenco e riprova.",
        'This option has not been configured with a Form Target. Please check the setup or contact your administrator.': "Questa opzione non è stata configurata con una destinazione del modulo. Controlla la configurazione o contatta l'amministratore.",
        'This QR Code does not contain the expected Culverdocs login details, you may have scanned an incorrect QR Code.': 'Questo codice QR non contiene i dettagli di accesso Culverdocs previsti, potresti aver scansionato un codice QR errato.',
        'This will permanently delete the form, are you sure?': 'Il modulo verrà eliminato definitivamente. Sei sicuro?',
        Title: 'Titolo',
        'Title Search': 'Ricerca del titolo',
        Types: 'Tipi',
        'Unable to download': 'Impossibile eseguire il download',
        'Unable to open this form as it cannot be retrieved.': 'Impossibile aprire questo modulo perché non può essere recuperato.',
        'Unable to save your Illustration, please try again.': "Impossibile salvare l'illustrazione, riprova.",
        'Unable to upload': 'Impossibile caricare',
        Uploading: 'Caricamento in corso',
        'Welcome to': 'Benvenuto a',
        'Welcome to Culverdocs, enter your details below to continue': 'Benvenuto in Culverdocs, inserisci i tuoi dati qui sotto per continuare',
        'Welcome to Culverdocs, scan your QR code to continue': 'Benvenuto in Culverdocs, scansiona il tuo codice QR per continuare',
        'Would you like to share this form via email? The recipient cannot change any information already provided by you. Enter the email address below to continue.': "Desideri condividere questo modulo via e-mail? Il destinatario non può modificare le informazioni già fornite da te. Inserisci l'indirizzo email qui sotto per continuare.",
        'You are unable to send this form as there are incomplete fields': 'Non puoi inviare questo modulo perché ci sono campi incompleti',
        'You are unable to upload files as you are currently offline. Please check your connection and try again.': 'Non puoi caricare file perché sei attualmente offline. Controlla la connessione e riprova.',
        'You are unable to upload this video this as you are currently offline. Please check your connection and try again.': 'Non puoi caricare questo video perché sei attualmente offline. Controlla la connessione e riprova.',
        'You are unable to view this as you are currently offline. Please check your connection and try again.': 'Non puoi visualizzarlo poiché sei attualmente offline. Controlla la connessione e riprova.',
        'You cannot login as the account is already in use on another device. Please contact your system administrator.': "Non puoi accedere perché l'account è già in uso su un altro dispositivo. Contatta l'amministratore di sistema.",
        'You cannot upload this file as it exceeds the maximum file size:': 'Non puoi caricare questo file poiché supera la dimensione massima del file:',
        'You do not have any Documents to view': 'Non hai documenti da visualizzare',
        'You do not have any forms within this filter. Please try again or clear your filtered list': "Non sono presenti moduli in questo filtro. Riprova o cancella l'elenco filtrato",
        'You have already updated this photo, would you like to open the Orignal or the Current version?': 'Hai già aggiornato questa foto, vuoi aprire la versione originale o quella corrente?',
        'You must complete all required fields before creating a new repeater.': 'È necessario completare tutti i campi obbligatori prima di creare un nuovo ripetitore.',
        'You will no longer be able to access completed or new forms. Are you sure?': 'Non sarai più in grado di accedere ai moduli completati o nuovi. Sei sicuro?'
    };
    translations['fr'] = {
        'Account in use': 'Compte utilisé',
        'An error exists on the form and cannot be sent. Please check the form to resolve the below error and try again': "Une erreur existe sur le formulaire et ne peut être envoyé. Veuillez vérifier le formulaire pour résoudre l'erreur ci-dessous et réessayer",
        'An error has occured, please try again.': "Une erreur s'est produite, veuillez réessayer.",
        Apply: 'Appliquer',
        Cancel: 'Annuler',
        Clear: 'Clair',
        'Close Form': 'Fermer le formulaire',
        Complete: 'Complet',
        'Confidential Form': 'Formulaire confidentiel',
        'Delete Form': 'Supprimer le formulaire',
        Deleting: 'Suppression',
        Documents: 'Documents',
        Done: 'Fait',
        'Download Failed': 'Échec du téléchargement',
        Drafts: 'Brouillons',
        'Email Address': 'Adresse e-mail',
        'Email Address is required': 'Adresse e-mail est nécessaire',
        'Enter your PIN to continue': 'Entrez votre code PIN pour continuer',
        Error: 'Erreur',
        'Error saving': "Erreur lors de l'enregistrement",
        'Execute Query': "Exécuter l'ordre",
        'Existing Illustration': 'Illustration existante',
        'Express Login': 'Connexion express',
        Filter: 'Filtre',
        'Forgot your login details? Contact your system administrator.': 'Vous avez oublié vos identifiants de connexion? Contactez votre administrateur système.',
        Forms: 'Formes',
        Home: 'Maison',
        'Incorrect PIN Code': 'Code PIN incorrect',
        'Invalid Email Address': 'Adresse e-mail invalide',
        'items in this view': 'éléments dans cette vue',
        'Large File': 'Fichier volumineux',
        'Logging Out...': 'Déconnecter...',
        'Login Attempts Blocked': 'Tentatives de connexion bloquées',
        'Login Details Incorrect': 'Informations de connexion incorrectes',
        'Login with Email': 'Connectez-vous avec e-mail',
        Logout: 'Se déconnecter',
        'Missing Form Target': 'Cible de formulaire manquante',
        'Missing Information': 'Information manquante',
        'Missing Required Field(s)': 'Champs obligatoires manquants',
        'Missing Selection': 'Sélection manquante',
        'My Forms': 'Mes formulaires',
        'New Form': 'Nouvelle forme',
        No: 'Non',
        'No Connection': 'Pas de connection',
        'No forms to display': 'Aucun formulaire à afficher',
        'No List Options': 'Aucune option de liste',
        OK: "D'ACCORD",
        Options: 'Possibilités',
        'Outcode is not valid.': "Le code sortant n'est pas valide.",
        Password: 'Mot de passe',
        'Password is required': 'Mot de passe requis',
        'Password is too short': 'Le mot de passe est trop court',
        Photo: 'Photo',
        'Please confirm all information has been provided before sending this form. Are you sure?': "Veuillez confirmer que toutes les informations ont été fournies avant d'envoyer ce formulaire. Es-tu sûr?",
        'Please wait...': "S'il vous plaît, attendez...",
        'Postcode is not valid.': "Le code postal n'est pas valide.",
        'Press here to return to Clocking screen': "Appuyez ici pour revenir à l'écran de pointage",
        Processing: 'Traitement',
        'Recovered - Unsaved form': 'Récupéré - Formulaire non enregistré',
        Rejected: 'Rejeté',
        Requested: 'Demandé',
        Returned: 'Revenu',
        'Returned from': 'Retour de',
        'Saved as Draft': 'Enregistré en tant que brouillon',
        'Saving as Draft': 'Enregistrer en tant que brouillon',
        Savomg: 'Savomg',
        Search: 'Recherche',
        Send: 'Envoyer',
        'Send a copy to me': 'Envoyez-moi une copie',
        'Send Form': 'Envoyer le formulaire',
        'Send Now': 'Envoyer maintenant',
        Sending: 'Envoi en cours',
        'Sending to': 'Envoi à',
        'Sending to': 'Envoi à',
        'Sent to': 'Envoyé à',
        'Share Form via Email': 'Partager le formulaire par e-mail',
        'Share via Email': 'Partager par e-mail',
        Shared: 'partagé',
        'Something went wrong': "Quelque chose s'est mal passé",
        Started: 'Commencé',
        'There are no': "Il n'y a pas",
        'There are no Documents within this search. Please try again or clear the search': "Il n'y a aucun document dans cette recherche. Veuillez réessayer ou effacer la recherche",
        'There was a problem downloading the selected file, please check your connection and try again.': 'Un problème est survenu lors du téléchargement du fichier sélectionné, veuillez vérifier votre connexion et réessayer.',
        'There was a problem saving this field. Please try again, if the problem continues, contact support.': "Un problème est survenu lors de l'enregistrement de ce champ. Veuillez réessayer, si le problème persiste, contactez l'assistance.",
        'There was a problem saving this repeater field. Please try again, if the problem continues, contact support.': "Un problème est survenu lors de l'enregistrement de ce champ de répéteur. Veuillez réessayer, si le problème persiste, contactez l'assistance.",
        'There was an error encountered processing this photo. Please try again.': "Une erreur s'est produite lors du traitement de cette photo. Veuillez réessayer.",
        'There was an error processing this video. Please try again.': "Une erreur s'est produite lors du traitement de cette vidéo. Veuillez réessayer.",
        'These options are based on your selection, please provide the required field first:': "Ces options sont basées sur votre sélection, veuillez d'abord fournir le champ requis :",
        'This Form is Confidential': 'Ce formulaire est confidentiel',
        'This list does not contain any options. Please update your list and try again.': 'Cette liste ne contient aucune option. Veuillez mettre à jour votre liste et réessayer.',
        'This option has not been configured with a Form Target. Please check the setup or contact your administrator.': "Cette option n'a pas été configurée avec une cible de formulaire. Veuillez vérifier la configuration ou contacter votre administrateur.",
        'This QR Code does not contain the expected Culverdocs login details, you may have scanned an incorrect QR Code.': 'Ce QR Code ne contient pas les informations de connexion Culverdocs attendues, vous avez peut-être scanné un QR Code incorrect.',
        'This will permanently delete the form, are you sure?': 'Cela supprimera définitivement le formulaire, en êtes-vous sûr ?',
        Title: 'Titre',
        'Title Search': 'Recherche de titre',
        Types: 'Les types',
        'Unable to download': 'Incapable de télécharger',
        'Unable to open this form as it cannot be retrieved.': "Impossible d'ouvrir ce formulaire car il ne peut pas être récupéré.",
        'Unable to save your Illustration, please try again.': "Impossible d'enregistrer votre illustration, veuillez réessayer.",
        'Unable to upload': 'Impossible de télécharger',
        Uploading: 'Téléchargement',
        'Welcome to': 'Bienvenue à',
        'Welcome to Culverdocs, enter your details below to continue': 'Bienvenue sur Culverdocs, entrez vos coordonnées ci-dessous pour continuer',
        'Welcome to Culverdocs, scan your QR code to continue': 'Bienvenue sur Culverdocs, scannez votre code QR pour continuer',
        'Would you like to share this form via email? The recipient cannot change any information already provided by you. Enter the email address below to continue.': "Souhaitez-vous partager ce formulaire par e-mail ? Le destinataire ne peut modifier aucune information déjà fournie par vous. Entrez l'adresse e-mail ci-dessous pour continuer.",
        'You are unable to send this form as there are incomplete fields': 'Vous ne pouvez pas envoyer ce formulaire car certains champs sont incomplets',
        'You are unable to upload files as you are currently offline. Please check your connection and try again.': 'Vous ne pouvez pas télécharger de fichiers car vous êtes actuellement hors ligne. Veuillez vérifier votre connexion et réessayer.',
        'You are unable to upload this video this as you are currently offline. Please check your connection and try again.': 'Vous ne pouvez pas télécharger cette vidéo car vous êtes actuellement hors ligne. Veuillez vérifier votre connexion et réessayer.',
        'You are unable to view this as you are currently offline. Please check your connection and try again.': 'Vous ne pouvez pas voir ceci car vous êtes actuellement hors ligne. Veuillez vérifier votre connexion et réessayer.',
        'You cannot login as the account is already in use on another device. Please contact your system administrator.': 'Vous ne pouvez pas vous connecter car le compte est déjà utilisé sur un autre appareil. Veuillez contacter votre administrateur système.',
        'You cannot upload this file as it exceeds the maximum file size:': 'Vous ne pouvez pas télécharger ce fichier car il dépasse la taille maximale :',
        'You do not have any Documents to view': "Vous n'avez aucun document à consulter",
        'You do not have any forms within this filter. Please try again or clear your filtered list': "Vous n'avez aucun formulaire dans ce filtre. Veuillez réessayer ou effacer votre liste filtrée",
        'You have already updated this photo, would you like to open the Orignal or the Current version?': 'Vous avez déjà mis à jour cette photo, souhaitez-vous ouvrir la version originale ou actuelle ?',
        'You must complete all required fields before creating a new repeater.': 'Vous devez remplir tous les champs obligatoires avant de créer un nouveau répéteur.',
        'You will no longer be able to access completed or new forms. Are you sure?': 'Vous ne pourrez plus accéder aux formulaires complétés ou nouveaux. Es-tu sûr?'
    };

    // Return the specified translations
    return translations[language];
}
