import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { CulverdocsButton } from '@src/Component/CulverdocsButton';
import { translate } from '../../../Common/CulverdocsTranslate';
import Stylesheet from 'reactjs-stylesheet';
import Font from '../../../Constant/font';
import Size from '../../../Constant/size';
import Colours from '../../../Constant/colour';

import { CulverdocsButton } from '../../../Component/CulverdocsButton';
import { Form } from 'react-bootstrap';
import CulverdocsFieldCaption from '../FormFields/_CulverdocsFieldCaption';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';

export const CulverdocsModalOptions = (props) => {
    const styles = getStyle(props.brandData);

    const [modalType, setModalType] = useState();
    const [shareEmailAddress, setShareEmailAddress] = useState();
    const [shareSendCopyToMe, setShareSendCopyToMe] = useState();
    const onCloseModal = () => {
        setModalType('main'); //Set the type back to main for next time we open
        props.onCloseModal();
    };

    const toggleShareSendCopyToMe = () => {
        setShareSendCopyToMe(!shareSendCopyToMe);
    };

    // Primary Menu -
    if (!modalType || modalType === 'main') {
        return (
            // <Modal
            //     centered
            //     show={props.showCulverdocsModalOptions}
            // >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    backgroundColor: Colours.MODALBG,
                    evelation: 4,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        // maxWidth: 400,
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: props.brandData?.primarycolour,
                        backgroundColor: Colours.WHITE
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            height: 50,
                            flexDirection: 'row',
                            alignContent: 'center',
                            borderTopRightRadius: 5,
                            borderTopLeftRadius: 5,
                            backgroundColor: props.brandData?.primarycolour,
                            borderBottomWidth: 0,
                            paddingRight: 0,
                            paddingTop: 10
                        }}
                    >
                        <p style={styles.renderOptionModalHeaderp}>{translate('Options')}</p>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: 10,
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignSelf: 'center',
                            paddingRight: 10,
                            paddingLeft: 10
                        }}
                    >
                        {props.showShareViaEmail && (
                            <CulverdocsButton
                                title={translate('Share via Email')}
                                icon={'share-from-square'}
                                onClick={() => {
                                    // 2023-10-07 (JJ): NFE - Support Shared via Email Address Source, now retrieves the field and/or select listitem to default the email address in the modal via callback
                                    setModalType('share');
                                    setShareEmailAddress(props.returnShareViaEmailAddress());
                                }}
                                extraStyle={{ backgroundColor: Colours.GREEN }}
                            />
                        )}

                        {props.showDelete && (
                            <CulverdocsButton
                                title={translate('Delete Form')}
                                icon={'circle-trash'}
                                onClick={() => {
                                    setModalType('delete');
                                }}
                                extraStyle={{ backgroundColor: Colours.DARKRED }}
                            />
                        )}

                        <CulverdocsButton
                            title={translate('Close Form')}
                            icon={'circle-xmark'}
                            extraStyle={{ backgroundColor: props.brandData?.primarycolour }}
                            onClick={() => {
                                onCloseModal();
                                props.onGoBack();
                            }}
                        />
                    </div>
                </div>
            </div>
            // </Modal>
        );
    }
    // Delete Menu
    if (modalType === 'delete') {
        return (
            // <Modal
            //     centered
            //     show={props.showCulverdocsModalOptions}
            // >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    backgroundColor: Colours.MODALBG,
                    evelation: 4,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        //  maxWidth: 400,
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: props.brandData?.primarycolour,
                        backgroundColor: Colours.WHITE
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: 50,
                            alignItems: 'center',
                            borderTopRightRadius: 5,
                            borderTopLeftRadius: 5,
                            backgroundColor: props.brandData?.primarycolour,
                            borderBottomWidth: 0,
                            paddingRight: 0
                        }}
                    >
                        <div
                            hitslop={{
                                left: Size.CountScale(10),
                                right: Size.CountScale(10),
                                bottom: Size.CountScale(10),
                                top: Size.CountScale(10)
                            }}
                            onClick={() => {
                                onCloseModal();
                            }}
                        >
                            <FontAwesomeIcon
                                icon={['fad', 'circle-chevron-left']}
                                color={Colours.WHITE}
                                style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer', width: 25, height: 25 }}
                            />
                        </div>
                        <p style={styles.renderOptionModalHeaderp}>{translate('Delete Form')}</p>
                    </div>

                    <div
                        style={{
                            padding: 10,
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignSelf: 'center'
                        }}
                    >
                        <p style={styles.modalp}>{translate('This will permanently delete the form, are you sure?')}</p>
                        <CulverdocsButton
                            title={translate('Delete Form')}
                            icon={'circle-trash'}
                            extraStyle={{ backgroundColor: Colours.DARKRED }}
                            onClick={() => {
                                onCloseModal();
                                props.onDeleteForm();
                            }}
                        />
                    </div>
                </div>
            </div>
            // </Modal>
        );
    }

    if (modalType === 'share') {
        return (
            // <Modal
            //     centered
            //     show={props.showCulverdocsModalOptions}
            // >
            <div
                style={{
                    flex: 1,
                    backgroundColor: Colours.MODALBG,
                    evelation: 4,
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: props.brandData?.primarycolour,
                        backgroundColor: Colours.WHITE
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            height: 50,
                            flexDirection: 'row',
                            alignItems: 'center',
                            borderTopRightRadius: 5,
                            borderTopLeftRadius: 5,
                            backgroundColor: props.brandData?.primarycolour,
                            borderBottomWidth: 0,
                            paddingRight: 0
                        }}
                    >
                        <div
                            hitslop={{
                                left: Size.CountScale(10),
                                right: Size.CountScale(10),
                                bottom: Size.CountScale(10),
                                top: Size.CountScale(10)
                            }}
                            onClick={() => {
                                onCloseModal();
                            }}
                        >
                            <FontAwesomeIcon
                                icon={['fad', 'circle-chevron-left']}
                                color={Colours.WHITE}
                                style={{ marginRight: 10, marginLeft: 10, cursor: 'pointer', width: 25, height: 25 }}
                            />
                        </div>
                        <p style={styles.renderOptionModalHeaderp}>{translate('Share Form via Email')}</p>
                    </div>

                    <div
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 10
                        }}
                    >
                        <p style={styles.modalp}>{translate('Would you like to share this form via email? The recipient cannot change any information already provided by you. Enter the email address below to continue.')}</p>
                        <div style={{ width: '100%', padding: 20 }}>
                            <CulverdocsFieldCaption
                                caption={translate('Email Address')}
                                mandatory={true}
                                editable={true}
                                hasValue={shareEmailAddress ? true : false}
                            />

                            <Form.Control
                                style={styles.fieldText}
                                value={shareEmailAddress}
                                onChange={(e) => {
                                    setShareEmailAddress(e.target.value); // 2024-08-28 (RB): Todo
                                }}
                                onBlur={() => {}}
                            />

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                    <div
                                        onClick={toggleShareSendCopyToMe}
                                        style={styles.checkboxContainer}
                                    >
                                        <div style={{ display: 'flex', flex: 0.1 }}>
                                            {!shareSendCopyToMe && (
                                                <FontAwesomeIcon
                                                    icon={['fal', 'square']}
                                                    color={props.brandData?.primarycolour}
                                                    style={{
                                                        alignSelf: 'center',
                                                        width: 25,
                                                        height: 25
                                                    }}
                                                />
                                            )}

                                            {shareSendCopyToMe && (
                                                <FontAwesomeIcon
                                                    icon={['fad', 'square-check']}
                                                    color={props.brandData?.primarycolour}
                                                    style={{
                                                        alignSelf: 'center',
                                                        width: 25,
                                                        height: 25
                                                    }}
                                                />
                                            )}
                                        </div>

                                        <p style={styles.checkboxp}>
                                            <p style={styles.checkboxCaption}>{translate('Send a copy to me')}</p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CulverdocsButton
                            title={translate('Send')}
                            icon={'envelope'}
                            extraStyle={{ ...(shareEmailAddress ? { backgroundColor: Colours.GREEN } : { backgroundColor: Colours.GREY }) }}
                            onClick={() => {
                                //Only attempt send if the email address has been populated.
                                if (shareEmailAddress !== '') {
                                    props.onFormShareViaEmail(shareEmailAddress, shareSendCopyToMe);
                                    onCloseModal();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            // </Modal>
        );
    }
};

// ====STYLES DECLARATION======//
const getStyle = (brandData) => {
    return Stylesheet.create({
        renderOptionModalHeaderp: {
            fontFamily: Font.family.bold,
            fontSize: Font.size.large,
            color: Colours.WHITE,
            width: '80%', //Fix for the caption ellipsis overlapping the modal window.,
            margin: 0,
            marginLeft: 10
        },
        modalp: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BACKGROUND,
            textAlign: 'center',
            paddingHorizontal: 10,
            marginTop: 10
        },
        fieldText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
            marginBottom: 10,
            paddingTop: 10, //for iOS
            paddingBottom: 10, //for iOS
            paddingLeft: 5, //for iOS

            height: 45,
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            borderRadius: 5,
            elevation: 5
        },
        fieldp: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
            // borderBottomWidth: 1,
            // borderColor:brandData?.primarycolour,
            backgroundColor: Colours.LIGHTGREY,
            marginBottom: 10,
            paddingTop: 10, //for iOS
            paddingBottom: 10, //for iOS
            paddingLeft: 5, //for iOS

            height: 45,
            borderWidth: 0.5,
            borderColor: brandData?.primarycolour,
            borderRadius: 5,
            elevation: 5
        },

        checkboxContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK,
            backgroundColor: Colours.LIGHTGREY,
            marginBottom: 10,
            // marginTop:10,
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 5,

            height: 45,
            borderWidth: 0.5,
            borderColor: brandData?.primarycolour,
            borderRadius: 5,
            elevation: 5
        },
        checkboxp: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BACKGROUND,
            flex: 0.9,
            top: 2,
            paddingLeft: 5,
            margin: 0
        },
        checkboxCaption: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK,
            margin: 0
        }
    });
};
