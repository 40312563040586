import React, { Component } from 'react';
// import { ActivityIndicator, p, div, div, AppState, Image, Modal, div, Alert, Dimensions, StyleSheet } from 'react-native';
// import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
// import ImagePicker from 'react-native-image-crop-picker';
// import ImageResizer from 'react-native-image-resizer';
// import { CameraRoll } from '@react-native-camera-roll/camera-roll'; // 2023-07-26 (JJ): NFE - Support for saving new photos in the Gallery / Camera Roll on device - using photostoreingallery
// import { lightenDarkenColor } from '@src/Helper/lightenDarkenColor';
// import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
// import { Colours, Font, Size } from '@src/Constant';
// import { connect } from 'react-redux';
// import { translate } from '@src/Helper/CulverdocsTranslate';
// import * as RNFS from '@dr.pogodin/react-native-fs';
// import ImageModal from 'react-native-image-modal'; // 2024-04-23 (RB): Re -Implement photo modal with pinch and zoom feature

// import CulverdocsIllustrationComponent from './CulverdocsIllustrationComponent';

import Font from '../../../Constant/font';
import Size from '../../../Constant/size';
import Resizer from 'react-image-file-resizer';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { translate } from '../../../Common/CulverdocsTranslate';
import FlatList from 'flatlist-react';
import { Camera } from 'react-camera-pro';
import Webcam from 'react-webcam';
import ClipLoader from 'react-spinners/ClipLoader';
import FadeLoader from 'react-spinners/FadeLoader';
import { Spinner } from '../../../Common/Loader';
import CulverdocsIllustrationComponent from './CulverdocsIllustrationComponent';

// 2024-01-07 (JJ): Fix for field Class names: Photo & RepeaterChild

class CulverdocsPhoto extends Component {
    state = {
        showCulverdocsPhotoModal: false,
        showIllustrationModal: false, // 2024-01-09 (JJ): Photo now supports Illustrations - No / Yes / Auto & modal tidy up
        selectedPhotoSrc: {},
        selectedPhotoSrcIndex: null,
        renderCameraModal: false
    };

    shouldComponentUpdate(prevProps, prevState) {
        // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
        return this.state !== prevState || this.props.visible !== prevProps.visible || this.props.itemValue !== prevProps.itemValue || this.props.caption !== prevProps.caption;
    }
    render() {
        // const RNFS = require("fs");

        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption} `);
        const styles = getStyle(this.props.brandData);

        if (!this.props.visible) {
            return null;
        }

        const items = [];
        if (this.props.item[this.props.item.id] == undefined) {
            this.props.item[this.props.item.id] = [];
        }

        // 2023-07-11 (JJ): Fix for Photo storage in array, min photos handling and field validation rework.
        if (this.props.item.value == undefined) {
            this.props.item.value = {}; //Init array if this is the first photo
        }

        if (this.props.item.mandatory && this.props.item.minvalue == '0') {
            this.props.item.minvalue = '1';
        }

        let photoCount = 0; // 2022-01-03 (JJ): Manage photoCount variable instead of .length() which may include deleted photos.

        if (this.props.item.value !== undefined) {
            Object.entries(this.props.item.value).map(([photoIndex, photo]) => {
                if (photo && !photo['deleted'] && (photo['photo'] || photo['fbstorage'])) {
                    photoCount++; // 2022-01-03 (JJ): Manage photoCount variable instead of .length() which may include deleted photos.
                }
            });
        }

        let countminmax = '0';
        let metRequiredField = false;
        let maxValueReached = false;
        if (this.props.item.value) {
            if (this.props.item.mandatory) {
                if (photoCount < this.props.item.minvalue) {
                    countminmax = `${photoCount}/${this.props.item.minvalue}`;
                    metRequiredField = false;
                } else {
                    metRequiredField = true;
                    countminmax = `${photoCount}`;
                    if (this.props.item.maxvalue !== 0) {
                        countminmax += `/${this.props.item.maxvalue}`;
                    }
                }
            } else {
                countminmax = `${photoCount}`;
                metRequiredField = photoCount > 0;
            }

            // 2023-12-29 (JJ): Respect Photo MaxValue if the field is not mandatory and greater than zero.
            maxValueReached = this.props.item.maxvalue > 0 && photoCount >= this.props.item.maxvalue;
        }

        return (
            <div>
                <CulverdocsFieldCaption
                    brandData={this.props.brandData}
                    caption={this.props.item.caption}
                    userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                    badge={countminmax}
                    mandatory={this.props.item.mandatory}
                    editable={this.props.editable}
                    hasValue={metRequiredField}
                />

                <div style={styles.photoContainer}>
                    <div
                        horizontal={true}
                        showsHorizontalScrollIndicator={true}
                        style={{ display: 'flex' }}
                    >
                        {this.props.editable && !maxValueReached && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                    justifyContent: 'space-evenly'
                                }}
                            >
                                {(this.props.item.photosource == 'Both' || this.props.item.photosource == 'Camera') && (
                                    <div onClick={() => this.openCamera(this.props.item)}>
                                        <FontAwesomeIcon
                                            icon={['fad', 'camera-alt']}
                                            style={{
                                                width: 30,
                                                height: 30
                                            }}
                                            color={this.props.brandData?.primarycolour}
                                        />
                                    </div>
                                )}

                                {/* Gallery Button */}
                                {(this.props.item.photosource == 'Both' || this.props.item.photosource == 'Gallery') && (
                                    <div onClick={() => this.openGallery(this.props.item)}>
                                        <FontAwesomeIcon
                                            icon={['fad', 'images']}
                                            style={{
                                                width: 30,
                                                height: 30
                                            }}
                                            color={this.props.brandData?.primarycolour}
                                        />
                                        <Form.Control
                                            ref={(ref) => (this.state.gallerRef = ref)}
                                            type="file"
                                            style={{ border: '0px solid' }}
                                            hidden={true}
                                            id="gallery"
                                            accept="image/*"
                                            onChange={(event) => {
                                                const file = event.target.files[0];
                                                if (file) {
                                                    const reader = new FileReader();
                                                    reader.onloadend = () => {
                                                        //  this.setState({ imagePreview: reader.result });
                                                        console.log('data for allery ', reader.result);
                                                        this.addImageToPhotoField(reader.result.split(',')[1], null, 'gallery');
                                                    };
                                                    reader.readAsDataURL(file);
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                        {/* this.returnFieldLengthValid() &&  - v1.1.2 - no longer check for length, we'll catch during posting. */}
                        {this.props.editable && !maxValueReached && <div style={styles.photoContainerVerticalBar} />}

                        {/* Render the Photos */}

                        {/* {  this.props.item.value && this.props.item.value.map((photo,photoIndex) => { */}
                        {this.props.item.value &&
                            Object.entries(this.props.item.value).map(([photoIndex, photo]) => {
                                return (
                                    <div
                                        key={photoIndex}
                                        contentContainerStyle={{
                                            flexDirection: 'row'
                                        }}
                                        horizontal={true}
                                    >
                                        <div
                                            onClick={() => {
                                                if (photo['photo']) {
                                                    const screenWidth = window.innerWidth * 0.9;
                                                    this.setState({
                                                        imageWidth: screenWidth,
                                                        imageHeight: 500,
                                                        showCulverdocsPhotoModal: true,
                                                        selectedPhoto: photo,
                                                        selectedPhotoIndex: photoIndex
                                                    });
                                                }
                                                // TODO: Reinstate download
                                                if (!photo['photo'] && photo['fbstorage']) {
                                                    this.props.downloadMediaFromStorage(photo, 'photo').then(() => {
                                                        this.forceUpdate(); //Update once downloaded
                                                    });
                                                    this.forceUpdate(); //Update when starting download
                                                }
                                            }}
                                        >
                                            {photo && !photo['deleted'] && photo['photo'] && (
                                                <img
                                                    src={'data:image/png;base64,' + photo['photo']}
                                                    resizeMode={'cover'}
                                                    style={styles.fieldPhoto}
                                                    onClick={() => {
                                                        // alert('hey');
                                                        if (photo['photo']) {
                                                            const screenWidth = window.innerWidth * 0.9;
                                                            this.setState({
                                                                imageWidth: screenWidth,
                                                                imageHeight: 500,
                                                                showCulverdocsPhotoModal: true,
                                                                selectedPhoto: photo,
                                                                selectedPhotoIndex: photoIndex
                                                            });
                                                        }
                                                        // TODO: Reinstate download
                                                        if (!photo['photo'] && photo['fbstorage']) {
                                                            this.props.downloadMediaFromStorage(photo, 'photo').then(() => {
                                                                this.forceUpdate(); //Update once downloaded
                                                            });
                                                            this.forceUpdate(); //Update when starting download
                                                        }
                                                    }}
                                                />
                                                // <ImageModal // 2024-04-23 (RB): Re -Implement photo modal with pinch and zoom feature
                                                //     resizeMode="cover"
                                                //     overlayBackgroundColor={
                                                //         Colours.Theme
                                                //     }
                                                //     modalImageResizeMode="contain"
                                                //     imageBackgroundColor="transparent"
                                                //     style={
                                                //         styles.fieldPhoto
                                                //     }
                                                //     //style={{ height: this.state.imageHeight, width: this.state.imageWidth, alignSelf: 'center' }}
                                                //     source={{
                                                //         uri:
                                                //             "data:image/png;base64," +
                                                //             photo[
                                                //                 "photo"
                                                //             ],
                                                //     }}
                                                //     renderHeader={(
                                                //         close
                                                //     ) => (
                                                //         <div
                                                //             style={{
                                                //                 flex: 1,
                                                //                 height: 60,
                                                //                 // maxHeight: 60,
                                                //                 maxWidth:
                                                //                      this
                                                //                         .state
                                                //                         .imageWidth,
                                                //                 // alignSelf: 'stretch',
                                                //                 backgroundColor:
                                                //                     this
                                                //                         .props
                                                //                         .brandData
                                                //                         ?.primarycolour,
                                                //                 borderTopLeftRadius: 5,
                                                //                 borderTopRightRadius: 5,
                                                //                 alignItems:
                                                //                     "center",
                                                //                 justifyContent:
                                                //                     "center",
                                                //                 flexDirection:
                                                //                     "row",
                                                //             }}
                                                //         >
                                                //             <div
                                                //                 style={{
                                                //                     flex: 0.1,
                                                //                     flexDirection:
                                                //                         "row",
                                                //                     justifyContent:
                                                //                         "flex-start",
                                                //                     alignItems:
                                                //                         "center",
                                                //                     paddingLeft: 10,
                                                //                 }}
                                                //             >
                                                //                 <div
                                                //                     hitslop={{
                                                //                         left: Size.CountScale(
                                                //                             10
                                                //                         ),
                                                //                         right: Size.CountScale(
                                                //                             10
                                                //                         ),
                                                //                         bottom: Size.CountScale(
                                                //                             10
                                                //                         ),
                                                //                         top: Size.CountScale(
                                                //                             10
                                                //                         ),
                                                //                     }}
                                                //                     onClick={() => {
                                                //                         close();
                                                //                         this.setState(
                                                //                             {
                                                //                                 showCulverdocsPhotoModal: false,
                                                //                             }
                                                //                         );
                                                //                     }}
                                                //                 >
                                                //                     <FontAwesomeIcon
                                                //                         icon={[
                                                //                             "fad",
                                                //                             "circle-chevron-left",
                                                //                         ]}
                                                //                         size={
                                                //                             30
                                                //                         }
                                                //                         color={
                                                //                             Colours.WHITE
                                                //                         }
                                                //                     />
                                                //                 </div>
                                                //             </div>
                                                //             <div
                                                //                 style={{
                                                //                     flex: 0.8,
                                                //                     flexDirection:
                                                //                         "row",
                                                //                     justifyContent:
                                                //                         "center",
                                                //                     alignItems:
                                                //                         "center",
                                                //                 }}
                                                //             >
                                                //                 <p
                                                //                     style={
                                                //                         styles.modalHeaderText
                                                //                     }
                                                //                     numberoflines={
                                                //                         1
                                                //                     }
                                                //                 >
                                                //                     {translate(
                                                //                         "Photo"
                                                //                     )}{" "}
                                                //                 </p>
                                                //             </div>
                                                //             <div
                                                //                 style={{
                                                //                     flex: 0.1,
                                                //                     flexDirection:
                                                //                         "row",
                                                //                     alignItems:
                                                //                         "center",
                                                //                     justifyContent:
                                                //                         "flex-end",
                                                //                     paddingRight: 10,
                                                //                 }}
                                                //             >
                                                //                 {this
                                                //                     .props
                                                //                     .editable && (
                                                //                     <div
                                                //                         style={{
                                                //                             flexDirection:
                                                //                                 "row",
                                                //                         }}
                                                //                     >
                                                //                         {/* { this.props.item.photoillustration && (this.props.item.photoillustration == "Yes" || this.props.item.photoillustration == "Auto") && */}
                                                //                         {this
                                                //                             .props
                                                //                             .item
                                                //                             .photoillustration && (
                                                //                             <div
                                                //                                 style={{
                                                //                                     paddingRight: 20,
                                                //                                 }}
                                                //                                 hitslop={{
                                                //                                     left: Size.CountScale(
                                                //                                         10
                                                //                                     ),
                                                //                                     right: Size.CountScale(
                                                //                                         10
                                                //                                     ),
                                                //                                     bottom: Size.CountScale(
                                                //                                         10
                                                //                                     ),
                                                //                                     top: Size.CountScale(
                                                //                                         10
                                                //                                     ),
                                                //                                 }}
                                                //                                 onClick={async () => {
                                                //                                     close();
                                                //                                     this.openIllustrationModal(
                                                //                                         photo
                                                //                                     );
                                                //                                 }}
                                                //                             >
                                                //                                 <FontAwesomeIcon
                                                //                                     icon={[
                                                //                                         "fad",
                                                //                                         "pencil",
                                                //                                     ]}
                                                //                                     size={
                                                //                                         25
                                                //                                     }
                                                //                                     color={
                                                //                                         Colours.WHITE
                                                //                                     }
                                                //                                 />
                                                //                             </div>
                                                //                         )}
                                                //                         <div
                                                //                             hitslop={{
                                                //                                 left: Size.CountScale(
                                                //                                     10
                                                //                                 ),
                                                //                                 right: Size.CountScale(
                                                //                                     10
                                                //                                 ),
                                                //                                 bottom: Size.CountScale(
                                                //                                     10
                                                //                                 ),
                                                //                                 top: Size.CountScale(
                                                //                                     10
                                                //                                 ),
                                                //                             }}
                                                //                             onClick={async () => {
                                                //                                 // 2022-01-03 (JJ): Soft delete photos which have been uploaded to FB/GCP.
                                                //                                 // When attempting to delete a photo, check if there's a fbstorage or storage location.
                                                //                                 // If so, that means the file has already been uploaded to GCP (Draft or Web Forms?) and we need to soft delete here.
                                                //                                 // This will allow the processing agent to delete the files on processing and allows Discard Changes to work as expected
                                                //                                 // as we dont delete the file "live", but once the user has either re-saved draft or sent as compelted.
                                                //                                 if (
                                                //                                     photo?.fbstorage ||
                                                //                                     photo?.storage
                                                //                                 ) {
                                                //                                     // There is a storage key, set to deleted for agent to process.
                                                //                                     this.state.selectedPhoto.deleted = true; //This is the soft delete flag.
                                                //                                     this.state.selectedPhoto.photo =
                                                //                                         null; //Clear the base64 so we dont attempt to upload on save.
                                                //                                     // Soft delete the photo from firebase
                                                //                                     // 2024-01-09 (JJ): Global update onFieldSoftDeletePhoto() to onFieldUpdateValueIndex() as this is used for Files, Photo Updates, etc.
                                                //                                     this.props.onFieldUpdateValueIndex(
                                                //                                         this
                                                //                                             .props
                                                //                                             .item,
                                                //                                         this
                                                //                                             .props
                                                //                                             .pItem,
                                                //                                         this
                                                //                                             .props
                                                //                                             .rIndex,
                                                //                                         photoIndex,
                                                //                                         photo
                                                //                                     );
                                                //                                     // Close the modal
                                                //                                     this.setState(
                                                //                                         {
                                                //                                             showCulverdocsPhotoModal: false,
                                                //                                         }
                                                //                                     );
                                                //                                 } else {
                                                //                                     //There's no storage, delete the photo locally.
                                                //                                     delete this
                                                //                                         .props
                                                //                                         .item
                                                //                                         .value[
                                                //                                         photoIndex
                                                //                                     ];
                                                //                                     // Delete the photo from
                                                //                                     // this.props.onFieldDeleteChild(this.props.item,this.state.selectedPhotoIndex);
                                                //                                     // 2023-07-11 (JJ): Update to onFieldDeleteChild to support deletions for photos inside repeaters - where an image is deleted before saving as draft etc.
                                                //                                     this.props.onFieldDeleteChild(
                                                //                                         this
                                                //                                             .props
                                                //                                             .item,
                                                //                                         this
                                                //                                             .props
                                                //                                             .pItem,
                                                //                                         this
                                                //                                             .props
                                                //                                             .rIndex,
                                                //                                         photoIndex
                                                //                                     );
                                                //                                     this.setState(
                                                //                                         {
                                                //                                             showCulverdocsPhotoModal: false,
                                                //                                         }
                                                //                                     );
                                                //                                 }
                                                //                             }}
                                                //                         >
                                                //                             <FontAwesomeIcon
                                                //                                 icon={[
                                                //                                     "fas",
                                                //                                     "trash-alt",
                                                //                                 ]}
                                                //                                 size={
                                                //                                     25
                                                //                                 }
                                                //                                 color={
                                                //                                     Colours.WHITE
                                                //                                 }
                                                //                             />
                                                //                         </div>
                                                //                     </div>
                                                //                 )}
                                                //             </div>
                                                //         </div>
                                                //     )}
                                                // />
                                            )}

                                            {photo && !photo['deleted'] && !photo['photo'] && photo['fbstorage'] && (
                                                <div
                                                    resizeMode={'cover'}
                                                    style={styles.fieldPhoto}
                                                >
                                                    <div style={styles.fieldPhotoTextView}>
                                                        <div style={styles.fieldPhotoTextContainer}>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flex: 1,
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'center',
                                                                    alignContent: 'center'
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={['fad', 'photo-video']}
                                                                    style={{
                                                                        opacity: 0.2,
                                                                        height: 70,
                                                                        width: 70
                                                                    }}
                                                                    color={this.props.brandData?.primarycolour}
                                                                />
                                                            </div>

                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flex: 1,
                                                                    position: 'absolute',
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'center',
                                                                    alignContent: 'center'
                                                                }}
                                                            >
                                                                {!photo['firebaseStorageProcessing'] && this.state.sendButtonState !== 'Uploading' && (
                                                                    <FontAwesomeIcon
                                                                        icon={['fad', 'search']}
                                                                        style={{ height: 20, width: 20 }}
                                                                        color={this.props.brandData?.primarycolour}
                                                                    />
                                                                )}

                                                                {photo['firebaseStorageProcessing'] && (
                                                                    <Spinner
                                                                        brandData={this.props.brandData}
                                                                        type={'FadingCircleAlt'}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>

                {/* Modal */}
                <Modal
                    centered
                    show={this.state.showCulverdocsPhotoModal}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%', width: '100%', backgroundColor: Colours.MODALBG, evelation: 4, justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                        {/* <div style={{ flex: 1, width: '100%', maxHeight: '50%', borderRadius: 10, borderWidth: 1, alignItems: 'center', borderColor: this.props.brandData?.primarycolour, backgroundColor: Colours.WHITE }}> */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row', // Updated to ensure horizontal alignment of children
                                flex: 1,
                                height: '100%',
                                width: '100%',
                                backgroundColor: this.props.brandData?.primarycolour,
                                borderTopLeftRadius: 5,
                                borderTopRightRadius: 5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '5px',
                                gap: '8px' // This will space out the children horizontally
                            }}
                        >
                            <div
                                onClick={() => this.setState({ showCulverdocsPhotoModal: false })}
                                style={{
                                    display: 'flex',
                                    flex: 0.1,
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    paddingLeft: 10
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={['fad', 'circle-chevron-left']}
                                    style={{ width: 30, height: 30, cursor: 'pointer' }}
                                    color={Colours.WHITE}
                                />
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    flex: 0.8,
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    textAlign: 'center'
                                }}
                            >
                                <p
                                    style={{
                                        ...styles.modalHeaderText,
                                        margin: 0,
                                        lineHeight: 'normal',
                                        marginBottom: 4
                                    }}
                                >
                                    {translate('Photo')}
                                </p>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    flex: 0.1,
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    paddingRight: 10
                                }}
                            >
                                {this.props.editable && (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {this.props.item.photoillustration && (
                                            <div
                                                style={{ paddingRight: 20, cursor: 'pointer' }}
                                                onClick={this.openIllustrationModal}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fad', 'pencil']}
                                                    color={Colours.WHITE}
                                                />
                                            </div>
                                        )}

                                        <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                if (this.state.selectedPhoto.fbstorage || this.state.selectedPhoto.storage) {
                                                    this.state.selectedPhoto.deleted = true;
                                                    this.state.selectedPhoto.photo = null;
                                                    this.props.onFieldUpdateValueIndex(this.props.item, this.props.pItem, this.props.rIndex, this.state.selectedPhotoIndex, this.state.selectedPhoto);
                                                    this.setState({ showCulverdocsPhotoModal: false });
                                                } else {
                                                    delete this.props.item.value[this.state.selectedPhotoIndex];
                                                    this.props.onFieldDeleteChild(this.props.item, this.props.pItem, this.props.rIndex, this.state.selectedPhotoIndex);
                                                    this.setState({ showCulverdocsPhotoModal: false });
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fad', 'trash-alt']}
                                                style={{ cursor: 'pointer' }}
                                                color={Colours.WHITE}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* <div style={styles.modalContent}> */}
                        {this.state.showCulverdocsPhotoModal && (
                            <img
                                src={`data:image/png;base64,${this.state.selectedPhoto['photo']}`}
                                style={{ height: '100%', width: '100%' }}
                                resizeMode="cover"
                            />
                        )}
                        {/* </div> */}
                        {/* </div> */}
                    </div>
                </Modal>
                {this.props.item.photoillustration && (
                    <Modal
                        centered
                        show={this.state.showIllustrationModal}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%', width: '100%', backgroundColor: Colours.MODALBG, evelation: 4, justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                            {/* <div style={{ flex: 1, width: '100%', maxHeight: '50%', borderRadius: 10, borderWidth: 1, alignItems: 'center', borderColor: this.props.brandData?.primarycolour, backgroundColor: Colours.WHITE }}> */}
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                    //   maxHeight: 50,
                                    //  maxWidth: this.state.imageWidth,
                                    // alignSelf: 'stretch',
                                    height: '100%',
                                    width: '100%',
                                    backgroundColor: this.props.brandData?.primarycolour,
                                    borderTopLeftRadius: 5,
                                    borderTopRightRadius: 5,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'row'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 1
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flex: 1,
                                            flexDirection: 'row'
                                        }}
                                    >
                                        <CulverdocsIllustrationComponent
                                            brandData={this.props.brandData}
                                            ref={(r) => {
                                                this.canvasRef = r;
                                            }}
                                            backgorundImage={this.state.illustrationImgPath}
                                            containerStyle={{
                                                // display: "flex",
                                                flexDirection: 'column',
                                                backgroundColor: lightenDarkenColor(this.props.brandData?.primarycolour, 50),
                                                flex: 1
                                            }}
                                            canvasStyle={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                backgroundColor: 'white',
                                                flex: 1,
                                                alignSelf: 'center'
                                            }}
                                            defaultStrokeIndex={0}
                                            defaultStrokeWidth={5}
                                            onSaveData={
                                                (data) => {
                                                    this.updatePhotoFromIllustration(data); // Copy to value
                                                    this.setState({
                                                        showIllustrationModal: false,
                                                        illustrationImgPath: null
                                                    });
                                                }
                                                // this.addImageToCanvasField(this.props.item, data)
                                            }
                                            onClose={() => this.hideModal()}
                                            // 2023-08-28 (JJ): Illustration updates - if Source Image is provided (without "donotcropoutput"=true property), remove text option and crop on save to avoid resizing in view + capture timestamp on save.
                                            cropOutput={this.props.item.imagepath && !this.props.item.donotcropoutput ? true : false}
                                            localSourceImage={{
                                                filename: this.props.item.imagepath ? this.props.item.imagepath : '',
                                                directory: '',
                                                mode: 'AspectFit'
                                            }}
                                            strokeComponent={(color) => (
                                                <div style={styles.strokeColorButton}>
                                                    <FontAwesomeIcon
                                                        icon={['fad', 'circle']}
                                                        color={color}
                                                    />
                                                </div>
                                            )}
                                            strokeSelectedComponent={(color, index, changed) => {
                                                return (
                                                    <div style={styles.strokeColorButton}>
                                                        <FontAwesomeIcon
                                                            icon={['fad', 'circle']}
                                                            style={{
                                                                width: 20,
                                                                height: 20,
                                                                top: -5,
                                                                left: -5
                                                            }}
                                                            color={color}
                                                        />
                                                    </div>
                                                );
                                            }}
                                            strokeWidthComponent={(w) => {
                                                let size = Math.ceil(Math.sqrt(w / 3) * 10);
                                                return (
                                                    <div style={styles.strokeWidthButton}>
                                                        <div
                                                            style={{
                                                                backgroundColor: Colours.BLACK,
                                                                marginHorizontal: 2.5,
                                                                width: size,
                                                                height: size,
                                                                borderRadius: size / 2
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            }}
                                            //addImageToCanvasField={addImageToCanvasField}
                                            // onSketchSaved={(item) => {
                                            //     console.log('item', item);
                                            //     this.addImageToCanvasField(item); // Copy to value
                                            //     this.setState({
                                            //         showModal: false
                                            //     }); // Close the modal
                                            // }}
                                            strokeColors={[
                                                { color: '#000000' }, //Black
                                                { color: '#FF0000' }, //Red
                                                { color: '#00FF00' }, //Green
                                                { color: '#0000FF' }, //Blue
                                                { color: '#00FFFF' },
                                                { color: '#0000A0' },
                                                { color: '#ADD8E6' },
                                                { color: '#800080' },
                                                { color: '#FFFF00' },
                                                { color: '#FF00FF' },
                                                { color: '#A020F0' },
                                                { color: '#FFFFFF' },
                                                { color: '#C0C0C0' },
                                                { color: '#808080' },
                                                { color: '#FFA500' },
                                                { color: '#A52A2A' },
                                                { color: '#800000' },
                                                { color: '#008000' },
                                                { color: '#808000' }
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}

                <Modal
                    centered
                    show={this.state.renderCameraModal}
                    // visible={this.state.renderQRScanModal}
                    // onRequestClose={() => {
                    //     this.setState({ renderQRScanModal: false });
                    // }}
                    // animationType="fade"
                    // transparent={true}
                >
                    <div style={styles.modalBackground}>
                        <div style={styles.modalContainer}>
                            <div style={styles.modalHeader}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 0.1,
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        paddingLeft: 5
                                    }}
                                >
                                    <div
                                        hitslop={{
                                            left: Size.CountScale(10),
                                            right: Size.CountScale(10),
                                            bottom: Size.CountScale(10),
                                            top: Size.CountScale(10)
                                        }}
                                        onClick={() =>
                                            this.setState({
                                                renderCameraModal: false
                                            })
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={['fad', 'circle-chevron-left']}
                                            color={Colours.WHITE}
                                            style={{
                                                alignSelf: 'flex-start',
                                                marginLeft: 5,
                                                width: 25,
                                                height: 25,
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, marginLeft: 5, alignItems: 'center' }}>
                                    <p style={{ ...styles.modalHeaderText, ...{ margin: 0, lineHeight: 1, marginBottom: 4 } }}>{translate(this.props.item.caption)}</p>
                                </div>
                            </div>
                            <Webcam
                                audio={false}
                                height={'100%'}
                                screenshotFormat="image/jpeg"
                                width={'100%'}
                                style={{ display: 'block', position: 'relative' }}
                                //   videoConstraints={videoConstraints}
                            >
                                {({ getScreenshot }) => (
                                    <FontAwesomeIcon
                                        icon={['fa-regular', 'fa-circle-dot']}
                                        color={this.props.brandData?.primarycolour}
                                        style={{
                                            alignSelf: 'flex-start',
                                            marginLeft: 10,
                                            width: 60,
                                            height: 60,
                                            position: 'absolute',
                                            bottom: 30,
                                            left: '40%'
                                        }}
                                        onClick={() => {
                                            const imageSrc = getScreenshot();

                                            this.addImageToPhotoField(imageSrc.split(',')[1], '', 'camera');
                                            this.setState({
                                                renderCameraModal: false
                                            });
                                        }}
                                    />
                                )}
                            </Webcam>
                            {this.state.renderQRScanModal && (
                                <div style={styles.modalContent}>
                                    {this.state.scanProcessing && (
                                        <div style={styles.processingOverlay}>
                                            {/* <ActivityIndicator
                                                size="large"
                                                color={
                                                    this.props.brandData
                                                        .primarycolour
                                                }
                                            /> */}
                                            <FadeLoader
                                                color={'green'}
                                                loading={true}
                                                cssOverride={{ display: 'block', margin: '0 auto', borderColor: 'red' }}
                                                size={30}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    // 2024-01-09 (JJ): Photo now supports Illustrations - No / Yes / Auto & modal tidy up
    // 2024-04-23 (RB): Re -Implement photo modal with pinch and zoom feature
    openIllustrationModal = async (photo = null) => {
        //   photo.preventDefault();
        let tmpImgPath = `data:image/png;base64,${this.state.selectedPhoto['photo']}`;
        this.setState({
            showCulverdocsPhotoModal: false,
            showIllustrationModal: true,
            illustrationImgPath: tmpImgPath
        });
        // }

        //   `data:image/png;base64,${this.state.selectedPhoto['photo']}`
    };
    // 2024-01-09 (JJ): Photo now supports Illustrations - No / Yes / Auto & modal tidy up
    async hideModal() {
        this.setState({ showIllustrationModal: false });
    }
    async updatePhotoFromIllustration(path) {
        // var RNFS = require('fs');
        // var exists = await RNFS.exists(path); //Wait for the image to be written.
        // if (!exists) {
        //     return; //Dont return if we cant find the image path.
        // }

        // imageSrc.split(',')[1]
        console.log('path data', path);
        const base64 = path.split(',')[1]; // await RNFS.readFile(path, 'base64');

        if (this.state.selectedPhoto.photo && !this.state.selectedPhoto.original) {
            this.state.selectedPhoto.original = this.state.selectedPhoto.photo;
        }

        this.state.selectedPhoto.photo = base64;
        this.state.selectedPhoto.timestamp = new Date().getTime();

        //RNFS.unlink(path); //Delete the original file

        this.props.onFieldUpdateValueIndex(this.props.item, this.props.pItem, this.props.rIndex, this.state.selectedPhotoIndex, this.state.selectedPhoto);
        this.forceUpdate();
    }

    openCamera = () => {
        try {
            this.setState({ renderCameraModal: true });
            // this.state.cameraRef.takePhoto();
            // ImagePicker.openCamera({
            //     mediaType: "photo",
            //     includeExif: true,
            // })
            //     .then(async (image) => {
            //         // 2023-10-06 (JJ): Update to photo openGallery (attach from gallery) to support multiple images, and rework ImagePicker.clean in then() after completion
            //         // 2023-10-15 (JJ): NFE - Photo and Gallery import, do not clean() after due to file access issues - removed .then()
            //         this.addImageToPhotoField(image, ImagePicker, "camera"); // 2022-07-27 (JJ): Stamp timestamp and source on each photo
            //     })
            //     .catch((error) => {
            //         // Do not need to do anything if we catch an error, as the photo won't be added via addImageToPhotoField above.
            //     });
        } catch {
            alert(translate('Error'), translate('There was an error encountered processing this photo. Please try again.'));
        }
    };

    openGallery = () => {
        this.state.gallerRef.click();
        // ImagePicker.openPicker({
        //     mediaType: "photo", // 2023-10-06 (JJ): Update to photo openGallery (attach from gallery) to support multiple images, and rework ImagePicker.clean in then() after completion + gallery only show photos
        //     includeExif: true,
        //     multiple: true, // 2023-10-15 (JJ): NFE - Photo field updates fix missing multiple prop for gallery and loop on images
        //     // writeTempFile:false, //for iOS, otherwise cant access the image. It gets deleted afterwards anyway.
        // })
        //     .then(async (images) => {
        //         // 2023-10-06 (JJ): Update to photo openGallery (attach from gallery) to support multiple images, and rework ImagePicker.clean in then() after completion
        //         // 2023-10-15 (JJ): NFE - Photo and Gallery import, do not clean() after due to file access issues - removed .then()
        //         images.forEach((image) =>
        //             this.addImageToPhotoField(image, ImagePicker, "gallery")
        //         );
        //     })
        //     .catch(() => {
        //         //Do nothing if it is closed without importing.
        //     });
    };

    addImageToPhotoField = async (image, ImagePicker, source) => {

        //const RNFS = require("fs");
        //var path = image.path;

        //v1.1.1 - Improvements to photos handling & deletion of photos after processing.
        // var exists = await RNFS.exists(path); //Wait for the photo to be written. Occasional issue with

        // if (!exists) {
        //     return; //Dont return if we cant find the image path.
        // }

        // 2023-07-26 (JJ): NFE - Support for saving new photos in the Gallery / Camera Roll on device - using photostoreingallery
        // 2023-08-10 (JJ): NFE - Update for photostoreingallery - only store the photo if the source is Camera (avoid duplicate files if attached from gallery)
        if (this.props.item.photostoreingallery && source == 'camera') {
            // CameraRoll.save(path).catch((err) =>
            //     alert(
            //         "Issue saving photo",
            //         `There was an issue saving the photo to your Camera Roll: ${err}`
            //     )
            // );
        }

        //v1.1.2 - Photo sizing from portal.
        var maxdimension = this.props.item.photomaxdimension ? parseInt(this.props.item.photomaxdimension) : 1000; //Default to 1k max if it hasnt been set.

        //Wait for the photo to exist with promise
        // const resizedImageUrl = await ImageResizer.createResizedImage(
        //     path,
        //     maxdimension,
        //     maxdimension,
        //     "JPEG",
        //     80,
        //     0,
        //     null
        // );
        // const resizedImageUrl = Resizer.imageFileResizer(
        //     image,
        //     300,
        //     300,
        //     "JPEG",
        //     100,
        //     0,
        //     (url) => {
        //         console.log(url);
        //     }
        // );
        // console.log("resizedImageUrl", resizedImageUrl);
        const base64 = image; //await RNFS.readFile(resizedImageUrl.uri, "base64");

        // 2022-07-27 (JJ): Stamp timestamp and source on each photo
        let timestamp = new Date().getTime();

        let newPhoto = { photo: base64, source: source, timestamp: timestamp };
        this.props.item.value[timestamp] = {
            photo: base64,
            source: source,
            timestamp: timestamp
        };
        //Push the newphoto reference in so we can pass that out to upload function
        //If we are on android, delete the image path and resized image URL.
        // if (Platform.OS !== "ios") {
        //     try {
        //         RNFS.unlink(image.path); //Delete the original
        //     } catch {
        //         //No catch required, we just try to delete those files if we can (android)
        //     }
        // }

        try {
            // RNFS.unlink(resizedImageUrl.uri); //Delete the resized - this will be created on either OS
        } catch {
            //No catch required.
        }

        // 2023-10-06 (JJ): Update to photo openGallery (attach from gallery) to support multiple images, and rework ImagePicker.clean in then() after completion
        //Now delete the two files.
        // await ImagePicker.clean();

        // Add the Photo to the new index
        this.props.onFieldNewChild(this.props.item, this.props.pItem, this.props.rIndex, timestamp, newPhoto);

        this.setState({ lastPhoto: image.path });

        // 2024-01-09 (JJ): Photo now supports Illustrations - No / Yes / Auto & modal tidy up
        if (this.props.item.photoillustration && this.props.item.photoillustration == 'Auto') {
            this.setState({
                selectedPhoto: this.props.item.value[timestamp],
                selectedPhotoIndex: timestamp
            });
        }
    };
}

export default CulverdocsPhoto;
const getStyle = (brandData) => {
    return Stylesheet.create({
        // Common Styles - Mandatory & Caption
        mandatoryimg: {
            alignContent: 'center',
            alignSelf: 'center',
            top: 2,
            marginRight: 0,
            marginLeft: 0,
            tintColor: Colours.RED
        },
        fieldcaption: {
            fontFamily: Font.family.bold,
            fontSize: Font.size.medium,
            color: Colours.BLACK
        },
        fieldNonEditable: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            backgroundColor: Colours.WHITE,
            color: Colours.GREY
        },
        fieldNonEditableView: {
            borderBottomWidth: 1,
            borderBottomColor: brandData?.primarycolour,
            backgroundColor: Colours.WHITE,
            marginBottom: 10,
            paddingTop: 10, //for iOS
            paddingBottom: 10, //for iOS
            paddingLeft: 5 //for iOS
        },
        fieldCaptionBadge: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.small,
            color: Colours.BLACK,
            marginLeft: 5,
            paddingLeft: 5,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: lightenDarkenColor(brandData?.primarycolour, 50),
            borderRadius: 5,
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            padding: 2,
            paddingLeft: 5,
            paddingRight: 5,
            height: 20,
            maxWidth: 150 // 2022-10-26 (JJ): Set maxWidth on fieldcaptions to limit content length (postcode changes)
        },

        // Field Specific
        photoContainer: {
            display: 'flex',
            flex: 1,
            height: 100,
            flexDirection: 'row',
            width: '100%',
            // marginTop:10,

            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            borderRadius: 5,
            elevation: 5,
            // height:45,
            marginBottom: 15,
            paddingLeft: 10
        },
        photoContainerVerticalBar: {
            borderLeftWidth: 1,
            borderColor: brandData?.primarycolour,
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 5,
            marginTop: 5
        },
        fieldPhoto: {
            height: 75,
            width: 75,
            marginTop: 10,
            marginRight: 5,
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            backgroundColor: Colours.LIGHTGREY,
            borderRadius: 5,
            objectFit: 'cover'
        },
        fieldPhotoTextView: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center'
        },
        fieldPhotoTextContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center'
        },
        fieldPhotoText: {
            color: brandData?.primarycolour,
            fontSize: 12,
            fontFamily: Font.family.bold,
            fontSize: Font.size.medium,
            lineHeight: 30,
            paddingLeft: 5
        },

        // Modal
        modalBackground: {
            display: 'flex',
            flex: 1,
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: Colours.MODALBG,
            padding: 0
        },
        modalContainer: {
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            // borderRadius: 5,
            width: '100%',
            height: '100%',
            elevation: 5
            // margin: 20
            //  width: '100%',
            // maxWidth: 300
        },
        modalHeader: {
            display: 'flex',
            height: 50,
            alignSelf: 'stretch',
            backgroundColor: brandData?.primarycolour,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,

            justifyContent: 'center',
            alignContent: 'center',

            // fl`ex:1,
            flexDirection: 'row'
        },
        modalHeaderText: {
            fontFamily: Font.family.bold,
            fontSize: Font.size.medium,
            color: Colours.WHITE,
            lineHeight: 1
        },
        modalContent: {
            // padding: 20,
            backgroundColor: 'transparent',
            maxWidth: 300
        },

        // 2024-01-09 (JJ): Photo now supports Illustrations - No / Yes / Auto & modal tidy up
        // Illustration Styles
        fieldCanvas: {
            display: 'flex',
            backgroundColor: Colours.WHITE,
            borderRadius: 3,
            flexDirection: 'column',
            flex: 1,
            marginBottom: 10, // 2023-09-03 (JJ): Standardising marginBottom:10 on all components which were 15 as there were some inconsistencies with styling
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            borderRadius: 5,
            elevation: 5
        },
        strokeColorButton: {
            marginHorizontal: 3,
            marginVertical: 8,
            width: 30,
            height: 30
        },
        canvasFunctionButton: {
            marginHorizontal: 2.5,
            marginVertical: 8,
            width: 30,
            height: 30,
            borderRadius: 15,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: brandData?.primarycolour
        },
        strokeWidthButton: {
            marginHorizontal: 2.5,
            marginVertical: 8,
            width: 30,
            height: 30,
            borderRadius: 15,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: brandData?.primarycolour
        }
    });
};
