import { createStore, combineReducers } from 'redux';
import DrawerReducer from '../reducers/DrawerReducer';

const rootReducer = combineReducers({
    drawerStatus: DrawerReducer
});

const configureStore = () => {
    return createStore(rootReducer);
};
export default configureStore;
