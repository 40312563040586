import React, { Component } from 'react';

import Font from '../../../Constant/font';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import { translate } from '../../../Common/CulverdocsTranslate';

export default class CulverdocsEmail extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        return this.props.itemValue !== prevProps.itemValue || this.props.visible !== prevProps.visible || this.props.item.value !== prevProps.item.value || this.props.caption !== prevProps.caption;
    }

    render() {
        if (!this.props.visible) {
            return null;
        }
        const styles = getStyle(this.props.brandData);

        return (
            <div style={{ position: 'relative' }}>
                <div style={{ flexDirection: 'row' }}>
                    <CulverdocsFieldCaption
                        brandData={this.props.brandData}
                        caption={this.props.item.caption}
                        userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                        mandatory={this.props.item.mandatory}
                        editable={this.props.editable}
                        hasValue={this.props.item.value && !this.props.item.invalidEmailAddress ? true : false}
                        badge={this.props.item.invalidEmailAddress ? translate('Invalid Email Address') : null}
                        badgeColour={this.props.item.invalidEmailAddress ? 'red' : null}
                    />
                </div>

                {this.props.item.lockfieldtype && (
                    <div style={{ position: 'absolute', right: 0, top: 0 }}>
                        <div
                            hitslop={{
                                left: 10,
                                right: 10,
                                bottom: 10,
                                top: 10
                            }}
                            onClick={() => {
                                this.props.toggleFieldLock(this.props.item, !this.props.item.locked).then(() => {
                                    // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                                    if (this.props.pItem) {
                                        this.props.forceRepeaterChildUpdate();
                                    }

                                    this.forceUpdate();
                                });
                            }}
                        >
                            <FontAwesomeIcon
                                icon={['fad', this.props.item.locked ? 'square-a-lock' : 'lock-keyhole-open']}
                                color={this.props.item.locked ? Colours.GREEN : Colours.GREY}
                            />
                        </div>
                    </div>
                )}

                {/* This.props.Editable - without keyboard type (bug - https://github.com/facebook/react-native/issues/8932) */}
                {this.props.editable && (
                    // <TextInput
                    //     ref={(ref) => {
                    //         this.thisRef = ref;
                    //     }}
                    //     style={styles.fieldText}
                    //     defaultValue={this.props.item.value}
                    //     keyboardType={'email-address'}
                    //     autoCapitalize={'none'}
                    //     autoCompleteType={'email'}
                    //     returnKeyType={'done'}
                    //     onChangeText={(text) => {
                    //         this.props.item.value = text;
                    //         if (text === '') {
                    //             this.props.item.value = null;
                    //         }
                    //         this.forceUpdate();

                    //         // Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                    //         // If onChangeText is called without focus, the field has been updated externally (i.e. S Pen) and needs to write to the DB by calling onEndEditing
                    //         if (!this.thisRef.isFocused()) {
                    //             clearTimeout(this.thisRef.timeoutToUpdate);
                    //             this.thisRef.timeoutToUpdate = setTimeout(() => {
                    //                 this.thisRef.props.onEndEditing();
                    //             }, 1000);
                    //         }
                    //     }}
                    //     onEndEditing={() => {
                    //         // Start validation of the field - strip spaces if there is content.
                    //         if (this.props.item.value) {
                    //             this.props.item.value = this.props.item.value.replace(/ /g, '');
                    //         }

                    //         // Now run through regex parse to make sure it's valid, otherwise throw error (see badge in caption)
                    //         // 2024-01-06 (JJ): Email field regex update to support plus notation, and default + "emailregex" property overrride incase it is required in the future.
                    //         // 2024-05-01 (JJ): Fix for Email Validation - simplify back to RegEx Literal (remove emailregex override) and store invalidEmailAddress key instead of deleting due to saving as draft.
                    //         let emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}[a-zA-Z0-9.-]*[a-zA-Z]{1,}$/;
                    //         let emailValid = this.props.item.value && this.props.item.value.match(emailRegExp);
                    //         this.props.item.invalidEmailAddress = !emailValid;

                    //         this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                    //         this.forceUpdate();
                    //     }}
                    // />
                    <Form.Control
                        type="email"
                        style={styles.fieldText}
                        value={this.props.item.value}
                        onChange={(text) => {
                            this.props.item.value = text.target.value;
                            if (text.target.value === '') {
                                this.props.item.value = null;
                            }
                            this.forceUpdate();

                            // Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                            // If onChangeText is called without focus, the field has been updated externally (i.e. S Pen) and needs to write to the DB by calling onEndEditing
                            // if (!this.thisRef.isFocused()) {
                            //     clearTimeout(this.thisRef.timeoutToUpdate);
                            //     this.thisRef.timeoutToUpdate = setTimeout(
                            //         () => {
                            //             this.thisRef.props.onEndEditing();
                            //         },
                            //         1000
                            //     );
                            // }
                        }}
                        onBlur={() => {
                            if (this.props.item.value) {
                                this.props.item.value = this.props.item.value.replace(/ /g, '');
                            }

                            // Now run through regex parse to make sure it's valid, otherwise throw error (see badge in caption)
                            // 2024-01-06 (JJ): Email field regex update to support plus notation, and default + "emailregex" property overrride incase it is required in the future.
                            // 2024-05-01 (JJ): Fix for Email Validation - simplify back to RegEx Literal (remove emailregex override) and store invalidEmailAddress key instead of deleting due to saving as draft.
                            let emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}[a-zA-Z0-9.-]*[a-zA-Z]{1,}$/;
                            let emailValid = this.props.item.value && this.props.item.value.match(emailRegExp);
                            this.props.item.invalidEmailAddress = !emailValid;

                            this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                            this.forceUpdate();
                        }}
                    />
                )}

                {/* Non-this.props.editable */}
                {!this.props.editable && (
                    <div style={styles.fieldText}>
                        <p style={styles.fieldNonEditable}>{this.props.item.value}</p>
                    </div>
                )}
            </div>
        );
    }
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        // Common Styles - Mandatory & Caption
        fieldNonEditable: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.GREY,
            top: 1.5,
            left: -0.5,
            margin: 0
        },
        fieldCaptionBadge: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.small,
            color: Colours.BLACK,
            marginLeft: 5,
            paddingLeft: 5,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: lightenDarkenColor(brandData?.primarycolour, 50),
            borderRadius: 5,
            borderWidth: 1,
            borderColor: lightenDarkenColor(brandData?.primarycolour, 50),
            padding: 2,
            paddingLeft: 5,
            paddingRight: 5,
            height: 20,
            maxWidth: 150 // 2022-10-26 (JJ): Set maxWidth on fieldcaptions to limit content length (postcode changes)
        },

        // Field Specific
        fieldText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
            backgroundColor: Colours.WHITE,
            marginBottom: 10,
            paddingTop: 10, //for iOS
            paddingBottom: 10, //for iOS
            paddingLeft: 5, //for iOS
            paddingRight: 5, // 2023-11-08 (JJ): NFE - Updating Short Answer field - apply paddingRight and change to minHeight due to non-editable fields wrapping outside of the field
            minHeight: 45, // 2023-11-08 (JJ): NFE - Updating Short Answer field - apply paddingRight and change to minHeight due to non-editable fields wrapping outside of the field
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            borderRadius: 5,
            elevation: 5
        }
    });
};
