import React from 'react';

export function calculateDateFromDays(days) {
    // Calculate the new date based on the extracted number
    const currentDate = new Date(); // Gets today's date
    currentDate.setDate(currentDate.getDate() + days); // Adds the specified number of days

    // Format the date as YYYY-MM-DD
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const day = currentDate.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}
