import React, { Component } from 'react';

// 2023-09-24 (JJ): Created new CulverdocsCreateFormV2Functions.js to centralise shared functions - returnCollapsibleRepeaterChildCaption()
import CulverdocsRepeaterChild from './CulverdocsRepeaterChild';
import { translate } from '../../../Common/CulverdocsTranslate';

export default class CulverdocsRepeater extends Component {
    state = {
        repeaterCollapsibleIndex: null
    };

    render() {
        if (!this.props.returnFieldVisibility(this.props.pItem)) {
            return null;
        }
        var editable = this.props.returnFieldEditable(this.props.pItem); // 2023-02-26 (JJ): Adding editable support to repeaters, for pre-populated data from API & Requests
        // Set the repeaterCollapsibleIndex to the last index from the fields array, so the last child is opened when rendering.
        if (this.props.pItem.repeatercollapsible && !this.state.repeaterCollapsibleIndex) {
            this.setState({
                repeaterCollapsibleIndex: Object.keys(this.props.pItem.fields).sort()[[Object.keys(this.props.pItem.fields).length - 1]]
            });
        }

        // 2023-07-27 (JJ): NFE - Updating repeaters to support incremental next repeater index, no longer needs to be sorted due to timestamp which caused issues with requests
        // Filter the children to remove the nulls which might have been deleted.
        var filteredChildren = Object.fromEntries(
            Object.entries(this.props.pItem.fields)
                .map(([key, value]) => [key, Array.isArray(value) ? value.filter((v) => v) : value])
                .filter(([, value]) => value && (!Array.isArray(value) || value.length))
        );
        var totalChildren = Object.keys(filteredChildren).length;

        // 2023-07-27 (JJ): NFE - Support Generated from List repeaters being saved as draft, flag as generated if there are >1 children.
        // 2023-11-08 (JJ): NFE - Fix for Generated Repeaters saved as draft where there is only a single child - onFieldNewChild() write generated key on repeater field
        if (!this.props.pItem.generated && this.props.pItem.generatefromlist && totalChildren > 1) {
            this.props.pItem.generated = true;
        }

        var childNumber = 0;
        return Object.entries(filteredChildren).map(([rIndex, item]) => {
            childNumber++;

            // 2023-07-28 (JJ): NFE - Repeater Performance Updates, handle calcs in parent for componentShouldUpdate checks
            let shouldRender = !this.props.pItem.repeatercollapsible || (this.props.pItem.repeatercollapsible && this.state.repeaterCollapsibleIndex == rIndex);
            // 2023-08-01 (JJ): NFE - Update repeaterCaption to use repeatercollapsiblechildcaption - defines the repeater header without collapsible being set
            let repeaterCaption = this.props.pItem.repeatercollapsiblechildcaption ? translate(this.props.returnCollapsibleRepeaterChildCaption(this.props.pItem, rIndex)) : `${translate(this.props.pItem.caption)} (${childNumber})`;
            let repeaterDeletable = !this.props.pItem.generatefromlist && totalChildren > 1 && this.props.navigationMode !== 'view' && editable && (!this.props.pItem.repeatercollapsible || (this.props.pItem.repeatercollapsible && rIndex == this.state.repeaterCollapsibleIndex));
            let showAddRepeater = childNumber == totalChildren && this.props.navigationMode !== 'view' && !this.props.pItem.generatefromlist && editable;

            // 2023-10-07 (JJ): NFE - Adding support for Repeater Min/Max - Disable add button if the maxvalue is reached, and validation support for minvalue
            if (this.props.pItem.maxvalue && this.props.pItem.maxvalue > 0 && totalChildren >= this.props.pItem.maxvalue) {
                showAddRepeater = false;
            }

            return (
                <CulverdocsRepeaterChild
                    brandData={this.props.brandData}
                    item={item}
                    key={childNumber} // 2024-03-24 (JJ): Adding key prop to CulverdocsChoice and CulverdocsRepeater - due to key warning (this prop is not used elsewhere)
                    mapIndex={childNumber}
                    totalChildren={totalChildren}
                    repeaterCollapsibleIndex={this.state.repeaterCollapsibleIndex}
                    shouldRender={shouldRender} // 2023-07-28 (JJ): NFE - Repeater Performance Updates, handle calcs in parent for componentShouldUpdate checks
                    repeaterCaption={repeaterCaption}
                    repeaterDeletable={repeaterDeletable}
                    showAddRepeater={showAddRepeater}
                    rIndex={rIndex}
                    editable={editable}
                    pItem={this.props.pItem}
                    formFields={this.props.formFields}
                    returnPlaceholderCaption={this.props.returnPlaceholderCaption}
                    returnFieldVisibility={this.props.returnFieldVisibility}
                    returnFieldEditable={this.props.returnFieldEditable}
                    downloadMediaFromStorage={this.props.downloadMediaFromStorage}
                    onFieldChanged={this.props.onFieldChanged}
                    onFieldNewChild={this.props.onFieldNewChild}
                    onFieldDeleteChild={this.props.onFieldDeleteChild}
                    returnFieldFromID={this.props.returnFieldFromID}
                    returnFieldValueFromID={this.props.returnFieldValueFromID}
                    returnListValues={this.props.returnListValues}
                    checkGPSPermissions={this.props.checkGPSPermissions}
                    setFieldDefaultValues={this.props.setFieldDefaultValues}
                    onFieldUpdateValueIndex={this.props.onFieldUpdateValueIndex} // 2024-01-09 (JJ): Global update onFieldSoftDeletePhoto() to onFieldUpdateValueIndex() as this is used for Files, Photo Updates, etc.
                    updateRepeaterRepeaterCollapsibleIndex={this.updateRepeaterRepeaterCollapsibleIndex}
                    addRepeater={this.addRepeater}
                    deleteRepeater={this.deleteRepeater}
                    generateRepeater={this.generateRepeater}
                    toggleFieldLock={this.props.toggleFieldLock} // 2023-07-09 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Short Answer, Number, Choice, Select)
                    setFieldDataFromID={this.props.setFieldDataFromID}
                    returnValueFromDataname={this.props.returnValueFromDataname} // 2023-08-01 (JJ): NFE - Update calculation returnValueFromDataname to support repeaters
                    getKeyForCurrentRecord={this.props.getKeyForCurrentRecord} // 2024-01-10 (JJ): New field type "query"
                    recordId={this.props.recordId} // 2024-05-14 (JJ): Implementing documentviewerembededfileid - embedded file IDs which are record specifc not account (unlike documentviewerfileid) or the base64 option (documentviewerbase64)
                />
            );
        });
    }

    updateRepeaterRepeaterCollapsibleIndex = (index) => {
        this.setState({ repeaterCollapsibleIndex: index });
        this.forceUpdate();
    };

    // 2023-06-09 (JJ): Support for Copy From Previous Repeater / repeatercopyfields
    addRepeater = async (header, item, copyFieldsFromPrevious) => {
        // 2023-07-30 (JJ): NFE - Adding support for "Fields Required on New" (using Mandatory) on repeater items to check all fields have been populated.
        if (header.mandatory) {
            var lastRepeaterChild = parseInt(Object.keys(this.props.pItem.fields).pop());
            let missingChildFieldValue = item.find((field) => field.mandatory && (!field.value || field.value == '') && this.props.returnFieldVisibility(field, header, lastRepeaterChild));
            if (missingChildFieldValue) {
                alert(translate('Missing Required Field(s)'), translate('You must complete all required fields before creating a new repeater.'));
                return;
            }
        }

        let copyofrepeaterfields = JSON.parse(JSON.stringify(item));

        let promises = []; // 2024-01-16 (JJ): Update to various setFieldDefaultValues references - pass the parent item to set to support Populate From List
        copyofrepeaterfields.forEach((field) => {
            // 2021-07-31 (JJ): New property on repeater child "copyonrepeat" (Copy when Repeating fields). This will retain the data instead of overwrite as default.
            // 2023-06-09 (JJ): Support for Copy From Previous Repeater / repeatercopyfields
            if (!field.copyonrepeat && !(copyFieldsFromPrevious && header.repeatercopyfields.includes(field.id))) {
                // 2023-04-07 (JJ): Increment Next Value On Repeat (Integer fields) for repeaters
                if (field.type == 'integer' && field.incrementvaluetonextrepeaterchild && field.value && !isNaN(field.value)) {
                    field.value = String(parseInt(field.value) + 1);
                } else {
                    field.fbstorage = null; // Clear storage keys for signatures
                    field.storage = null; // Clear storage keys for signatures

                    field.value = null; //Clear the field before copying them.
                    field.selected = null; //v1.1.6 - new selection for lists, clear the value in case it has been set.
                    field.listitem = null; // 2023-08-23 (JJ): NFE - Fix for select fields not clearing listitem key when repeating

                    // 2024-01-16 (JJ): Update to various setFieldDefaultValues references - pass the parent item to set to support Populate From List
                    // 2024-04-26 (JJ): Fix for setFieldDefaultValues in addRepeater() as this was not being called
                    promises.push(this.props.setFieldDefaultValues(field, copyofrepeaterfields)); //Default the field values after setting to null
                }

                // 2022-10-26 (JJ): Clear success/error text when repeating postcode fields
                if (field.type == 'postcode') {
                    field.successtext = null;
                    field.errortext = null;
                }
            }
        });

        await Promise.all(promises); // 2024-01-16 (JJ): Update to various setFieldDefaultValues references - pass the parent item to set to support Populate From List

        // 2023-07-27 (JJ): NFE - Updating repeaters to support incremental next repeater index, no longer needs to be sorted due to timestamp which caused issues with requests
        // let nextRepeaterIndex = new Date().getTime();
        let nextRepeaterIndex = parseInt(Object.keys(this.props.pItem.fields).pop()) + 1;
        header.fields[nextRepeaterIndex] = copyofrepeaterfields;

        // 2024-04-29 (JJ): Improved Calculations on first load - run processFieldCalculation() after all default values have been set and run when creating new repeater children
        await this.props.onFieldNewChild(header, null, null, nextRepeaterIndex, JSON.parse(JSON.stringify(copyofrepeaterfields)));

        if (this.props.pItem.repeatercollapsible) {
            this.setState({ repeaterCollapsibleIndex: nextRepeaterIndex });
        }

        this.forceUpdate();
    };

    deleteRepeater = async (pItem, index) => {
        // pItem['fields'].splice(index, 1);
        delete pItem['fields'][index];
        this.props.onFieldDeleteChild(pItem, null, null, index); // 2023-07-11 (JJ): Update to onFieldDeleteChild to support deletions for photos inside repeaters - where an image is deleted before saving as draft etc.

        // If it's collapsible, re-open the final repeater on deletion
        if (this.props.pItem.repeatercollapsible) {
            this.setState({
                repeaterCollapsibleIndex: Object.keys(this.props.pItem.fields)[Object.keys(this.props.pItem.fields).length - 1]
            });
        }

        this.forceUpdate();
    };

    generateRepeater = async (header, item, rIndex) => {
        if (!header.generatefromlist) {
            return;
        }

        // 2023-07-27 (JJ): NFE - Updating repeaters to support incremental next repeater index, no longer needs to be sorted due to timestamp which caused issues with requests
        let nextRepeaterIndex = 0; //Always start at 0 to replace the first entry.

        //This will generate a repeater field group for every row returned from the selected list.
        let listname = header.listname;
        this.setState({ generatingrepeater: true });

        var listResult = await this.props.returnListValues(header, item, rIndex, null);
        if (listResult && !listResult.success) {
            await this.setState({ renderSelectModal: false });
            alert(listResult.title, listResult.message);
            return;
        }

        // We have the List Values, lets loop through list.
        // Clear the values from the copy of children fields first.
        let copyofrepeaterfields = JSON.parse(JSON.stringify(item));
        copyofrepeaterfields.forEach((field) => {
            field.value = null; //Clear the field before copying them.
            field.selected = null; //v1.1.6 - new selection for lists, clear the value in case it has been set.
            // 2024-01-16 (JJ): Update to various setFieldDefaultValues references - pass the parent item to set to support Populate From List
            this.props.setFieldDefaultValues(field, copyofrepeaterfields); // 2022-08-29 (JJ): Fix to support default values in Generated Repeaters
        });

        listResult.options.forEach((litem) => {
            //Take another copy, this will be the version we update per loop
            let repeaterFieldsToPush = JSON.parse(JSON.stringify(copyofrepeaterfields));

            //This is our list item, lets match the listtofieldvalue to the child field before inserting
            if (header.listtofieldvalue) {
                // We have field values to populate.
                header.listtofieldvalue.forEach((val, index) => {
                    //val.Name = the List Field Name (e.g. Hair Colour) || val.Value = the Field ID that needs setting.
                    if (val.Name && val.Value) {
                        // 2023-12-19 (JJ): NFE - Rework Repeater (Generated), Select and QR fields to support Document IDs in Populate From List.
                        repeaterFieldsToPush.forEach((field) => {
                            if (field.id == val.Value) {
                                if (field.type == 'documentviewer') {
                                    if (litem[val.Name + '_id']) {
                                        field.documentviewerfilename = litem[val.Name];
                                        field.documentviewerfileid = litem[val.Name + '_id'];
                                    }
                                } else {
                                    if (litem[val.Name]) {
                                        field.value = litem[val.Name];
                                    }
                                }
                            }
                        });
                    }
                });
            }

            // Add to the header/repeater fields - we'll add to Firebase once we've finished looping all records.
            header.fields[nextRepeaterIndex] = repeaterFieldsToPush;

            // 2023-07-27 (JJ): NFE - Updating repeaters to support incremental next repeater index, no longer needs to be sorted due to timestamp which caused issues with requests
            // Add the new child during the loop so it's written to firebase once the data is prepared.
            this.props.onFieldNewChild(header, null, null, nextRepeaterIndex, JSON.parse(JSON.stringify(repeaterFieldsToPush)));

            // Increment the timestamp, as we need these to be incremental for the next generated child. (instead of getting new time which might clash)
            nextRepeaterIndex++;
        });

        // Set the header to generated so the values are shown.
        header.generated = true;

        // 2023-07-27 (JJ): NFE - Updating repeaters to support incremental next repeater index, no longer needs to be sorted due to timestamp which caused issues with requests
        // Scapped the approach for single write due to immutable issues and really limited impact as it's no different than the user adding multiple children.
        // this.props.onFieldChanged(header); // Write all new fields to firebase in a single write transaction including "generated".

        if (this.props.pItem.repeatercollapsible) {
            // this.setState({ repeaterCollapsibleIndex: firstChildTimestamp });
        }

        this.setState({ generatingrepeater: false }); // 2022-10-26 (JJ): Fix where multiple generate repeaters in a single form would getting stuck on "Please Wait" due to state.
        this.forceUpdate();
    };
}
