export const setLocalStorage = (key, value) => {
    try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
    } catch (error) {
        console.error('Error saving to local storage', error);
    }
};

// Function to get data from local storage
export const getLocalStorage = (key) => {
    try {
        const serializedValue = localStorage.getItem(key);
        if (serializedValue === null) {
            return undefined;
        }
        return JSON.parse(serializedValue);
    } catch (error) {
        console.error('Error retrieving from local storage', error);
        return undefined;
    }
};

// Function to remove data from local storage
export const removeLocalStorage = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error('Error removing from local storage', error);
    }
};
export const removeAllLocalStorage = () => {
    try {
        localStorage.clear();
    } catch (error) {
        console.error('Error removing from local storage', error);
    }
};
