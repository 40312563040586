import React, { Component } from "react";
import Font from "../../../Constant/font";
import Colours from "../../../Constant/colour";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lightenDarkenColor } from "../../../Helper/lightenDarkenColor";
import CulverdocsFieldCaption from "./_CulverdocsFieldCaption";
import Stylesheet from "reactjs-stylesheet";
import { Form } from "react-bootstrap";
import { translate } from "../../../Common/CulverdocsTranslate";

export default class CulverdocsError extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        return (
            this.props.visible !== prevProps.visible ||
            this.props.item.value !== prevProps.item.value ||
            this.props.caption !== prevProps.caption
        );
    }

    render() {
        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption}`);
        if (!this.props.visible) {
            return null;
        }

        //v1.1.5 - Alignment option for Captions
        var customstyle = [];
        if (this.props.item.captionalignment) {
            switch (this.props.item.captionalignment) {
                case "Left":
                    customstyle.push({ textAlign: "left" });
                    break;
                case "Centre":
                    customstyle.push({ textAlign: "center" });
                    break;
                case "Right":
                    customstyle.push({ textAlign: "right" });
                    break;
                default:
                    break;
            }
        }
        const styles = getStyle(this.props.brandData, customstyle);
        return (
            <div>
                <div style={styles.renderErrorContainer}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            flex: 0.1,
                        }}
                    >
                        <FontAwesomeIcon
                            icon={["fad", "exclamation-triangle"]}
                            color={Colours.WHITE}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            flex: 0.9,
                        }}
                    >
                        <p
                            style={styles.renderErrorText} // 2024-05-23 (RB): Todo customstyle
                        >
                            {translate(this.props.caption)}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

const getStyle = (brandData, customstyle) => {
    return Stylesheet.create({
        renderErrorContainer: {
            display: "flex",
            justifyContent: "center",
            paddingRight: 10,
            width: "100%",
            marginBottom: 10, // 2023-09-03 (JJ): Standardising marginBottom:10 on all components which were 15 as there were some inconsistencies with styling
            backgroundColor: Colours.RED,
            padding: 15,
            borderRadius: 5,
            flex: 1,

            flexDirection: "row",

            elevation: 5,
        },
        renderErrorText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.WHITE,
            lineHeight: 20,
            textAlignVertical: "center",
            customstyle,
        },
    });
};
