import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '../../../Common/CulverdocsTranslate';
import Stylesheet from 'reactjs-stylesheet';
import Font from '../../../Constant/font';
import Size from '../../../Constant/size';
import Colours from '../../../Constant/colour';
import FlatList from 'flatlist-react';
import { CulverdocsButton } from '../../../Component/CulverdocsButton';

//TODO: Update branding - not loading
export const CulverdocsModalValidation = (props) => {
    const styles = getStyle(props.brandData);
    return (
        <Modal
            centered
            show={props.showCulverdocsModalValidation}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    backgroundColor: Colours.MODALBG,
                    evelation: 4,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        // maxWidth: 400,
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: props.brandData?.primarycolour,

                        backgroundColor: Colours.WHITE
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: 50,
                            flexDirection: 'row',
                            alignContent: 'center',
                            borderTopRightRadius: 5,
                            borderTopLeftRadius: 5,
                            backgroundColor: props.brandData?.primarycolour,
                            borderBottomColor: props.brandData?.primarycolour,
                            borderBottomWidth: 2,
                            padding: 10
                        }}
                    >
                        <div
                            hitslop={{
                                left: Size.CountScale(10),
                                right: Size.CountScale(10),
                                bottom: Size.CountScale(10),
                                top: Size.CountScale(10)
                            }}
                            onClick={() => {
                                props.onDiscard();
                            }}
                        >
                            <FontAwesomeIcon
                                icon={['fad', 'circle-chevron-left']}
                                color={Colours.WHITE}
                                style={{
                                    marginHorizontal: 10,
                                    width: 25,
                                    height: 25,
                                    cursor: 'pointer'
                                }}
                            />
                        </div>
                        <p
                            style={styles.renderValidationModalHeaderp}
                            numberoflines={1}
                        >
                            {translate('Missing Information')}
                        </p>
                    </div>
                    <div
                        style={{
                            flex: 0.9,
                            justifyContent: 'flex-start',
                            padding: 10
                        }}
                    >
                        <div
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {props.validationCount > 0 && <p style={styles.renderValidationModalp}>{translate('You are unable to send this form as there are incomplete fields')}:</p>}

                            {props.validationCount == -1 && <p style={styles.renderValidationModalp}>{translate('An error exists on the form and cannot be sent. Please check the form to resolve the below error and try again')}:</p>}
                        </div>

                        {/* // 2023-10-08 (JJ): NFE - CulverdocsModalValidation updates to wrap text for longer messages (remove numberoflines=1 property) */}
                        <FlatList
                            list={props.validationField}
                            renderItem={(item) => <p style={styles.renderValidationModalCaptionp}>• {translate(item)}</p>}
                            keyExtractor={(item) => item.toString()}
                        />
                    </div>
                    <div style={{ padding: 20 }}>
                        <CulverdocsButton
                            title={translate('OK')}
                            icon={'check-circle'}
                            backgroundColor={Colours.GREEN}
                            onClick={() => {
                                props.onDiscard();
                            }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

// ====STYLES DECLARATION======//
const getStyle = (brandData) => {
    return Stylesheet.create({
        renderValidationModalHeaderp: {
            fontFamily: Font.family.bold,
            fontSize: Font.size.large,
            color: Colours.WHITE,
            textAlign: 'center',
            marginLeft: 10
        },
        renderValidationModalp: {
            marginTop: 10,
            marginBottom: 10,
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK,
            textAlign: 'left',
            paddingHorizontal: 10
        },
        renderValidationModalCaptionp: {
            fontFamily: Font.family.bold,
            fontSize: Font.size.medium,
            color: Colours.BLACK,
            textAlign: 'left',
            paddingLeft: 20,
            paddingRight: 20
        },
        renderValidationModalButton: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignSelf: 'flex-end',
            justifyContent: 'center',
            height: 40,
            borderRadius: 0,
            borderWidth: 3,
            borderColor: Colours.GREEN,
            backgroundColor: Colours.WHITE,
            alignItems: 'center'
        },
        renderValidationModalButtonp: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.GREEN,
            textAlign: 'center'
        }
    });
};
