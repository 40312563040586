import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '../../../Common/CulverdocsTranslate';
import Stylesheet from 'reactjs-stylesheet';
import Font from '../../../Constant/font';
import Size from '../../../Constant/size';
import Colours from '../../../Constant/colour';
import { CulverdocsButton } from '../../../Component/CulverdocsButton';

export const CulverdocsModalConfirmSend = (props) => {
    const styles = getStyles(props.brandData);
    return (
        <Modal
            centered
            show={props.showCulverdocsModalConfirmSubmit}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,

                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        borderRadius: 5,
                        backgroundColor: Colours.WHITE
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            height: 50,
                            flexDirection: 'row',

                            borderTopRightRadius: 5,
                            borderTopLeftRadius: 5,
                            backgroundColor: props.brandData?.primarycolour,
                            borderBottomColor: props.brandData?.primarycolour,
                            borderBottomWidth: 2
                        }}
                    >
                        <div
                            hitslop={{
                                left: Size.CountScale(10),
                                right: Size.CountScale(10),
                                bottom: Size.CountScale(10),
                                top: Size.CountScale(10)
                            }}
                            onClick={() => props.onDiscard()}
                            style={{
                                paddingLeft: 10,

                                marginTop: 10
                            }}
                        >
                            <FontAwesomeIcon
                                icon={['fad', 'circle-chevron-left']}
                                style={{ cursor: 'pointer' }}
                                color={Colours.WHITE}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,

                                alignItems: 'center'
                            }}
                        >
                            <p
                                style={styles.renderOptionModalHeaderp}
                                numberoflines={1}
                            >
                                {translate('Send Form')}
                            </p>
                        </div>
                    </div>

                    <div
                        style={{
                            paddingRight: 5,
                            paddingLeft: 5,
                            paddingTop: 0,
                            paddingBottom: 20
                        }}
                    >
                        <p style={styles.modalp}>{translate('Please confirm all information has been provided before sending this form. Are you sure?')}</p>

                        <CulverdocsButton
                            title={translate('Send Now')}
                            icon={'cloud-upload'}
                            onClick={() => {
                                props.onDiscard();
                                props.onFormSubmit();
                            }}
                            extraStyle={{ backgroundColor: Colours.GREEN }}
                        />

                        <CulverdocsButton
                            title={translate('No')}
                            icon={'times'}
                            extraStyle={{ backgroundColor: Colours.RED }}
                            onClick={() => {
                                props.onDiscard();
                            }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

// ====STYLES DECLARATION======//
const getStyles = (brandData) => {
    return Stylesheet.create({
        renderOptionModalHeaderp: {
            fontFamily: Font.family.bold,
            fontSize: Font.size.large,
            color: Colours.WHITE,
            marginLeft: 10
        },
        modalp: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BACKGROUND,
            textAlign: 'center',
            paddingHorizontal: 10,
            marginTop: 10
        }
    });
};
