import React, { Component } from 'react';
import Font from '../../../Constant/font';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import { translate } from '../../../Common/CulverdocsTranslate';

export default class CulverdocsChoice extends Component {
    dimensions = {}; // 2023-12-19 (JJ): NFE - Support for AutoScroll property on Choice, Checkbox & Select.

    state = {
        selectedItem: null
    };

    shouldComponentUpdate(prevProps, prevState) {
        // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
        return this.props.itemValue !== prevProps.itemValue || this.state.selectedItem !== prevState.selectedItem || this.props.visible !== prevProps.visible || this.props.item.value !== prevProps.item.value || this.props.caption !== prevProps.caption || this.props.item.colourconditions !== prevProps.item.colourconditions;
    }

    render() {
        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption}`);

        if (!this.props.visible) {
            return null;
        }
        const styles = getStyle(this.props.brandData);
        return (
            <div
                style={{ justifyContent: 'center', width: '100%' }}
                onLayout={(event) => {
                    this.dimensions = event.nativeEvent.layout;
                }}
            >
                <CulverdocsFieldCaption
                    brandData={this.props.brandData}
                    caption={this.props.item.caption}
                    userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                    mandatory={this.props.item.mandatory}
                    editable={this.props.editable}
                    hasValue={this.props.item.value ? true : false}
                />

                {/* // 2023-07-09 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Short Answer, Number, Choice, Select) */}
                {this.props.item.lockfieldtype && (
                    <div style={{ position: 'absolute', right: 0, top: 5 }}>
                        <div
                            hitslop={{
                                left: 10,
                                right: 10,
                                bottom: 10,
                                top: 10
                            }}
                            onClick={() => {
                                this.props.toggleFieldLock(this.props.item, !this.props.item.locked).then(() => {
                                    // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                                    if (this.props.pItem) {
                                        this.props.forceRepeaterChildUpdate();
                                    }

                                    this.forceUpdate();
                                });
                            }}
                        >
                            <FontAwesomeIcon
                                icon={['fad', this.props.item.locked ? 'square-a-lock' : 'lock-keyhole-open']}
                                style={{ width: 15, height: 15 }}
                                color={this.props.item.locked ? Colours.GREEN : Colours.GREY}
                            />
                        </div>
                    </div>
                )}

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {this.props.item.optionvalue.map((item, index) => {
                        // 2023-08-26 (JJ): Support for ColourConditions on Choice fields and reworked to use map instead of 5 fixed buttons
                        let colourConditionBackground = null;
                        let colourConditionFont = null;
                        if (this.props.item.value == this.props.item.optionvalue[index] && this.props.item.colourconditions) {
                            let colourCondition = this.props.item.colourconditions.find((option) => option.value == this.props.item.value);
                            if (colourCondition) {
                                colourConditionBackground = colourCondition.backgroundcolour ? colourCondition.backgroundcolour : null;
                                colourConditionFont = colourCondition.fontcolour ? colourCondition.fontcolour : null;
                            }
                        }

                        return (
                            <div
                                disabled={!this.props.editable}
                                key={index} // 2024-03-24 (JJ): Adding key prop to CulverdocsChoice and CulverdocsRepeater - due to key warning (this prop is not used elsewhere)
                                onClick={() => {
                                    //Todod(JJ): why not getting colour condition
                                    this.props.editable && this.updateChoiceOption(index);
                                }}
                                style={{
                                    ...styles.renderYesNoButton,
                                    ...{
                                        flex: 1 / this.props.item.optionvalue.length,
                                        marginLeft: index > 0 ? 5 : null
                                    },

                                    ...(this.props.item.value == this.props.item.optionvalue[index] ? styles.renderYesNoSelected : null),
                                    ...(this.props.item.value == this.props.item.optionvalue[index] && colourConditionBackground ? { backgroundColor: colourConditionBackground } : null)
                                }}
                            >
                                <p
                                    style={{ ...styles.renderYesNoText, ...(this.props.item.value == this.props.item.optionvalue[index] ? styles.renderYesNoTextSelected : null), ...(this.props.item.value == this.props.item.optionvalue[index] && colourConditionFont ? { color: colourConditionFont } : null) }}
                                    // 2023-11-19 (JJ): NFE - Choice Field is now set to noOfLines=1 with padding to avoid wrapping and left aligning long answers
                                >
                                    {translate(this.props.item.optionvalue[index])}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    updateChoiceOption(option) {
        if (this.props.item.value !== this.props.item.optionvalue[option]) {
            this.props.item.value = this.props.item.optionvalue[option];
            this.setState({ selectedItem: option });
        } else {
            this.props.item.value = null;
            this.setState({ selectedItem: null });
        }

        // 2023-12-19 (JJ): NFE - Support for AutoScroll property on Choice, Checkbox & Select.
        this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex, this.props.item.autoscroll ? this.dimensions.height : null);

        // 2024-01-06 (JJ): Field Modifiers functionality added to Choice fields
        if (this.props.item.fieldmodifiers) {
            // There are field modifiers to process, get the list of modifiers based on the selected value
            let modifierOptions = this.props.item.fieldmodifiers.filter((item) => item.value == this.props.item.value);
            modifierOptions.forEach((val, index) => {
                //val.setfield = the Destination Field ID (e.g. 92)
                //val.setvalue = the Destination Field Value that will be set.
                if (val.setfield && val.setvalue) {
                    let setNewValue = val.setvalue !== "''" ? val.setvalue : null;
                    this.props.returnFieldFromID(val.setfield, this.props.pItem, this.props.rIndex).then(async (destinationField) => {
                        this.props.setFieldDataFromID(val.setfield, this.props.pItem, this.props.rIndex, { value: setNewValue });
                    });
                }
            });
        }
    }
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        renderYesNoButton: {
            cursor: 'pointer',
            display: 'flex',
            // borderBottomWidth: 1,
            // borderColor: brandData?.primarycolour,
            justifyContent: 'center',
            alignItems: 'center',
            //  marginLeft: 5, //Change to marginLeft so we can force marginLeft to 0 on the 1st choice field to fix issue with last choice leaving 5px gap.
            height: 40,
            marginBottom: 10, // 2023-09-03 (JJ): Standardising marginBottom:10 on all components which were 15 as there were some inconsistencies with styling
            backgroundColor: Colours.WHITE,
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            borderColor: brandData?.primarycolour,
            borderRadius: 5,
            elevation: 5
        },
        renderYesNoSelected: {
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'center',
            alignContent: 'center',
            // marginLeft: 5, //Change to marginLeft so we can force marginLeft to 0 on the 1st choice field to fix issue with last choice leaving 5px gap.
            height: 40,
            marginBottom: 10, // 2023-09-03 (JJ): Standardising marginBottom:10 on all components which were 15 as there were some inconsistencies with styling
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            borderColor: brandData?.primarycolour,
            backgroundColor: brandData?.primarycolour,
            borderRadius: 5,
            elevation: 5
        },

        renderYesNoText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            //padding: 5, // 2023-11-19 (JJ): NFE - Choice Field is now set to noOfLines=1 with padding to avoid wrapping and left aligning long answers
            color: 'black',
            margin: 0,
            cursor: 'pointer'
        },

        renderYesNoTextSelected: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.WHITE,
            cursor: 'pointer'
        }
    });
};
