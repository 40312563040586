import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import '../../styles.css';
import Colours from '../../Constant/colour';
import { useNavigate } from 'react-router-dom';
import { auth, database } from '../../firebase';
import { child, get, onValue, ref } from 'firebase/database';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../../Helper/localStorage';
import CulverdocsTile from '../../Component/CulverdocsTile';
import { ShowLoader } from '../../Common/Loader';
import { translate } from '../../Common/CulverdocsTranslate';
import Stylesheet from 'reactjs-stylesheet';
import Font from '../../Constant/font';
import { CulverdocsScreenHeader2 } from '../../Component/CulverdocsScreenHeader2';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Size from '../../Constant/size';

function NewFormScreen() {
    const drawerStatus = useSelector((state) => state.drawerStatus.key);
    const location = useLocation();
    const currentPath = location.pathname;
    const [showSignOutModal, setShowSignOutModal] = useState(false);

    const [formList, setFormList] = useState([]);
    const [formListCopy, setFormListCopy] = useState([]);
    const [brandData, setBrandData] = useState({});
    const [userProfile, setUserProfile] = useState({});
    const [formCategory, setFormCategory] = useState([]);
    const [filterformCategory, setFilterFormCategory] = useState([]);
    const [screen, setScreen] = useState('New Form');
    const [loader, setLoader] = useState(false);
    const [emptySearch, setEmptySearch] = useState(false);
    const [visible, setVisible] = React.useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);


    function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }



    const [searchText, setSearchText] = useState('');
    const [searchCategoryText, setSearchCategoryText] = useState('');

    const navigate = useNavigate();
    //const dispatch = useDispatch();
    const handleLogouthide = () => {
        setShowSignOutModal(false);
    };

    const hideDialog = () => {
        setShowFilterModal(false);
        setShowFilterModal(false);
        setVisible(false);
    };
    const showDialog = () => {
        setShowFilterModal(true);
        setVisible(true);
    };
    useEffect(() => {
        let brandData = getLocalStorage('brandData');
        let userProfiles = getLocalStorage('userProfiles');
        setBrandData(brandData);
        setUserProfile(userProfiles);
    }, []);
    useEffect(() => {
        setLoader(true);
        const getProfile = async () => {
            let currentUser = auth.currentUser;
            if (!currentUser) {
                return false;
            }

            // Store the uid
            let userId = currentUser.uid;
            if (!userId) {
                return false;
            }

            let profileListener = ref(database, `/users/${userId}/profile`);
            onValue(profileListener, async (data) => {
                data = data.val();
                data = await prepareProfileData(auth.currentUser, data);
                removeLocalStorage('userProfiles');
                setLocalStorage('userProfiles', data);
                setUserProfile(data);
            });
        };
        getProfile();
    }, []);
    useEffect(() => {
        if (userProfile?.accountid) {
            const getFormData = async () => {
                let formListener = ref(database, `/accounts/${userProfile.accountid}/form/`);
                onValue(formListener, async (data) => {
                    if (data) {
                        data = data.val();
                        let dataValue = prepareFormData(data, userProfile);
                        setFormList(dataValue);
                        setFormListCopy(dataValue);
                        setLocalStorage('userForms', dataValue);
                        setLoader(false);
                        if (screen === 'Category') {
                            orgData(data);
                        }
                    }
                });
            };
            getFormData();
        }
    }, [userProfile]);
    useEffect(() => {
        if (screen === 'Category') {
            orgData(formList);
        }
    }, [screen]);
    const orgData = (data) => {
        if (!data) {
            return;
        }
        const organizedData = data.reduce((acc, curr) => {
            const category = curr.category || 'Uncategorised';
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(curr);

            return acc;
        }, {});

        let structuredData = Object.entries(organizedData)
            .sort(([keyA], [keyB]) => {
                if (keyA === 'Uncategorised') {
                    return -1; // 'uncategorized' comes first
                } else if (keyB === 'Uncategorised') {
                    return 1; // 'uncategorized' comes first
                } else {
                    return keyA.localeCompare(keyB); // Sort other categories alphabetically
                }
            })
            .map(([key, value]) => ({
                categoryname: key,
                detail: value,
                expand: false
            }));
        setFormCategory(structuredData);
        setFilterFormCategory(structuredData);
    };
    const updateStatus = useCallback((cat_name) => {
        setFilterFormCategory((prevData) => {
            return prevData.map((item) => {
                if (item.categoryname === cat_name) {
                    return { ...item, expand: !item.expand };
                }
                return item;
            });
        });
    }, []);
    function prepareFormData(data, userProfile) {
        // Filter the data based on formassignment if it exists in our userProfile
        let filteredForms = Object.values(data);

        if (userProfile?.formassignment && userProfile.formassignment.length > 0) {
            filteredForms = filteredForms.filter((item) => userProfile.formassignment.includes(item.formid));
        }

        // Sort Alphabetically
        filteredForms.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });

        return filteredForms;
    }
    async function prepareProfileData(authUser, userProfile) {
        // Append userId to userProfile
        userProfile.userId = authUser?.uid;

        // Prepare the profile data (handle and adjustments)
        userProfile.homescreen = userProfile.taskviewid ? 'TaskViewHome' : 'HomeScreen';

        // Set Internal Placeholders
        userProfile.internalPlaceholders = {
            'Account.Name': userProfile?.accountname,
            'Device.Name': userProfile?.name,
            'Device.Email': authUser?.email,
            'User.Name': userProfile?.name,
            'User.Email': authUser?.email
        };

        // Append userproperties into internalPlaceholders
        if (userProfile.userproperties) {
            for (const [key, value] of Object.entries(userProfile.userproperties)) {
                if (key && value) {
                    userProfile.internalPlaceholders[`User.${key}`] = value;
                }
            }
        }

        // TODO: Need to implement cleardown of /tmp directory on loading the app. This should auto clear but we can cleardown to keep the storage down before OS clears due to size/age.
        // userProfile._tmpFiles = `${utils.FilePath.CACHES_DIRECTORY}`;

        return userProfile;
    }
    const onRightIconClick = () => {
        if (screen === 'New Form') {
            setScreen('Category');
        } else {
            setScreen('New Form');
        }
    };
    const handlePress = async (item) => {
        get(child(ref(database), `/accounts/${userProfile.accountid}/form/${item.formid}`))
            .then((formData) => {
                if (formData.exists()) {
                    if (formData) {
                        formData = formData.val();
                        navigate('/createform', {
                            state: {
                                form: formData,
                                mode: 'new'
                            }
                        });
                    }
                } else {
                    console.log('Data not available');
                }
            })
            .catch((error) => {
                console.error(error);
            }); // 2024-04-14 (JJ): Fix for NewFormScreen re-rendering all tiles when a form is updated. Removed componentShouldUpdate for fields and using firebase .once to retrieve latest data.
    };
    const handleSearchCategory = useCallback(
        (text) => {
            if (text !== '') {
                let filteredForms = formCategory?.filter((form) => form.categoryname?.toLowerCase().includes(text?.toLowerCase()));
                setSearchCategoryText(text);
                filteredForms.length == 0 ? setEmptySearch(true) : setEmptySearch(false);
                setFilterFormCategory(filteredForms);
            } else {
                // setLoader(true);
                setEmptySearch(false);
                setFilterFormCategory(formCategory);
                setSearchCategoryText('');
            }
        },
        [searchCategoryText]
    );
    const handleSearch = useCallback(
        (text) => {
            if (text !== '') {
                let filteredForms = formListCopy?.filter((form) => form.name?.toLowerCase().includes(text?.toLowerCase()));
                filteredForms.length == 0 ? setEmptySearch(true) : setEmptySearch(false);

                setSearchText(text);
                setFormList(filteredForms);
            } else {
                setEmptySearch(false);
                setFormList(formListCopy);
                setSearchText('');
                //  setLoader(true);
            }
        },
        [searchText]
    );
    return (
        <div style={styles.container}>
            <div
                style={styles.mainContainer}
                className="content-area"
            >
                <CulverdocsScreenHeader2
                    brandData={brandData}
                    ScreenName={'New Form'}
                    title={screen}
                    RightIconType={'fad'}
                    RightIcon={screen === 'New Form' ? 'folder-open' : 'file-lines'}
                    LeftIcon={'circle-chevron-left'}
                    userProfile={userProfile}
                    onLeftIconClick={() => navigate('/home')}
                    onRightIconClick={() => onRightIconClick()}
                />
                <ShowLoader
                    flag={loader}
                    brandData={brandData}
                />
                <div style={styles.boxrapper}>
                    {screen === 'New Form' && (
                        <Container
                            fluid
                            style={styles.zeroPadding}

                        >
                            <Row
                                style={styles.rowWrapper}
                                className={'custom_row'}
                            >
                                {formList.map((item) => {
                                    return (
                                        <Col
                                            style={styles.zeroPadding}
                                            sm={12}
                                            key={`Wrapper${item.formid}`}
                                        >
                                            <CulverdocsTile
                                                brandData={brandData}
                                                key={item.formid}
                                                fields={item.fields}
                                                onPress={() => handlePress(item)}
                                                caption={item.name}
                                                iconName={item.icon}
                                                colour={item.colour ? item.colour : brandData.primarycolour}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Container>
                    )}
                    {screen === 'Category' && (
                        <Container
                            fluid
                            style={styles.zeroPadding}
                        >
                            <Row>
                                <Col lg={12}>
                                    <Form.Control
                                        style={styles.fieldText}
                                        placeholder={translate('Search')}
                                        onChange={(e) => {
                                            handleSearchCategory(e.target.value);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row style={styles.tileWrapper}>
                                {filterformCategory.map((item, index) => {
                                    return (
                                        <Col
                                            style={styles.zeroPadding}
                                            sm={12}
                                        >
                                            <CulverdocsTile
                                                brandData={brandData}
                                                fields={item.detail}
                                                key={item?.categoryname}
                                                caption={item?.categoryname}
                                                colour={'orange'}
                                                iconName={'folder-open'}
                                                onPress={() => {
                                                    updateStatus(item.categoryname);
                                                }}
                                                onRightIconClick={() => updateStatus(item.categoryname)} // 2024-05-03 (RB): Add Favorite Form
                                                index={index}
                                            />
                                            {item?.expand && (
                                                <div style={{ ...styles.tileContainer, ...{ border: '1px solid' + brandData.primarycolour } }}>
                                                    {item.detail.map((item, index) => (
                                                        <CulverdocsTile
                                                            brandData={brandData}
                                                            fields={item.fields}
                                                            key={item.formid}
                                                            caption={item.name}
                                                            colour={brandData?.primarycolour}
                                                            iconName={'file-lines'}
                                                            onPress={() => handlePress(item)}
                                                            index={index}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Container>
                    )}
                </div>
            </div>
            <div>
                <div>
                    <Sidebar
                        collapsed={drawerStatus}
                        collapsedWidth={0}
                        backgroundColor={brandData.primarycolour}
                        style={{ width: 400, paddingLeft: 0, paddingRight: 0, marginLeft: 20, marginRight: 0, marginTop: 30, border: '5px solid ' + brandData.primarycolour, borderRadius: '5px' }}
                    >
                        <Container
                            fluid
                            style={{
                                paddingLeft: 10,
                                paddingRight: 10
                            }}
                        >
                            <Row
                                style={{
                                    paddingLeft: 0,
                                    paddingRight: 0
                                }}
                            >
                                <Col>
                                    <div
                                        style={{
                                            width: '100%',
                                            width: 400,
                                            borderRadius: 10,
                                            borderWidth: 1,
                                            borderColor: brandData?.primarycolour,
                                            backgroundColor: Colours.WHITE
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                height: 50,
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                borderTopRightRadius: 5,
                                                borderTopLeftRadius: 5,
                                                backgroundColor: brandData?.primarycolour,
                                                borderBottomWidth: 0,
                                                paddingRight: 0
                                            }}
                                        >
                                            <p style={styles.renderOptionModalHeaderp}>{translate('Options')}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    lg={12}
                                    style={{ display: 'flex', marginTop: 10, paddingLeft: 0, paddingRight: 0 }}
                                >
                                    <Form.Control
                                        style={styles.fieldText}
                                        placeholder={translate('Search')}
                                        defaultValue={searchText}
                                        onChange={(e) => {
                                            if (screen === 'Category') {
                                                handleSearchCategory(e.target.value);
                                            } else {
                                                handleSearch(e.target.value);
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Sidebar>
                </div>
            </div>
        </div>
    );
}

export default NewFormScreen;
const styles = Stylesheet.create({
    container: { display: 'flex', flexDirection: 'row', justifyContent: 'space-around' },
    mainContainer: { position: 'relative', marginLeft: 30, marginRight: 30, backgroundColor: Colours.WHITE, paddingBottom: 40, alignSelf: 'center' },
    zeroPadding: {
        paddingLeft: 0,
        paddingRight: 0
    },
    boxrapper: { padding: 30, paddingBottom: 0 },
    rowWrapper: {
        paddingLeft: 0,
        paddingRight: 0,
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'flex-start',
        overflow: 'auto'
    },
    fieldText: {
        fontFamily: Font.family.regular,
        fontSize: Font.size.medium,
        color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
        backgroundColor: Colours.WHITE,
        marginBottom: 10,
        paddingTop: 10, //for iOS
        paddingBottom: 10, //for iOS
        paddingLeft: 5, //for iOS
        height: 45,
        border: '0.5px solid #000',
        borderRadius: 5,
        elevation: 5
    },
    renderOptionModalHeaderp: {
        fontFamily: Font.family.bold,
        fontSize: Font.size.large,
        color: Colours.WHITE,
        width: '80%', //Fix for the caption ellipsis overlapping the modal window.,
        margin: 0
    },
    tileWrapper: {
        paddingLeft: 10,
        paddingRight: 10
    },
    tileContainer: {
        flex: 1,
        backgroundColor: 'white',
        borderRadius: 5,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 5,
        marginBottom: 5,
        minHeight: 45,
        borderRadius: 4,
        elevation: 3
    }
});
