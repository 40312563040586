// 2023-12-30 (JJ): New field type "video" using react-native-image-picker (for max duration) and react-native-compressor (pre-upload compression)
import React, { Component } from 'react';
// import { p, div, div, div, Alert, StyleSheet } from 'react-native';
// import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
// import * as ImagePicker from 'react-native-image-picker';
// import storage from '@react-native-firebase/storage';
// import NetInfo from '@react-native-community/netinfo';
// import { Video, getVideoMetaData } from 'react-native-compressor';
// import FontAwesomeSpin from '@src/Component/FontAwesomeSpin';
// import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
// import { Colours, Font } from '@src/Constant';
// import { connect } from 'react-redux';
// import { translate } from '@src/Helper/CulverdocsTranslate';
// import * as RNFS from '@dr.pogodin/react-native-fs';
//import { useRecordWebcam } from 'react-record-webcam'

import Font from '../../../Constant/font';
import Size from '../../../Constant/size';

import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { translate } from '../../../Common/CulverdocsTranslate';
import FlatList from 'flatlist-react';
import Webcam from 'react-webcam';
//import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { storage } from '../../../firebase';
import { deleteObject, ref as sRef } from 'firebase/storage';
import { uploadBytesResumable } from 'firebase/storage';
import { Spinner } from '../../../Common/Loader';
class CulverdocsVideo extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
        return this.state !== prevState || this.props.visible !== prevProps.visible || this.props.itemValue !== prevProps.itemValue || this.props.caption !== prevProps.caption;
    }
    constructor(props) {
        super(props);
        this.webcamRef = React.createRef();
        this.mediaRecorderRef = React.createRef();
        this.state = {
            renderModal: false,
            capturing: false,
            recordedChunks: [],
            recordingTime: 0,
            compressing: false
        };
        //  this.ffmpeg = createFFmpeg({ log: true });
        this.timerInterval = null;
    }

    startTimer = () => {
        this.timerInterval = setInterval(() => {
            this.setState((prevState) => ({ recordingTime: prevState.recordingTime + 1 }));
        }, 1000);
    };

    stopTimer = () => {
        clearInterval(this.timerInterval);
        this.setState({ recordingTime: 0 });
    };
    formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };

    // const { createRecording, openCamera, startRecording, stopRecording, downloadRecording } = useRecordWebcam()

    //  recordVideo = async () => {
    //   const recording = await createRecording();
    //   await openCamera(recording.id);
    //   await startRecording(recording.id);
    //   await new Promise(resolve => setTimeout(resolve, 3000)); // Record for 3 seconds
    //   await stopRecording(recording.id);
    //   await downloadRecording(recording.id); // Download the recording
    // };

    handleStartCaptureClick = () => {
        let maxTime = this.props.item.videomaxduration ? parseInt(this.props.item.videomaxduration) : 60;
        if (this.webcamRef.current && this.webcamRef.current.stream) {
            this.setState({ capturing: true, videoURL: null });
            this.mediaRecorderRef = new MediaRecorder(this.webcamRef.current.stream, {
                mimeType: 'video/webm; codecs=vp9'
            });
            this.mediaRecorderRef.addEventListener('dataavailable', this.handleDataAvailable);
            this.mediaRecorderRef.start();
            this.startTimer();
            this.recordingTimer = setTimeout(() => {
                this.handleStopCaptureClick();
            }, maxTime * 1000);
        }
    };

    handleDataAvailable = ({ data }) => {
        if (data.size > 0) {
            this.setState((prevState) => ({
                recordedChunks: [...prevState.recordedChunks, data]
            }));
        }
    };

    handleStopCaptureClick = () => {
        if (this.mediaRecorderRef && this.mediaRecorderRef.state !== 'inactive') {
            this.mediaRecorderRef.stop();
            this.setState({ capturing: false });
            this.stopTimer();
            //this.handleDownload();
        }
    };

    // loadFFmpeg = async () => {
    //     if (!this.ffmpeg.isLoaded()) {
    //         await this.ffmpeg.load();
    //     }
    // };

    // compressVideo = async (videoBlob) => {
    //     this.setState({ compressing: true });
    //     await this.loadFFmpeg();

    //     this.ffmpeg.FS('writeFile', 'input.webm', await fetchFile(videoBlob));
    //     await this.ffmpeg.run('-i', 'input.webm', '-vcodec', 'libx264', '-crf', '28', 'output.mp4');
    //     const data = this.ffmpeg.FS('readFile', 'output.mp4');

    //     this.setState({ compressing: false });
    //     return new Blob([data.buffer], { type: 'video/mp4' });
    // };
    handleDownload = async () => {
        if (this.state.recordedChunks.length > 0) {
            console.log('reached to download recordedChunks.length', this.state.recordedChunks.length);
            const blob = new Blob(this.state.recordedChunks, { type: 'video/webm' });
            //  const compressedBlob = await this.compressVideo(blob);

            this.addVideoToField(blob);
            this.setState({ recordedChunks: [] });
        }
    };
    addVideoToField = async (video) => {
        // NetInfo.fetch().then(async (connected) => {
        // if (connected.isConnected) {
        //var path = decodeURI(video.uri);

        let timestamp = new Date().getTime();
        let recordKey = await this.props.getKeyForCurrentRecord();
        let durationRounded = parseFloat(video.duration).toFixed(0); // 2024-01-18 (JJ): Video update to round duration toFixed(0) to avoid ms in filename
        let fileName = `video-${timestamp}-${durationRounded}s.mp4`;
        let fileUploadBucketPath = `/users/${this.props.userId}/${recordKey}/${fileName}`;
        let newVideo = {
            name: fileName,
            filename: fileName,
            type: video.type,
            timestamp: timestamp,
            originalSize: 0,
            size: 0,
            sizeMB: 0,
            duration: durationRounded,
            state: 'compressing',
            uploadPercent: 0
        };
        this.props.item.value[timestamp] = newVideo;
        // // Get the original size in MB
        // let metaData = await getVideoMetaData(video.uri);
        let originalSize = parseFloat(video.size * 1024).toFixed(0); //Coming from kb in metaData - *1024 to bring back to bytes to match everything else
        this.props.item.value[timestamp].originalSize = originalSize;
        const storageobject = sRef(storage, fileUploadBucketPath);
        const uploadTask = uploadBytesResumable(storageobject, video);

        uploadTask.on(
            'state_changed',
            (taskSnapshot) => {
                let percent = parseFloat((taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 100).toFixed(0);
                this.props.item.value[timestamp].state = 'uploading';
                this.props.item.value[timestamp].uploadPercent = percent;
                this.forceUpdate();
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        console.log(" User doesn't have permission to access the object");
                    break;
                    case 'storage/canceled':
                        console.log(' User canceled the upload');
                    break;
                    case 'storage/unknown':
                        console.log(' Unknown error occurred, inspect error.serverResponse');
                    break;
                    default:
                        break;
                }
            }
        );
        uploadTask.then(
            (taskSnapshot) => {
                this.props.item.value[timestamp].state = 'complete';
                delete this.props.item.value[timestamp].uploadPercent;
                this.props.item.value[timestamp].fbstorage = taskSnapshot.metadata.fullPath; //Set the path once file upload is complete..
                this.props.item.value[timestamp].uploadComplete = true;
                this.props.onFieldNewChild(this.props.item, this.props.pItem, this.props.rIndex, timestamp, this.props.item.value[timestamp]).then(() => {
                    this.forceUpdate(); // 2024-01-18 (JJ): Video update, onFieldNewChild.then => forceUpdate due to refresh issue after upload is complete
                });
            },
            (error) => {
                switch (error.code) {
                    case 'storage/unauthorized':
                            console.log(" User doesn't have permission to access the object");
                        break;
                    case 'storage/canceled':
                            console.log(' User canceled the upload');
                        break;
                    case 'storage/unknown':
                            console.log(' Unknown error occurred, inspect error.serverResponse222');
                        break;
                    default:
                        console.log("Unknown Error");
                    break;
                }
            }
        );
    };
    // });

    render() {
        const styles = getStyle(this.props.brandData);
        if (!this.props.visible) {
            return null;
        }

        if (this.props.item.value == undefined) {
            this.props.item.value = {}; //Init array if this is the first photo
        }

        let videoCount = 0;

        if (this.props.item.value !== undefined) {
            Object.entries(this.props.item.value).map(([index, document]) => {
                if (document && !document['deleted'] && document['fbstorage']) {
                    videoCount++; // 2022-01-03 (JJ): Manage photoCount variable instead of .length() which may include deleted photos.
                }
            });
        }

        let countminmax = null;
        let metRequiredField = false;
        let maxValueReached = false;
        if (this.props.item.value) {
            if (this.props.item.mandatory) {
                if (videoCount < this.props.item.minvalue) {
                    countminmax = `${videoCount}/${this.props.item.minvalue}`;
                    metRequiredField = false;
                } else {
                    metRequiredField = true;
                    countminmax = `${videoCount}/${this.props.item.maxvalue}`;
                }
            } else {
                countminmax = `${videoCount}`;
                metRequiredField = videoCount > 0;
            }

            maxValueReached = this.props.item.maxvalue > 0 && videoCount >= this.props.item.maxvalue;
        }

        if (this.props.item.value) {
            if (this.props.item.maxvalue && this.props.item.maxvalue > 0 && videoCount == this.props.item.maxvalue) {
                this.props.editable = false;
            }
        }

        return (
            <div>
                <CulverdocsFieldCaption
                    brandData={this.props.brandData}
                    caption={`${this.props.item.caption}`}
                    userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                    mandatory={this.props.item.mandatory}
                    editable={this.props.editable}
                    // badge={countminmax}
                    hasValue={metRequiredField}
                />

                <div style={styles.fieldContainer}>
                    <div
                        // horizontal={true}
                        showsHorizontalScrollIndicator={true}
                        style={{ display: 'flex' }}
                    >
                        {this.props.editable && !maxValueReached && (
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    justifyContent: 'space-evenly'
                                }}
                            >
                                <div
                                    onClick={() => {
                                        this.setState({ renderModal: true });
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={['fad', 'video']}
                                        style={{ width: 40, height: 40 }}
                                        color={this.props.brandData?.primarycolour}
                                    />
                                </div>
                            </div>
                        )}
                        {this.props.editable && !maxValueReached && (
                            <div
                                style={{
                                    borderLeftWidth: 1,
                                    borderColor: this.props.brandData?.primarycolour,
                                    marginLeft: 7,
                                    marginRight: 10,
                                    marginBottom: 5,
                                    marginTop: 5
                                }}
                            />
                        )}

                        {this.props.item.value &&
                            Object.entries(this.props.item.value).map(([itemIndex, file]) => {
                                return (
                                    <div
                                        key={itemIndex}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            paddingLeft: 10,
                                            position: 'relative',
                                            alignItems: 'center'
                                        }}
                                        horizontal={true}
                                    >
                                        <div
                                            onClick={() => {
                                                if (file.state == 'complete' && file.fbstorage) {
                                                    // 2024-05-01 (JJ): Video Field - adding a "Delete Video" confirmation when selecting a video (Need to implement video viewer)
                                                    this.deleteFile(file, itemIndex);
                                                    // alert(
                                                    //     translate('Delete Video'),
                                                    //     translate('This will delete the selected video, are you sure?'),
                                                    //     [
                                                    //         {
                                                    //             text: 'Cancel',
                                                    //             style: 'cancel',
                                                    //             onPress: () => {}
                                                    //         },
                                                    //         {
                                                    //             text: 'Yes',
                                                    //             onPress: () => {
                                                    //                 this.deleteFile(file, itemIndex);
                                                    //             }
                                                    //         }
                                                    //     ],
                                                    //     { cancelable: true }
                                                    // );
                                                }
                                            }}
                                        >
                                            {file.state == 'compressing' && (
                                                <div style={styles.fieldDocument}>
                                                    <Spinner
                                                        brandData={this.props.brandData}
                                                        type={'FadingCircleAlt'}
                                                    />
                                                    <p
                                                        style={styles.fieldDocumentState}
                                                        numberoflines={1}
                                                    >
                                                        Preparing
                                                    </p>
                                                    <p
                                                        style={styles.fieldDocumentText}
                                                        numberoflines={1}
                                                    >
                                                        {this.props.item.value[itemIndex]['uploadPercent']}%
                                                    </p>
                                                </div>
                                            )}

                                            {file.state == 'uploading' && (
                                                <div style={styles.fieldDocument}>
                                                    <Spinner
                                                        brandData={this.props.brandData}
                                                        type={'FadingCircleAlt'}
                                                    />
                                                    <p
                                                        style={styles.fieldDocumentState}
                                                        numberoflines={1}
                                                    >
                                                        Uploading
                                                    </p>
                                                    <p
                                                        style={styles.fieldDocumentText}
                                                        numberoflines={1}
                                                    >
                                                        {this.props.item.value[itemIndex]['uploadPercent']}%
                                                    </p>
                                                </div>
                                            )}

                                            {file.state == 'complete' && (
                                                <div style={styles.fieldDocument}>
                                                    <FontAwesomeIcon
                                                        icon={['fad', 'file']}
                                                        style={{ width: 50, height: 50 }}
                                                        color={this.props.brandData?.primarycolour}
                                                    />
                                                    <p
                                                        style={styles.fieldDocumentText}
                                                        numberoflines={1}
                                                    >
                                                        {this.props.item.value[itemIndex]['name']}
                                                    </p>
                                                    <FontAwesomeIcon
                                                        icon={['fad', 'trash']}
                                                        color={Colours.RED}
                                                        style={{ ...styles.fieldDocumentIcon, ...{ height: 15, width: 15, top: 20, right: 10 } }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        <Modal
                            centered
                            show={this.state.renderModal}
                            // visible={this.state.renderQRScanModal}
                            // onRequestClose={() => {
                            //     this.setState({ renderQRScanModal: false });
                            // }}
                            // animationType="fade"
                            // transparent={true}
                        >
                            <div style={styles.modalBackground}>
                                <div style={styles.modalContainer}>
                                    <div style={styles.modalHeader}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 0.1,
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                paddingLeft: 5
                                            }}
                                        >
                                            <div
                                                hitslop={{
                                                    left: Size.CountScale(10),
                                                    right: Size.CountScale(10),
                                                    bottom: Size.CountScale(10),
                                                    top: Size.CountScale(10)
                                                }}
                                                onClick={() =>
                                                    this.setState({
                                                        renderModal: false
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fad', 'circle-chevron-left']}
                                                    color={Colours.WHITE}
                                                    style={{
                                                        alignSelf: 'flex-start',
                                                        cursor: 'pointer',
                                                        marginLeft: 5,
                                                        width: 25,
                                                        height: 25
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, marginLeft: 5, alignItems: 'center' }}>
                                            <p style={{ ...styles.modalHeaderText, ...{ margin: 0, lineHeight: 1, marginBottom: 4 } }}>{translate(this.props.item.caption)}</p>
                                        </div>
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <Webcam
                                            audio={false}
                                            ref={this.webcamRef}
                                            style={{ display: 'block', position: 'relative', width: '100%', height: '100%' }}
                                        />
                                        <div style={{ position: 'absolute', top: 10, left: '50%', transform: 'translateX(-50%)' }}>{this.formatTime(this.state.recordingTime)}</div>
                                        {this.state.capturing ? (
                                            <FontAwesomeIcon
                                                icon={['fa-regular', 'fa-stop-circle']}
                                                color={Colours.RED}
                                                style={{
                                                    alignSelf: 'flex-start',
                                                    marginLeft: 10,
                                                    width: 60,
                                                    height: 60,
                                                    position: 'absolute',
                                                    bottom: 30,
                                                    left: '40%',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={this.handleStopCaptureClick}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={['fa-regular', 'fa-circle-dot']}
                                                color={this.props.brandData?.primarycolour}
                                                style={{
                                                    alignSelf: 'flex-start',
                                                    marginLeft: 10,
                                                    width: 60,
                                                    height: 60,
                                                    position: 'absolute',
                                                    bottom: 30,
                                                    left: '40%',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={this.handleStartCaptureClick}
                                            />
                                        )}
                                        {this.state.recordedChunks.length > 0 && !this.state.capturing && (
                                            <div>
                                                <FontAwesomeIcon
                                                    icon={['fa-regular', 'fa-circle-check']}
                                                    color={Colours.GREEN}
                                                    style={{
                                                        alignSelf: 'flex-start',
                                                        marginLeft: 10,
                                                        width: 60,
                                                        height: 60,
                                                        position: 'absolute',
                                                        bottom: 30,
                                                        left: '10%',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={this.handleDownload}
                                                />
                                                <FontAwesomeIcon
                                                    icon={['fa-solid', 'fa-rotate-reverse']}
                                                    color={Colours.RED}
                                                    style={{
                                                        alignSelf: 'flex-start',
                                                        marginLeft: 10,
                                                        width: 60,
                                                        height: 60,
                                                        position: 'absolute',
                                                        bottom: 30,
                                                        right: '10%',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        this.setState({ recordedChunks: [], capturing: true });
                                                    }}
                                                />
                                            </div>
                                            // <button
                                            //     onClick={this.handleDownload}
                                            //     style={{ position: 'absolute', bottom: 10, left: '40%', height: 40, padding: 10 }}
                                            // >
                                            //     Upload
                                            // </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }

    deleteFile = async (file, itemIndex) => {
        if (!file.fbstorage) {
            return; //Cannot delete as we don't have FB storage.
        }

        if (file.storage) {
            // We have storage, need to soft delete as it is on GCP Storage
            this.props.item.value[itemIndex].deleted = true;
            // 2024-01-09 (JJ): Global update onFieldSoftDeletePhoto() to onFieldUpdateValueIndex() as this is used for Files, Photo Updates, etc.
            this.props.onFieldUpdateValueIndex(this.props.item, this.props.pItem, this.props.rIndex, itemIndex, this.props.item.value[itemIndex]);
        } else {
            // The file is only on FB Storage, delete the file directly and the key
            const storageobject = sRef(storage, file.fbstorage);
            await deleteObject(storageobject);
            delete this.props.item.value[itemIndex];
            this.props.onFieldDeleteChild(this.props.item, this.props.pItem, this.props.rIndex, itemIndex);
        }

        this.forceUpdate();
    };
}

export default CulverdocsVideo;
const getStyle = (brandData) => {
    return Stylesheet.create({
        fieldContainer: {
            display: 'flex',
            height: 100,
            flexDirection: 'row',
            width: '100%',

            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            backgroundColor: Colours.WHITE,
            borderRadius: 5,
            elevation: 5,
            marginBottom: 10, // 2023-09-03 (JJ): Standardising marginBottom:10 on all components which were 15 as there were some inconsistencies with styling
            paddingLeft: 10
        },

        fieldDocument: {
            display: 'flex',
            flexDirection: 'column',
            height: 75,
            width: 75,
            //  marginTop: 10,

            marginRight: 5,
            // borderColor: brandData?.primarycolour,
            // borderWidth: 0.5,
            border: '0.5px solid' + brandData?.primarycolour,

            borderRadius: 5,

            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center'
            // paddingLeft:10,
        },
        fieldDocumentText: {
            display: 'flex',
            flexDirection: 'column',
            color: Colours.WHITE,
            backgroundColor: brandData?.primarycolour,
            // fontSize: 10,
            fontFamily: Font.family.light,
            fontSize: Font.size.small,
            //lineHeight: 20,
            paddingLeft: 5,
            position: 'absolute',
            opacity: 0.7,
            width: '82%',
            maxHeight: 20,
            alignSelf: 'center',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            top: 40
        },
        fieldDocumentIcon: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            color: Colours.RED,
            position: 'absolute',
            right: 5,
            top: 5
        },
        modalBackground: {
            display: 'flex',
            flex: 1,
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: Colours.MODALBG,
            padding: 0
        },
        modalContainer: {
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            // borderRadius: 5,
            width: '100%',
            height: '100%',
            elevation: 5
            // margin: 20
            //  width: '100%',
            // maxWidth: 300
        },
        modalHeader: {
            display: 'flex',
            height: 50,
            alignSelf: 'stretch',
            backgroundColor: brandData?.primarycolour,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,

            justifyContent: 'center',
            alignContent: 'center',

            // fl`ex:1,
            flexDirection: 'row'
        },
        modalHeaderText: {
            fontFamily: Font.family.bold,
            fontSize: Font.size.medium,
            color: Colours.WHITE,
            lineHeight: 1
        },
        modalContent: {
            // padding: 20,
            backgroundColor: 'transparent',
            maxWidth: 300
        }
    });
};
