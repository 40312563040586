import React, { Component } from 'react';
import Font from '../../../Constant/font';
import Size from '../../../Constant/size';

import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { translate } from '../../../Common/CulverdocsTranslate';
import FlatList from 'flatlist-react';
import { storage } from '../../../firebase';
import { deleteObject, ref as sRef } from 'firebase/storage';
import { uploadBytesResumable } from 'firebase/storage';
import { Spinner } from '../../../Common/Loader';
class CulverdocsFile extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
        return this.props.visible !== prevProps.visible || this.props.itemValue !== prevProps.itemValue || this.props.caption !== prevProps.caption;
    }
    state = {
        gallerRef: false
    };
    render() {
        const styles = getStyle(this.props.brandData);
        if (!this.props.visible) {
            return null;
        }

        if (this.props.item.value == undefined) {
            this.props.item.value = {}; //Init array if this is the first photo
        }

        let documentCount = 0; // 2022-01-03 (JJ): Manage documentCount variable instead of .length() which may include deleted files.

        if (this.props.item.value !== undefined) {
            Object.entries(this.props.item.value).map(([index, document]) => {
                if (document && !document['deleted'] && document['fbstorage']) {
                    documentCount++; // 2022-01-03 (JJ): Manage photoCount variable instead of .length() which may include deleted photos.
                }
            });
        }

        let countminmax = null;
        let metRequiredField = false;
        let maxValueReached = false;
        if (this.props.item.value) {
            if (this.props.item.mandatory) {
                if (documentCount < this.props.item.minvalue) {
                    countminmax = `${documentCount}/${this.props.item.minvalue}`;
                    metRequiredField = false;
                } else {
                    metRequiredField = true;
                    countminmax = `${documentCount}/${this.props.item.maxvalue}`;
                }
            } else {
                countminmax = `${documentCount}`;
                metRequiredField = documentCount > 0;
            }

            maxValueReached = this.props.item.maxvalue > 0 && documentCount >= this.props.item.maxvalue;
        }

        if (this.props.item.value) {
            if (this.props.item.maxvalue && this.props.item.maxvalue > 0 && documentCount == this.props.item.maxvalue) {
                this.props.editable = false;
            }
        }
        const acceptTypes = this.props.item.documentfileextensions
            .split(',')
            .map((type) => `.${type}`)
            .join(',');

        return (
            <div>
                <CulverdocsFieldCaption
                    brandData={this.props.brandData}
                    caption={`${this.props.item.caption}`}
                    userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                    mandatory={this.props.item.mandatory}
                    editable={this.props.editable}
                    badge={countminmax}
                    hasValue={metRequiredField}
                />

                <div style={styles.fieldContainer}>
                    <div
                        horizontal={true}
                        showsHorizontalScrollIndicator={true}
                        style={{ display: 'flex' }}
                    >
                        {this.props.editable && !maxValueReached && (
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    justifyContent: 'space-evenly'
                                }}
                            >
                                <div
                                    onClick={() => {
                                        this.state.gallerRef.click();
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={['fad', 'file-circle-plus']}
                                        style={{ width: 40, height: 40 }}
                                        color={this.props.brandData?.primarycolour}
                                    />
                                    <Form.Control
                                        ref={(ref) => (this.state.gallerRef = ref)}
                                        type="file"
                                        hidden={true}
                                        id="galleryyy"
                                        accept={acceptTypes}
                                        onChange={(event) => {
                                            const file = event.target.files[0];
                                            this.openDocumentPicker(file);
                                            // if (file) {
                                            //     const reader = new FileReader();
                                            //     reader.onloadend = () => {
                                            //         //  this.setState({ imagePreview: reader.result });
                                            //         console.log('data for allery ', reader.result);
                                            //         //    this.openDocumentPicker(reader.result.split(',')[1]);
                                            //     };
                                            //     reader.readAsDataURL(file);
                                            // }
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {this.props.editable && !maxValueReached && (
                            <div
                                style={{
                                    borderLeftWidth: 1,
                                    borderColor: this.props.brandData?.primarycolour,
                                    marginLeft: 7,
                                    marginRight: 10,
                                    marginBottom: 5,
                                    marginTop: 5
                                }}
                            />
                        )}

                        {this.props.item.value &&
                            Object.entries(this.props.item.value).map(([itemIndex, file]) => {
                                return (
                                    <div
                                        key={itemIndex}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            paddingLeft: 10,
                                            position: 'relative',
                                            alignItems: 'center'
                                        }}
                                        horizontal={true}
                                    >
                                        <div
                                            onClick={() => {
                                                if (file.uploadComplete && file.fbstorage) {
                                                    this.deleteFile(file, itemIndex);
                                                    // 2024-05-01 (JJ): CulverdocsFile Field - adding a "Delete File" confirmation when selecting an uploaded file
                                                }
                                            }}
                                        >
                                            {file.uploadComplete && (
                                                <div style={styles.fieldDocument}>
                                                    <FontAwesomeIcon
                                                        icon={['fad', 'file']}
                                                        style={{ width: 50, height: 50 }}
                                                        color={this.props.brandData?.primarycolour}
                                                    />
                                                    <p
                                                        style={styles.fieldDocumentText}
                                                        numberoflines={1}
                                                    >
                                                        {this.props.item.value[itemIndex]['name']}
                                                    </p>
                                                    <FontAwesomeIcon
                                                        icon={['fad', 'trash']}
                                                        color={Colours.RED}
                                                        style={{ ...styles.fieldDocumentIcon, ...{ height: 15, width: 15, top: 20, right: 10 } }}
                                                    />
                                                </div>
                                            )}

                                            {!file.uploadComplete && (
                                                <div style={styles.fieldDocument}>
                                                    <Spinner
                                                        brandData={this.props.brandData}
                                                        type={'FadingCircleAlt'}
                                                    />
                                                    <p
                                                        style={styles.fieldDocumentText}
                                                        numberoflines={1}
                                                    >
                                                        {this.props.item.value[itemIndex]['uploadPercent']}%
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        );
    }

    openDocumentPicker = async (document) => {
        //  2024-01-18 (JJ): Fix for File/openDocumentPicker() now correctly checks NetInfo connection state before returning upload error

        try {
            // let document = await DocumentPicker.pickSingle({
            //     presentationStyle: 'fullScreen',
            //     copyTo: 'cachesDirectory',
            //     type: acceptedTypes
            // });
            if (document) {
                let filesizeMB = parseFloat(document.size / 1024 / 1024).toFixed(2);
                // 2024-01-18 (JJ): File upload - Fixed issue with filesizeMB checks, floats documentmaxfilesizemb as it was incorrect on iOS file sizes
                if (this.props.item.documentmaxfilesizemb && filesizeMB > parseFloat(this.props.item.documentmaxfilesizemb)) {
                    alert(translate('Large File\n') + translate('You cannot upload this file as it exceeds the maximum file size:') + ` ${filesizeMB}mb/${this.props.item.documentmaxfilesizemb}mb`);
                    return;
                }

                // var path = decodeURI(document.fileCopyUri);
                // var exists = true; //await RNFS.exists(path); //Wait for the document to be copied.
                // if (!exists) {
                //     return; //Dont return if we cant find the image path.
                // }
                let timestamp = new Date().getTime();
                let recordKey = await this.props.getKeyForCurrentRecord();
                let fileUploadBucketPath = `/users/${this.props.userId}/${recordKey}/${timestamp}-${document.name}`;
                let fileName = `${timestamp}-${document.name}`;
                let newDocument = {
                    name: document.name,
                    filename: fileName,
                    type: document.type,
                    timestamp: timestamp,
                    size: document.size,
                    sizeMB: filesizeMB,
                    uploadComplete: false,
                    uploadPercent: 0
                };
                this.props.item.value[timestamp] = newDocument;
                // Now upload to storage
                //   const storageobject = storage().ref(fileUploadBucketPath);
                const storageobject = sRef(storage, fileUploadBucketPath);
                const uploadTask = uploadBytesResumable(storageobject, document);

                // let uploadTask = storageobject.putFile(document.fileCopyUri);
                uploadTask.on(
                    'state_changed',
                    (taskSnapshot) => {
                        let percent = parseFloat((taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 100).toFixed(0);
                        this.props.item.value[timestamp].uploadPercent = percent;
                        this.forceUpdate();
                    },
                    (error) => {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/unauthorized':
                                    console.log(" User doesn't have permission to access the object");
                                break;
                            case 'storage/canceled':
                                    console.log(' User canceled the upload');
                                break;
                            case 'storage/unknown':
                                // Unknown error occurred, inspect error.serverResponse
                                    console.log(' Unknown error occurred, inspect error.serverResponse');
                                break;
                            default:
                                break;
                        }
                    }
                );
                uploadTask.then(
                    (taskSnapshot) => {
                        delete this.props.item.value[timestamp].uploadPercent;
                        this.props.item.value[timestamp].fbstorage = taskSnapshot.metadata.fullPath; //Set the path once file upload is complete..
                        this.props.item.value[timestamp].uploadComplete = true;
                        console.log('data render', this.props.item, this.props.pItem, this.props.rIndex, timestamp, this.props.item.value[timestamp]);
                        this.props.onFieldNewChild(this.props.item, this.props.pItem, this.props.rIndex, timestamp, this.props.item.value[timestamp]);
                        this.forceUpdate();
                    },
                    (error) => {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/unauthorized':
                                    console.log(" User doesn't have permission to access the object222");
                            break;
                            case 'storage/canceled':
                                console.log(' User canceled the upload222'); 
                            break;
                            case 'storage/unknown':
                                console.log(' Unknown error occurred, inspect error.serverResponse222');
                                
                            break;
                            default:
                                break;
                        }
                    }
                );
            }
        } catch (err) {
            console.log('err from file', err);
            // User has likely cancelled the document picker or there was a problem with the upload.
        }
        // } else {
        //     alert(
        //         translate("Unable to upload"),
        //         translate(
        //             "You are unable to upload files as you are currently offline. Please check your connection and try again."
        //         )
        //     );
        //return false;
    };
    // });

    deleteFile = async (file, itemIndex) => {
        if (!file.fbstorage) {
            return; //Cannot delete as we don't have FB storage.
        }

        if (file.storage) {
            // We have storage, need to soft delete as it is on GCP Storage
            this.props.item.value[itemIndex].deleted = true;
            // 2024-01-09 (JJ): Global update onFieldSoftDeletePhoto() to onFieldUpdateValueIndex() as this is used for Files, Photo Updates, etc.
            this.props.onFieldUpdateValueIndex(this.props.item, this.props.pItem, this.props.rIndex, itemIndex, this.props.item.value[itemIndex]);
        } else {
            // The file is only on FB Storage, delete the file directly and the key
            const storageobject = sRef(storage, file.fbstorage);
            await deleteObject(storageobject);
            delete this.props.item.value[itemIndex];
            this.props.onFieldDeleteChild(this.props.item, this.props.pItem, this.props.rIndex, itemIndex);
        }

        this.forceUpdate();
    };
}

export default CulverdocsFile;

const getStyle = (brandData) => {
    return Stylesheet.create({
        fieldContainer: {
            display: 'flex',
            height: 100,
            flexDirection: 'row',
            width: '100%',

            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            backgroundColor: Colours.WHITE,
            borderRadius: 5,
            elevation: 5,
            marginBottom: 10, // 2023-09-03 (JJ): Standardising marginBottom:10 on all components which were 15 as there were some inconsistencies with styling
            paddingLeft: 10
        },

        fieldDocument: {
            display: 'flex',
            flexDirection: 'column',
            height: 75,
            width: 75,
            //  marginTop: 10,

            marginRight: 5,
            // borderColor: brandData?.primarycolour,
            // borderWidth: 0.5,
            border: '0.5px solid' + brandData?.primarycolour,

            borderRadius: 5,

            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center'
            // paddingLeft:10,
        },
        fieldDocumentText: {
            display: 'flex',
            flexDirection: 'column',
            color: Colours.WHITE,
            backgroundColor: brandData?.primarycolour,
            // fontSize: 10,
            fontFamily: Font.family.light,
            fontSize: Font.size.small,
            //lineHeight: 20,
            paddingLeft: 5,
            position: 'absolute',
            opacity: 0.7,
            width: '82%',
            alignSelf: 'center',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            top: 40
        },
        fieldDocumentIcon: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            color: Colours.RED,
            position: 'absolute',
            right: 5,
            top: 5
        }
    });
};
