import React, { Component, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateUserAuthenticated, updateUserProfile, updateUserData, updateUserBranding, updateUserForms } from '../Redux/actions/firebaseActions';
//import { toggleListenerUser } from "@src/Redux/actions/listenerActions";

// import auth, { getAuth } from "@react-native-firebase/auth";
// import app, { utils } from "@react-native-firebase/app"; //Firebase
// import database from "@react-native-firebase/database";

// import { updateDeviceInfo } from "./updateDeviceInfo";
import { getDatabase, ref, child, push, update, onValue, onChildAdded, onChildChanged, onChildRemoved } from 'firebase/database';

//import DeviceInfo from "react-native-device-info";
import { auth, database } from '../firebase';
import { removeAllLocalStorage, removeLocalStorage, setLocalStorage } from '../Helper/localStorage';
import { addTranslations, getNativeDeviceLanguage, supportedLanguage } from '../Common/CulverdocsTranslate';

// import {
//     supportedLanguage,
//     getNativeDeviceLanguage,
//     addTranslations,
// } from "@src/Helper/CulverdocsTranslate";

// Firebase Globals
var currentUser = null;
var userId = null;
var accountId = null;

// Listeners
var authListener = null;
var brandingListener = null;
var formListener = null;
var profileListener = null;
var translationListener = null;
var dataListener_child_added = null;
var dataListener_child_changed = null;
var dataListener_child_removed = null;
var deviceIdListener = null;

var userProfileData = {};
var brandDataValue = {};

// Redux
var dispatch = null;
var userDataRecords = [];

export async function setDispatch(pDispatch) {
    dispatch = pDispatch; //Set the global in FirebaseListenerControl
}

export async function startFirebaseListeners() {
    removeLocalStorage('userProfiles');
    // First, confirm the user is signed in and get the account ID.
    currentUser = auth.currentUser;
    if (!currentUser) {
        return false;
    }


    // Store the uid
    userId = currentUser.uid;
    if (!userId) {
        return false;
    }

    // Get the accountID from Custom Claims.
    let tokenResult = await currentUser.getIdTokenResult();
    accountId = tokenResult.claims.accountid;

    if (!accountId) {
        return false;
    }

    // Start the listeners which maintain the Redux State
    // --------------------------------------------------
    // Branding
    brandingListener = ref(database, `/accounts/${accountId}/branding/`);

    onValue(brandingListener, async (data) => {
        if (data) {
            data = data.val();
            setLocalStorage('brandData', data);
            brandDataValue = data;
            // dispatch(updateUserBranding(true, data));
        }
    });

    profileListener = ref(database, `/users/${userId}/profile`);
    onValue(profileListener, async (data) => {
        data = data.val();
        data = await prepareProfileData(currentUser, data);

        if (data.deviceid == '') {
            removeAllLocalStorage();
            await auth.signOut();
        }
        setLocalStorage('userProfiles', data);
        // dispatch(updateUserProfile(data));
        userProfileData = data;
    });

    // Load Translations

    if (supportedLanguage()) {
        let language = getNativeDeviceLanguage();
        translationListener = database();
        ref(database, `/accounts/${accountId}/translations/${language}/`);
        onValue(translationListener, async (data) => {
            if (data) {
                data = data.val();
                addTranslations(data);
            }
        });
    }

    return userProfileData;
}

export async function startFirebaseUserDataListeners(userId) {
    dataListener_child_added = ref(database, `/users/${userId}/data/`);
    onChildAdded(dataListener_child_added, async (data) => {

        if (data) {
            data = await prepareRecordKeyUpdateAdd(data);

            let keyToUpdateIndex = userDataRecords.findIndex((item) => item && item.key == data.key);
            if (keyToUpdateIndex === -1) {
                userDataRecords.push(data);
            } else {
                userDataRecords[keyToUpdateIndex] = data;
            }

        }
    });

    dataListener_child_changed = ref(database, `/users/${userId}/data/`);
    onChildChanged(dataListener_child_changed, async (data) => {

        data = await prepareRecordKeyUpdateAdd(data);

        let keyToUpdateIndex = userDataRecords.findIndex((item) => item.key == data.key);
        if (keyToUpdateIndex !== -1) {
            userDataRecords[keyToUpdateIndex] = data;
        }
    });

    dataListener_child_removed = ref(database, `/users/${userId}/data/`);
    onChildRemoved(dataListener_child_removed, async (data) => {
        let keyToUpdateIndex = userDataRecords.findIndex((item) => item.key === data.key);
        if (keyToUpdateIndex !== -1) {
            userDataRecords.splice(keyToUpdateIndex, 1);
        }
    });
    return userDataRecords;
}

async function prepareProfileData(authUser, userProfile) {
    // Append userId to userProfile
    userProfile.userId = authUser.uid;

    // Prepare the profile data (handle and adjustments)
    userProfile.homescreen = userProfile.taskviewid ? 'TaskViewHome' : 'HomeScreen';

    // Set Internal Placeholders
    userProfile.internalPlaceholders = {
        'Account.Name': userProfile.accountname,
        'Device.Name': userProfile.name,
        'Device.Email': authUser.email,
        'User.Name': userProfile.name,
        'User.Email': authUser.email
    };

    // Append userproperties into internalPlaceholders
    if (userProfile.userproperties) {
        for (const [key, value] of Object.entries(userProfile.userproperties)) {
            if (key && value) {
                userProfile.internalPlaceholders[`User.${key}`] = value;
            }
        }
    }

    // TODO: Need to implement cleardown of /tmp directory on loading the app. This should auto clear but we can cleardown to keep the storage down before OS clears due to size/age.
    // userProfile._tmpFiles = `${utils.FilePath.CACHES_DIRECTORY}`;

    return userProfile;
}

async function prepareRecordKeyUpdateAdd(data) {
    console.log('prepareRecordKeyUpdateAdd() for key:', data.key);
    let recData = data.val();
    return {
        key: data.key,
        name: recData.name,
        timestamp: recData.timestamp,
        title: recData.title,
        state: recData.state,
        sendto: recData.sendto,
        formid: recData.formid,
        confidential: recData.confidential,
        requesticon: recData.requesticon, // 2023-07-01 (JJ): Fix for missing requesticon and requesticoncolour not showing on requests
        requesticoncolour: recData.requesticoncolour, // 2023-07-01 (JJ): Fix for missing requesticon and requesticoncolour not showing on requests
        requestcategory: recData.requestcategory // 2023-07-08 (JJ): Fix for requestcategory not being passed into component class for caption and filtering
    };
}
