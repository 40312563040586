import React, { Component } from 'react';
// import { p, div, div, AppState, TextInput, ActivityIndicator, Modal, StyleSheet } from 'react-native';
// import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
// //import QRCodeScanner from 'react-native-qrcode-scanner'; // 2022-07-03 (RB): This is replace by camera vision package
// import database from '@react-native-firebase/database';
// import { lightenDarkenColor } from '@src/Helper/lightenDarkenColor';
// import { Colours, Font, Size } from '@src/Constant';
// import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
// import CulverdocsBarcodescannerComponent from './CulverdocsBarcodescannerComponent';
// import { connect } from 'react-redux';
// import AlertAsync from 'react-native-alert-async';
// import { translate } from '@src/Helper/CulverdocsTranslate';
// import QRCodeScanner from 'react-native-qrcode-scanner'; // 2022-07-03 (JJ): Adding QR Code field type
import Font from '../../../Constant/font';
import Size from '../../../Constant/size';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { translate } from '../../../Common/CulverdocsTranslate';
import { Scanner } from '@yudiel/react-qr-scanner';

class CulverdocsQRCode extends Component {
    state = {
        renderQRScanModal: false,
        scanProcessing: false
    };

    shouldComponentUpdate(prevProps, prevState) {
        // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
        return this.state !== prevState || this.props.visible !== prevProps.visible || this.props.itemValue !== prevProps.itemValue || this.props.caption !== prevProps.caption;
    }

    render() {
        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption}`);

        if (!this.props.visible) {
            return null;
        }

        var height = 350;

        // 2023-02-26 (JJ): Adding QR Source Type to QR Field - Camera or Manual for Keyboard Wedge - Reducing to single input height for manual scan.
        let scanType;
        if (this.props.item && (!this.props.item.qrsourcetype || (this.props.item.qrsourcetype && this.props.item.qrsourcetype == 'Camera'))) {
            scanType = 'Camera';
        } else {
            scanType = 'Manual';
            height = 120;
        }
        const styles = getStyle(this.props.brandData);

        return (
            <div>
                <CulverdocsFieldCaption
                    brandData={this.props.brandData}
                    caption={this.props.item.caption}
                    userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                    mandatory={this.props.item.mandatory}
                    editable={this.props.editable}
                    hasValue={this.props.item.value ? true : false}
                />

                {/* Editable */}
                {this.props.editable && (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1
                            }}
                        >
                            {scanType == 'Camera' && (
                                <div
                                    onClick={async () => {
                                        if (this.props.item.state == 'scanning') {
                                            return;
                                        }
                                        this.props.item.value = '';
                                        this.setState({
                                            renderQRScanModal: true
                                        });
                                        // this.setState({ renderQRScanModal: true, item: this.props.item, pItem: pItem })
                                        // this.props.item.state = "scanning";
                                        // this.forceUpdate();
                                    }}
                                    style={styles.fieldContainerSelect}
                                    value={this.props.item.value}
                                >
                                    <p style={styles.fieldText}>{this.props.item.value ? this.props.item.value : ''}</p>
                                    {/* // 2023-12-18 (JJ): NFE - Fix for Select and QR fields with long values, now uses minHeight instead of height. - QR button middle aligned for long text */}
                                    <div
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fad', 'qrcode']}
                                            color={lightenDarkenColor(this.props.brandData?.primarycolour, 50)}
                                            style={{
                                                alignSelf: 'flex-end',
                                                right: 10
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {scanType == 'Manual' && (
                                // TODO: Need to disable keyboard for manual input (this will be via external scanner)
                                // <TextInput
                                //     style={styles.fieldContainerText}
                                //     defaultValue={this.props.item.value}
                                //     returnKeyType={"done"} //v1.1.6 - changed to "Done" field
                                //     onChangeText={(text) => {
                                //         this.props.item.value = text;
                                //         if (text === "") {
                                //             this.props.item.value = null;
                                //         }
                                //     }}
                                //     onEndEditing={async () => {
                                //         // Validate input on EndEditing with the value if we have a list name.
                                //         if (
                                //             this.props.item.listname &&
                                //             this.props.item.value
                                //         ) {
                                //             await this.validateQRCodeInput(
                                //                 this.props.item,
                                //                 this.props.item.value,
                                //                 false
                                //             );
                                //         }

                                //         this.props.item.timestamp =
                                //             new Date().getTime(); // 2023-10-14 (JJ): NFE - Fix for QR Code - now correctly capture timestamp regardless of camera/manual and with or without list validation

                                //         this.props
                                //             .onFieldChanged(
                                //                 this.props.item,
                                //                 this.props.pItem,
                                //                 this.props.rIndex
                                //             )
                                //             .then(() => {
                                //                 // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                                //                 if (this.props.pItem) {
                                //                     this.props.forceRepeaterChildUpdate();
                                //                 }
                                //             });
                                //         this.forceUpdate();
                                //     }}
                                // />
                                <div></div>
                            )}
                        </div>
                    </div>
                )}

                {/* Non-editable */}
                {!this.props.editable && (
                    <div style={styles.fieldContainerSelect}>
                        <p style={styles.fieldNonEditable}>{this.props.item.value}</p>
                    </div>
                )}
                <Modal
                    centered
                    show={this.state.renderQRScanModal}
                    // visible={this.state.renderQRScanModal}
                    // onRequestClose={() => {
                    //     this.setState({ renderQRScanModal: false });
                    // }}
                    // animationType="fade"
                    // transparent={true}
                >
                    <div style={styles.modalBackground}>
                        <div style={styles.modalContainer}>
                            <div style={styles.modalHeader}>
                                <div
                                    style={{
                                        display: 'flex',
                                        //flex: 0.1,
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                        //  paddingLeft: 5
                                    }}
                                >
                                    <div
                                        hitslop={{
                                            left: Size.CountScale(10),
                                            right: Size.CountScale(10),
                                            bottom: Size.CountScale(10),
                                            top: Size.CountScale(10)
                                        }}
                                        onClick={() =>
                                            this.setState({
                                                renderQRScanModal: false
                                            })
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={['fad', 'circle-chevron-left']}
                                            color={Colours.WHITE}
                                            style={{
                                                marginLeft: 10,
                                                width: 25,
                                                height: 25,
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginLeft: 10, padding: 0 }}>
                                    <p
                                        style={styles.modalHeaderText}
                                        numberoflines={1}
                                    >
                                        {translate(this.props.item.caption)}
                                    </p>
                                </div>
                            </div>

                            {this.state.renderQRScanModal && (
                                <div style={styles.modalContent}>
                                    {/* <QRCodeScanner
                                        ref={(node) => {
                                            this.scanner = node;
                                        }}
                                        onRead={async (e) => {
                                            this.setState({
                                                scanProcessing: true,
                                            }); //Set processing to true to hide camera during processing.

                                            // 2024-04-28 (JJ): QR Code field (Source: Camera) support for Regex Tests to validate the scanned string pattern.
                                            if (this.props.item.regextest) {
                                                const regex = new RegExp(
                                                    this.props.item.regextest
                                                );
                                                if (!regex.test(e.data)) {
                                                    // If the string does not test successfully, return an async error to the user and re-initialise the scanner afterwards.
                                                    let errorMessage = this
                                                        .props.item.regexerror
                                                        ? translate(
                                                              this.props.item
                                                                  .regexerror
                                                          )
                                                        : translate(
                                                              "The scanned value does not match the expected format."
                                                          );
                                                    const choice =
                                                        await AlertAsync(
                                                            translate(
                                                                "Scan Validation Error"
                                                            ),
                                                            errorMessage,
                                                            [
                                                                {
                                                                    text: translate(
                                                                        "OK"
                                                                    ),
                                                                    onPress:
                                                                        () =>
                                                                            "ok",
                                                                },
                                                            ],
                                                            {
                                                                cancelable: false,
                                                                onDismiss: () =>
                                                                    "no",
                                                            }
                                                        );
                                                    if (choice === "ok") {
                                                        this.scanner.reactivate();
                                                        this.setState({
                                                            scanProcessing: false,
                                                        });
                                                        return;
                                                    }
                                                }
                                            }

                                            if (this.props.item.listname) {
                                                await this.validateQRCodeInput(
                                                    this.props.item,
                                                    e.data,
                                                    true
                                                );
                                            } else {
                                                // We aren't validating against list, set value and close.
                                                this.props.item.value = e.data;
                                                this.setState({
                                                    renderQRScanModal: false,
                                                    scanProcessing: false,
                                                });
                                            }

                                            this.props.item.timestamp =
                                                new Date().getTime(); // 2023-10-14 (JJ): NFE - Fix for QR Code - now correctly capture timestamp regardless of camera/manual and with or without list validation

                                            this.props
                                                .onFieldChanged(
                                                    this.props.item,
                                                    this.props.pItem,
                                                    this.props.rIndex
                                                )
                                                .then(() => {
                                                    // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                                                    if (this.props.pItem) {
                                                        this.props.forceRepeaterChildUpdate();
                                                    }
                                                });
                                        }}
                                        fadeIn={false}
                                        showMarker={true}
                                        // flashMode={RNCamera.Constants.FlashMode.torch}
                                        containerStyle={{
                                            flex: 0,
                                            justifyContent: "center",
                                            alignItems: "center",
                                            resizeMode: "contain",
                                            overflow: "hidden",
                                        }}
                                        markerStyle={{
                                            width: 100,
                                            height: 100,
                                            borderColor:
                                                this.props.brandData
                                                    .primarycolour,
                                        }}
                                        topViewStyle={{ flex: 0 }}
                                        bottomViewStyle={{ flex: 0 }}
                                        cameraContainerStyle={{
                                            justifyContent: "center",
                                            resizeMode: "contain",
                                            overflow: "hidden",
                                            alignContent: "stretch",
                                        }}
                                        cameraStyle={{
                                            flex: 0,
                                            alignItems: "stretch",
                                            overflow: "hidden",
                                            height: 300,
                                            maxHeight: "100%",
                                            maxWidth: "100%",
                                            borderBottomRightRadius: 8,
                                            borderBottomLeftRadius: 8,
                                        }}
                                    /> */}
                                    <Scanner
                                        ref={(node) => {
                                            this.scanner = node;
                                        }}
                                        onScan={async (e) => {
                                            this.setState({
                                                scanProcessing: true
                                            }); //Set processing to true to hide camera during processing.

                                            // 2024-04-28 (JJ): QR Code field (Source: Camera) support for Regex Tests to validate the scanned string pattern.
                                            if (this.props.item.regextest) {
                                                const regex = new RegExp(this.props.item.regextest);
                                                if (!regex.test(e[0].rawValue)) {
                                                    // If the string does not test successfully, return an async error to the user and re-initialise the scanner afterwards.
                                                    let errorMessage = this.props.item.regexerror ? translate(this.props.item.regexerror) : translate('The scanned value does not match the expected format.');
                                                    // const choice =
                                                    //     await AlertAsync(
                                                    //         translate(
                                                    //             "Scan Validation Error"
                                                    //         ),
                                                    //         errorMessage,
                                                    //         [
                                                    //             {
                                                    //                 text: translate(
                                                    //                     "OK"
                                                    //                 ),
                                                    //                 onPress:
                                                    //                     () =>
                                                    //                         "ok",
                                                    //             },
                                                    //         ],
                                                    //         {
                                                    //             cancelable: false,
                                                    //             onDismiss: () =>
                                                    //                 "no",
                                                    //         }
                                                    //     );
                                                    // if (choice === "ok") {
                                                    //     this.scanner.reactivate();
                                                    //     this.setState({
                                                    //         scanProcessing: false,
                                                    //     });
                                                    //  return;
                                                    // }
                                                }
                                            }

                                            if (this.props.item.listname) {
                                                await this.validateQRCodeInput(this.props.item, e[0].rawValue, true);
                                            } else {
                                                // We aren't validating against list, set value and close.
                                                this.props.item.value = e[0].rawValue;
                                                this.setState({
                                                    renderQRScanModal: false,
                                                    scanProcessing: false
                                                });
                                            }

                                            this.props.item.timestamp = new Date().getTime(); // 2023-10-14 (JJ): NFE - Fix for QR Code - now correctly capture timestamp regardless of camera/manual and with or without list validation

                                            this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex).then(() => {
                                                // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                                                if (this.props.pItem) {
                                                    this.props.forceRepeaterChildUpdate();
                                                }
                                            });
                                        }}
                                    />
                                    {/* <Scanner
                                        ref={(node) => {
                                            this.scanner = node;
                                        }}
                                        onScan={(e) => {
                                            console.log(e);
                                            // this.setState({
                                            //     scanProcessing: true,
                                            // }); //Set processing to true to hide camera during processing.

                                            // // 2024-04-28 (JJ): QR Code field (Source: Camera) support for Regex Tests to validate the scanned string pattern.
                                            // if (this.props.item.regextest) {
                                            //     const regex = new RegExp(
                                            //         this.props.item.regextest
                                            //     );
                                            //     if (!regex.test(e.data)) {
                                            //         // If the string does not test successfully, return an async error to the user and re-initialise the scanner afterwards.
                                            //         let errorMessage = this
                                            //             .props.item.regexerror
                                            //             ? translate(
                                            //                   this.props.item
                                            //                       .regexerror
                                            //               )
                                            //             : translate(
                                            //                   "The scanned value does not match the expected format."
                                            //               );
                                            //         // const choice =
                                            //         //     await AlertAsync(
                                            //         //         translate(
                                            //         //             "Scan Validation Error"
                                            //         //         ),
                                            //         //         errorMessage,
                                            //         //         [
                                            //         //             {
                                            //         //                 text: translate(
                                            //         //                     "OK"
                                            //         //                 ),
                                            //         //                 onPress:
                                            //         //                     () =>
                                            //         //                         "ok",
                                            //         //             },
                                            //         //         ],
                                            //         //         {
                                            //         //             cancelable: false,
                                            //         //             onDismiss: () =>
                                            //         //                 "no",
                                            //         //         }
                                            //         //     );
                                            //         // if (choice === "ok") {
                                            //         //     this.scanner.reactivate();
                                            //         //     this.setState({
                                            //         //         scanProcessing: false,
                                            //         //     });
                                            //         //  return;
                                            //         // }
                                            //     }
                                            // }

                                            // if (this.props.item.listname) {
                                            //     await this.validateQRCodeInput(
                                            //         this.props.item,
                                            //         e.data,
                                            //         true
                                            //     );
                                            // } else {
                                            //     // We aren't validating against list, set value and close.
                                            //     this.props.item.value = e.data;
                                            //     this.setState({
                                            //         renderQRScanModal: false,
                                            //         scanProcessing: false,
                                            //     });
                                            // }

                                            // this.props.item.timestamp =
                                            //     new Date().getTime(); // 2023-10-14 (JJ): NFE - Fix for QR Code - now correctly capture timestamp regardless of camera/manual and with or without list validation

                                            // this.props
                                            //     .onFieldChanged(
                                            //         this.props.item,
                                            //         this.props.pItem,
                                            //         this.props.rIndex
                                            //     )
                                            //     .then(() => {
                                            //         // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                                            //         if (this.props.pItem) {
                                            //             this.props.forceRepeaterChildUpdate();
                                            //         }
                                            //     });
                                        }}
                                        // onError={(error) =>
                                        //     console.log(error?.message)
                                        // }
                                    /> */}
                                    {this.state.scanProcessing && (
                                        <div style={styles.processingOverlay}>
                                            {/* <ActivityIndicator
                                                size="large"
                                                color={
                                                    this.props.brandData
                                                        .primarycolour
                                                }
                                            /> */}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    async validateQRCodeInput(item, data, fromScanner) {
        // item.qrvalidatefield =
        //     item.qrvalidatefield !== "" ? item.qrvalidatefield : "value"; //Default lookup to value if it's not provided for any reason.
        // var list = await database()
        //     .ref(
        //         `accounts/${this.props.userProfile.accountid}/list/${item.listname}/values`
        //     )
        //     .orderByChild(item.qrvalidatefield)
        //     .equalTo(data)
        //     .once("value");
        // list = list.val();
        // // 2023-10-15 (JJ): NFE - Update to support List Filters in QR Code fields.
        // if (list && item.listfilter) {
        //     Object.filter = (obj, predicate) =>
        //         Object.keys(obj)
        //             .filter((key) => predicate(obj[key]))
        //             .reduce((res, key) => ((res[key] = obj[key]), res), {});
        //     await item.listfilter.forEach(async (filter) => {
        //         if (filter.Type == "field") {
        //             this.props
        //                 .returnFieldValueFromID(
        //                     filter.Value,
        //                     this.props.item,
        //                     this.props.pItem,
        //                     this.props.rIndex
        //                 )
        //                 .then((fieldFilter) => {
        //                     list = Object.filter(
        //                         list,
        //                         (item) =>
        //                             item[filter.Name] == fieldFilter.fieldval
        //                     );
        //                     this.validateQRCodeInputList(
        //                         item,
        //                         data,
        //                         fromScanner,
        //                         list
        //                     );
        //                 });
        //         }
        //         //Set the Name and Value from the listfilter when hardcoded with text.
        //         if (filter.Type == "text") {
        //             let fieldFilter = {
        //                 fieldname: filter.Name,
        //                 fieldval: filter.Value,
        //             };
        //             list = Object.filter(
        //                 list,
        //                 (item) =>
        //                     item[fieldFilter.fieldname] == fieldFilter.fieldval
        //             );
        //             this.validateQRCodeInputList(item, data, fromScanner, list);
        //         }
        //     });
        // } else {
        //     this.validateQRCodeInputList(item, data, fromScanner, list);
        // }
    }

    //2023-10-15 (JJ): NFE - Update to support List Filters in QR Code fields.
    async validateQRCodeInputList(item, data, fromScanner, list) {
        if (list && list[Object.keys(list)[0]]) {
            // We have found the list option, save as per Select fields with "listitem" array for dot notation and list to field..

            var listitem = list[Object.keys(list)[0]]; //Get the first key of the list response, and return into listitem.

            item.value = listitem.value;
            item.listitem = listitem;

            if (item.listtofieldvalue) {
                item.listtofieldvalue.forEach((val, index) => {
                    //val.Name = the List Field Name (e.g. Hair Colour) || val.Value = the Field ID that needs setting.
                    if (val.Name && val.Value) {
                        // 2023-12-19 (JJ): NFE - Rework Repeater (Generated), Select and QR fields to support Document IDs in Populate From List.
                        // var nameValue = item.listitem[val.Name];
                        // if (nameValue) {
                        //     this.props.setFieldDataFromID(val.Value,this.props.pItem,this.props.rIndex,{'value':nameValue});
                        // }
                        this.props.returnFieldFromID(val.Value, this.props.pItem, this.props.rIndex).then(async (destinationField) => {
                            if (destinationField.type == 'documentviewer') {
                                if (item.listitem[val.Name + '_id']) {
                                    this.props.setFieldDataFromID(val.Value, this.props.pItem, this.props.rIndex, {
                                        documentviewerfilename: item.listitem[val.Name],
                                        documentviewerfileid: item.listitem[val.Name + '_id']
                                    });
                                }
                            } else {
                                if (item.listitem[val.Name]) {
                                    this.props.setFieldDataFromID(val.Value, this.props.pItem, this.props.rIndex, { value: item.listitem[val.Name] });
                                }
                            }
                        });
                    }
                });
            }

            this.setState({ renderQRScanModal: false, scanProcessing: false });
        } else {
            if (!fromScanner) {
                // Close the scanner if the ID isn't found, they can re-open to re-focus on the TextInput
                this.setState({
                    renderQRScanModal: false,
                    scanProcessing: false
                });
            } else {
                // 2022-07-27 (JJ): Adding QRValidateFieldErrorText to QR/Barcode field to return a custom error
                //     const choice = await AlertAsync(
                //         ``,
                //         `${translate(item.qrvalidatefielderrortext)}`,
                //         [{ text: translate("OK"), onPress: () => "ok" }],
                //         {
                //             cancelable: false,
                //             onDismiss: () => "no",
                //         }
                //     );
                //     if (choice === "ok" && fromScanner) {
                //         this.scanner.reactivate();
                //         this.setState({ scanProcessing: false });
                //     }
            }
        }
    }
}

export default CulverdocsQRCode;
const getStyle = (brandData) => {
    return Stylesheet.create({
        fieldNonEditable: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            // backgroundColor: Colours.WHITE,
            color: Colours.GREY
        },
        // fieldNonEditableView : {
        //     backgroundColor: Colours.MIDGREY,
        //     marginBottom: 10,
        //     paddingTop:10, //for iOS
        //     paddingBottom:10, //for iOS
        //     paddingLeft:5, //for iOS

        //     height: 45,
        //     borderWidth: 0.5,
        //     borderColor: brandData?.primarycolour,
        //     borderRadius: 5,
        //     elevation:5,
        // },

        fieldContainerSelect: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: Colours.WHITE,
            marginBottom: 10,
            paddingTop: 10, //for iOS
            paddingBottom: 10, //for iOS
            paddingLeft: 5, //for iOS
            paddingRight: 10, //for iOS

            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            borderRadius: 4,
            elevation: 3,
            minHeight: 45 // 2023-12-18 (JJ): NFE - Fix for Select and QR fields with long values, now uses minHeight instead of height.
        },

        fieldContainerText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
            backgroundColor: Colours.WHITE, // 2023-06-25 (JJ): QR Code (Manual / External) field styling
            marginBottom: 10,
            paddingTop: 10, //for iOS
            paddingBottom: 10, //for iOS
            paddingLeft: 5, //for iOS

            height: 45,
            borderWidth: 0.5,
            borderColor: brandData?.primarycolour,
            borderRadius: 5,
            elevation: 5
        },
        fieldText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BACKGROUND,
            flex: 0.9
        },

        // Modal
        modalBackground: {
            display: 'flex',
            flex: 1,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.6)'
        },
        modalContainer: {
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            // borderRadius: 5,
            elevation: 5,
            //  margin: 20,
            width: '100%',
            maxWidth: 300
        },
        modalHeader: {
            display: 'flex',
            height: 50,
            alignSelf: 'stretch',
            backgroundColor: brandData?.primarycolour,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',

            // fl`ex:1,
            flexDirection: 'row'
        },
        modalHeaderText: {
            fontFamily: Font.family.bold,
            fontSize: Font.size.medium,
            color: Colours.WHITE,
            margin: 0,
            marginBottom: 4,
            padding: 0,
            lineHeight: 'normal'
        },
        modalContent: {
            // padding: 20,
            backgroundColor: 'transparent',
            maxWidth: 300
        },
        processingOverlay: {
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            height: 300,
            width: 300,
            justifyContent: 'center'
        }
    });
};
