import React, { Component } from 'react';
import CulverdocsHeading from './CulverdocsHeading';
import CulverdocsShortAnswer from './_CulverdocsShortAnswer';
import CulverdocsSeparator from './CulverdocsSeparator';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import CulverdocsLongAnswer from './CulverdocsLongAnswer.js';
import CulverdocsTelephone from './CulverdocsTelephone.js';
import CulverdocsEmail from './CulverdocsEmail.js';
import CulverdocsSelect from './CulverdocsSelect.js';
import CulverdocsNumber from './CulverdocsNumber.js';
import CulverdocsYouTube from './CulverdocsYouTube.js';
import CulverdocsDate from './CulverdocsDate.js';
import CulverdocsSlider from './CulverdocsSlider.js';
import CulverdocsChoice from './CulverdocsChoice.js';
import CulverdocsCheckbox from './CulverdocsCheckbox.js';
import CulverdocsCalculation from './CulverdocsCalculation.js';
import CulverdocsLocation from './CulverdocsLocation.js';
import CulverdocsError from './CulverdocsError.js';
import CulverdocsQRCode from './CulverdocsQRCode.js';
import CulverdocsSignature from './CulverdocsSignature.js';
import CulverdocsDocumentViewer from './CulverdocsDocumentViewer.js';
import CulverdocsPINCode from './CulverdocsPINCode.js';
import CulverdocsPhoto from './CulverdocsPhoto.js';
import CulverdocsVideo from './CulverdocsVideo.js';
import CulverdocsFile from './CulverdocsFile.js';
import CulverdocsQuery from './CulverdocsQuery.js';
import CulverdocsIllustration from './CulverdocsIllustration.js';
import CulverdocsImage from './CulverdocsImage.js';
import CulverdocsPostcode from './CulverdocsPostcode.js';
// import CulverdocsHeading ./CulverdocsFile.jsading';
// import CulverdocsSeparator from './CulverdocsSeparator';
// import CulverdocsShortAnswer from './CulverdocsShortAnswer';
// import CulverdocsImage from './CulverdocsImage';
// import CulverdocsYouTube from './CulverdocsYouTube';
// import CulverdocsEmail from './CulverdocsEmail';
// import CulverdocsLongAnswer from './CulverdocsLongAnswer';
// import CulverdocsDate from './CulverdocsDate';
// import CulverdocsNumber from './CulverdocsNumber';
// import CulverdocsSlider from './CulverdocsSlider';
// import CulverdocsCaption from './CulverdocsCaption';
// import CulverdocsSelect from './CulverdocsSelect';
// import CulverdocsCheckbox from './CulverdocsCheckbox';
// import CulverdocsPostcode from './CulverdocsPostcode';
// import CulverdocsCalculation from './CulverdocsCalculation';
// import CulverdocsChoice from './CulverdocsChoice';
// import CulverdocsTelephone from './CulverdocsTelephone';
// import CulverdocsError from './CulverdocsError';
// import CulverdocsDocumentViewer from './CulverdocsDocumentViewer';
// import CulverdocsFile from './CulverdocsFile';
// import CulverdocsLocation from './CulverdocsLocation';
// import CulverdocsPINCode from './CulverdocsPINCode';
// import CulverdocsQuery from './CulverdocsQuery';
// import CulverdocsQRCode from './CulverdocsQRCode';
// import CulverdocsPhoto from './CulverdocsPhoto';
// import CulverdocsVideo from './CulverdocsVideo';
// import CulverdocsSignature from './CulverdocsSignature';
// import CulverdocsIllustration from './CulverdocsIllustration';

export const CulverdocsFieldRender = (props) => {
    console.log('CulverdocsFieldRender', props.item.type);
    return (
        <div>
            {props.item.type == 'heading' ? (
                <CulverdocsHeading
                    item={props.item}
                    brandData={props.brandData}
                    pItem={props.pItem}
                    rIndex={props.rIndex}
                    caption={props.returnPlaceholderCaption(props.item, props.pItem, props.rIndex)}
                    visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                    editable={props.returnFieldEditable(props.item)}
                />
            ) : null}
            <div style={{ marginRight: 10, marginLeft: 10 }}>
                {props.item.type == 'text' ? (
                    <CulverdocsShortAnswer
                        brandData={props.brandData}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item) && !props.item.locked} // 2023-07-11 (JJ): Fix for Smart Lock - set field to non-editable and update caption to locked icon as per non-editable fields
                        onFieldChanged={props.onFieldChanged}
                        toggleFieldLock={props.toggleFieldLock} // 2023-07-09 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Short Answer, Number, Choice, Select)
                        forceRepeaterChildUpdate={props.forceRepeaterChildUpdate} // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                    />
                ) : null}

                {props.item.type == 'separator' ? (
                    <CulverdocsSeparator
                        brandData={props.brandData}
                        item={props.item}
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                    />
                ) : null}
                {props.item.type == 'caption' ? (
                    <CulverdocsFieldCaption
                        brandData={props.brandData}
                        item={props.item}
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        caption={props.returnPlaceholderCaption(props.item, props.pItem, props.rIndex)}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                    />
                ) : null}
                {props.item.type == 'postcode' ? (
                    <CulverdocsPostcode
                        brandData={props.brandData}
                        userProfile={props.userProfile}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                        onFieldChanged={props.onFieldChanged}
                    />
                ) : null}
                {props.item.type == 'textblock' ? (
                    <CulverdocsLongAnswer
                        brandData={props.brandData}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item) && !props.item.locked}
                        onFieldChanged={props.onFieldChanged}
                        toggleFieldLock={props.toggleFieldLock} // 2023-09-24 (JJ): Adding props to FieldRender to support Long Answer toggleFieldLock and forceRepeaterChildUpdate
                        forceRepeaterChildUpdate={props.forceRepeaterChildUpdate} // 2023-09-24 (JJ): Adding props to FieldRender to support Long Answer toggleFieldLock and forceRepeaterChildUpdate
                    />
                ) : null}
                {props.item.type == 'telephone' ? (
                    <CulverdocsTelephone
                        brandData={props.brandData}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                        onFieldChanged={props.onFieldChanged}
                    />
                ) : null}
                {props.item.type == 'email' ? (
                    <CulverdocsEmail
                        brandData={props.brandData}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item) && !props.item.locked} // 2023-07-11 (JJ): Fix for Smart Lock - set field to non-editable and update caption to locked icon as per non-editable fields
                        onFieldChanged={props.onFieldChanged}
                        toggleFieldLock={props.toggleFieldLock} // 2023-07-09 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Short Answer, Number, Choice, Select)
                        forceRepeaterChildUpdate={props.forceRepeaterChildUpdate} // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                    />
                ) : null}
                {props.item.type == 'select' ? (
                    <CulverdocsSelect
                        brandData={props.brandData}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-08-10 (JJ): NFE - Implementing itemValue check for Select field due to refresh issues with Smart Lock.
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item) && !props.item.locked} // 2023-07-11 (JJ): Fix for Smart Lock - set field to non-editable and update caption to locked icon as per non-editable fields
                        setFieldDataFromID={props.setFieldDataFromID}
                        formFields={props.formFields}
                        onFieldChanged={props.onFieldChanged}
                        returnFieldFromID={props.returnFieldFromID}
                        returnFieldValueFromID={props.returnFieldValueFromID}
                        returnListValues={props.returnListValues}
                        toggleFieldLock={props.toggleFieldLock} // 2023-07-09 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Short Answer, Number, Choice, Select)
                        forceRepeaterChildUpdate={props.forceRepeaterChildUpdate} // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                    />
                ) : null}
                {props.item.type == 'integer' ? (
                    <CulverdocsNumber
                        brandData={props.brandData}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item) && !props.item.locked} // 2023-07-11 (JJ): Fix for Smart Lock - set field to non-editable and update caption to locked icon as per non-editable fields
                        onFieldChanged={props.onFieldChanged}
                        toggleFieldLock={props.toggleFieldLock} // 2023-07-09 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Short Answer, Number, Choice, Select)
                        forceRepeaterChildUpdate={props.forceRepeaterChildUpdate} // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                    />
                ) : null}
                {props.item.type == 'youtube' ? (
                    <CulverdocsYouTube
                        brandData={props.brandData}
                        item={props.item}
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)} // 2024-01-18 (JJ): YouTube update: non-editable support for completed forms, disable pointer events & firebase update.
                        onFieldChanged={props.onFieldChanged}
                    />
                ) : null}

                {props.item.type == 'image' ? (
                    <CulverdocsImage
                        brandData={props.brandData}
                        item={props.item}
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        downloadMediaFromStorage={props.downloadMediaFromStorage}
                    />
                ) : null}
                {props.item.type == 'date' ? (
                    <CulverdocsDate
                        brandData={props.brandData}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                        onFieldChanged={props.onFieldChanged}
                    />
                ) : null}
                {props.item.type == 'slider' ? (
                    <CulverdocsSlider
                        brandData={props.brandData}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item) && !props.item.locked} // 2023-07-11 (JJ): Fix for Smart Lock - set field to non-editable and update caption to locked icon as per non-editable fields
                        onFieldChanged={props.onFieldChanged}
                    />
                ) : null}
                {props.item.type == 'yesno' ? (
                    <CulverdocsChoice
                        brandData={props.brandData}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item) && !props.item.locked} // 2023-07-11 (JJ): Fix for Smart Lock - set field to non-editable and update caption to locked icon as per non-editable fields
                        onFieldChanged={props.onFieldChanged}
                        toggleFieldLock={props.toggleFieldLock} // 2023-07-09 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Short Answer, Number, Choice, Select)
                        forceRepeaterChildUpdate={props.forceRepeaterChildUpdate} // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                        returnFieldFromID={props.returnFieldFromID} // 2024-01-06 (JJ): Field Modifiers functionality added to Choice fields
                        setFieldDataFromID={props.setFieldDataFromID} // 2024-01-06 (JJ): Field Modifiers functionality added to Choice fields
                    />
                ) : null}

                {props.item.type == 'checkbox' ? (
                    <CulverdocsCheckbox
                        brandData={props.brandData}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                        onFieldChanged={props.onFieldChanged}
                    />
                ) : null}
                {props.item.type == 'calculation' ? (
                    <CulverdocsCalculation
                        brandData={props.brandData}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                        onFieldChanged={props.onFieldChanged}
                        returnSumFromDatanameInsideRepeater={props.returnSumFromDatanameInsideRepeater}
                        returnValueFromDataname={props.returnValueFromDataname}
                    />
                ) : null}
                {props.item.type == 'location' ? (
                    <CulverdocsLocation
                        item={props.item}
                        brandData={props.brandData}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                        onFieldChanged={props.onFieldChanged}
                        checkGPSPermissions={props.checkGPSPermissions}
                    />
                ) : null}
                {props.item.type == 'error' ? (
                    <CulverdocsError
                        brandData={props.brandData}
                        item={props.item}
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        caption={props.returnPlaceholderCaption(props.item, props.pItem, props.rIndex)}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                    />
                ) : null}

                {props.item.type == 'qrcode' ? (
                    <CulverdocsQRCode
                        brandData={props.brandData}
                        userProfile={props.userProfile}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                        returnFieldValueFromID={props.returnFieldValueFromID} // 2023-10-15 (JJ): NFE - Update to support List Filters in QR Code fields.
                        setFieldDataFromID={props.setFieldDataFromID}
                        onFieldChanged={props.onFieldChanged}
                        forceRepeaterChildUpdate={props.forceRepeaterChildUpdate} // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                        returnFieldFromID={props.returnFieldFromID} // 2023-12-19 (JJ): NFE - Rework Repeater (Generated), Select and QR fields to support Document IDs in Populate From List.
                    />
                ) : null}

                {props.item.type == 'signature' ? (
                    <CulverdocsSignature
                        brandData={props.brandData}
                        userProfile={props.userProfile}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                        onFieldChanged={props.onFieldChanged}
                        downloadMediaFromStorage={props.downloadMediaFromStorage}
                    />
                ) : null}
                {props.item.type == 'documentviewer' ? (
                    <CulverdocsDocumentViewer
                        brandData={props.brandData}
                        userProfile={props.userProfile}
                        userId={props.userId}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        documentviewerfilename={props.item.documentviewerfilename}
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        // caption={props.returnPlaceholderCaption(props.item,props.pItem,props.rIndex)}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                        onFieldChanged={props.onFieldChanged}
                    />
                ) : null}
                {props.item.type == 'pincode' ? (
                    <CulverdocsPINCode
                        brandData={props.brandData}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                        onFieldChanged={props.onFieldChanged}
                        returnListValues={props.returnListValues}
                        setFieldDataFromID={props.setFieldDataFromID}
                        forceRepeaterChildUpdate={props.forceRepeaterChildUpdate} // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                    />
                ) : null}
                {props.item.type == 'photo' ? (
                    <CulverdocsPhoto
                        brandData={props.brandData}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                        onFieldChanged={props.onFieldChanged}
                        onFieldNewChild={props.onFieldNewChild}
                        onFieldDeleteChild={props.onFieldDeleteChild}
                        downloadMediaFromStorage={props.downloadMediaFromStorage}
                        onFieldUpdateValueIndex={props.onFieldUpdateValueIndex} // 2024-01-09 (JJ): Global update onFieldSoftDeletePhoto() to onFieldUpdateValueIndex() as this is used for Files, Photo Updates, etc.
                    />
                ) : null}
                {props.item.type == 'video' ? (
                    <CulverdocsVideo
                        brandData={props.brandData}
                        userId={props.userId}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                        onFieldChanged={props.onFieldChanged}
                        getKeyForCurrentRecord={props.getKeyForCurrentRecord}
                        onFieldNewChild={props.onFieldNewChild}
                        onFieldDeleteChild={props.onFieldDeleteChild}
                    />
                ) : null}
                {props.item.type == 'document' ? (
                    <CulverdocsFile
                        brandData={props.brandData}
                        userId={props.userId}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                        onFieldChanged={props.onFieldChanged}
                        getKeyForCurrentRecord={props.getKeyForCurrentRecord}
                        onFieldNewChild={props.onFieldNewChild}
                        onFieldDeleteChild={props.onFieldDeleteChild}
                    />
                ) : null}

                {props.item.type == 'query' ? (
                    <CulverdocsQuery
                        brandData={props.brandData}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)} // 2024-01-18 (JJ): Query handles previous state when loading draft/completed with a value & set button to disabled in view mode.// 2024-01-18 (JJ): Query update: remove buttonProperties support, handle previous state when loading draft/completed with a value & set button to disabled in view mode.
                        onFieldChanged={props.onFieldChanged}
                        getKeyForCurrentRecord={props.getKeyForCurrentRecord}
                        setFieldDataFromID={props.setFieldDataFromID}
                        forceRepeaterChildUpdate={props.forceRepeaterChildUpdate} // 2024-01-10 (JJ): Query updates to support repeaters & forceUpdates after populateFromResponse
                    />
                ) : null}
                {props.item.type == 'canvas' ? (
                    <CulverdocsIllustration
                        brandData={props.brandData}
                        item={props.item}
                        caption={props.item.caption} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        itemValue={props.item.value} // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
                        pItem={props.pItem}
                        rIndex={props.rIndex}
                        visible={props.returnFieldVisibility(props.item, props.pItem, props.rIndex)}
                        editable={props.returnFieldEditable(props.item)}
                        onFieldChanged={props.onFieldChanged}
                    />
                ) : null}
                {/*
                        
            

                

                

                

                

                 
*/}
            </div>
        </div>
    );
};
