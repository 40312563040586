// 2023-09-24 (JJ): Created new CulverdocsCreateFormV2Functions.js to centralise shared functions - returnCollapsibleRepeaterChildCaption()
import React from 'react';

export function returnCollapsibleRepeaterChildCaption(pItem, mapIndex) {
    if (!pItem || !pItem.repeatercollapsiblechildcaption || !pItem.fields[mapIndex]) {
        return pItem.caption;
    }

    var caption = pItem.repeatercollapsiblechildcaption; //Copy of the field caption before processing.

    var regexpr = '(\\[.*?\\])'; //Get everything between []
    var regex = new RegExp(regexpr, 'g');
    let results;

    while ((results = regex.exec(pItem.repeatercollapsiblechildcaption)) !== null) {
        let result = results[0];
        result = result.replace('[', '').replace(']', '');

        let newval = '';
        if (result == 'index') {
            newval = parseInt(mapIndex) + 1;
        } else {
            let field = pItem.fields[mapIndex].find((child) => child.dataname === result);
            if (field && field['value']) {
                newval = field['value'];
            }
        }

        caption = caption.replace(`[${result}]`, newval);
    }

    return caption;
}
