import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FontAwesomeSpinCss.css'; // import the CSS file

class FontAwesomeSpin extends React.Component {
    render() {
        const { style, children, icon, size, color } = this.props;
        return (
            <div
                className="spin"
                style={style}
            >
                <FontAwesomeIcon
                    icon={icon}
                    style={{ height: size, width: size }}
                    color={color}
                >
                    {children}
                </FontAwesomeIcon>
            </div>
        );
    }
}

export default FontAwesomeSpin;
