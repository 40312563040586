/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Font from '../../Constant/font';
import Size from '../../Constant/size';
import '../../styles.css';
import Colours from '../../Constant/colour';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { database } from '../../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { ref, child, get, onChildAdded, onChildChanged, onChildRemoved } from 'firebase/database';
import HomeScreenRecord from './Component/HomeScreenRecord';
import { getLocalStorage } from '../../Helper/localStorage';
import { getNativeDeviceLanguage, initialiseTranslations, translate } from '../../Common/CulverdocsTranslate';
import FilterModal from './Component/FilterModal';
import { filterData } from './Helper/filterFormData';
import PinInput from 'react-pin-input';
import Stylesheet from 'reactjs-stylesheet';
import { lightenDarkenColor } from '../../Helper/lightenDarkenColor';
import { ShowLoader } from '../../Common/Loader';
import { CulverdocsScreenHeader2 } from '../../Component/CulverdocsScreenHeader2';
import { setDrawerAction } from '../../Redux/actions/setDrawerAction';

import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import './Style/home.scss';
function HomeScreen() {
    const dispatch = useDispatch();
    const [showDrawer, setShowDrawer] = useState(false);
    const [uidState, setUidState] = useState('');
    const [brandData, setBrandData] = useState({});
    const [userProfile, setUserProfile] = useState({});
    const [showFilterModal, setShowFilterModal] = useState(false);

    const [recordData, setRecordData] = useState([]); // 2024-05-12 (JJ): Update HomeScreen Firebase Listeners - simplified to store all data key/values instead of building and listening on New, Change, Delete for performance.
    const [filteredRecordData, setFilterRecordData] = useState([]); // 2024-05-12 (JJ): Update HomeScreen Firebase Listeners - simplified to store all data key/values instead of building and listening on New, Change, Delete for performance.

    const [queryPin, setQueryPin] = React.useState(false);
    const [queryItem, setQueryItem] = React.useState();
    const [code, setCode] = React.useState('');
    const [formFilterData, setFormFilterData] = React.useState([]);
    const [formType, setFormType] = useState([]);
    const [visible, setVisible] = React.useState(false);
    const [titleFilter, setTitleFilter] = useState('');
    const [selectedTypesFilter, setSelectedTypesFilter] = useState([]);
    const [selectedFormsFilter, setSelectedFormsFilter] = useState([]);
    const [status, setStatus] = useState('data');
    const [loader, setLoader] = useState(false);
    const [searchText, setSearchText] = useState(''); // 2024-05-08 (RB): Impelementaion of search by name

    const pinRef = useRef(null);

    const handleClose = () => setShowDrawer(false);
    const handleShow = () => setShowDrawer(true);

    const drawerStatus = useSelector((state) => state.drawerStatus.key);
    const location = useLocation();
    const currentPath = location.pathname;
    const [showSignOutModal, setShowSignOutModal] = useState(false);

    //const dispatch = useDispatch();
    const handleLogouthide = () => {
        setShowSignOutModal(false);
    };

    const hideDialog = () => {
        setShowFilterModal(false);
        setShowFilterModal(false);
        setVisible(false);
    };
    const showDialog = () => {
        setShowFilterModal(true);
        setVisible(true);
    };

    const navigate = useNavigate();
    //const dispatch = useDispatch();
    const auth = getAuth();

    useEffect(() => {
        initialiseTranslations();
        let lan = getNativeDeviceLanguage();
        let brandData = getLocalStorage('brandData');
        let userProfiles = getLocalStorage('userProfiles');

        setBrandData(brandData);
        setUserProfile(userProfiles);
    }, []);
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUidState(user.uid);
            } else {
                setUidState('');
            }
        });

        return unsubscribe;
    }, [auth]);
    useEffect(() => {
        setLoader(true);
        if (!uidState) {
            return;
        }

        // 2024-05-12 (JJ): Update HomeScreen Firebase Listeners - simplified to store all data key/values instead of building and listening on New, Change, Delete for performance.
        const dataRef = ref(database, `/users/${uidState}/data`);

        const handleNewData = (snap) => {
            const newItem = { key: snap.key, ...snap.val() };
            setRecordData((prevData) => [...prevData, newItem]);
            setLoader(false);
        };

        const handleDataChange = (snap) => {
            const updatedItem = { key: snap.key, ...snap.val() };
            setRecordData((prevData) => prevData.map((item) => (item.key === snap.key ? updatedItem : item)));
            setLoader(false);
        };

        const handleDataRemove = (snap) => {
            setRecordData((prevData) => prevData.filter((item) => item.key !== snap.key));
            setLoader(false);
        };
        onChildAdded(dataRef, handleNewData);
        onChildChanged(dataRef, handleDataChange);
        onChildRemoved(dataRef, handleDataRemove);

        // Disable the listener on unmount
        return () => {
            //  off(dataRef, 'child_added', handleNewData);     
            //  off(dataRef, 'child_changed', handleDataChange);
            //  off(dataRef, 'child_removed', handleDataRemove); 
        };
    }, [uidState]);
    useEffect(() => {
        if (recordData && recordData.length > 0) {
            if (titleFilter !== '' || selectedFormsFilter.length > 0 || selectedTypesFilter.length > 0) {
                applyFilter(titleFilter, selectedTypesFilter, selectedFormsFilter);
            } else {
                setFilterRecordData(recordData);
            }
        } else {
            // 2024-05-20 (JJ): HomeScreen updates - fix for devices with no data, and clearing filteredRecordData with no data to support last record deletion

            setFilterRecordData([]);
        }
    }, [recordData, selectedFormsFilter, selectedTypesFilter, titleFilter]);
    useEffect(() => {
        if (true) {
            
            const uniqueArray = recordData.filter((item, index, array) => {
                return array.findIndex((obj) => obj.formid === item.formid) === index;
            });
            // 2024-04-14 (JJ): Re-implementing Request Category filters (if a record has a requestcategory set)
            let distinctState = [
                ...new Set(
                    recordData.map((item) => {
                        if (item?.state === 'requested') {
                            if (item?.requestcategory) {
                                return `${item?.state} (${item?.requestcategory})`; // Return item.state + item.requestcategory if state is "requested"
                            } else {
                                return item?.state; // Otherwise, return the state as is
                            }
                        } else {
                            return item?.state; // Otherwise, return the state as is
                        }
                    })
                )
            ];

            setFormType(distinctState);
            setFormFilterData(uniqueArray);
        }
        // setLoader(false);
    }, [recordData]);

    const navigateToForm = async (item) => {
        get(child(ref(database), `/users/${uidState}/data/${item.key}`)).then((dbRecord) => {
            dbRecord = dbRecord.val(); //Get the value from dbRecord data key (we no longer store in the FlatList Item for performance)

            if (!dbRecord.fields) {
                alert(translate('Unable to open this form as it cannot be retrieved.'));
                return;
            }

            let editableStatus = ['started', 'saving', 'draft', 'requested', 'rejected', 'sent', 'returned'];

            navigate('/createform', {
                state: {
                    form: dbRecord,
                    mode: editableStatus.includes(item.state) ? 'draft' : 'view',
                    recordId: item.key
                }
            });
        });
    };
    const applyFilter = async (title, selectedTypes, selectedFormNames, name = '') => {
        let filteredDataFromHandler = await filterData(recordData, title, selectedTypes, selectedFormNames, name);
        setFilterRecordData(filteredDataFromHandler);
        setTitleFilter(title);
        setSelectedTypesFilter(selectedTypes);
        setSelectedFormsFilter(selectedFormNames);

        // dispatch(setShowFilterModalAction(false));

        setShowFilterModal(false);
        if (title !== '' || selectedTypes.length > 0 || selectedFormNames.length > 0) {
            filteredDataFromHandler.length === 0 ? setStatus('no_filterData') : setStatus('data');
        } else {
            setStatus('data');
        }
    };
    function recordSort(a, b) {
        let sortOrder = ['started', 'requested', 'returned', 'rejected', 'saving', 'draft', 'sending', 'sent', 'uploading', 'processing', 'complete'];
        a.sortOrder = sortOrder.indexOf(a.state) > -1 ? sortOrder.indexOf(a.state) : 99;
        b.sortOrder = sortOrder.indexOf(b.state) > -1 ? sortOrder.indexOf(b.state) : 99;

        if (a.state === b.state) {
            if (a.state === 'requested' && userProfile?.requestsortby === 'title') {
                let aCombined = (a.requestcategory ? a.requestcategory : '') + a.title;
                let bCombined = (b.requestcategory ? b.requestcategory : '') + b.title;
                return aCombined.localeCompare(bCombined);
            } else {
                return parseInt(b.timestamp) - parseInt(a.timestamp);
            }
        } else {
            return a.sortOrder - b.sortOrder;
        }
    }
    const calculateModalHeight = (length) => {
        if (length > 8) return '95%';
        if (length > 5) return '90%';
        if (length > 2) return '80%';
        return '70%';
    };
    const handlePress = (item) => {
        if (item.confidential) {
            setQueryPin(true);
            setQueryItem(item);
        } else {
            navigateToForm(item);
        }
    };
    function _checkCode(value) {
        var item = queryItem;
        if (value !== userProfile.viewpin) {
            if (pinRef.current) {
                pinRef.current.clear();
            }
        } else {
            setQueryPin(false);
            if (pinRef.current) {
                pinRef.current.clear();
            }

            navigateToForm(item);
        }
    }
    function renderQueryPinModal() {
        return (
            <Modal
                centered
                show={queryPin}
            >
                <div className="modal-container">
                    <div
                        className="modal-content"
                        style={{ borderColor: brandData?.primarycolour }}
                    >
                        <div
                            className="modal-header"
                            style={{
                                backgroundColor: brandData?.primarycolour,
                                borderBottomColor: brandData?.primarycolour
                            }}
                        >
                            <div
                                className="back-icon"
                                hitslop={{
                                    left: Size.CountScale(10),
                                    right: Size.CountScale(10),
                                    bottom: Size.CountScale(10),
                                    top: Size.CountScale(10)
                                }}
                                onClick={() => setQueryPin(false)}
                            >
                                <FontAwesomeIcon
                                    icon={['fad', 'circle-chevron-left']}
                                    color={Colours.WHITE}
                                />
                            </div>

                            <div className="modal-title-container">
                                <p className="renderOptionModalHeaderText">{translate('Confidential Form')}</p>
                            </div>
                        </div>

                        <div className="modal-body">
                            <FontAwesomeIcon
                                icon={['fad', 'user-lock']}
                                style={{ color: brandData?.primarycolour }}
                                className="icon-style"
                            />
                            <p className="confidential-text">{translate('This Form is Confidential')}</p>
                            <p className="enter-pin-text">{translate('Enter your PIN to continue')}</p>

                            <PinInput
                                ref={pinRef}
                                length={userProfile?.viewpin.length}
                                secret
                                secretDelay={100}
                                onChange={(value, index) => setCode(value)}
                                type="numeric"
                                inputMode="number"
                                className="pin-input-container"
                                inputStyle={{
                                    borderColor: lightenDarkenColor(brandData?.primarycolour, 50),
                                    color: brandData?.primarycolour
                                }}
                                inputFocusStyle={{ borderColor: brandData?.primarycolour }}
                                onComplete={(value, index) => _checkCode(value)}
                                autoSelect={true}
                                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <div className="container">
            <div className="mainContainer content-area">
                <CulverdocsScreenHeader2
                    brandData={brandData}
                    title={'My Forms'}
                    ScreenName={'HomeScreen'}
                    RightIconType={'fad'}
                    RightIcon={'filter'}
                    LeftIconType={'fad'}
                    LeftIcon={'bars'}
                    userProfile={userProfile}
                    userid={uidState}
                    onLeftIconClick={() => {
                        dispatch(setDrawerAction(!drawerStatus));
                    }}
                    onRightIconClick={() => showDialog()}
                />
                <div className="boxPadding">
                    <ShowLoader
                        flag={loader}
                        brandData={brandData}
                    />
                    <Container
                        fluid
                        className="containerWrapping"
                    >
                        <Row className={'rowWrapper custom_row'}>
                            {filteredRecordData &&
                                filteredRecordData.sort(recordSort).map((item) => {
                                    return (
                                        <Col
                                            key={`Wrapper${item.key}`}
                                            className="zeroPadding"
                                            sm={12}
                                        >
                                            <HomeScreenRecord
                                                brandData={brandData}
                                                navigation={navigate}
                                                item={item}
                                                handlePress={handlePress}
                                                keyValue={item.key}
                                            />
                                        </Col>
                                    );
                                })}
                        </Row>
                    </Container>

                    {showFilterModal && (
                        <FilterModal
                            navigation={navigate}
                            brandData={brandData}
                            showFilterModal={showFilterModal}
                            hideDialog={hideDialog}
                            applyFilter={applyFilter}
                            typeData={formType}
                            length={calculateModalHeight(formFilterData?.length)}
                            formFilterData={formFilterData}
                            titleFilter={titleFilter}
                            selectedTypesFilter={selectedTypesFilter}
                            selectedFormsFilter={selectedFormsFilter}
                        />
                    )}
                    {queryPin && renderQueryPinModal()}
                </div>
            </div>
            <div>
                <div>
                    <Sidebar
                        collapsed={drawerStatus}
                        collapsedWidth={0}
                        backgroundColor={brandData.primarycolour}
                        className="sideNavBar"
                        style={{ border: '5px solid ' + brandData.primarycolour }}
                    >
                        <FilterModal
                            navigation={navigate}
                            brandData={brandData}
                            showFilterModal={true}
                            hideDialog={hideDialog}
                            applyFilter={applyFilter}
                            typeData={formType}
                            length={calculateModalHeight(formFilterData?.length)}
                            formFilterData={formFilterData}
                            titleFilter={titleFilter}
                            selectedTypesFilter={selectedTypesFilter}
                            selectedFormsFilter={selectedFormsFilter}
                        />
                    </Sidebar>
                </div>
            </div>
        </div>
    );
}

export default HomeScreen;
const styles = Stylesheet.create({
    container: { display: 'flex', flexDirection: 'row', justifyContent: 'space-around' },
    mainContainer: { position: 'relative', marginLeft: 30, marginRight: 30, backgroundColor: Colours.WHITE, paddingBottom: 40 },
    containerWrapping: {
        paddingLeft: 0,
        paddingRight: 0,
        flexDirection: 'column',
        alignItems: 'center'
    },
    sideNavBar: { width: 400, paddingLeft: 0, paddingRight: 0, marginLeft: 20, marginRight: 0, marginTop: 30, borderRadius: '5px' },
    rowWrapper: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'flex-start',
        overflow: 'auto'
        //height: '70vh'
    },
    zeroPadding: { paddingLeft: 0, paddingRight: 0 },
    fieldText: {
        fontFamily: Font.family.regular,
        fontSize: Font.size.medium,
        color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
        backgroundColor: Colours.WHITE,
        marginBottom: 10,
        paddingTop: 10, //for iOS
        paddingBottom: 10, //for iOS
        paddingLeft: 5, //for iOS
        height: 45,
        border: '0.5px solid #000',
        borderRadius: 5,
        elevation: 5
    },

    logoContainer: {
        flex: 1,
        zIndex: 99,
        alignItems: 'center',
        justifyContent: 'center'
    },
    footerWrapper: { position: 'absolute', bottom: 0, left: 0, right: 0 },
    renderOptionModalHeaderText: {
        fontFamily: Font.family.regular,
        fontSize: Font.size.large,
        color: Colours.WHITE
    },
    renderOptionModalClearText: {
        fontFamily: Font.family.regular,
        fontSize: Font.size.medium,
        color: Colours.WHITE,
        textAlign: 'right',
        marginRight: 10
    },
    renderOptionModalSearchText: {
        fontFamily: Font.family.regular,
        fontSize: Font.size.medium,
        width: '100%',
        height: 40,
        paddingLeft: 10
    },
    renderOptionModalListText: {
        fontFamily: Font.family.regular,
        fontSize: Font.size.medium,
        color: Colours.BLACK
    },
    renderOptionModalButtonText: {
        fontFamily: Font.family.regular,
        fontSize: Font.size.large,
        color: Colours.WHITE
    }
});
