import React, { Component } from 'react';
import Font from '../../../Constant/font';
import Size from '../../../Constant/size';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form, InputGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { translate } from '../../../Common/CulverdocsTranslate';
import FlatList from 'flatlist-react';

class CulverdocsSelect extends Component {
    dimensions = {}; // 2023-12-19 (JJ): NFE - Support for AutoScroll property on Choice, Checkbox & Select.

    state = {
        renderSelectModal: false,
        selectOnceValues: [] // 2023-09-03 (JJ): Fixing issue with Select Field - selectOnceValues error if the state hadn't been set outside of repeaters
    };

    shouldComponentUpdate(prevProps, prevState) {
        // 2023-08-10 (JJ): NFE - Implementing itemValue check for Select field due to refresh issues with Smart Lock.
        return this.state !== prevState || this.props.itemValue !== prevProps.itemValue || this.state !== prevState || this.props.visible !== prevProps.visible || this.props.item.value !== prevProps.item.value || this.props.caption !== prevProps.caption || this.props.theme !== prevProps.theme; // 2024-04-23 (RB): this.props.theme !== prevProps.theme for updating the field whenever mode switch
    }

    render() {
        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption}`);
        const styles = getStyle(this.props.brandData);

        if (!this.props.visible) {
            return null;
        }

        return (
            <div
                style={{ position: 'relative' }}
                onLayout={(event) => {
                    this.dimensions = event.nativeEvent.layout;
                }}
            >
                <CulverdocsFieldCaption
                    brandData={this.props.brandData}
                    caption={this.props.item.caption}
                    userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                    mandatory={this.props.item.mandatory}
                    editable={this.props.editable}
                    hasValue={this.props.item.value ? true : false}
                />

                {/* // 2023-07-09 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Short Answer, Number, Choice, Select) */}
                {this.props.item.lockfieldtype && (
                    <div style={{ position: 'absolute', right: 0, top: 0 }}>
                        <div
                            hitslop={{
                                left: 10,
                                right: 10,
                                bottom: 10,
                                top: 10
                            }}
                            onClick={() => {
                                this.props.toggleFieldLock(this.props.item, !this.props.item.locked).then(() => {
                                    // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                                    if (this.props.pItem) {
                                        this.props.forceRepeaterChildUpdate();
                                    }

                                    this.forceUpdate();
                                });
                            }}
                        >
                            <FontAwesomeIcon
                                icon={['fad', this.props.item.locked ? 'square-a-lock' : 'lock-keyhole-open']}
                                color={this.props.item.locked ? Colours.GREEN : Colours.GREY}
                            />
                        </div>
                    </div>
                )}

                {/* Editable */}
                {this.props.editable && (
                    <div
                        onClick={async () => {
                            // 2023-03-03 (JJ): Support for Extended Lists, default item using placeholders
                            if (this.props.item.listextendeddefaultitemvalue && this.props.item.listextendeddefaultitemvalue.includes('[')) {
                                var reBrackets = /\[(.*?)\]/g;
                                var found;
                                var newitemVal = this.props.item.listextendeddefaultitemvalue;
                                while ((found = reBrackets.exec(this.props.item.listextendeddefaultitemvalue))) {
                                    let foundParameter = found[1];
                                    newitemVal = newitemVal.replace(`[${foundParameter}]`, this.returnValueFromDataname(foundParameter, this.props.item, this.props.pItem, this.props.rIndex));
                                }
                                this.props.item.listextendeddefaultitemvalue = newitemVal;
                            }

                            // 2023-01-08 (JJ): Adding support for Extended / Advanced select view types - show list columns and default item values
                            this.setState({
                                renderSelectModal: true,
                                item: this.props.item,
                                pItem: this.props.pItem,
                                listMultiSelect: this.props.item.listmultiselect,
                                selectId: this.props.item.id,
                                selectedItems: this.props.item.selected,
                                selectedModalCaption: this.props.item.caption,
                                selectListOptions: null,
                                selectDefaultitem: this.props.item.listextendeddefaultitemvalue && this.props.item.listextendeddefaultitemvalue !== '' ? this.props.item.listextendeddefaultitemvalue : '',
                                itemText: ''
                            });

                            // 2023-09-02 (JJ): NFE - Fix to SelectOnce support to exclude any options selected within the repeater
                            if (this.props.item.selectonce && this.props.pItem) {
                                let selectedValues = [];
                                this.props.pItem.fields.forEach((child) => {
                                    let field = child.find((field) => field.id == this.props.item.id && field.selectonce);
                                    if (field && field.value !== '') {
                                        selectedValues.push(field.value);
                                    }
                                });
                                this.setState({
                                    selectOnceValues: selectedValues
                                });
                            }

                            var listResult = await this.props.returnListValues(this.props.item, this.props.pItem, this.props.rIndex, null);
                            if (listResult && !listResult.success) {
                                await this.setState({
                                    renderSelectModal: false
                                });
                                alert(listResult.title, listResult.message);

                                return;
                            }

                            this.setState({
                                selectListOptions: listResult.options,
                                flatListDataBackUp: listResult.options
                            });

                            // 2023-01-08 (JJ): Adding support for Extended / Advanced select view types - show list columns and default item values
                            if (this.props.item.listviewtype && this.props.item.listviewtype == 'Extended' && this.state.selectDefaultitem !== '') {
                                this.setState({
                                    itemText: this.state.selectDefaultitem
                                });
                            }
                        }}
                        style={styles.fieldSelect}
                        value={this.props.item.value}
                    >
                        <p style={styles.renderSelectText}>{translate(this.props.item.value) ? translate(this.props.item.value) : ''}</p>
                        <div>
                            <FontAwesomeIcon
                                icon={['fad', 'square-chevron-down']}
                                color={lightenDarkenColor(this.props.brandData?.primarycolour, 50)}
                                style={{ alignSelf: 'flex-end', right: 10 }}
                            />
                        </div>
                    </div>
                )}

                {/* Non-editable */}
                {!this.props.editable && (
                    <div style={styles.fieldSelect}>
                        <p style={styles.fieldNonEditable}>{this.props.item.value}</p>
                    </div>
                )}

                {/* Modal */}
                <Modal
                    centered
                    show={this.state.renderSelectModal}

                    // onRequestClose={() =>
                    //     this.setState({ renderSelectModal: false })
                    // }
                    // animationType={"fade"}
                    // animated={true}
                    // transparent={true}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            height: '90%',
                            width: '100%',
                            backgroundColor: Colours.MODALBG,
                            evelation: 4,
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignSelf: 'center'
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                width: '100%',
                                height: '90%',
                                maxHeight: '98%',
                                ///  borderRadius: 10,
                                borderWidth: 1,
                                borderColor: this.props.brandData?.primarycolour,
                                backgroundColor: Colours.WHITE
                            }} // 2024-04-22 (RB): Dark Theme Implementation
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        height: 50,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: this.props.brandData?.primarycolour,
                                        borderBottomColor: this.props.brandData?.primarycolour,
                                        borderBottomWidth: 2
                                    }}
                                >
                                    <div
                                        style={{ display: 'flex', alignItems: 'center' }}
                                        // style={styles.backIconStyle}
                                        hitslop={{
                                            left: Size.CountScale(10),
                                            right: Size.CountScale(10),
                                            bottom: Size.CountScale(10),
                                            top: Size.CountScale(10)
                                        }}
                                        onClick={() =>
                                            this.setState({
                                                renderSelectModal: false
                                            })
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={['fad', 'circle-chevron-left']}
                                            //size={30}
                                            color={Colours.WHITE}
                                            style={{
                                                marginLeft: 10,
                                                marginRight: 10,
                                                width: 30,
                                                height: 30,
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </div>
                                    <p
                                        style={styles.renderOptionModalHeaderText}
                                        numberoflines={1}
                                    >
                                        {translate(this.state.selectedModalCaption)}
                                    </p>

                                    {/* // 2023-02-18 (JJ): Added a clear button (trash icon) to select modals where there is already a selection, to clear previous value */}
                                    {this.state.selectedItems && (
                                        <div
                                            hitslop={{
                                                left: Size.CountScale(10),
                                                right: Size.CountScale(10),
                                                bottom: Size.CountScale(10),
                                                top: Size.CountScale(10)
                                            }}
                                            style={{ marginRight: 10 }}
                                            onClick={() => {
                                                // Unset the item value, selected and listitem keys
                                                this.state.item.value = null;
                                                this.state.item.selected = null;
                                                this.state.item.listitem = null;

                                                // Close the modal and save to local
                                                this.setState({
                                                    renderSelectModal: false
                                                });
                                                this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex).then(() => {
                                                    // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                                                    if (this.props.pItem) {
                                                        this.props.forceRepeaterChildUpdate();
                                                    }
                                                });
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fad', 'trash-alt']}
                                                style={{ cursor: 'pointer' }}
                                                color={Colours.WHITE}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div style={styles.fieldText}>
                                    {/* // 2023-01-08 (JJ): Adding support for Extended / Advanced select view types - show list columns and default item values */}
                                    {/* <TextInput
                                        placeholder={translate("item")}
                                        style={
                                            styles.renderOptionModalitemText
                                        }
                                        placeholderTextColor={
                                            Colours.BACKGROUND
                                        }
                                        onChangeText={(text) => {
                                            this.setState({ itemText: text });
                                        }}
                                        defaultValue={
                                            this.state.selectDefaultitem
                                        }
                                    /> */}

                                    <InputGroup style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                        <InputGroup.Text style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                            <FontAwesomeIcon
                                                icon={['fad', 'search']}
                                                color={this.props.brandData?.primarycolour}
                                                style={{ height: 25, width: 25 }}
                                            />
                                        </InputGroup.Text>
                                        <Form.Control
                                            style={{ boxShadow: 'none', borderColor: 'transparent' }}
                                            placeholder={translate('search')}
                                            className="no-active-border"
                                            onChange={(e) => {
                                                this.setState({
                                                    itemText: e.target.value
                                                });
                                            }}
                                        />
                                    </InputGroup>
                                </div>

                                {/* // 2023-07-25 (JJ): Rework Select item functionality, using filter and allow iteming inside extended column visibility. */}
                                {this.state.selectListOptions && (
                                    <FlatList
                                        list={this.state.selectListOptions.filter((option) => {
                                            if (this.state.itemText !== '') {
                                                if (this.state.item.listviewtype == 'Extended' || this.state.item.listextendedcolumnvisibility) {
                                                    var text = '';
                                                    this.state.item.listextendedcolumnvisibility.map((data) => {
                                                        text += option[data];
                                                    });
                                                    return option.value.toLowerCase().match(this.state.itemText.toLowerCase()) || text.toLowerCase().match(this.state.itemText.toLowerCase());
                                                } else {
                                                    return option.value.toLowerCase().match(this.state.itemText.toLowerCase());
                                                }
                                            } else {
                                                return true;
                                            }
                                        })}
                                        extraData={this.state}
                                        contentContainerStyle={{
                                            marginLeft: 10
                                        }}
                                        keyExtractor={(item, index) => index.toString()}
                                        renderWhenEmpty={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flex: 1,
                                                    margin: 20,
                                                    alignSelf: 'center'
                                                }}
                                            ></div>
                                        }
                                        renderItem={(item) => {
                                            // 2023-09-03 (JJ): Fixing issue with Select Field - selectOnceValues error if the state hadn't been set outside of repeaters
                                            // 2023-09-10 (JJ): Select Once update - now shows the value that is already selected
                                            if ((this.state.selectOnceValues && !this.state.selectOnceValues.includes(item.value)) || this.state.item.value == item.value) {
                                                return (
                                                    <div
                                                        onClick={async () => {
                                                            if (this.state.listMultiSelect) {
                                                                var selected = this.state.selectedItems ? [...this.state.selectedItems] : [];
                                                                if (selected && selected.includes(item.item.value)) {
                                                                    selected.forEach((val, index) => {
                                                                        if (val == item.item.value) {
                                                                            selected.splice(index, 1);
                                                                        }
                                                                    });
                                                                } else {
                                                                    selected.push(item.item.value);
                                                                }

                                                                this.setState({
                                                                    selectedItems: selected
                                                                });
                                                            } else {
                                                                selected = [];
                                                                selected.push(item.value);

                                                                this.state.item.value = item.value;
                                                                this.state.item.selected = selected;
                                                                this.state.item.listitem = item;

                                                                // v1.2.0 - ListToFieldValues
                                                                if (this.state.item.listtofieldvalue) {
                                                                    await this.processPopulateFromList(item); // 2024-01-15 (JJ): Update Select with processPopulateFromList() to support update promises to write fields before repeater child update.
                                                                }

                                                                this.setState({
                                                                    renderSelectModal: false
                                                                });
                                                                // 2023-12-19 (JJ): NFE - Support for AutoScroll property on Choice, Checkbox & Select.
                                                                this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex, this.props.item.autoscroll ? this.dimensions.height : null).then(() => {
                                                                    if (this.props.pItem) {
                                                                        this.props.forceRepeaterChildUpdate();
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                        style={{
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            // marginVertical: 5,
                                                            alignItems: 'center',
                                                            marginTop: 5,
                                                            marginBottom: 5
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                flex: 0.1,
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            {(!this.state.listMultiSelect && item.value == this.props.item.value) || (this.state.listMultiSelect && this.state.selectedItems && this.state.selectedItems.includes(item.value)) ? (
                                                                <FontAwesomeIcon
                                                                    icon={['fad', 'check-circle']}
                                                                    style={{ width: 20, height: 20 }}
                                                                    color={this.props.brandData?.primarycolour}
                                                                />
                                                            ) : (
                                                                <FontAwesomeIcon
                                                                    icon={['fal', 'circle']}
                                                                    style={{ width: 20, height: 20 }}
                                                                    color={this.props.brandData?.primarycolour}
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                // alignItems: 'center',
                                                                flexDirection: 'column',
                                                                justifyContent: 'flex-start',
                                                                flex: 0.9
                                                            }}
                                                        >
                                                            <p style={styles.renderOptionModalListText}>{translate(item.value)}</p>

                                                            {this.renderExtendedListOptions(this.state.item, item, styles)}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }}
                                    />
                                )}

                                {this.state.listMultiSelect && (
                                    <div
                                        onClick={() => {
                                            var multival = '';
                                            // 2023-02-11 (JJ): Fix for multi select clicking Done without selection, was crashing the app due to undefined forEach
                                            if (this.state.selectedItems) {
                                                this.state.selectedItems.forEach((val) => {
                                                    multival += multival > '' ? ', ' : '';
                                                    multival += val;
                                                });
                                            }

                                            this.state.item.selected = this.state.selectedItems;
                                            this.state.item.value = multival;

                                            this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                                            this.setState({
                                                renderSelectModal: false
                                            });
                                        }}
                                        style={{
                                            display: 'flex',
                                            height: 45,
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: this.props.brandData?.primarycolour,
                                            borderBottomRightRadius: 5,
                                            borderBottomLeftRadius: 5,
                                            top: 1
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fad', 'check-circle']}
                                            color={Colours.WHITE}
                                            style={{
                                                marginRight: 5,
                                                marginLeft: 5,
                                                width: 20,
                                                height: 20
                                            }}
                                        />
                                        <p style={styles.renderOptionModalButtonText}>{translate('Done')}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    // 2024-01-15 (JJ): Update Select with processPopulateFromList() to support update promises to write fields before repeater child update.
    processPopulateFromList = async (item) => {
        let promises = []; // 2024-01-15 (JJ): Update to Select, QR Code, PIN Code, Query to support repeater Promises.resolve for PopulateFromList to complete updates before updating parent.
        let updates = this.state.item.listtofieldvalue;
        updates.forEach((val, index) => {
            //val.Name = the List Field Name (e.g. Hair Colour)
            //val.Value = the Field ID that needs setting.
            if (val.Name && val.Value) {
                // Only attempt to set it if the value exists on the list - i.e. blanks wont overwrite fields which may contain a value
                // Check if this is a document field or standard before defining the value
                // 2023-12-19 (JJ): NFE - Rework Repeater (Generated), Select and QR fields to support Document IDs in Populate From List.
                this.props.returnFieldFromID(val.Value, this.props.pItem, this.props.rIndex).then(async (destinationField) => {
                    if (destinationField.type == 'documentviewer') {
                        if (item[val.Name + '_id']) {
                            promises.push(
                                this.props.setFieldDataFromID(val.Value, this.props.pItem, this.props.rIndex, {
                                    documentviewerfilename: item[val.Name],
                                    documentviewerfileid: item[val.Name + '_id']
                                })
                            );
                        }
                    } else {
                        // 2024-01-16 (JJ): Select fields processPopulateFromList() - now overwrites with a blank value instead of skipping.
                        let newVal = item[val.Name] ? item[val.Name] : '';
                        promises.push(this.props.setFieldDataFromID(val.Value, this.props.pItem, this.props.rIndex, { value: newVal }));
                    }
                });
            }
        });

        await Promise.all(promises);
    };

    renderExtendedListOptions(selectitem, listitem, styles) {
        // Only render the listextendedcolumnvisibility values if the listviewtype is set to Extended.
        // 2023-03-03 (JJ): Fix for extended lists not including Column Visibility which was throwing map error
        if (!this.state.item.listviewtype || selectitem.listviewtype !== 'Extended' || !selectitem.listextendedcolumnvisibility) {
            return;
        }

        return selectitem.listextendedcolumnvisibility.map((data) => {
            return (
                <div style={styles.renderOptionModalListExtendedOptionRow}>
                    <p style={styles.renderOptionModalListExtendedOptionField}>{data}:</p>
                    <p style={styles.renderOptionModalListExtendedOptionText}>{listitem[data]}</p>
                </div>
            );
        });
    }
}
export default CulverdocsSelect; // 2024-04-22 (RB): Dark Theme Implementation
const getStyle = (brandData) => {
    return Stylesheet.create({
        fieldNonEditable: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            // backgroundColor: Colours.MIDGREY,
            color: Colours.GREY
        },
        // fieldNonEditableView : {
        //     backgroundColor: Colours.MIDGREY,
        //     marginBottom: 10,
        //     paddingTop:10,
        //     paddingBottom:10,
        //     paddingLeft:5,

        //     height:45,
        //     borderWidth: 0.5,
        //     borderColor: brandData?.primarycolour,
        //     borderRadius: 5,
        //     elevation:5,
        // },
        fieldText: {
            display: 'flex',
            alignItems: 'center',
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
            // borderBottomWidth: 1,
            // borderColor: brandData?.primarycolour,
            backgroundColor: Colours.WHITE,
            marginBottom: 10,
            // paddingTop: 10, //for iOS
            //paddingBottom: 10, //for iOS
            //paddingLeft: 5, //for iOS
            //paddingRight: 5, // 2023-11-08 (JJ): NFE - Updating Short Answer field - apply paddingRight and change to minHeight due to non-editable fields wrapping outside of the field
            // minHeight: 45, // 2023-11-08 (JJ): NFE - Updating Short Answer field - apply paddingRight and change to minHeight due to non-editable fields wrapping outside of the field
            // boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            borderRadius: 5,
            margin: 10,
            elevation: 5
        },
        fieldCaptionBadge: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.small,
            color: Colours.BLACK,
            marginLeft: 5,
            paddingLeft: 5,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: lightenDarkenColor(brandData?.primarycolour, 50),
            borderRadius: 5,
            borderWidth: 1,
            borderColor: lightenDarkenColor(brandData?.primarycolour, 50),
            padding: 2,
            paddingLeft: 5,
            paddingRight: 5,
            height: 20,
            maxWidth: 150 // 2022-10-26 (JJ): Set maxWidth on fieldcaptions to limit content length (postcode changes)
        },

        // Field Specific
        fieldSelect: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: Colours.WHITE,
            marginBottom: 10, // 2023-09-03 (JJ): Standardising marginBottom:10 on all components which were 15 as there were some inconsistencies with styling
            paddingTop: 10, //for iOS
            paddingBottom: 10, //for iOS
            paddingLeft: 5, //for iOS
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            paddingRight: 10,
            borderRadius: 4,
            elevation: 3,
            minHeight: 45 // 2023-12-18 (JJ): NFE - Fix for Select and QR fields with long values, now uses minHeight instead of height.
        },
        renderSelectText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            flex: 0.9,
            color: Colours.BACKGROUND,
            margin: 0
        },

        renderOptionModalHeaderText: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            fontFamily: Font.family.bold,
            fontSize: Font.size.medium,
            color: Colours.WHITE,
            width: '80%',
            margin: 0
            //  marginBottom: 4 //Fix for the caption ellipsis overlapping the modal window.
        },
        renderOptionModalitemText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
            width: '100%',
            height: 40,
            paddingLeft: 10
        },
        renderSendModalHeadingText: {
            fontFamily: Font.family.bold,
            fontSize: Font.size.medium,
            color: Colours.BACKGROUND,
            textAlign: 'center',
            marginBottom: 15,
            marginTop: 50
        },
        renderSendModalText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BACKGROUND,
            textAlign: 'center',
            padding: 10
        },
        renderOptionModalListText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.TEXTCOLOUR,
            margin: 0
        },
        renderOptionModalListExtendedOptionRow: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center'
        },
        renderOptionModalListExtendedOptionField: {
            fontFamily: Font.family.bold,
            fontSize: Font.size.small,
            color: Colours.GREY,
            margin: 0
        },
        renderOptionModalListExtendedOptionText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.small,
            color: Colours.GREY,
            paddingLeft: 10,
            margin: 0
        },
        renderOptionModalButtonText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.large,
            color: Colours.WHITE,
            margin: 0
        }
    });
};
