import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import '../../styles.css';
import Colours from '../../Constant/colour';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { auth, database } from '../../firebase';
import { onValue, ref } from 'firebase/database';
import { getLocalStorage } from '../../Helper/localStorage';
import CulverdocsTile from '../../Component/CulverdocsTile';
import Stylesheet from 'reactjs-stylesheet';
import Font from '../../Constant/font';
import { getDownloadURL } from 'firebase/storage';
import { ref as sRef } from 'firebase/storage';
import { storage } from '../../firebase';
import FileViewer from 'react-file-viewer';
import { ShowLoader } from '../../Common/Loader';
import { CulverdocsScreenHeader2 } from '../../Component/CulverdocsScreenHeader2';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Size from '../../Constant/size';
import { translate } from '../../Common/CulverdocsTranslate';
function Documents() {
    const drawerStatus = useSelector((state) => state.drawerStatus.key);
    const location = useLocation();
    const [searchText, setSearchText] = useState('');
    const [fileData, setFileData] = useState([]);
    const [folders, setFolders] = useState([]);
    const [currentFolder, setCurrentFolder] = useState('');
    const [downloadingFileKey, setDownloadingFileKey] = useState(null);
    const [loader, setLoader] = useState(false);

    const [folderCopy, setFolderCopy] = useState([]);
    const [brandData, setBrandData] = useState({});
    const [url, setUrl] = useState(null);
    const [userProfile, setUserProfile] = useState({});
    const navigate = useNavigate();
    const styles = getStyles(brandData);

    useEffect(() => {
        let brandData = getLocalStorage('brandData');
        let userProfiles = getLocalStorage('userProfiles');

        setBrandData(brandData);
        setUserProfile(userProfiles);
    }, []);

    useEffect(() => {
        setLoader(true);
        if (userProfile?.accountid) {
            const getDocument = async () => {
                let currentUser = auth.currentUser;
                if (!currentUser) {
                    return false;
                }
                let userId = currentUser.uid;
                if (!userId) {
                    return false;
                }

                let documnetData = ref(database, `/accounts/${userProfile.accountid}/document/`);
                onValue(documnetData, async (data) => {
                    documentListener(data);
                });
            };
            getDocument();
        }
    }, [userProfile]);
    const documentListener = (data) => {
        if (data) {
            fileParser(data);
        }
    };
    const handleTileTap = async (item) => {
        setCurrentFolder(item);
        setFolders([item]);
    };
    const fileParser = async (data) => {
        let currentUser = auth.currentUser;
        if (!currentUser) {
            return false;
        }
        let userId = currentUser.uid;
        if (!userId) {
            return false;
        }
        var datatopush = [];
        var folders = [];
        data.forEach((rec) => {
            let key = rec.key;
            rec = rec.val();
            if (rec.name && (!rec.user || rec.user == userId)) {
                if (rec.folder) {
                    if (folders.indexOf(rec.folder) === -1) {
                        folders.push(rec.folder);
                    }
                } else {
                    rec.folder = ''; //Set it to blank if its root, so we show on the root screen.
                }
                datatopush.push({ key: key, ...rec });
            }
        });

        folders.sort(); //Sort Alphabetically

        // 2023-04-07 (JJ): Improvements to Documents, alphabetical sorting and friendly No Documents & filter message
        datatopush.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        setCurrentFolder('');
        setFileData(datatopush);
        setFolders(folders);
        setFolderCopy(folders);
        setLoader(false);
    };

    const openFile = async (file) => {
        setLoader(true);
        if (downloadingFileKey) {
            setLoader(false);
            return; //Don't try to open the file again...
        }

        setDownloadingFileKey(file.key);

        let source = `/documents/${userProfile.accountid}/${file.key}`;
        let mediaRef = sRef(storage, source);

        let downloadedUrl = await getDownloadURL(mediaRef).then((url) => {
            return url;
        });

        await fetch(downloadedUrl)
            .then((response) => response)
            .then((data) => {
                setUrl(data?.url);
                setLoader(false);
                return data;
            })
            .catch((error) => {
                console.error('Error fetching base64 data:', error);
                setLoader(false);
            });

        setDownloadingFileKey(null);
    };
    var filesToShow = fileData.filter((file) => {
        if (searchText !== '') {
            if (currentFolder !== '') {
                return file.folder == currentFolder && file.name.toLowerCase().match(searchText.toLowerCase());
            } else {
                return file.name.toLowerCase().match(searchText.toLowerCase());
            }
        } else {
            if (file.folder == currentFolder) {
                return true;
            }
        }
    });
    return (
        <div style={styles.container}>
            <div
                style={styles.mainContainer}
                className="content-area"
            >
                <CulverdocsScreenHeader2
                    brandData={brandData}
                    ScreenName={'Documents'}
                    title={'Documents'}
                    LeftIcon={'circle-chevron-left'}
                    userProfile={userProfile}
                    onLeftIconClick={() => navigate('/home')}
                />
                <ShowLoader
                    flag={loader}
                    brandData={brandData}
                />
                <div style={{ padding: 30 }}>
                    <Container
                        fluid
                        style={styles.containerWraper}
                    >
                        {!url && (
                            <Row
                                style={styles.rowWrapper}
                                className={'custom_row'}
                            >
                                {searchText === '' &&
                                    folders.map((item) => {
                                        return (
                                            <Col
                                                style={styles.zeroPadding}  
                                                sm={12}
                                                key={`folder-${item.formid}`}
                                            >
                                                <CulverdocsTile
                                                    brandData={brandData}
                                                    key={item.formid}
                                                    onPress={() => handleTileTap(item)}
                                                    caption={item}
                                                    iconName={'folder-open'}
                                                    colour={'orange'}
                                                />
                                            </Col>
                                        );
                                    })}
                            </Row>
                        )}
                        {!url && (
                            <Row
                                style={styles.rowContainer}
                                className={'custom_row'}
                            >
                                {filesToShow.map((item) => {
                                    return (
                                        <Col
                                            style={styles.zeroPadding}
                                            sm={12}
                                            key={`file-${item.key}`}
                                        >
                                            <CulverdocsTile
                                                brandData={brandData}
                                                key={item.key}
                                                fields={item.fields}
                                                onPress={() => {
                                                    openFile(item);
                                                }}
                                                caption={item.name}
                                                iconName={item.icon}
                                                colour={item.colour ? item.colour : brandData.primarycolour}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        )}
                        <Row>
                            {url && (
                                <FileViewer
                                    fileType={'pdf'}
                                    filePath={url}
                                    style={{ display: 'flex', alignSelf: 'center' }}
                                    onError={() => console.log('error')}
                                />
                            )}
                        </Row>
                    </Container>
                </div>
            </div>
            <div>
                <div>
                    <Sidebar
                        collapsed={drawerStatus}
                        collapsedWidth={0}
                        backgroundColor={brandData.primarycolour}
                        style={{ ...styles.sideNavBar, ...{ border: '5px solid ' + brandData.primarycolour } }}
                    >
                        <Container
                            fluid
                            style={styles.boxWrapper}
                        >
                            <Row style={styles.zeroPadding}>
                                <Col>
                                    <div style={styles.tileWrapper}>
                                        <div style={styles.Header}>
                                            <p style={styles.renderOptionModalHeaderp}>{translate('Options')}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    lg={12}
                                    style={styles.colWrapper}
                                >
                                    <Form.Control
                                        style={styles.fieldText}
                                        placeholder={translate('Search')}
                                        defaultValue={searchText}
                                        onChange={(e) => {
                                            setSearchText(e.target.value);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Sidebar>
                </div>
            </div>
        </div>
    );
}

export default Documents;
const getStyles = (brandData) => {
    return Stylesheet.create({
        container: { display: 'flex', flexDirection: 'row', justifyContent: 'space-around' },
        mainContainer: { position: 'relative', marginLeft: 30, marginRight: 30, backgroundColor: Colours.WHITE, paddingBottom: 40, alignSelf: 'center' },
        rowWrapper: {
            paddingLeft: 0,
            paddingRight: 0,
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignContent: 'flex-start',
            overflow: 'auto'
            //height: '70vh'
        },
        rowContainer: {
            paddingLeft: 0,
            paddingRight: 0,
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignContent: 'flex-start',
            overflow: 'auto'
            //  height: '70vh'
        },
        sideNavBar: { width: 400, paddingLeft: 0, paddingRight: 0, marginLeft: 20, marginRight: 0, marginTop: 30, borderRadius: '5px' },
        zeroPadding: {
            paddingLeft: 0,
            paddingRight: 0
        },
        boxWrapper: {
            paddingLeft: 10,
            paddingRight: 10
        },
        tileWrapper: {
            width: '100%',
            width: 400,
            borderRadius: 10,
            borderWidth: 1,
            borderColor: brandData?.primarycolour,
            backgroundColor: Colours.WHITE
        },
        Header: {
            display: 'flex',
            height: 50,
            flexDirection: 'row',
            alignItems: 'center',
            borderTopRightRadius: 5,
            borderTopLeftRadius: 5,
            backgroundColor: brandData?.primarycolour,
            borderBottomWidth: 0,
            paddingRight: 0
        },
        containerWraper: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            paddingLeft: 0,
            paddingRight: 0
        },
        fieldText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
            backgroundColor: Colours.WHITE,
            marginBottom: 10,
            paddingTop: 10, //for iOS
            paddingBottom: 10, //for iOS
            paddingLeft: 5, //for iOS
            height: 45,
            border: '0.5px solid #000',
            borderRadius: 5,
            elevation: 5
        },

        renderOptionModalHeaderp: {
            fontFamily: Font.family.bold,
            fontSize: Font.size.large,
            color: Colours.WHITE,
            width: '80%', //Fix for the caption ellipsis overlapping the modal window.,
            margin: 0
        },
        colWrapper: { display: 'flex', marginTop: 10, paddingLeft: 0, paddingRight: 0 }
    });
};
