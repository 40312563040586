import React, { Component } from "react";
import Font from "../../../Constant/font";
import Colours from "../../../Constant/colour";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lightenDarkenColor } from "../../../Helper/lightenDarkenColor";
import CulverdocsFieldCaption from "./_CulverdocsFieldCaption";
import Stylesheet from "reactjs-stylesheet";
import { Form } from "react-bootstrap";
import { evaluate } from "mathjs";
export default class CulverdocsCalculation extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        // 2023-06-25 (JJ): NFE Re-added Calculation but requires performance rework to only trigger when source fields change
        return true; //(this.props.visible !== prevProps.visible || this.props.item.value !== prevProps.item.value || this.props.caption !== prevProps.caption);
    }

    render() {
        const styles = getStyle(this.props.brandData);

        if (!this.props.item.calculationexpression) {
            return;
        }

        var regexpr = "(\\[.*?\\])"; //Get everything between []
        var regex = new RegExp(regexpr, "g");
        var calcexpr = this.props.item.calculationexpression;

        // Loop through calculation expression to find all placeholders and parse them.
        // console.log(`Calculation calcexpr START: ${calcexpr}`);
        let results;
        while (
            (results = regex.exec(this.props.item.calculationexpression)) !==
            null
        ) {
            let result = results[0];
            result = result.replace("[", "").replace("]", "");
            // console.log(`Calculation calcexpr INSIDE 1: ${result}`);

            let newval;
            if (result.includes(".")) {
                newval = this.props.returnSumFromDatanameInsideRepeater(result); //This will sum the values in each child matching the dataname.
            } else {
                newval = this.props.returnValueFromDataname(
                    result,
                    this.props.item,
                    this.props.pItem,
                    this.props.rIndex
                ); // 2023-08-01 (JJ): NFE - Update calculation returnValueFromDataname to support repeaters
            }
            calcexpr = calcexpr.replace(`[${result}]`, newval);
        }

        // console.log(`Calculation calcexpr END: ${calcexpr}`);
        var calculated_value;
        try {
            calculated_value = evaluate(calcexpr);
            // math.
            // 2022-10-29 (JJ): Fix for iOS calculation fields containing a non-numeric value, returns object which crashed the app. If the response is an object it will be set to blank.
            // 2022-11-15 (JJ): Fix when saving as draft if the fields is NaN or Infinity - now clearing the value on calc.
            if (
                typeof calculated_value === "object" ||
                isNaN(calculated_value) ||
                !isFinite(calculated_value)
            ) {
                calculated_value = "";
            }
        } catch (exception) {
            // this.props.item.value = null; // 2022-10-26 (JJ): Fix for calculation fields throwing immutable error when saving with an in-app calc field. No longer try to set value in catch statement.
        }
        // console.log(`Calculated with Value: ${this.props.item.value}`);

        // 2023-06-25 (JJ): NFE Re-added Calculation but requires performance rework to only trigger when source fields change
        if (calculated_value !== this.props.item.value) {
            this.props.item.value = calculated_value;
            this.props.onFieldChanged(
                this.props.item,
                this.props.pItem,
                this.props.rIndex
            );
        }

        // 2022-07-07 (JJ): Allow calculation to run for hidden calculations, run calc and optionally display based on visibility rules.
        if (this.props.visible) {
            return (
                <div>
                    <CulverdocsFieldCaption
                        brandData={this.props.brandData}
                        icon={{ name: "calculator", colour: Colours.GREY }}
                        userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                        caption={this.props.item.caption}
                        mandatory={this.props.item.mandatory}
                        editable={false}
                        hasValue={this.props.item.value ? true : false}
                    />

                    <div style={styles.fieldNonEditableView}>
                        <p style={styles.fieldNonEditable}>
                            {this.props.item.value}
                        </p>
                    </div>
                </div>
            );
        }
    }
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        fieldNonEditable: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK,
        },
        fieldNonEditableView: {
            backgroundColor: Colours.WHITE,
            marginBottom: 10, // 2023-09-03 (JJ): Standardising marginBottom:10 on all components which were 15 as there were some inconsistencies with styling
            paddingTop: 10,
            // paddingBottom:10,
            paddingLeft: 5,
            height: 45,
            boxShadow: "2px 3px 1px #d2d2d2",
            border:
                "0.5px solid" +
                lightenDarkenColor(brandData?.primarycolour, 50),
            borderRadius: 5,
            elevation: 5,
        },
    });
};
