import { scale } from "./scale";

const width = window.innerWidth;
const height = window.innerHeight;
const Size = {
    section: scale(25),
    smallSection: scale(12.5),
    baseSection: scale(40),
    doubleBaseSection: scale(80),
    baseMargin: scale(10),
    headingHeight: scale(50),
    mediumBaseMargin: scale(15),
    bigMargin: scale(40),
    doubleBaseMargin: scale(20),
    tinyMargin: scale(2),
    miniMargin: scale(3),
    smallMargin: scale(5),
    mediumMargin: scale(8),
    doubleSection: scale(52),
    horizontalLineHeight: 1,
    fontScale: scale(25),
    bigMediumMargin: scale(35),
    barHeight: scale(30),
    screenWidth: width < height ? width : height,
    screenHeight: width < height ? height : width,
    navBarHeight: scale(64),
    rowHeight: scale(60),
    sectionMargin: scale(150),
    scalingHeight: scale(45),
    IconSection: scale(50),
    imageProfileSection: scale(130),
    imageProfileSmallSection: scale(100),
    inputHeight: scale(50),
    buttonHeight: scale(40),
    buttonRadius: scale(5),
    borderRadius: scale(5),
    borderDoubleRadius: scale(10),
    borderTripleRadius: scale(15),
    fontMediumScale: scale(17),
    borderWidth: 1, //scale(2),
    borderDoubleWidth: scale(4),
    borderThick: 4, //scale(5),
    thinBorder: 1,
    widthBox: scale(300),
    compaignItemHeight: scale(200),
    channelImage: scale(210),
    brandItemHeight: scale(80),
    smallBrandHeight: scale(70),
    inspireImageHeight: scale(125),
    inspireImageWidth: scale(210),
    badgeSize: scale(20), // 2024-04-24 (RB): Add badge scaled size
    icons: {
        tiny: scale(12),
        small: scale(25),
        xsmall: scale(20),

        medium: scale(30),
        large: scale(40),
        xl: scale(50),
    },
    images: {
        small: scale(20),
        medium: scale(45),
        large: scale(90),
        logo: scale(200),
    },
    keyboardVerticalOffset: 64,
    keyboardShouldPersistTaps: "handled",
    CountScale: (val) => {
        return scale(val);
    },
};

export default Size;
