import React, { Component } from 'react';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import Font from '../../../Constant/font';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
export default class CulverdocsLongAnswer extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
        return this.props.itemValue !== prevProps.itemValue || this.props.visible !== prevProps.visible || this.props.item.value !== prevProps.item.value || this.props.caption !== prevProps.caption;
    }
    constructor(props) {
        super(props);
        this.textAreaRef = React.createRef();
    }
    componentDidMount() {
        this.adjustTextareaHeight();
    }
    adjustTextareaHeight = () => {
        if (this.textAreaRef.current) {
            const textarea = this.textAreaRef.current;
            textarea.style.height = 'auto';
            textarea.style.overflow = 'hidden'; // 2024-08-23 (RB): Add this for removing scroller
            textarea.style.height = textarea.scrollHeight + 'px'; // Set height to scrollHeight}
        }
    };

    render() {
        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption}`);

        if (!this.props.visible) {
            return null;
        }
        const styles = getStyle(this.props.brandData);

        return (
            <div style={{ position: 'relative' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CulverdocsFieldCaption
                        brandData={this.props.brandData}
                        caption={this.props.item.caption}
                        userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                        mandatory={this.props.item.mandatory}
                        editable={this.props.editable}
                        hasValue={this.props.item.value ? true : false}
                    />

                    {/* // 2023-09-10 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Long Answer) */}
                    {this.props.item.lockfieldtype && (
                        <div style={{ position: 'absolute', right: 0, top: 0 }}>
                            <div
                                hitslop={{
                                    left: 10,
                                    right: 10,
                                    bottom: 10,
                                    top: 10
                                }}
                                onClick={() => {
                                    this.props.toggleFieldLock(this.props.item, !this.props.item.locked).then(() => {
                                        // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                                        if (this.props.pItem) {
                                            this.props.forceRepeaterChildUpdate();
                                        }

                                        this.forceUpdate();
                                    });
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={['fad', this.props.item.locked ? 'square-a-lock' : 'lock-keyhole-open']}
                                    color={this.props.item.locked ? Colours.GREEN : Colours.GREY}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {/* Editable */}
                {this.props.editable && (
                    // <TextInput
                    //     ref={(ref) => {
                    //         this.thisRef = ref;
                    //     }} // 2023-11-06 (JJ): Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                    //     placeholderTextColor={Colours.BACKGROUND}
                    //     defaultValue={this.props.item.value}
                    //     multiline={true}
                    //     autogrow={true}
                    //     scrollEnabled={this.props.item.height ? true : false} // 2023-08-11 (JJ): NFE - Disabling scrollEnabled on Long Answer - this will simply grow the textinput and should fix KeyboardAware iOS issues
                    //     style={[styles.fieldTextBlock, this.props.item.height ? { height: this.props.item.height } : null]}
                    //     maxLength={this.props.item.maxvalue && parseInt(this.props.item.maxvalue) > 0 ? parseInt(this.props.item.maxvalue) : null} // 2023-10-07 (JJ): NFE - Updating Short Answer & Long Answer to support maxLength (maxvalue) property if it has been set
                    //     onChangeText={(text) => {
                    //         this.props.item.value = text;
                    //         this.forceUpdate();
                    //         // 2023-11-06 (JJ): Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                    //         // If onChangeText is called without focus, the field has been updated externally (i.e. S Pen) and needs to write to the DB by calling onEndEditing
                    //         if (!this.thisRef.isFocused()) {
                    //             clearTimeout(this.thisRef.timeoutToUpdate);
                    //             this.thisRef.timeoutToUpdate = setTimeout(() => {
                    //                 this.thisRef.props.onEndEditing();
                    //             }, 1000);
                    //         }
                    //     }}
                    //     onEndEditing={() => {
                    //         this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                    //         this.forceUpdate();
                    //     }}
                    //     defaultvalue={this.props.item.value}
                    // />
                    <Form.Control
                        ref={this.textAreaRef}
                        as="textarea"
                        rows={2}
                        style={styles.fieldTextBlock}
                        value={this.props.item.value}
                        onChange={(text) => {
                            this.props.item.value = text.target.value;
                            this.adjustTextareaHeight();
                            this.forceUpdate();
                        }}
                        onBlur={() => {
                            this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                            this.forceUpdate();
                        }}
                    />
                )}

                {/* Non-editable */}
                {!this.props.editable && (
                    <div style={styles.fieldTextBlock}>
                        <p style={styles.fieldNonEditable}>{this.props.item.value}</p>
                    </div>
                )}
            </div>
        );
    }
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        fieldNonEditable: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            // backgroundColor: Colours.MIDGREY,
            color: Colours.GREY
        },

        // Field Specific
        fieldTextBlock: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
            minHeight: 40,
            backgroundColor: Colours.WHITE,
            // borderBottomWidth: 1,
            marginBottom: 10,
            paddingTop: 10, //for iOS
            paddingBottom: 10, //for iOS
            paddingLeft: 5, //for iOS
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            borderRadius: 5,
            elevation: 5
        }
    });
};
