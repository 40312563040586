import { ref, update } from 'firebase/database';
import moment from 'moment';
import { database } from '../firebase';
// TODO: Need to re-test the toISOString to avoid issues with deprecation warnings
export async function updateDeviceInfo(uid) {
    // const deviceId = uuidv4();
    let databaseRef = ref(database, `/users/${uid}/appdata`);

    const getBrowserName = () => {
        const userAgent = navigator.userAgent;

        if (userAgent.indexOf('Firefox') > -1) {
            return 'Firefox';
        } else if (userAgent.indexOf('SamsungBrowser') > -1) {
            return 'Samsung Browser';
        } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
            return 'Opera';
        } else if (userAgent.indexOf('Trident') > -1) {
            return 'Internet Explorer';
        } else if (userAgent.indexOf('Edge') > -1) {
            return 'Edge';
        } else if (userAgent.indexOf('Chrome') > -1) {
            return 'Chrome';
        } else if (userAgent.indexOf('Safari') > -1) {
            return 'Safari';
        }
        return 'Unknown';
    };
    const getOSName = () => {
        const userAgent = navigator.userAgent;

        if (userAgent.indexOf('Win') > -1) {
            return 'Windows';
        } else if (userAgent.indexOf('Mac') > -1) {
            return 'MacOS';
        } else if (userAgent.indexOf('X11') > -1 || userAgent.indexOf('Linux') > -1) {
            return 'Linux';
        } else if (userAgent.indexOf('Android') > -1) {
            return 'Android';
        } else if (userAgent.indexOf('like Mac') > -1) {
            return 'iOS';
        }
        return 'Unknown';
    };

    return update(databaseRef, {
        version: '1.0.0', //DeviceInfo.getVersion(), //app version
        lastauth: moment(new Date().toISOString()).format('YYYY-MM-DD HH:mm:ss'),
        brand: getBrowserName(), //apple etc
        model: 'Web', //model name of the device (may be incorrect)
        systemname: 'Web', //iOS or Android
        systemversion: getOSName(),
        signedin: true
    });
}
