import React, { Component } from 'react';
import Font from '../../../Constant/font';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';

export default class CulverdocsLocation extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
        return this.props.visible !== prevProps.visible || this.props.itemValue !== prevProps.itemValue || this.props.caption !== prevProps.caption;
    }

    render() {
        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption}`);

        if (!this.props.visible) {
            return null;
        }
        const styles = getStyle(this.props.brandData);
        return (
            <div>
                <CulverdocsFieldCaption
                    brandData={this.props.brandData}
                    caption={this.props.item.caption}
                    userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                    mandatory={this.props.item.mandatory}
                    editable={this.props.editable}
                    hasValue={this.props.item.value ? true : false}
                />

                {/* Editable */}
                {this.props.editable && (
                    <div style={{ flex: 1 }}>
                        <div
                            onClick={async () => {
                                // 2022-07-05 (JJ): Update for GPS Permission Check for generic function and support location field without permissions.
                                //  await this.props.checkGPSPermissions();

                                if (this.props.item.state == 'searching') {
                                    return;
                                }
                                this.props.item.state = 'searching';
                                this.forceUpdate();

                                navigator.geolocation.getCurrentPosition(
                                    async (location) => {
                                        //Stamp the location when we have it.
                                        var gps = `${location.coords.latitude},${location.coords.longitude}`;
                                        this.props.item.value = gps;
                                        this.props.item.data = {
                                            ...location.coords,
                                            //   mocked: location.mocked,
                                            timestamp: location.timestamp
                                        };

                                        // 2022-10-26 (JJ): Update location field to support latitude/longitude split for distance calculations
                                        this.props.item.latitude = location.coords.latitude;
                                        this.props.item.longitude = location.coords.longitude;

                                        this.props.item.state = null;
                                        this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                                        this.forceUpdate();
                                    },
                                    (e) => {
                                        // What if we've failed retrieving GPS?
                                        // console.log("Failed gps",e)
                                        //  this.props.item.state = '';
                                        //this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                                        //this.forceUpdate();
                                    },
                                    // {'enableHighAccuracy':true,  'maximumAge':0, 'timeout':30000}
                                    { enableHighAccuracy: true, timeout: 30000 }
                                );
                            }}
                            style={styles.fieldLocation}
                            value={this.props.item.value}
                        >
                            <p style={styles.valueText}>{this.props.item.value ? this.props.item.value : ''}</p>

                            <div style={styles.fieldIcon}>
                                {/* { this.props.item.state == "searching" && 
                            // <Spinner size={25} type={'ThreeBounce'} color={this.props.brandData?.primarycolour} style={{alignSelf: 'flex-end', right:5}}/>
                        } */}
                                {this.props.item.state !== 'searching' && (
                                    <FontAwesomeIcon
                                        icon={['fad', 'location-dot']}
                                        color={lightenDarkenColor(this.props.brandData?.primarycolour, 50)}
                                        style={{
                                            alignSelf: 'flex-end',
                                            right: 5,
                                            width: 25,
                                            height: 25
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {/* Non-editable */}
                {!this.props.editable && (
                    <div style={styles.fieldLocation}>
                        <p style={styles.fieldNonEditable}>{this.props.item.value}</p>
                    </div>
                )}
            </div>
        );
    }
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        // Common Styles - Mandatory & Caption
        // mandatoryimg: {
        //     alignContent: 'center',
        //     alignSelf: 'center',
        //     top:2,
        //     marginRight: 0,
        //     marginLeft: 0,
        //     tintColor: Colours.RED
        // },
        // fieldcaption: {
        //     fontFamily: Font.family.bold,
        //     fontSize: Font.size.medium,
        //     color: Colours.BLACK
        // },
        fieldNonEditable: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            // backgroundColor: Colours.WHITE,
            color: Colours.GREY
        },
        // fieldNonEditableView : {
        //     // borderBottomWidth: 1,
        //     // borderBottomColor: brandData?.primarycolour,
        //     backgroundColor: Colours.WHITE,
        //     marginBottom: 10,
        //     paddingTop:10, //for iOS
        //     paddingBottom:10, //for iOS
        //     paddingLeft:5, //for iOS

        //     height: 45,
        //     borderWidth: 0.5,
        //     borderColor: brandData?.primarycolour,
        //     borderRadius: 5,
        //     elevation:5,
        // },
        fieldCaptionBadge: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.small,
            color: Colours.BLACK,
            marginLeft: 5,
            paddingLeft: 5,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: lightenDarkenColor(brandData?.primarycolour, 50),
            borderRadius: 5,
            borderWidth: 1,
            borderColor: lightenDarkenColor(brandData?.primarycolour, 50),
            padding: 2,
            paddingLeft: 5,
            paddingRight: 5,
            height: 20,
            maxWidth: 150 // 2022-10-26 (JJ): Set maxWidth on fieldcaptions to limit content length (postcode changes)
        },

        fieldLocation: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'center',
            backgroundColor: Colours.WHITE,
            // borderBottomWidth: 1,
            marginBottom: 10, // 2023-09-03 (JJ): Standardising marginBottom:10 on all components which were 15 as there were some inconsistencies with styling
            paddingTop: 10, //for iOS
            paddingBottom: 10, //for iOS
            paddingLeft: 5, //for iOS

            height: 45,
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            borderRadius: 5,
            elevation: 5
        },
        fieldIcon: {
            alignSelf: 'flex-end',
            right: 5,
            marginRight: 10
        },
        valueText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            flex: 0.9,
            color: Colours.BACKGROUND
        }

        // renderLeftIconText: {
        //     fontFamily: Font.family.regular,
        //     fontSize: Font.size.medium,
        //     flex: 1,
        //     color: Colours.BACKGROUND,
        //     paddingLeft:10,
        // },
    });
};
