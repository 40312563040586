import React, { Component } from 'react';
import Font from '../../../Constant/font';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import FileViewer from 'react-file-viewer';
import { getDownloadURL } from 'firebase/storage';
import { ref as sRef } from 'firebase/storage';
import { translate } from '../../../Common/CulverdocsTranslate';
import { storage } from '../../../firebase';
import Modal from 'react-bootstrap/Modal';
import Size from '../../../Constant/size';
import { ShowLoader } from '../../../Common/Loader';

// 2023-12-19 (JJ): NFE - Updates to CulverdocsDocument - renamed to DocumentViewer and updated props to support Populate From List
class CulverdocsDocumentViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            downloadingFile: false,
            url: ''
        };
    }
    shouldComponentUpdate(prevProps, prevState) {
        // 2023-12-19 (JJ): NFE - Updates to CulverdocsDocument - renamed to DocumentViewer and updated props to support Populate From List
        return this.state !== prevState || this.props.visible !== prevProps.visible || this.props.item.timestamp !== prevProps.item.timestamp || this.props.caption !== prevProps.caption || this.props.documentviewerfilename !== prevProps.documentviewerfilename;
    }

    render() {
        if (!this.props.visible) {
            return null;
        }

        const styles = getStyle(this.props.brandData);
        return (
            <div>
                <CulverdocsFieldCaption
                    brandData={this.props.brandData}
                    caption={this.props.item.caption}
                    userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                    mandatory={this.props.item.mandatory}
                    editable={this.props.editable}
                    hasValue={this.props.item.timestamp ? true : false}
                />

                <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1
                        }}
                    >
                        <div
                            onClick={() => {
                                this.openDocumentLibraryFile();
                            }}
                            style={styles.fieldContainer}
                        >
                            <p style={styles.fieldText}>
                                {/* // 2023-12-19 (JJ): NFE - Updates to CulverdocsDocument - renamed to DocumentViewer and updated props to support Populate From List */}
                                {translate(this.props.documentviewerfilename)}
                            </p>
                            <div style={{ marginRight: 10 }}>
                                <FontAwesomeIcon
                                    icon={['fad', this.state.downloadingFileKey == this.props.item.id ? 'ellipsis' : 'file-pdf']}
                                    color={lightenDarkenColor(this.props.brandData?.primarycolour, 50)}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignSelf: 'flex-end',
                                        right: 10
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    centered
                    show={this.state.showDoc}
                >
                    <div
                        // behavior="height"
                        // enabled
                        style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: Colours.MODALBG, evelation: 4, justifyContent: 'center', alignItems: 'center', width: '100%' }}
                    >
                        <div style={{ width: window.innerWidth / 2, height: '100%', borderRadius: 10, borderWidth: 1, borderColor: this.props.brandData.primarycolour, backgroundColor: Colours.WHITE }}>
                            <div style={{ height: 50, display: 'flex', flexDirection: 'row', alignContent: 'center', borderTopRightRadius: 5, borderTopLeftRadius: 5, backgroundColor: this.props.brandData.primarycolour, borderBottomColor: this.props.brandData.primarycolour, borderBottomWidth: 2, paddingTop: 10 }}>
                                <div
                                    onClick={() => {
                                        this.setState({ showDoc: false, url: '' });
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={['fad', 'circle-chevron-left']}
                                        color={Colours.WHITE}
                                        style={{ marginLeft: 10, width: 30, height: 30, cursor: 'pointer' }}
                                    />
                                </div>
                                <p
                                    style={{
                                        fontFamily: Font.family.bold,
                                        fontSize: Font.size.large,
                                        color: Colours.WHITE,
                                        marginLeft: 5
                                    }}
                                    numberoflines={1}
                                >
                                    {translate('Documents')}
                                </p>
                            </div>
                            {this.state.url && (
                                <FileViewer
                                    fileType={'pdf'}
                                    filePath={this.state.url}
                                    errorComponent={
                                        <div>
                                            <p>no file</p>
                                        </div>
                                    }
                                    onError={() => console.log('error')}
                                />
                            )}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    async openDocumentLibraryFile(file) {
        this.setState({ showDoc: true });
        if (!this.props.editable) {
            return; //Don't attempt to open if it's a non-editable form
        }

        if (this.state.downloadingFile) {
            return; //Don't try to open the file again...
        }

        var destination; // 2023-04-10 (JJ): Support for Embedded Document Viewer and Temp File Deletion

        // Check if we have a local cached version, this includes the file.key to ensure it is the correct version of the file, as the key will change when deleted/reuploaded.
        if (this.props.item.documentviewerfileid) {
            //destination = 'abc'; //`${utils.FilePath.CACHES_DIRECTORY}/${this.props.item.documentviewerfileid}.pdf`; //v1.6.0 is hardcoded for .pdf files, so best to cache using the key as the filename to avoid filename changes on portal re-downloading.

            //  let downloaded = true; // await RNFS.exists(destination);
            let filepath = `/documents/${this.props.userProfile.accountid}/${this.props.item.documentviewerfileid}`; // 2021-12-29 (JJ): Build the path based on the storage folder.
            let mediaRef = sRef(storage, filepath);

            let downloadedUrl = await getDownloadURL(mediaRef).then((url) => {
                return url;
                // Or inserted into an <img> element
                // const img = document.getElementById("myimg");
                // img.setAttribute("src", url);
            });
            this.setState({ url: destination });
            //  let downloaded = true;
            destination = await fetch(downloadedUrl)
                .then((response) => response)
                .then((data) => {
                    this.setState({ url: data.url });
                    return data;
                })
                .catch((error) => console.error('Error fetching base64 data:', error));
            //const url = URL.createObjectURL(destination.blob());

            // this.setState({ url: url });
            //console.log('downloaded', downloaded);
            // if (!downloaded) {
            //     this.setState({ downloadingFile: true });
            //     let source = `/documents/${this.props.userProfile.accountid}/${this.props.item.documentviewerfileid}`;

            //     // Download the file to cache directory
            //     // const storageobject = storage().ref(source);
            //     // await storageobject.writeToFile(destination).catch(() => {
            //     //     alert(
            //     //         translate("Download Failed"),
            //     //         translate(
            //     //             "There was a problem downloading the selected file, please check your connection and try again."
            //     //         )
            //     //     );
            //     //     return; //Caught an error writing the file, this could happen if the file doesn't exist in the storage bucket.
            //     // });

            //     downloaded = true; // await RNFS.exists(destination);
            // }
        }

        // 2023-04-10 (JJ): Support for Embedded Document Viewer and Temp File Deletion
        //TODO: Jeremy checks if this required
        // Support base64 to file and clear the data from the form on download.
        // if (this.props.item.documentviewerbase64) {
        //     // 2023-04-10 (JJ): Implementing cache/tmp directory for files being opened/cached in CreateFormScreen, now clears the directory when loading the app (excluding Documents)
        //     destination = `${this.props.userProfile._tmpFiles}/${this.state.form.formid}-${this.props.item.id}-${new Date().getTime()}.pdf`;
        //     let downloaded = true; //await RNFS.exists(destination);

        //     if (!downloaded) {
        //         this.setState({ downloadingFile: true });

        //         // await RNFS.writeFile(
        //         //     destination,
        //         //     this.props.item.documentviewerbase64,
        //         //     "base64"
        //         // ).catch(() => {
        //         //     alert(
        //         //         translate("Download Failed"),
        //         //         translate(
        //         //             "There was a problem downloading the selected file, please check your connection and try again."
        //         //         )
        //         //     );
        //         //     return;
        //         // });

        //         downloaded = true; // await RNFS.exists(destination);
        //     }
        // }

        // 2023-06-24 (JJ): DocumentOpenNewTask / intentFlagActivityNewTask to open document in new task - repo fork https://github.com/jeremygmajor/react-native-file-viewer.git
        let intentFlagActivityNewTask = this.props.item.documentopennewtask ? true : false;

        // // Lets try to open it
        // FileViewer.open(destination, {
        //     intentFlagActivityNewTask: intentFlagActivityNewTask,
        // }).catch((error) => {
        //     alert(
        //         translate("Download Failed"),
        //         translate(
        //             "There was a problem downloading the selected file, please check your connection and try again."
        //         )
        //     );
        // });

        this.setState({ downloadingFile: false });

        // Set the timestamp of when the file was opened.
        this.props.item.timestamp = new Date().getTime();
        this.props.item.value = this.props.item.documentviewerfilename ? this.props.item.documentviewerfilename : this.props.item.caption; // 2023-04-10 (JJ): Stamp the filename on opening a document if it exists, otherwise flal back to caption
        this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
        this.forceUpdate();
    }
}

export default CulverdocsDocumentViewer;
const getStyle = (brandData) => {
    return Stylesheet.create({
        fieldContainer: {
            display: 'flex',
            flexDirection: 'row',
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK,
            backgroundColor: Colours.WHITE,
            marginBottom: 10, // 2023-09-03 (JJ): Standardising marginBottom:10 on all components which were 15 as there were some inconsistencies with styling
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 5,
            justifyContent: 'space-between',
            height: 45,
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            borderRadius: 5,
            elevation: 5
        },
        // fieldContainerNE: {
        //     flexDirection: 'row',
        //     fontFamily: Font.family.regular,
        //     fontSize: Font.size.medium,
        //     color: Colours.BLACK,
        //     backgroundColor: Colours.MIDGREY,
        //     marginBottom: 10,
        //     paddingTop:10,
        //     paddingBottom:10,
        //     paddingLeft:5,
        //     height:45,
        //     borderWidth: 0.5,
        //     borderColor: this.props.brandData?.primarycolour,
        //     borderRadius: 5,
        //     elevation:5,
        // },
        fieldText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BACKGROUND,
            flex: 0.9
        }
    });
};
