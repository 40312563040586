import React, { Component } from 'react';
// import {
//     PixelRatio,
//     div,
//     KeyboardAvoidingView,
//     BackHandler,
//     ScrollView,
//     Modal,
//     Text,
//     StyleSheet,
//     TouchableHighlight,
//     Image,
//     div,
//     TextInput,
//     FlatList,
//     Dimensions,
//     AppState,
//     Alert,
//     PermissionsAndroid,
//     Platform,
//     Keyboard,
// } from "react-native";
// import RNSketchCanvas from "@terrylinla/react-native-sketch-canvas";
// import SketchCanvas from "@terrylinla/react-native-sketch-canvas/src/SketchCanvas";
// import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
// import { Colours, Font } from "@src/Constant";
import Font from '../../../Constant/font';
import Size from '../../../Constant/size';

import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { translate } from '../../../Common/CulverdocsTranslate';
import FlatList from 'flatlist-react';
import CanvasDraw from 'react-canvas-draw';

export default class CulverdocsIllustrationComponent extends Component {
    state = {
        ...this.state,
        strokeStyle: 'draw',
        canvasText: [],
        showTextState: false,
        showColourComponentState: false,
        previousColour: '#000000',
        color: this.props.defaultStrokeColor ? this.props.defaultStrokeColor : '#000000', // 2024-01-09 (JJ): Illustration field support for defaultStrokeColor (used for Photo illustration, default to black if null)
        textToInsert: '',
        canvasTextBackup: [],
        history: [],
        strokeWidth: 2
    };

    toggleColourComponent(state) {
        this.setState({ showColourComponentState: state });
    }

    render() {
        const styles = getStyle(this.props.brandData);
        return (
            <div style={this.props.containerStyle}>
                <div
                    style={{
                        display: 'flex',
                        height: 50,
                        flexDirection: 'column',
                        borderTopRightRadius: 5,
                        borderTopLeftRadius: 5,
                        backgroundColor: this.props.brandData?.primarycolour,
                        borderBottomColor: this.props.brandData?.primarycolour,
                        borderBottomWidth: 2
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row',
                            alignContent: 'center'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.9,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <div
                                style={{
                                    paddingHorizontal: 10
                                }}
                                hitslop={{
                                    left: Size.CountScale(10),
                                    right: Size.CountScale(10),
                                    bottom: Size.CountScale(10),
                                    top: Size.CountScale(10)
                                }}
                                onClick={() => this.props.onClose()}
                            >
                                <FontAwesomeIcon
                                    icon={['fad', 'circle-chevron-left']}
                                    style={{
                                        width: Size.icons.small,
                                        height: Size.icons.small,
                                        marginLeft: 5,
                                        marginRight: 5,
                                        cursor: 'pointer'
                                    }}
                                    color={Colours.WHITE}
                                />
                            </div>
                            <p
                                style={styles.renderOptionModalHeaderText}
                                numberoflines={1}
                            >
                                {/* {translate(this.props.item.caption)}{' '} */}
                            </p>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 0.1,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                paddingRight: 10
                            }}
                        >
                            <div
                                hitslop={{
                                    left: Size.CountScale(10),
                                    right: Size.CountScale(10),
                                    bottom: Size.CountScale(10),
                                    top: Size.CountScale(10)
                                }}
                                onClick={async () => {
                                    //this.canvasRef.saveToFile();
                                    // console.log(this._sketchCanvas.getDataURL());
                                    if (!this.props.backgorundImage) {
                                        this.props.onSaveData(this._sketchCanvas.getDataURL());
                                    } else {
                                        let data = await this.saveCanvas(this._sketchCanvas);
                                        //console.log('this is majot data', data);
                                        this.props.onSaveData(data);
                                    }

                                    //this.canvasRef.getSaveData();
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={['fad', 'check-circle']}
                                    style={{
                                        width: Size.icons.small,
                                        height: Size.icons.small
                                    }}
                                    color={Colours.WHITE}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {!this.state.showTextState && (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flex: 1,
                                justifyContent: 'flex-start'
                            }}
                        >
                            {this.props.closeComponent && (
                                <div
                                    onClick={() => {
                                        this.props.onClosePressed();
                                    }}
                                >
                                    {this.props.closeComponent}
                                </div>
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flex: 1,
                                justifyContent: 'flex-end'
                            }}
                        >
                            <div
                                onClick={() => {
                                    // 2023-03-07 (JJ): Adding support for eraser tool in Illustration
                                    if (this.state.strokeStyle == 'eraser' || this.state.strokeStyle == 'text') {
                                        this.setState({
                                            color: this.state.previousColour
                                        });
                                    }

                                    this.setState({
                                        strokeStyle: 'draw',
                                        showTextState: false,
                                        showColourComponentState: false
                                    });
                                }}
                            >
                                <div style={styles.canvasFunctionButton}>
                                    <FontAwesomeIcon
                                        icon={['fad', 'wave-sine']}
                                        style={{ width: 20, height: 20 }}
                                        color={this.state.strokeStyle == 'draw' ? Colours.BLACK : Colours.GREY}
                                    />
                                </div>
                            </div>

                            {/* Line */}
                            <div
                                onClick={() => {
                                    // 2023-03-07 (JJ): Adding support for eraser tool in Illustration
                                    if (this.state.strokeStyle == 'eraser' || this.state.strokeStyle == 'text') {
                                        this.setState({
                                            color: this.state.previousColour
                                        });
                                    }
                                    this.setState({
                                        strokeStyle: 'line',
                                        showTextState: false,
                                        showColourComponentState: false
                                    });
                                }}
                            >
                                <div style={styles.canvasFunctionButton}>
                                    <FontAwesomeIcon
                                        icon={['fad', 'ruler']}
                                        style={{ width: 20, height: 20 }}
                                        color={this.state.strokeStyle == 'line' ? Colours.BLACK : Colours.GREY}
                                    />
                                </div>
                            </div>

                            {/* Eraser */}
                            {/* // 2023-03-07 (JJ): Adding support for eraser tool in Illustration */}
                            <div
                                onClick={() => {
                                    this.setState({
                                        strokeStyle: 'eraser',
                                        previousColour: this.state.color,
                                        color: '#FFFFFF',
                                        showTextState: false,
                                        showColourComponentState: false
                                    });
                                }}
                            >
                                <div style={styles.canvasFunctionButton}>
                                    <FontAwesomeIcon
                                        icon={['fad', 'eraser']}
                                        style={{ width: 20, height: 20 }}
                                        color={this.state.strokeStyle == 'eraser' ? Colours.BLACK : Colours.GREY}
                                    />
                                </div>
                            </div>

                            {/* Text */}
                            {/* // 2023-08-28 (JJ): Illustration updates - if Source Image is provided (without "donotcropoutput"=true property), remove text option and crop on save to avoid resizing in view + capture timestamp on save. */}
                            {!this.props.cropOutput && (
                                <div
                                    onClick={() => {
                                        // 2023-03-07 (JJ): Adding support for eraser tool in Illustration
                                        // Only set the previous colour if the tool wasn't previously eraser, otherwise it returns to white. This will return to the last known colour.
                                        if (this.state.strokeStyle !== 'eraser') {
                                            this.setState({
                                                previousColour: this.state.color
                                            });
                                        }

                                        this.setState({
                                            strokeStyle: 'text',
                                            color: '#00000000',
                                            showTextState: true,
                                            canvasTextBackup: JSON.parse(JSON.stringify(this.state.canvasText)),
                                            showColourComponentState: false,
                                            textToInsert: ''
                                        });
                                    }}
                                >
                                    <div style={styles.canvasFunctionButton}>
                                        <FontAwesomeIcon
                                            icon={['fad', 'text']}
                                            style={{ width: 20, height: 20 }}
                                            color={this.state.strokeStyle == 'text' ? Colours.BLACK : Colours.GREY}
                                        />
                                    </div>
                                </div>
                            )}

                            {this.props.strokeWidthComponent && (
                                <div
                                    style={styles.canvasFunctionButton}
                                    onClick={() => {
                                        this.setState((prevState) => ({
                                            strokeWidth: prevState.strokeWidth < 20 ? prevState.strokeWidth + 2 : 2
                                        }));
                                    }}
                                >
                                    <div style={{ backgroundColor: 'black', height: this.state.strokeWidth, width: this.state.strokeWidth, borderRadius: 50 }}></div>
                                    {/* {this.props.strokeWidthComponent(this.state.strokeWidth)} */}
                                </div>
                            )}

                            <div
                                style={{
                                    // borderLeftWidth: 2,
                                    // borderColor:
                                    //     this.props.brandData?.primarycolour,
                                    border: '2px solid' + this.props.brandData?.primarycolour,
                                    marginLeft: 8,
                                    marginTop: 7,
                                    marginBottom: 7
                                    // paddingRight: 7,
                                }}
                            ></div>

                            <div
                                onClick={() => {
                                    this.toggleColourComponent(!this.state.showColourComponentState);
                                }}
                            >
                                <div style={styles.canvasFunctionButton}>
                                    <FontAwesomeIcon
                                        icon={['fad', 'palette']}
                                        style={{ width: 12, height: 12 }}
                                        color={this.state.color}
                                    />
                                </div>
                            </div>

                            <div
                                onClick={() => {
                                    // this.props.onUndoPressed(this.undo())
                                    this._sketchCanvas.undo();
                                    let lastHistory = this.state.history[this.state.history.length - 1];
                                    // if (lastHistory) {
                                    //     if (lastHistory.type == 'path') {
                                    //         this.deletePath(lastHistory.id);
                                    //     }

                                    //     if (lastHistory.type == 'text') {
                                    //         let textEntry = this.state.canvasText.find((child) => child.id === lastHistory.id);
                                    //         if (textEntry) {
                                    //             textEntry.text = ''; //Remove the text!
                                    //         }
                                    //     }

                                    //     this.state.history.pop(); //Remove the last element
                                    //     this.forceUpdate();
                                    // }
                                }}
                            >
                                <div style={styles.canvasFunctionButton}>
                                    <FontAwesomeIcon
                                        icon={['fad', 'undo']}
                                        style={{ width: 12, height: 12 }}
                                        color={Colours.WHITE}
                                    />
                                </div>
                            </div>

                            <div
                                style={{ paddingRight: 5 }}
                                onClick={() => {
                                    this._sketchCanvas.clear();
                                    // this.clear();
                                    //   this.props.onClearPressed();
                                    this.setState({ canvasText: [] });
                                }}
                            >
                                <div style={styles.canvasFunctionButton}>
                                    <FontAwesomeIcon
                                        icon={['fad', 'trash']}
                                        style={{ width: 12, height: 12 }}
                                        color={Colours.WHITE}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {this.state.showTextState && (
                    <div style={{ flexDirection: 'row' }}>
                        <div style={styles.inputTextContainer}>
                            {/* <TextInput
                                // ref={(input) => { this.textInput = input; }}
                                ref={(ref) => (this._textInput = ref)}
                                style={styles.inputText}
                                onChangeText={(text) => {
                                    this.setState({ textToInsert: text });
                                }}
                                autoFocus={true}
                            /> */}
                            <Form.Control
                                style={{ border: '0px solid' }}
                                placeholder={translate('search')}
                                onChange={(text) => {
                                    this.setState({
                                        textToInsert: text.target.value
                                    });
                                }}
                            />
                            {/* Accept Button */}
                            <div
                                onClick={() => {
                                    this.setState({
                                        strokeStyle: 'draw',
                                        showTextState: false,
                                        showColourComponentState: false,
                                        color: this.state.previousColour
                                    });
                                }}
                            >
                                <div style={[styles.canvasFunctionButton, { top: -5, right: 3 }]}>
                                    <FontAwesomeIcon
                                        icon={['fad', 'check']}
                                        color={this.state.strokeStyle == 'text' ? Colours.BLACK : Colours.GREY}
                                    />
                                </div>
                            </div>

                            {/* Cancel Button */}
                            <div
                                onClick={() => {
                                    this.setState({
                                        strokeStyle: 'draw',
                                        showTextState: false,
                                        showColourComponentState: false,
                                        canvasText: this.state.canvasTextBackup,
                                        color: this.state.previousColour
                                    });
                                }}
                            >
                                <div style={[styles.canvasFunctionButton, { top: -5, right: 3 }]}>
                                    <FontAwesomeIcon
                                        icon={['fad', 'times']}
                                        color={this.state.strokeStyle == 'text' ? Colours.BLACK : Colours.GREY}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* // 2024-05-01 (JJ): Fix for CulverdocsIllustrationComponent, colour picker now positioned absolute to stop moving drawing when opening and minor colours update */}
                {this.state.showColourComponentState && (
                    <div
                        // style={{

                        //     position: "absolute",
                        //     height: 45,
                        //     backgroundColor:
                        //         this.props.brandData?.primarycolour,
                        // }}
                        style={{
                            position: 'absolute',

                            width: '100%',
                            overflowX: 'auto',
                            display: 'flex',
                            zIndex: 999,
                            backgroundColor: lightenDarkenColor(this.props.brandData?.primarycolour, 50)
                        }}
                    >
                        <FlatList
                            list={[
                                { color: '#000000' }, // Black
                                { color: '#FFFFFF' }, // White
                                { color: '#FF0000' }, // Red
                                { color: '#00FF00' }, // Green
                                { color: '#0080FF' }, // Deep Sky Blue
                                { color: '#0000FF' }, // Blue
                                { color: '#00FFFF' }, // Cyan
                                { color: '#FF8000' }, // Orange
                                { color: '#FFFF00' }, // Yellow
                                { color: '#7F00FF' }, // Violet
                                { color: '#FF00FF' }, // Magenta
                                { color: '#FF007F' }, // Deep Pink
                                { color: '#800000' }, // Maroon
                                { color: '#808000' }, // Olive
                                { color: '#008080' }, // Teal
                                { color: '#800080' }, // Purple
                                { color: '#808080' }, // Gray
                                { color: '#C0C0C0' } // Silver
                            ]}
                            extraData={this.state}
                            keyExtractor={(item) => item.color}
                            renderItem={this._renderItem}
                            // display={{ grid: false, row: false }}
                            // showsHorizontalScrollIndicator={true}
                        />
                    </div>
                )}

                {/* <SketchCanvas
                    ref={(ref) => (this._sketchCanvas = ref)}
                    style={this.props.canvasStyle}
                    strokeColor={
                        this.state.color +
                        (this.state.color.length === 9 ? "" : this.state.alpha)
                    }
                    onStrokeStart={(x, y) => {
                        if (this.state.strokeStyle == "text") {
                            let _screenScale =
                                Platform.OS === "ios" ? 1 : PixelRatio.get();

                            // Check if the last entry matches the text value.
                            let entry = this.state.canvasText.find(
                                (child) =>
                                    child.text === this.state.textToInsert
                            );
                            if (entry) {
                                this.state.canvasText.pop();
                                this.state.history.pop();
                            }

                            let idText = Math.ceil(
                                Math.random() * 10000000
                            ).toString();
                            this.state.canvasText.push({
                                id: idText,
                                text: this.state.textToInsert,
                                position: {
                                    x: x * _screenScale,
                                    y: y * _screenScale,
                                },
                                overlay: "SketchOnText",
                                fontSize: Platform.select({
                                    ios: 24,
                                    android: 48,
                                }),
                            });
                            this.state.history.push({
                                type: "text",
                                id: idText,
                            });
                            this.forceUpdate();
                        }
                    }}
                    onStrokeChanged={this.props.onStrokeChanged}
                    onStrokeEnd={(args) => {
                        if (this.state.strokeStyle == "line") {
                            // We are in straight line mode.
                            let startPOS = args.path.data[0];
                            let endPOS =
                                args.path.data[args.path.data.length - 1];

                            let newPath = JSON.parse(JSON.stringify(args));
                            newPath.path.data = [startPOS, endPOS, endPOS];
                            newPath.path.id = newPath.path.id + 1;
                            this.addPath(newPath);

                            this.deletePath(args.path.id);

                            this.state.history.push({
                                type: "path",
                                id: newPath.path.id,
                            });
                        }

                        if (this.state.strokeStyle == "text") {
                            this.deletePath(args.path.id);
                        }

                        // 2023-03-07 (JJ): Adding support for eraser tool in Illustration
                        if (
                            this.state.strokeStyle == "draw" ||
                            this.state.strokeStyle == "eraser"
                        ) {
                            this.state.history.push({
                                type: "path",
                                id: args.path.id,
                            });
                        }
                    }}
                    user={this.props.user}
                    strokeWidth={this.state.strokeWidth}
                    onSketchSaved={(success, path) =>
                        this.props.onSketchSaved(success, path)
                    }
                    onPathsChange={this.props.onPathsChange}
                    text={this.state.canvasText}
                    localSourceImage={this.props.localSourceImage}
                    permissionDialogTitle={this.props.permissionDialogTitle}
                    permissionDialogMessage={this.props.permissionDialogMessage}
                /> */}
                <CanvasDraw
                    style={this.props.canvasStyle}
                    ref={(ref) => (this._sketchCanvas = ref)}
                    brushColor={this.state.color}
                    canvasWidth={500}
                    canvasHeight={400}
                    brushRadius={this.state.strokeWidth ? this.state.strokeWidth : 2}
                    hideGridX={true}
                    hideGridY={true}
                    imgSrc={this.props.backgorundImage ? this.props.backgorundImage : null}
                    //imgSrc="https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg"
                />
            </div>
        );
    }

    _renderItem = (item, index) => (
        <div
            style={{ paddingLeft: 10, height: 40, paddingTop: 5 }}
            onClick={() => {
                if (this.state.color === item.color) {
                    const index = this.props.alphlaValues.indexOf(this.state.alpha);
                    if (this._alphaStep < 0) {
                        this._alphaStep = index === 0 ? 1 : -1;
                        this.setState({
                            alpha: this.props.alphlaValues[index + this._alphaStep]
                        });
                    } else {
                        this._alphaStep = index === this.props.alphlaValues.length - 1 ? -1 : 1;
                        this.setState({
                            alpha: this.props.alphlaValues[index + this._alphaStep]
                        });
                    }
                } else {
                    // 2024-05-01 (JJ): Fix for CulverdocsIllustrationComponent, colour picker now positioned absolute to stop moving drawing when opening and minor colours update
                    this.setState({
                        color: item.color,
                        showColourComponentState: false
                    });
                    this._colorChanged = true;
                }
            }}
        >
            {this.state.color !== item.color && this.props.strokeComponent && this.props.strokeComponent(item.color)}
            {this.state.color === item.color && this.props.strokeSelectedComponent && this.props.strokeSelectedComponent(item.color + this.state.alpha, index, this._colorChanged)}
        </div>
    );

    saveToFile() {
        // 2023-08-28 (JJ): Illustration updates - if Source Image is provided (without "donotcropoutput"=true property), remove text option and crop on save to avoid resizing in view + capture timestamp on save.
        //  this._sketchCanvas.save('jpg', false, 'Culverdocs', String(new Date().getTime()), true, true, this.props.cropOutput);
        // this._sketchCanvas.getSaveData();
        this.props.onSaveData(this.this._sketchCanvas);
    }

    saveCanvas = (canvasDrawInstance) => {
        return new Promise((resolve) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const { canvasWidth, canvasHeight, imgSrc } = canvasDrawInstance.props;

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            const img = new Image();
            img.src = imgSrc;

            img.onload = () => {
                context.drawImage(img, 0, 0, canvasWidth, canvasHeight);

                const drawingImage = new Image();
                drawingImage.src = canvasDrawInstance.canvas.drawing.toDataURL();

                drawingImage.onload = () => {
                    context.drawImage(drawingImage, 0, 0, canvasWidth, canvasHeight);
                    const dataURL = canvas.toDataURL('image/png');
                    resolve(dataURL);
                };
            };
        });
    };

    // Usage in your component
    //    handleSave = async () => {
    //     const dataURL = await saveCanvasAsBase64(canvasRef.current);
    //     console.log('Base64 Image:', dataURL);
    //     // Use dataURL as needed, e.g., sending it to a server or displaying it
    //   };

    // Helper function to trigger download (if needed)
    downloadImage = (dataURL, filename) => {
        const a = document.createElement('a');
        a.href = dataURL;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        canvasFunctionButton: {
            display: 'flex',
            marginRight: 2.5,
            marginLeft: 2.5,

            marginTop: 8,
            marginBottom: 8,

            width: 30,
            height: 30,
            borderRadius: 15,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: brandData?.primarycolour
        },

        inputTextContainer: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            // height:100,
            padding: 0,
            margin: 5,
            marginTop: 10,
            marginBottom: 0
        },
        inputText: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,

            fontFamily: Font.family.regular,
            fontSize: Font.size.small,
            color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
            borderBottomWidth: 1,
            backgroundColor: Colours.WHITE,
            marginBottom: 10,
            marginLeft: 5,
            height: 30,
            borderRadius: 5,
            // width:100,
            // alignSelf:'flex-end',
            // justifyContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 10,
            padding: 0,
            paddingLeft: 5
        }
    });
};
