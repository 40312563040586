import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Loader from 'react-js-loader';
import FadeLoader from 'react-spinners/FadeLoader';

export const ShowLoader = ({ flag, brandData }) => {
    return (
        <div>
            {flag && brandData.primarycolour && (
                <div className="position-absolute top-50 start-50 translate-middle  p-4  rounded">
                    <Loader
                        type="spinner-default"
                        bgColor={brandData.primarycolour}
                        color={brandData.primarycolour}
                        title={'Loading...'}
                        size={100}
                        // style={{ marginTop: 30 }}
                    />
                </div>
            )}
        </div>
    );
};
export const Spinner = ({ brandData, type }) => {
    return (
        <div style={{ paddingTop: 5 }}>
            {type == 'FadingCircleAlt' && (
                <FadeLoader
                    style={{ position: 'absolute', top: 40, height: 10, width: 10 }}
                    color={brandData?.primarycolour}
                    loading={true}
                    height={10}
                    width={5}
                    margin={0}
                    radius={10}
                    //cssOverride={{ height: 10, width: 10, display: 'block', marginTop: 10 }}
                    size={5}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            )}
        </div>
    );
};
