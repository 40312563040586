import { v4 as uuidv4 } from 'uuid'; // If you need a unique identifier
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import { setLocalStorage } from '../Helper/localStorage';

export async function validateRTDeviceID() {
    try {
        const deviceId = uuidv4(); // Generating a UUID for the device ID
        console.log('deviceId', deviceId);
        // Replace 'validateRTDeviceIDEUWest1' with your Firebase Function name
        const validateRTDeviceID = httpsCallable(functions, 'validateUserAuthenticationGen1');
        const response = await validateRTDeviceID({ deviceid: deviceId });
        console.log(`validateRTDeviceID() - Validating Device ID ${deviceId} resulted in: ${JSON.stringify(response.data)}`);
        setLocalStorage('deviceId', deviceId);
        return response.data.success;
    } catch (error) {
        console.error('Error validating device ID:', error);
        return false;
    }
}
