// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

//import 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// 2024-08-28 (RB): Production Environment

// const firebaseConfig = {
//     apiKey: 'AIzaSyCxHPTl9zTJjG0RnH0i8iRttUOfXn3OZKs',
//     authDomain: 'culverdocsfb.firebaseapp.com',
//     databaseURL: 'https://culverdocsfb-euwest1.europe-west1.firebasedatabase.app',
//     projectId: 'culverdocsfb',
//     storageBucket: 'culverdocsfb.appspot.com',
//     messagingSenderId: '699804234995',
//     appId: '1:699804234995:web:0d14a6a93346073dbe7386',
//     measurementId: 'G-57T72QDNWM'
// };
// 2024-08-28 (RB): Development Environment
const firebaseConfig = {
    apiKey: 'AIzaSyCLPGvWc2v3vjYIzudK0Rs7qZScuvsaDuc',
    authDomain: 'culverdocs-dev.firebaseapp.com',
    databaseURL: 'https://culverdocs-dev-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'culverdocs-dev',

    storageBucket: 'culverdocs-dev.appspot.com',
    messagingSenderId: '724736589188',
    appId: '1:724736589188:web:09039e0a6a36c3383671d1',
    measurementId: 'G-LSNTJRVQQ9'
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, 'europe-west2');

export default app;
