export const filterData = (data, title, states, formIds, name) => {
    // Return blank array if no data is passed in.

    if (!data) {
        return [];
    }

    return data.filter((item) => {
        // 2024-04-26 (JJ): Fix for Item Title filter in HomeSreen Filter Modal
        // 2024-04-28 (JJ): Rework from merge
        if (name && !item.name.toLowerCase().includes(name.toLowerCase())) {
            
            // 2024-05-08 (RB): Impelementaion of search by name
 
            return false;
        }

        if (title) {
            if (!item.title) {
                return false;
            }
            if (!item.title.toLowerCase().includes(title.toLowerCase())) {
                return false;
            }
        }

        // Filter by state
        // 2024-04-14 (JJ): Re-implementing Request Category filters (if a record has a requestcategory set)
        if (states && states.length > 0) {
            if (item?.state == 'requested' && item?.requestcategory) {
                if (!states.includes(`${item.state} (${item.requestcategory})`)) {
                    return false;
                }
            } else {
                if (!states.includes(item.state)) {
                    return false;
                }
            }
        }

        // Filter by formId
        if (formIds && formIds.length > 0 && !formIds.includes(item.formid)) {
            return false;
        }

        // If all filters pass, include the item in the result
        return true;
    });
};
