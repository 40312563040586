import React, { Component } from 'react';

import Font from '../../../Constant/font';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';

export default class CulverdocsShortAnswer extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
        return this.props.itemValue !== prevProps.itemValue || this.props.visible !== prevProps.visible || this.props.item.value !== prevProps.item.value || this.props.caption !== prevProps.caption;
    }

    render() {
        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption} - with value: ${this.props.item.value}`);
        const styles = getStyle(this.props.brandData);
        if (!this.props.visible) {
            return null;
        }


 
        var textinputmask;
        // 2022-07-26 (JJ): Support for text field InputMask property - adding MaskedTextInput field
        if (this.props.item.textinputmask && this.props.item.textinputmask !== '') {
            // Convert the inputmask from Portal / Web Style - remove [] and replace * with "S" for alphanumeric
            textinputmask = this.props.item.textinputmask.replace(/\[/g, '').replace(/\]/g, '').replace(/\*/g, 'S');
        }

        return (
            <div style={{ position: 'relative' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CulverdocsFieldCaption
                        brandData={this.props.brandData}
                        caption={this.props.item.caption}
                        userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                        mandatory={this.props.item.mandatory}
                        editable={this.props.editable}
                        hasValue={this.props.item.value ? true : false}
                    />

                    {/* // 2022-07-26 (JJ): Support for text field InputMask property - adding MaskedTextInput field */}
                    {textinputmask && textinputmask !== '' && (
                        <p style={styles.fieldCaptionBadge}>
                            {/* Convert the inputmask from "S" back to * to match the portal view */}
                            {textinputmask.replace(/S/g, '*')}
                        </p>
                    )}
                </div>

                {/* // 2023-07-09 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Short Answer, Number, Choice, Select) */}
                {this.props.item.lockfieldtype && (
                    <div style={{ position: 'absolute', right: 0, top: 0 }}>
                        <div
                            hitslop={{
                                left: 10,
                                right: 10,
                                bottom: 10,
                                top: 10
                            }}
                            onClick={() => {
                                this.props.toggleFieldLock(this.props.item, !this.props.item.locked).then(() => {
                                    console.log('toggle hit', this.props.item, !this.props.item.locked);
                                    // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                                    if (this.props.pItem) {
                                        this.props.forceRepeaterChildUpdate();
                                    }

                                    this.forceUpdate();
                                });
                            }}
                        >
                            <FontAwesomeIcon
                                icon={['fad', this.props.item.locked ? 'square-a-lock' : 'lock-keyhole-open']}
                                color={this.props.item.locked ? Colours.GREEN : Colours.GREY}
                            />
                        </div>
                    </div>
                )}

                {/* This.props.Editable - without keyboard type (bug - https://github.com/facebook/react-native/issues/8932) */}

                {/* {this.props.editable &&
                    keyboardtype == "default" &&
                    (!textinputmask || textinputmask == "") && (
                        <TextInput
                            ref={(ref) => {
                                this.thisRef = ref;
                            }} // 2023-11-06 (JJ): Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                            style={styles.fieldText}
                            defaultValue={this.props.item.value}
                            autoCapitalize={capitalisewords} //v1.1.6 - Fix for capitalise words, default to words if it hasnt been set.
                            autoCompleteType={autocompletetype}
                            returnKeyType={"done"} //v1.1.6 - changed to "Done" field
                            maxLength={
                                this.props.item.maxvalue &&
                                parseInt(this.props.item.maxvalue) > 0
                                    ? parseInt(this.props.item.maxvalue)
                                    : null
                            } // 2023-10-07 (JJ): NFE - Updating Short Answer & Long Answer to support maxLength (maxvalue) property if it has been set
                            onChangeText={(text) => {
                                this.props.item.value = text;
                                if (text === "") {
                                    this.props.item.value = null;
                                }
                                this.forceUpdate();

                                // 2023-11-06 (JJ): Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                                // If onChangeText is called without focus, the field has been updated externally (i.e. S Pen) and needs to write to the DB by calling onEndEditing
                                if (!this.thisRef.isFocused()) {
                                    clearTimeout(this.thisRef.timeoutToUpdate);
                                    this.thisRef.timeoutToUpdate = setTimeout(
                                        () => {
                                            this.thisRef.props.onEndEditing();
                                        },
                                        1000
                                    );
                                }
                            }}
                            onEndEditing={() => {
                                this.props.onFieldChanged(
                                    this.props.item,
                                    this.props.pItem,
                                    this.props.rIndex
                                );
                                this.forceUpdate();
                            }}
                        />
                    )} */}

                {/* // 2022-07-26 (JJ): Support for text field InputMask property - adding MaskedTextInput field */}
                {/* {this.props.editable &&
                    keyboardtype == "default" &&
                    textinputmask &&
                    textinputmask !== "" && (
                        <MaskedTextInput
                            ref={(ref) => {
                                this.thisRef = ref;
                            }} // 2023-11-06 (JJ): Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                            mask={textinputmask}
                            style={styles.fieldText}
                            defaultValue={this.props.item.value}
                            autoCapitalize={"characters"}
                            autoCompleteType={autocompletetype}
                            returnKeyType={"done"} //v1.1.6 - changed to "Done" field
                            maxLength={
                                this.props.item.maxvalue &&
                                parseInt(this.props.item.maxvalue) > 0
                                    ? parseInt(this.props.item.maxvalue)
                                    : null
                            } // 2023-10-07 (JJ): NFE - Updating Short Answer & Long Answer to support maxLength (maxvalue) property if it has been set
                            onChangeText={(text) => {
                                this.props.item.value = text;
                                if (text === "") {
                                    this.props.item.value = null;
                                }
                                this.forceUpdate();

                                // 2023-11-06 (JJ): Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                                // If onChangeText is called without focus, the field has been updated externally (i.e. S Pen) and needs to write to the DB by calling onEndEditing
                                if (!this.thisRef.isFocused()) {
                                    clearTimeout(this.thisRef.timeoutToUpdate);
                                    this.thisRef.timeoutToUpdate = setTimeout(
                                        () => {
                                            this.thisRef.props.onEndEditing();
                                        },
                                        1000
                                    );
                                }
                            }}
                            onEndEditing={() => {
                                if (
                                    this.props.item.value &&
                                    this.props.item.value.length <
                                        textinputmask.length
                                ) {
                                    //TODO: Translate
                                    Alert.alert(
                                        "Invalid Value",
                                        `${this.props.item.caption} value (${
                                            this.props.item.value
                                        }) does not meet the field format requiremements. Please enter: ${textinputmask.replace(
                                            /S/g,
                                            "*"
                                        )}`
                                    );
                                    this.props.item.value = null;
                                    this.forceUpdate();
                                } else {
                                    this.props.onFieldChanged(
                                        this.props.item,
                                        this.props.pItem,
                                        this.props.rIndex
                                    );
                                    this.forceUpdate();
                                }
                            }}
                        />
                    )} */}

                {/* This.props.Editable - with keyboard type (bug - https://github.com/facebook/react-native/issues/8932) */}
                {this.props.editable && (!textinputmask || textinputmask === '') && (
                    <Form.Control
                        ref={(ref) => {
                            this.thisRef = ref;
                        }}
                        style={styles.fieldText}
                        value={this.props.item.value}
                        onChange={(e) => {
                            this.props.item.value = e.target.value;
                            if (e.target.value === '') {
                                this.props.item.value = null;
                            }
                            this.forceUpdate();
                        }}
                        onBlur={() => {
                            // console.log(
                            //     "this.state",
                            //     this.props.item.value,
                            //     this.props.item.value.length
                            // );
                            // if (this.props.item.value) {
                            //     //TODO: Translate
                            //     alert(
                            //         "Invalid Value",
                            //         `${this.props.item.caption} value (${
                            //             this.props.item.value
                            //         }) does not meet the field format requiremements. Please enter: ${textinputmask.replace(
                            //             /S/g,
                            //             "*"
                            //         )}`
                            //     );
                            //     this.props.item.value = null;
                            //     this.forceUpdate();
                            // } else {
                            this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                            //     this.forceUpdate();
                            // }
                        }}
                    />
                    // <TextInput
                    //     ref={(ref) => {
                    //         this.thisRef = ref;
                    //     }} // 2023-11-06 (JJ): Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                    //     style={styles.fieldText}
                    //     defaultValue={this.props.item.value}
                    //     keyboardType={keyboardtype}
                    //     autoCapitalize={capitalisewords} //v1.1.6 - Fix for capitalise words, default to words if it hasnt been set.
                    //     autoCompleteType={autocompletetype}
                    //     returnKeyType={"done"} //v1.1.6 - changed to "Done" field
                    //     maxLength={
                    //         this.props.item.maxvalue &&
                    //         parseInt(this.props.item.maxvalue) > 0
                    //             ? parseInt(this.props.item.maxvalue)
                    //             : null
                    //     } // 2023-10-07 (JJ): NFE - Updating Short Answer & Long Answer to support maxLength (maxvalue) property if it has been set
                    //     onChangeText={(text) => {
                    //         this.props.item.value = text;
                    //         if (text === "") {
                    //             this.props.item.value = null;
                    //         }
                    //         this.forceUpdate();
                    //         // 2023-11-06 (JJ): Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                    //         // If onChangeText is called without focus, the field has been updated externally (i.e. S Pen) and needs to write to the DB by calling onEndEditing
                    //         if (!this.thisRef.isFocused()) {
                    //             clearTimeout(this.thisRef.timeoutToUpdate);
                    //             this.thisRef.timeoutToUpdate = setTimeout(
                    //                 () => {
                    //                     this.thisRef.props.onEndEditing();
                    //                 },
                    //                 1000
                    //             );
                    //         }
                    //     }}
                    //     onEndEditing={() => {
                    //         this.props.onFieldChanged(
                    //             this.props.item,
                    //             this.props.pItem,
                    //             this.props.rIndex
                    //         );
                    //         this.forceUpdate();
                    //     }}
                    // />
                )}

                {/* Non-this.props.editable */}
                {!this.props.editable && (
                    <div style={styles.fieldText}>
                        <p style={styles.fieldNonEditable}>{this.props.item.value}</p>
                    </div>
                )}
            </div>
        );
    }
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        // Common Styles - Mandatory & Caption
        fieldNonEditable: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            // backgroundColor: Colours.MIDGREY,
            color: Colours.GREY,

            // 2023-07-09 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Short Answer, Number, Choice, Select)
            top: 1.5,
            left: -0.5,
            margin: 0
        },
        fieldCaptionBadge: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.small,
            color: Colours.BLACK,
            marginLeft: 5,
            // paddingLeft: 5,
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '2px 3px 1px #d2d2d2',
            backgroundColor: lightenDarkenColor(brandData?.primarycolour, 50), //TODO: Should store primarycolourlight in brandData
            borderRadius: 5,
            borderWidth: 1,
            borderColor: brandData?.primarycolour,
            // padding: 5,
            paddingLeft: 5,
            paddingRight: 3,
            // paddingHorizontal: 10,
            top: 3,
            height: 17,
            maxWidth: 150 // 2022-10-26 (JJ): Set maxWidth on fieldcaptions to limit content length (postcode changes)
        },

        // Field Specific
        fieldText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
            // borderBottomWidth: 1,
            // borderColor: brandData?.primarycolour,
            marginBottom: 10,
            paddingTop: 10, //for iOS
            paddingBottom: 10, //for iOS
            paddingLeft: 5, //for iOS
            paddingRight: 5, // 2023-11-08 (JJ): NFE - Updating Short Answer field - apply paddingRight and change to minHeight due to non-editable fields wrapping outside of the field
            minHeight: 45, // 2023-11-08 (JJ): NFE - Updating Short Answer field - apply paddingRight and change to minHeight due to non-editable fields wrapping outside of the field
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            borderRadius: 5,
            elevation: 5
        }
    });
};
