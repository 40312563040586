import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Stylesheet from 'reactjs-stylesheet';

export default class CulverdocsImage extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        return this.props.visible !== prevProps.visible || this.props.item.image !== prevProps.item.image;
    }

    render() {
        const styles = getStyle(this.props.brandData);

        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption}`);
        if (!this.props.visible) {
            return null;
        }

        // 2021-03-30 (JJ): Use the height / width if it is provided by the portal, otherwise default to 100. Increased width to 95%
        var imgheight = this.props.item.height ? this.props.item.height : 200;
        var imgwidth = this.props.item.width ? this.props.item.width : '100%';

        //TODO: Auto download?
        // 2021-12-30 (JJ): Auto download the media if it's in the fbstorage location and we're online.
        // if (!this.props.item.image && this.props.item.fbstorage && !this.props.item.firebaseStorageProcessing && this.state.isConnected && !global.disableautodownloadmedia && !this.state.isProcessingToSend) {
        //     this.DownloadMediaFromStorage(this.props.item,"image");
        // }

        // console.log(this.props.item);

        // 2021-12-30 (JJ): Support for renderImage() with fbstorage
        if (this.props.item && !this.props.item.image && this.props.item.fbstorage && this.props.item.fbstorage !== '') {
            return (
                <div style={{ cursor: 'default' }}>
                    <div
                        style={{ borderRadius: 5 }}
                        onClick={() => {
                            this.props.downloadMediaFromStorage(this.props.item, 'image').then(() => {
                                this.forceUpdate();
                            });
                        }}
                    >
                        <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <div
                                resizeMode={'contain'}
                                style={{ height: 50, width: '100%', marginBottom: 10 }}
                            >
                                <div style={styles.fieldPhotoTextContainer}>
                                    <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                        <FontAwesomeIcon
                                            icon={['fad', 'image']}
                                            style={{ opacity: 0.2 }}
                                            color={this.props.brandData?.primarycolour}
                                        />
                                    </div>
                                    <div style={{ flex: 1, position: 'absolute', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                        {!this.props.item.firebaseStorageProcessing && (
                                            <FontAwesomeIcon
                                                icon={['fad', 'search']}
                                                color={this.props.brandData?.primarycolour}
                                            />
                                        )}

                                        {this.props.item.firebaseStorageProcessing && (
                                            // <Spinner
                                            //     size={30}
                                            //     type={'FadingCircleAlt'}
                                            //     color={this.props.brandData?.primarycolour}
                                            //     style={{ alignSelf: 'flex-end' }}
                                            // />
                                            <div> </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            // If the item has an image
            if (this.props.item && this.props.item.image) {
                return (
                    <div>
                        <div>
                            <img
                                style={{ height: '100%', width: imgwidth, maxHeight: 400, alignSelf: 'center', marginBottom: 10, resizeMode: 'contain', objectFit: 'contain', cursor: 'default' }}
                                resizeMode={'contain'}
                                src={'data:image/png;base64,' + this.props.item.image}
                            ></img>
                        </div>
                    </div>
                );
            }
        }
    }
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        fieldPhotoTextContainer: {
            cursor: 'default',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center'
        }
    });
};
