import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import '../../styles.css';
import { CulverdocsButton } from '../../Component/CulverdocsButton';
import Colours from '../../Constant/colour';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, database } from '../../firebase';
import { setDispatch, startFirebaseListeners } from '../../Firebase/FirebaseListenerControl';
import { useDispatch } from 'react-redux';
import { getLocalStorage, removeAllLocalStorage, setLocalStorage } from '../../Helper/localStorage';
import { ShowLoader } from '../../Common/Loader';
import { child, get, onValue, ref } from 'firebase/database';
import { validateRTDeviceID } from '../../Firebase/validateRTDeviceID';
import { translate } from '../../Common/CulverdocsTranslate';
import { releaseRTDeviceID } from '../../Firebase/releaseRTDeviceID';
import { updateDeviceInfo } from '../../Firebase/updateDeviceInfo';
import CulverdocsFieldCaption from '../CreateFormScreen/FormFields/_CulverdocsFieldCaption';
function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [screen, setScreen] = useState('loading');
    const [preventNavigate, setPreventNavigate] = useState(false);
    const [loader, setLoader] = useState(false);

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const location = useLocation();
    const dispatch = useDispatch();
    const getOSName = () => {
        const userAgent = navigator.userAgent;

        if (userAgent.indexOf('Win') > -1) {
            return 'Windows';
        } else if (userAgent.indexOf('Mac') > -1) {
            return 'MacOS';
        } else if (userAgent.indexOf('X11') > -1 || userAgent.indexOf('Linux') > -1) {
            return 'Linux';
        } else if (userAgent.indexOf('Android') > -1) {
            return 'Android';
        } else if (userAgent.indexOf('like Mac') > -1) {
            return 'iOS';
        }
        return 'Unknown';
    };

    const redirectToApp = () => {
        const osName = getOSName();
        const appUrlScheme = 'scheme://'; // 2024-08-12 (RB): Todo need to update with realdata
        const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.culverdocs&hl=en';
        const appStoreUrl = 'https://apps.apple.com/app/idyourappid'; //// 2024-08-12 (RB): Todo need to update with realdata

        if (osName === 'Android') {
            // Try opening the app using a URL scheme, if it exists
            window.location.href = appUrlScheme;
            // If the app isn't installed, redirect to the Google Play Store
            setTimeout(() => {
                window.location.href = playStoreUrl;
            }, 500);
            return false;
        } else if (osName === 'iOS') {
            // Try opening the app using a URL scheme, if it exists
            window.location.href = appUrlScheme;
            // If the app isn't installed, redirect to the Apple App Store
            setTimeout(() => {
                window.location.href = appStoreUrl;
            }, 500);
            return false;
        }
        return true;
    };
    useEffect(() => {
        //  signOut();
        if (redirectToApp()) {
            var sub = onAuthStateChanged(auth, async (user) => {
                if (user) {
                    try {
                        // startFirebaseListeners().then((res) => {
                        let userProfile;
                        updateDeviceInfo(user.uid);
                        userProfile = await get(child(ref(database), `/users/${user.uid}/profile`)).then((snapshot) => snapshot.val());

                        setTimeout(() => {
                            // navigate('/home');
                            // return;
                            // 2024-05-14 (RB): resolved first time kiosk mode issue
                            if (userProfile?.kioskformid) {
                                let databaseRef = ref(database, `/accounts/${userProfile.accountid}/form/${userProfile.kioskformid}`);
                                onValue(databaseRef, (data) => {
                                    navigate('/kiosk', {
                                        state: {
                                            form: data.val(),
                                            mode: 'new'
                                        }
                                    });
                                });
                            } else {
                                if (screen === 'loading') {
                                    navigate('/home');
                                }
                            }
                        }, 3000);

                        // });
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    setTimeout(() => {
                        setScreen('login');         
                    }, 2000);
                }   
            });
        }
        return sub;
    }, [screen]);
    const signOut = async () => {
        // v1.3.0 - We need to release the ID before signing out.
        // setSignoutText(signouttextinprogress);

        //await releaseRTDeviceID();

        removeAllLocalStorage();
        await auth.signOut();
        // setShowSignOutModal(false); //Close the modal after clicking yes.
        // await auth().signOut(); //Sign out of Firebase, clear the UID.
        //stopFirebaseListeners();

        // navigation.reset({
        //     index: 0,
        //     routes: [{ name: 'SplashScreen' }] // Replace 'InitialRouteName' with the name of your initial route
        // });
    };
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleValidation = () => {
        let isValid = true;

        if (!email.trim()) {
            setEmailError(translate('Email Address is required'));
            isValid = false;
        } else if (!validateEmail(email)) {
            setEmailError(translate('Invalid Email Address'));
            isValid = false;
        } else {
            setEmailError('');
        }

        if (password.length < 1) {
            setPasswordError(translate('Password is required'));
            isValid = false;
        } else if (password.length < 8) {
            setPasswordError(translate('Password is too short'));
            isValid = false;
        } else {
            setPasswordError('');
        }

        return isValid;
    };

    const onLogin = async (e) => {
        if (!handleValidation()) {
            e.preventDefault();
            setLoader(false);
            return false;
        }
        e.preventDefault();
        setLoader(true);
        await signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                // Signed in
                const user = userCredential.user;
                let validateRTDeviceIDResult = await validateRTDeviceID();

                if (validateRTDeviceIDResult) {
                    setLoader(false);
                    startFirebaseListeners().then((res) => {
                        setTimeout(() => {
                            navigate('/home');
                        }, 3000);
                    });
                } else {
                    //setPreventNavigate(true);
                    setScreen('login');
                    alert(translate('Account in use\n') + translate('You cannot login as the account is already in use on another device. Please contact your system administrator.'));
                    signOut();
                    setLoader(false);
                    // setLoader(false);
                }
            })
            .catch((error) => {
                setLoader(false);
                // Generic errors based on any email or password related errors (do not define for security). Show a blocked message or generic "There was a problem signing in"
                if (['auth/email-already-in-use', 'auth/invalid-email', 'auth/user-not-found', 'auth/wrong-password'].includes(error.code)) {
                    alert(translate('Login Details Incorrect\n') + translate("Oops! It looks like your Email Address or Password isn't quite right. Please try again, or contact your Administrator to reset your password if needed."));
                } else if (error.code === 'auth/too-many-requests') {
                    alert(translate('Login Attempts Blocked\n') + translate("Oops! It seems there have been too many login attempts. For your account's security, attempts have been temporary blocked. Please wait a few mintes minutes before trying again or consider resetting your password."));
                } else {
                    alert(translate('No Connection\n') + translate("Oops! It seems like you're offline. Please check your internet connection and try again.") + `\n\n${error}`);
                }
                //  setLoader(false);
            });
    };
    return (
        <>
            {screen === 'login' ? (
                <div
                    className="main_container"
                    style={{
                        height: '100vh',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        flex: 1,
                        minHeight: '100vh'
                    }}
                >
                    <Container
                        fluid
                        style={{
                            alignSelf: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Row
                            style={{
                                alignSelf: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Col>
                                <Image src={require('../../Assets/Images/logo_white.png')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ color: 'white', marginBottom: 20 }}>1.0.0</Col>
                        </Row>
                    </Container>
                    <div
                        className="shadow-box"
                        style={{
                            backgroundColor: 'white',
                            padding: 20,

                            display: 'flex',
                            flexDirection: 'column'
                            //  minWidth: 300,
                        }}
                    >
                        <p
                            style={{
                                fontSize: 12,
                                textAlign: 'center',
                                marginBottom: 30
                            }}
                        >
                            Welcome to Culverdocs, enter your details below to continue
                        </p>
                        <Form>
                            <Form.Group
                                controlId="formBasicEmail"
                                style={{ borderColor: Colours.THEME }}
                            >
                                {/* <Form.Label>Email address</Form.Label> */}
                                <CulverdocsFieldCaption
                                    brandData={Colours.THEME}
                                    caption={translate('Email Address')}
                                    mandatory={true}
                                    editable={true}
                                    hasValue={email ? true : false}
                                    badge={emailError ? emailError : ''}
                                    badgeColour={'red'}
                                />
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon
                                            icon={['fad', 'user']}
                                            color={Colours.THEME}
                                        />
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group
                                controlId="formBasicPassword"
                                style={{ marginTop: 10 }}
                            >
                                <CulverdocsFieldCaption
                                    brandData={Colours.THEME}
                                    caption={translate('Password')}
                                    mandatory={true}
                                    editable={true}
                                    hasValue={password ? true : false}
                                    badge={passwordError ? passwordError : ''}
                                    badgeColour={'red'}
                                />
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon
                                            icon={['fad', 'lock']}
                                            color={Colours.THEME}
                                        />
                                    </InputGroup.Text>
                                    <Form.Control
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <InputGroup.Text>
                                        <FontAwesomeIcon
                                            icon={['fad', 'eye']}
                                            color={Colours.THEME}
                                            onClick={() => setShowPassword(!showPassword)}
                                        />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>

                            <CulverdocsButton
                                title="Login"
                                icon={'envelope'}
                                disabled={loader}
                                loader={loader}
                                extraStyle={{
                                    backgroundColor: Colours.THEME,
                                    borderColor: Colours.THEME
                                }}
                                onClick={onLogin}
                            />
                        </Form>
                    </div>
                </div>
            ) : (
                <div
                    className="main_container"
                    style={{
                        height: '100vh',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        flex: 1,
                        minHeight: '100vh'
                    }}
                >
                    <Container
                        fluid
                        style={{
                            //  flex: 1,
                            alignSelf: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Row
                            style={{
                                alignSelf: 'center',
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Col>
                                <Image
                                    src={require('../../Assets/Images/logo_white.png')}
                                    style={{ flex: 1, width: window.innerWidth / 3 }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ color: 'white', marginBottom: 20 }}>1.0.0</Col>
                        </Row>
                    </Container>
                </div>
            )}
        </>
    );
}

export default Login;
