import React, { Component } from 'react';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import CulverdocsIllustrationComponent from './CulverdocsIllustrationComponent';
import Font from '../../../Constant/font';
import Size from '../../../Constant/size';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import Stylesheet from 'reactjs-stylesheet';
import Modal from 'react-bootstrap/Modal';
import { translate } from '../../../Common/CulverdocsTranslate';

class CulverdocsIllustration extends Component {
    state = {
        showModal: false
    };

    shouldComponentUpdate(prevProps, prevState) {
        // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
        return this.state !== prevState || this.props.visible !== prevProps.visible || this.props.itemValue !== prevProps.itemValue || this.props.caption !== prevProps.caption;
    }

    render() {
        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption} `);

        if (!this.props.visible) {
            return null;
        }
        const styles = getStyle(this.props.brandData);
        // if (!item.value && item.fbstorage && !item.firebaseStorageProcessing && this.state.isConnected && !global.disableautodownloadmedia && !this.state.isProcessingToSend) {
        //     this.DownloadMediaFromStorage(item,"canvas");
        // }
        var canvasRef = [];

        if (this.props.item.image) {
            // async function to write the base64 image to cache for opening
            const SaveCanvasImageToCache = async () => {
                // await RNFS.writeFile(this.props.item.imagepath, this.props.item.image, 'base64');
            };

            if (this.props.item.imagepath) {
                // RNFS.exists(this.props.item.imagepath).then((result) => {
                //     if (!result) {
                //         SaveCanvasImageToCache(); //Re-save to the current location in the file.
                //     }
                // });
            } else {
                this.props.item.imagepath = `${this.props.userProfile._tmpFiles}/${new Date().getTime()}.jpg`;
                SaveCanvasImageToCache();
            }
        }

        //Non-editable
        if (!this.props.editable) {
            return (
                <div>
                    <CulverdocsFieldCaption
                        brandData={this.props.brandData}
                        caption={this.props.item.caption}
                        userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                        mandatory={this.props.item.mandatory}
                        editable={this.props.editable}
                        hasValue={this.props.item.value ? true : false}
                    />

                    <div style={styles.fieldCanvas}>
                        {/* No Value or Storage - blank field */}
                        {!this.props.item.value && !this.props.item.fbstorage && (
                            <div
                                style={{
                                    height: 200,
                                    width: '100%',
                                    marginBottom: 10
                                }}
                            ></div>
                        )}

                        {/* No Value but exists in Storage */}
                        {!this.props.item.value && this.props.item.fbstorage && this.props.item.fbstorage !== '' && (
                            <div
                                style={{ borderRadius: 5 }}
                                onClick={() => {
                                    this.DownloadMediaFromStorage(this.props.item, 'canvas').then(() => {
                                        this.forceUpdate();
                                    });
                                    this.forceUpdate();
                                }}
                            >
                                <div
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div
                                        resizeMode={'contain'}
                                        style={{
                                            height: 200,
                                            width: '100%',
                                            marginBottom: 10
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: 1,
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fad', 'signature']}
                                                    style={{ opacity: 0.2 }}
                                                    color={this.props.brandData?.primarycolour}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    flex: 1,
                                                    position: 'absolute',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                {!this.props.item.firebaseStorageProcessing && (
                                                    <FontAwesomeIcon
                                                        icon={['fad', 'search']}
                                                        style={{
                                                            width: 20,
                                                            height: 20
                                                        }}
                                                        color={this.props.brandData?.primarycolour}
                                                    />
                                                )}

                                                {this.props.item.firebaseStorageProcessing && (
                                                    // <Spinner
                                                    //     size={30}
                                                    //     type={
                                                    //         "FadingCircleAlt"
                                                    //     }
                                                    //     color={
                                                    //         this.props
                                                    //             .brandData
                                                    //             ?.primarycolour
                                                    //     }
                                                    //     style={{
                                                    //         alignSelf:
                                                    //             "flex-end",
                                                    //     }}
                                                    // />
                                                    <div></div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Has Value regardless of Storage */}
                        {this.props.item.value && this.props.item.value !== '' && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,

                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                {/* <Image
                                        style={{
                                            height: 200,
                                            width: "100%",
                                            marginBottom: 10,
                                        }}
                                        resizeMode={"contain"}
                                        source={{
                                            uri:
                                                "data:image/png;base64," +
                                                this.props.item.value,
                                        }}
                                    ></Image> */}
                                {this.props.item.value ? (
                                    <img
                                        style={{
                                            display: 'flex',
                                            flex: 1,
                                            height: 250,
                                            width: '100%',
                                            marginBottom: 5,
                                            marginTop: 3
                                        }}
                                        src={'data:image/png;base64,' + this.props.item.value}
                                        alt={this.props.item.value}
                                    ></img>
                                ) : (
                                    <div
                                        style={{
                                            flex: 1,
                                            height: 150,
                                            width: '100%',
                                            marginBottom: 5,
                                            marginTop: 3
                                        }}
                                    ></div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <CulverdocsFieldCaption
                        brandData={this.props.brandData}
                        caption={this.props.item.caption}
                        userGuidance={this.props.item.userguidance}
                        mandatory={this.props.item.mandatory}
                        editable={this.props.editable}
                        hasValue={this.props.item.value ? true : false}
                    />

                    <div
                        style={styles.fieldCanvas}
                        onClick={() => {
                            this.props.item.value = '';
                            this.setState({ showModal: true });

                            // this.renderCanvas(item, pItem);
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: 5
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                {/* <Image
                                    style={{
                                        minHeight: 200,
                                        width: "100%",
                                        marginBottom: 5,
                                    }}
                                    resizeMode={"contain"}
                                    source={{
                                        uri:
                                            "data:image/png;base64," +
                                            (this.props.item.value
                                                ? this.props.item.value
                                                : this.props.item.image),
                                    }}
                                ></Image> */}
                                {this.props.item.value || this.props.item.image ? (
                                    <img
                                        style={{
                                            flex: 1,
                                            height: 150,
                                            width: '100%',
                                            marginBottom: 5,
                                            marginTop: 3
                                        }}
                                        src={'data:image/png;base64,' + (this.props.item.value ? this.props.item.value : this.props.item.image)}
                                        alt={this.props.item.value}
                                    ></img>
                                ) : (
                                    <div
                                        style={{
                                            flex: 1,
                                            height: 150,
                                            width: '100%',
                                            marginBottom: 5,
                                            marginTop: 3
                                        }}
                                    ></div>
                                )}
                            </div>
                        </div>
                    </div>

                    <Modal
                        centered
                        show={this.state.showModal}
                        // onRequestClose={() =>
                        //     this.setState({ showModal: false })
                        // }
                        // animationType={"fade"}
                        // animated={true}
                        // transparent={true}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                height: '100%',
                                width: '100%',
                                backgroundColor: Colours.MODALBG,
                                evelation: 4,
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'center'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                    width: '100%',
                                    height: '100%',
                                    maxHeight: '98%',
                                    borderRadius: 10,
                                    borderWidth: 2,
                                    borderColor: this.props.brandData?.primarycolour,
                                    backgroundColor: Colours.WHITE
                                }}
                            >
                                {/* <div
                                    style={{
                                        display: 'flex',
                                        height: 50,
                                        flexDirection: 'column',
                                        borderTopRightRadius: 5,
                                        borderTopLeftRadius: 5,
                                        backgroundColor: this.props.brandData?.primarycolour,
                                        borderBottomColor: this.props.brandData?.primarycolour,
                                        borderBottomWidth: 2
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flex: 1,
                                            flexDirection: 'row',
                                            alignContent: 'center'
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 0.9,
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    paddingHorizontal: 10
                                                }}
                                                hitslop={{
                                                    left: Size.CountScale(10),
                                                    right: Size.CountScale(10),
                                                    bottom: Size.CountScale(10),
                                                    top: Size.CountScale(10)
                                                }}
                                                onClick={() =>
                                                    this.setState({
                                                        showModal: false
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fad', 'circle-chevron-left']}
                                                    style={{
                                                        width: Size.icons.small,
                                                        height: Size.icons.small,
                                                        marginLeft: 5,
                                                        marginRight: 5
                                                    }}
                                                    color={Colours.WHITE}
                                                />
                                            </div>
                                            <p
                                                style={styles.renderOptionModalHeaderText}
                                                numberoflines={1}
                                            >
                                                {translate(this.props.item.caption)}{' '}
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 0.1,
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end',
                                                paddingRight: 10
                                            }}
                                        >
                                            <div
                                                hitslop={{
                                                    left: Size.CountScale(10),
                                                    right: Size.CountScale(10),
                                                    bottom: Size.CountScale(10),
                                                    top: Size.CountScale(10)
                                                }}
                                                onClick={() => {
                                                    if (this.canvasRef) {
                                                        //this.canvasRef.saveToFile();
                                                        this.canvasRef.current.getSaveData();
                                                    }
                                                    //this.canvasRef.getSaveData();
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fad', 'check-circle']}
                                                    style={{
                                                        width: Size.icons.small,
                                                        height: Size.icons.small
                                                    }}
                                                    color={Colours.WHITE}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'row'
                                    }}
                                >
                                    <CulverdocsIllustrationComponent
                                        brandData={this.props.brandData}
                                        ref={(r) => {
                                            this.canvasRef = r;
                                        }}
                                        containerStyle={{
                                            // display: "flex",
                                            flexDirection: 'column',
                                            backgroundColor: lightenDarkenColor(this.props.brandData?.primarycolour, 50),
                                            flex: 1
                                        }}
                                        canvasStyle={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            backgroundColor: 'white',
                                            flex: 1,
                                            alignSelf: 'center'
                                        }}
                                        defaultStrokeIndex={0}
                                        defaultStrokeWidth={5}
                                        onSaveData={(data) => this.addImageToCanvasField(this.props.item, data)}
                                        onClose={() => this.hideModal()}
                                        // 2023-08-28 (JJ): Illustration updates - if Source Image is provided (without "donotcropoutput"=true property), remove text option and crop on save to avoid resizing in view + capture timestamp on save.
                                        cropOutput={this.props.item.imagepath && !this.props.item.donotcropoutput ? true : false}
                                        localSourceImage={{
                                            filename: this.props.item.imagepath ? this.props.item.imagepath : '',
                                            directory: '',
                                            mode: 'AspectFit'
                                        }}
                                        strokeComponent={(color) => (
                                            <div style={styles.strokeColorButton}>
                                                <FontAwesomeIcon
                                                    icon={['fad', 'circle']}
                                                    color={color}
                                                />
                                            </div>
                                        )}
                                        strokeSelectedComponent={(color, index, changed) => {
                                            return (
                                                <div style={styles.strokeColorButton}>
                                                    <FontAwesomeIcon
                                                        icon={['fad', 'circle']}
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                            top: -5,
                                                            left: -5
                                                        }}
                                                        color={color}
                                                    />
                                                </div>
                                            );
                                        }}
                                        strokeWidthComponent={(w) => {
                                            let size = Math.ceil(Math.sqrt(w / 3) * 10);
                                            return (
                                                <div style={styles.strokeWidthButton}>
                                                    <div
                                                        style={{
                                                            backgroundColor: Colours.BLACK,
                                                            marginHorizontal: 2.5,
                                                            width: size,
                                                            height: size,
                                                            borderRadius: size / 2
                                                        }}
                                                    />
                                                </div>
                                            );
                                        }}
                                        //addImageToCanvasField={addImageToCanvasField}
                                        // onSketchSaved={(item) => {
                                        //     console.log('item', item);
                                        //     this.addImageToCanvasField(item); // Copy to value
                                        //     this.setState({
                                        //         showModal: false
                                        //     }); // Close the modal
                                        // }}
                                        strokeColors={[
                                            { color: '#000000' }, //Black
                                            { color: '#FF0000' }, //Red
                                            { color: '#00FF00' }, //Green
                                            { color: '#0000FF' }, //Blue
                                            { color: '#00FFFF' },
                                            { color: '#0000A0' },
                                            { color: '#ADD8E6' },
                                            { color: '#800080' },
                                            { color: '#FFFF00' },
                                            { color: '#FF00FF' },
                                            { color: '#A020F0' },
                                            { color: '#FFFFFF' },
                                            { color: '#C0C0C0' },
                                            { color: '#808080' },
                                            { color: '#FFA500' },
                                            { color: '#A52A2A' },
                                            { color: '#800000' },
                                            { color: '#008000' },
                                            { color: '#808000' }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            );
        }
    }
    async hideModal() {
        this.setState({ showModal: false });
    }
    async addImageToCanvasField(item, data) {
        // console.log('item value', item);
        // return;
        var exists = true; //await RNFS.exists(path); //Wait for the image to be written.
        if (!exists) {
            return; //Dont return if we cant find the image path.
        }

        // Resize the image to max dimension
        // var maxdimension = (item.photomaxdimension) ? parseInt(item.photomaxdimension) : 1000; //Default to 600 if not set.
        // const resizedImageUrl = await ImageResizer.createResizedImage(path, maxdimension, maxdimension, 'JPEG', 100, 0, null);
        // const base64 = await RNFS.readFile(resizedImageUrl.uri, 'base64');

        const base64 = data.split(',')[1]; //await RNFS.readFile(path, 'base64');
        console.log(base64);
        item.value = base64;

        // 2023-08-28 (JJ): Illustration updates - if Source Image is provided (without "donotcropoutput"=true property), remove text option and crop on save to avoid resizing in view + capture timestamp on save.
        item.timestamp = new Date().getTime();

        // RNFS.unlink(path); //Delete the original file

        this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
        this.forceUpdate();
        this.setState({ showModal: false });
    }
}

export default CulverdocsIllustration;

const getStyle = (brandData) => {
    return Stylesheet.create({
        // Modal Specific
        renderOptionModalHeaderText: {
            fontFamily: Font.family.bold,
            fontSize: Font.size.large,
            color: Colours.WHITE,
            marginTop: 8,
            width: '80%' //Fix for the caption ellipsis overlapping the modal window.
        },
        renderDiscardModalText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BACKGROUND,
            textAlign: 'center',
            paddingHorizontal: 10,
            marginTop: 10
        },

        // Field Specific
        fieldCanvas: {
            display: 'flex',
            height: 60,
            backgroundColor: Colours.WHITE,
            borderRadius: 3,
            flex: 1,
            justifyContent: 'center',
            marginBottom: 10, // 2023-09-03 (JJ): Standardising marginBottom:10 on all components which were 15 as there were some inconsistencies with styling
            height: 300,
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            borderRadius: 5,
            elevation: 5
        },

        strokeColorButton: {
            marginHorizontal: 3,
            marginVertical: 8,
            width: 30,
            height: 30
        },
        canvasFunctionButton: {
            marginHorizontal: 2.5,
            marginVertical: 8,
            width: 30,
            height: 30,
            borderRadius: 15,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: brandData?.primarycolour
        },

        strokeWidthButton: {
            marginHorizontal: 2.5,
            marginVertical: 8,
            width: 30,
            height: 30,
            borderRadius: 15,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: brandData?.primarycolour
        }
    });
};
