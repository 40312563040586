const Colours = {
    BACKGROUND: 'rgb(30,30,30)',
    TEXT: '#5aa6b8',
    TEXTCOLOUR: '#1E1E1E',
    SUBTEXTCOLOUR: '#282828',

    HEADER: '#5aa6b8',
    WHITE: '#FFF',
    TILE: '#f9f9f9',

    LIGHTGREY: 'rgb(245,244,245)',
    LODERGREY: 'rgb(245,244,245)',
    GREEN: 'green',

    GREY: '#808080',
    BLACK: '#000',
    LOGOUTBUTTON: 'rgb(172,92,95)',

    THEME: '#5aa6b8',
    THEMELIGHT: '#abd1da', // 2021-07-23 (JJ): Added new THEMELIGHT for a ligher/disabled button

    //My Colours
    DARKRED: '#FC351B',
    RED: '#FC351B', //Updated to new red
    ORANGE: 'orange',
    GREEN: '#03564B', //Updated to new green
    MODALBG: 'rgba(0,0,0,0.7)',
    SECONDARY: '#BDE5EE',
    //Social Login Colour
    facebook: '#316FF6',
    google: '#EA4335',
    apple: '#000'
};
export default Colours;
