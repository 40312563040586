import React, { useState, useRef, useImperativeHandle } from 'react';

const Pincode = React.forwardRef((props, ref) => {
    const { length = 4, secret = false, type = 'numeric', secretDelay = 1000, onChange, onComplete, value = '', style, inputStyle, inputFocusStyle, regexCriteria = /./, autoSelect = false, ...rest } = props;

    const [internalValue, setInternalValue] = useState(value);
    const inputRefs = useRef([]);

    const handleInputChange = (e, index) => {
        const { value } = e.target;
        if (!regexCriteria.test(value)) return;

        const newValue = internalValue.split('');
        newValue[index] = value;
        const newPin = newValue.join('');
        setInternalValue(newPin);
        onChange?.(newPin, index);

        if (value && index < length - 1) {
            inputRefs.current[index + 1].focus();
        } else if (newPin.length === length) {
            onComplete?.(newPin);
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !internalValue[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const triggerChange = (newPin) => {
        setInternalValue(newPin);
        onChange?.(newPin);
    };

    const triggerComplete = (newPin) => {
        onComplete?.(newPin);
    };

    useImperativeHandle(ref, () => ({
        triggerChange,
        triggerComplete
    }));

    const renderInputFields = () => {
        return Array.from({ length }).map((_, index) => (
            <input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                type={secret ? 'password' : type}
                value={internalValue[index] || ''}
                style={{
                    ...{ display: 'flex', alignItems: 'center', justifyContent: 'center' },
                    ...inputStyle,
                    ...(inputRefs.current[index] === document.activeElement && inputFocusStyle)
                }}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                maxLength={1}
                autoComplete="off"
                autoFocus={index === 0 && autoSelect}
            />
        ));
    };

    return (
        <div
            style={{ display: 'flex', ...style }}
            {...rest}
        >
            {renderInputFields()}
        </div>
    );
});

export default Pincode;
