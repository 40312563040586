import React, { Component } from 'react';
import Font from '../../../Constant/font';
import Size from '../../../Constant/size';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import SignatureCanvas from 'react-signature-canvas';
import Image from 'react-bootstrap/Image';

export default class CulverdocsSignature extends Component {
    state = {
        showCulverdocsSignatureModal: false
    };

    shouldComponentUpdate(prevProps, prevState) {
        // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
        return this.state !== prevState || this.props.visible !== prevProps.visible || this.props.itemValue !== prevProps.itemValue || this.props.caption !== prevProps.caption;
    }

    render() {
        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption} `);
        const styles = getStyle(this.props.brandData);

        if (!this.props.visible) {
            return null;
        }

        // TODO: Download Data from Firebase
        // if (!this.props.item.value && this.props.item.fbstorage && !this.props.item.firebaseStorageProcessing && this.state.isConnected && !global.disableautodownloadmedia && !this.state.isProcessingToSend) {
        //     this.DownloadMediaFromStorage(item,"signature");
        // }
        return (
            <div>
                <CulverdocsFieldCaption
                    brandData={this.props.brandData}
                    caption={this.props.item.caption}
                    userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                    mandatory={this.props.item.mandatory}
                    editable={this.props.editable}
                    hasValue={this.props.item.value || this.props.item.fbstorage ? true : false}
                />

                <div style={styles.fieldSignature}>
                    {!this.props.item.value && this.props.item.fbstorage && this.props.item.fbstorage !== '' && (
                        <div
                            style={{ borderRadius: 5 }}
                            onClick={() => {
                                this.props.downloadMediaFromStorage(this.props.item, 'signature').then(() => {
                                    this.forceUpdate();
                                });
                                this.forceUpdate();
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <div
                                    resizeMode={'contain'}
                                    style={{
                                        display: 'flex',
                                        height: 150,
                                        width: '100%',
                                        marginBottom: 5,
                                        alignItems: 'center',
                                        flexDirection: 'column'

                                        //  justifyContent:"center"
                                    }}
                                >
                                    <div style={styles.fieldPhotoTextContainer}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 1,

                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fal', 'signature']}
                                                style={{
                                                    height: 100,
                                                    width: 100,
                                                    opacity: 0.3
                                                }}
                                                color={this.props.brandData?.primarycolour}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 1,
                                                position: 'absolute',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {!this.props.item.firebaseStorageProcessing && (
                                                <FontAwesomeIcon
                                                    icon={['fad', 'search']}
                                                    style={{
                                                        height: 20,
                                                        width: 20
                                                    }}
                                                    color={this.props.brandData?.primarycolour}
                                                />
                                            )}

                                            {this.props.item.firebaseStorageProcessing && (
                                                // <ActivityIndicator
                                                //     size="large"
                                                //     color={
                                                //         this.props.brandData
                                                //             ?.primarycolour
                                                //     }
                                                //     style={{
                                                //         alignSelf:
                                                //             "flex-end",
                                                //     }}
                                                // />
                                                <div></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {(this.props.item.value || (!this.props.item.value && !this.props.item.fbstorage)) && (
                        <div
                            style={{ borderRadius: 5 }}
                            onClick={() => {
                                if (this.props.editable) {
                                    this.setState({
                                        showCulverdocsSignatureModal: true
                                    });
                                }
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                {this.props.item.value ? (
                                    <img
                                        style={{
                                            display: 'flex',

                                            height: 150,
                                            width: '60%',
                                            marginBottom: 5,
                                            marginTop: 3,
                                            justifyContent: 'center',
                                            alignSelf: 'center'
                                        }}
                                        src={`data:image/png;base64,${this.props.item.value}`}
                                        alt={this.props.item.value}
                                    ></img>
                                ) : (
                                    <div
                                        style={{
                                            flex: 1,
                                            height: 150,
                                            width: '100%',
                                            marginBottom: 5,
                                            marginTop: 3
                                        }}
                                    ></div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                {/* Modal */}
                <Modal
                    centered
                    show={this.state.showCulverdocsSignatureModal}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            backgroundColor: Colours.MODALBG,
                            evelation: 4,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                maxHeight: 250,
                                width: '100%',
                                height: '100%',
                                borderRadius: 10,
                                borderWidth: 1,
                                borderColor: this.props.brandData?.primarycolour,

                                backgroundColor: Colours.WHITE
                            }}
                        >
                            <div style={{ flex: 1 }}>
                                <div
                                    style={{
                                        flex: 0.3,
                                        flexDirection: 'column',
                                        borderTopRightRadius: 5,
                                        borderTopLeftRadius: 5,
                                        backgroundColor: this.props.brandData?.primarycolour,
                                        borderBottomColor: this.props.brandData?.primarycolour,
                                        borderBottomWidth: 2
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flex: 1,
                                            flexDirection: 'row',
                                            padding: 5
                                        }}
                                    >
                                        <div
                                            // style={styles.backIconStyle}
                                            hitslop={{
                                                left: Size.CountScale(10),
                                                right: Size.CountScale(10),
                                                bottom: Size.CountScale(10),
                                                top: Size.CountScale(10)
                                            }}
                                            onClick={() =>
                                                this.setState({
                                                    showCulverdocsSignatureModal: false
                                                })
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={['fad', 'circle-chevron-left']}
                                                color={Colours.WHITE}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    width: 30,
                                                    height: 30,
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 0.9,
                                                paddingLeft: 15,
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <p
                                                style={styles.renderOptionModalHeaderText}
                                                numberoflines={1}
                                            >
                                                {this.props.item.caption}
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 0.1,
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div
                                                hitslop={{
                                                    left: Size.CountScale(10),
                                                    right: Size.CountScale(10),
                                                    bottom: Size.CountScale(10),
                                                    top: Size.CountScale(10)
                                                }}
                                                onClick={() => {
                                                    let dataUrl = this.state.signatureRef.toDataURL();
                                                    console.log('dataUrl', dataUrl);

                                                    this.props.item.value = dataUrl.split(',')[1];
                                                    this.props.item.timestamp = new Date().getTime(); // 2023-02-09 (JJ): Capture a timestamp when saving the signature field

                                                    // Clear signature fbstorage and storage keys when the new signature is set to avoid being stripped on sending. These will be re-saved & overwritten on processing.
                                                    if (this.props.item.fbstorage) {
                                                        delete this.props.item.fbstorage;
                                                        delete this.props.item.storage;
                                                    }

                                                    this.state.signatureRef = null; // 2021-07-28 (JJ): v1.4.0 Set the refernce to null to avoid circular references.

                                                    this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                                                    this.setState({
                                                        showCulverdocsSignatureModal: false
                                                    });
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fad', 'circle-check']}
                                                    style={{ width: 30, height: 30, cursor: 'pointer' }}
                                                    color={Colours.WHITE}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.showCulverdocsSignatureModal && this.props.item && (
                                    // <SignatureCapture
                                    //     style={{ flex: 1, margin: 3 }}
                                    //     onSaveEvent={(event) => {
                                    //         this.props.item.value = event.encoded; //Save the value
                                    //         this.props.item.timestamp = new Date().getTime(); // 2023-02-09 (JJ): Capture a timestamp when saving the signature field

                                    //         // Clear signature fbstorage and storage keys when the new signature is set to avoid being stripped on sending. These will be re-saved & overwritten on processing.
                                    //         if (this.props.item.fbstorage) {
                                    //             delete this.props.item.fbstorage;
                                    //             delete this.props.item.storage;
                                    //         }

                                    //         this.state.signatureRef = null; // 2021-07-28 (JJ): v1.4.0 Set the refernce to null to avoid circular references.

                                    //         this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                                    //         this.setState({ showCulverdocsSignatureModal: false });
                                    //     }}
                                    //     ref={(r) => {
                                    //         this.state.signatureRef = r;
                                    //     }}
                                    //     saveImageFileInExtStorage={false}
                                    //     showTitleLabel={false}
                                    //     minStrokeWidth={4}
                                    //     maxStrokeWidth={4}
                                    //     showNativeButtons={false}
                                    //     backgroundColor={'transparent'}
                                    // />
                                    <SignatureCanvas
                                        ref={(r) => {
                                            this.state.signatureRef = r;
                                        }}
                                        penColor="black"
                                        canvasProps={{
                                            width: 500,
                                            height: 200,
                                            className: 'sigCanvas'
                                        }}
                                        fromDataURL={(event) => {
                                            // this.props.item.value =
                                            //     event.encoded; //Save the value
                                            // this.props.item.timestamp =
                                            //     new Date().getTime(); // 2023-02-09 (JJ): Capture a timestamp when saving the signature field

                                            // // Clear signature fbstorage and storage keys when the new signature is set to avoid being stripped on sending. These will be re-saved & overwritten on processing.
                                            // if (this.props.item.fbstorage) {
                                            //     delete this.props.item
                                            //         .fbstorage;
                                            //     delete this.props.item
                                            //         .storage;
                                            // }

                                            // this.state.signatureRef = null; // 2021-07-28 (JJ): v1.4.0 Set the refernce to null to avoid circular references.

                                            // this.props.onFieldChanged(
                                            //     this.props.item,
                                            //     this.props.pItem,
                                            //     this.props.rIndex
                                            // );
                                            // this.setState({
                                            //     showCulverdocsSignatureModal: false,
                                            // });
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        // Common Styles - Mandatory & Caption
        // mandatoryimg: {
        //     alignContent: 'center',
        //     alignSelf: 'center',
        //     top:2,
        //     marginRight: 0,
        //     marginLeft: 0,
        //     tintColor: Colours.RED
        // },
        // fieldcaption: {
        //     fontFamily: Font.family.bold,
        //     fontSize: Font.size.medium,
        //     color: Colours.BLACK
        // },
        // fieldNonEditable : {
        //     fontFamily: Font.family.regular,
        //     fontSize: Font.size.medium,
        //     backgroundColor: Colours.WHITE,
        //     color: Colours.GREY,
        // },
        // fieldNonEditableView : {
        //     borderBottomWidth: 1,
        //     borderBottomColor: brandData?.primarycolour,
        //     backgroundColor: Colours.WHITE,
        //     marginBottom: 10,
        //     paddingTop:10, //for iOS
        //     paddingBottom:10, //for iOS
        //     paddingLeft:5, //for iOS
        // },
        fieldCaptionBadge: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.small,
            color: Colours.BLACK,
            marginLeft: 5,
            paddingLeft: 5,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: lightenDarkenColor(brandData?.primarycolour, 50),
            borderRadius: 5,
            borderWidth: 1,
            borderColor: lightenDarkenColor(brandData?.primarycolour, 50),
            padding: 2,
            paddingLeft: 5,
            paddingRight: 5,
            height: 20,
            maxWidth: 150 // 2022-10-26 (JJ): Set maxWidth on fieldcaptions to limit content length (postcode changes)
        },

        // Modal Specific
        renderOptionModalHeaderText: {
            fontFamily: Font.family.bold,
            fontSize: Font.size.large,
            color: Colours.WHITE,
            width: '80%',
            margin: 0,
            marginBottom: 4 //Fix for the caption ellipsis overlapping the modal window.
        },
        renderDiscardModalText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BACKGROUND,
            textAlign: 'center',
            paddingHorizontal: 10,
            marginTop: 10
        },

        // Field Specific
        fieldSignature: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: Colours.WHITE,
            borderColor: brandData?.primarycolour,
            borderRadius: 5,
            flex: 1,
            marginBottom: 15,
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            borderRadius: 5,
            elevation: 5
        },
        fieldPhotoTextContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center'
        }
    });
};
