// Get the screen dimensions from the window object
const screenWidth = window.innerWidth;
const screenHeight = window.innerHeight;

// Define a baseline height for scaling
const baselineHeight = screenHeight < 750 ? 680 : 800;

// Function to scale values based on screen height and baselineHeight
export const scale = (value) =>
    Math.floor((screenHeight / baselineHeight) * value);

// Function to calculate width based on percentage of screen width
export const wp = (percentage) => {
    const value = (percentage * screenWidth) / 100;
    return Math.round(value);
};

// Usage
const scaledValue = scale(100); // Example: Scale 100 to fit the screen height
const widthPercentage = wp(50); // Example: Get 50% of the screen width

// You can then use scaledValue and widthPercentage in your React JS components
