import React, { Component } from 'react';
// import { p, div, div,  FlatList, StyleSheet } from 'react-native';

// import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

// import { Colours, Font } from '@src/Constant';
// import { CulverdocsFieldRender } from './_CulverdocsFieldRender';
// import { CulverdocsButton } from '@src/Component/CulverdocsButton';
// import { translate } from '@src/Helper/CulverdocsTranslate';
import Font from '../../../Constant/font';
import Size from '../../../Constant/size';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { translate } from '../../../Common/CulverdocsTranslate';
import FlatList from 'flatlist-react';
import { CulverdocsFieldRender } from './_CulverdocsFiledRender';
import { CulverdocsButton } from '../../../Component/CulverdocsButton';

// 2024-01-07 (JJ): Fix for field Class names: Photo & RepeaterChild
export default class CulverdocsRepeaterChild extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        // 2023-06-26 (JJ): Fix for repeater shouldComponentUpdate - now check all props to update the add button, delete etc.
        // return (this.state !== prevState || this.props !== prevProps || this.props.repeaterCollapsibleIndex !== prevProps.repeaterCollapsibleIndex || this.props.visible !== prevProps.visible || this.props.caption !== prevProps.caption);
        // 2023-07-28 (JJ): NFE - Repeater Performance Updates, handle calcs in parent for componentShouldUpdate checks
        // 2023-07-30 (JJ): NFE - Improvements to repeater rendering on deletion of a child, issue with updating deleting index 0
        return (
            this.state !== prevState ||
            // || this.props.repeaterCollapsibleIndex !== prevProps.repeaterCollapsibleIndex
            this.props.visible !== prevProps.visible ||
            this.props.repeaterCaption !== prevProps.repeaterCaption ||
            this.props.rIndex !== prevProps.rIndex ||
            this.props.repeaterDeletable !== prevProps.repeaterDeletable ||
            this.props.showAddRepeater !== prevProps.showAddRepeater ||
            this.props.shouldRender !== prevProps.shouldRender
        );
    }

    state = {
        generatingrepeater: false,
        repeaterRepeaterCollapsibleIndex: null
    };

    componentDidMount() {
        if (this.props.pItem.repeatercollapsible || !this.state.repeaterRepeaterCollapsibleIndex) {
            this.setState({
                repeaterRepeaterCollapsibleIndex: Object.keys(this.props.pItem.fields)[0]
            });
        }
    }

    render() {
        const styles = getStyle(this.props.brandData);
        // 2023-08-27 (JJ): NFE - Repeater now supports style width, colour and font colour properties
        return (
            <div style={this.props.pItem.stylewidth && this.props.pItem.stylewidth == 11 ? styles.repeaterSubHeadingContainer : null}>
                <div style={styles.repeaterHeadingView}>
                    <div
                        style={{
                            ...styles.repeaterHeadingTitle,
                            ...{
                                backgroundColor: this.props.pItem.stylecolour ? this.props.pItem.stylecolour : this.props.brandData.primarycolour
                            }
                        }}
                    >
                        {/* // 2022-09-30 (JJ): Updating Repeater Styling - added numberoflines property to stop wrapping on long names */}
                        {/* // 2023-04-02 (JJ): Implementing collapsible repeaters for performance, reduce the amount of fields/data rendered. */}
                        {/* // 2023-08-27 (JJ): NFE - Repeater now supports style width, colour and font colour properties */}
                        {this.props.pItem.repeatercollapsible && (
                            <div
                                // style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                                onClick={() => {
                                    this.props.updateRepeaterRepeaterCollapsibleIndex(this.props.rIndex);
                                }}
                            >
                                <p
                                    style={{
                                        ...styles.repeaterHeadingText,
                                        ...{
                                            color: this.props.pItem.stylefontcolour ? this.props.pItem.stylefontcolour : Colours.WHITE
                                        }
                                    }}
                                >
                                    {this.props.repeaterCaption}
                                </p>
                            </div>
                        )}

                        {!this.props.pItem.repeatercollapsible && (
                            <p
                                style={{
                                    ...styles.repeaterHeadingText,
                                    ...{
                                        color: this.props.pItem.stylefontcolour ? this.props.pItem.stylefontcolour : Colours.WHITE
                                    }
                                }}
                            >
                                {this.props.repeaterCaption}
                            </p>
                        )}
                    </div>
                    {/* v1.1.6 - Delete button on repeaters, only shown if there is >1 repeater otherwise hide it. Hide on view */}
                    {/* // 2022-01-05 (JJ): Fix to hide the repeater delete button when the form is in view / completed mode */}
                    {/* // 2023-08-27 (JJ): NFE - Repeater now supports style width, colour and font colour properties */}
                    <div
                        style={{
                            ...styles.repeaterHeadingDelete,
                            ...{
                                backgroundColor: this.props.pItem.stylecolour ? this.props.pItem.stylecolour : this.props.brandData.primarycolour
                            }
                        }}
                    >
                        {/* // 2023-02-26 (JJ): Adding editable support to repeaters, for pre-populated data from API & Requests */}
                        {this.props.repeaterDeletable && (
                            <div
                                style={{ display: 'flex', alignItems: 'center' }}
                                onClick={() => {
                                    this.props.deleteRepeater(this.props.pItem, this.props.rIndex);
                                }}
                            >
                                {/* // 2023-08-27 (JJ): NFE - Repeater now supports style width, colour and font colour properties */}
                                <FontAwesomeIcon
                                    icon={['fad', 'trash-alt']}
                                    style={{ width: 20, height: 20, cursor: 'pointer', margin: 0 }}
                                    color={this.props.pItem.stylefontcolour ? this.props.pItem.stylefontcolour : Colours.WHITE}
                                />
                            </div>
                        )}

                        {/* // 2023-04-02 (JJ): Implementing collapsible repeaters for performance, reduce the amount of fields/data rendered. */}
                        {this.props.pItem.repeatercollapsible && this.props.repeaterCollapsibleIndex !== this.props.rIndex && (
                            <div
                                onClick={() => {
                                    this.props.updateRepeaterRepeaterCollapsibleIndex(this.props.rIndex);
                                }}
                            >
                                {/* // 2023-08-27 (JJ): NFE - Repeater now supports style width, colour and font colour properties */}
                                <FontAwesomeIcon
                                    icon={['fad', 'chevrons-down']}
                                    style={{ width: 20, height: 20, cursor: 'pointer' }}
                                    color={this.props.pItem.stylefontcolour ? this.props.pItem.stylefontcolour : Colours.WHITE}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div style={styles.container}>
                    {/* // 2023-04-07 (JJ): Repeater updates - new renderRepeaterItem() to handle rendering of children */}
                    {(!this.props.pItem.generatefromlist || this.props.pItem.generated) && (!this.props.pItem.repeatercollapsible || (this.props.pItem.repeatercollapsible && this.props.repeaterCollapsibleIndex == this.props.rIndex)) && (
                        <>
                            <FlatList
                                list={this.props.item.filter((field) => this.props.returnFieldVisibility(field, this.props.pItem, this.props.rIndex))}
                                // extraData={this.state}
                                // 2023-07-27 (JJ): NFE - Updating FieldRenderer and RepeaterChild to use listKey and keyExtractor with field index for performance
                                //listKey={(item, index) => index.toString()}
                                // 2023-07-30 (JJ): NFE - Improvements to repeater rendering on deletion of a child, issue with updating deleting index 0
                                // 2023-07-31 (JJ): NFE - Added item.dataname to KeyExtractor to support toggling field updates on the same index as this wasn't refreshing the field
                                // keyExtractor={(item, index) => this.props.repeaterCaption + item.dataname + index.toString() + this.props.rIndex.toString()}
                                // v1.1.6 - Added padding to top of field section
                                //  ListHeaderComponent={<div></div>}
                                // 2023-08-27 (JJ): NFE - Repeater now supports style width, colour and font colour properties
                                // ListHeaderComponentStyle={[styles.repeaterContainerLeftBar, this.props.pItem.stylecolour ? { borderColor: this.props.pItem.stylecolour } : null]}
                                // ListHeaderComponentStyle={{paddingTop:10}}
                                // renderItem={(item) => this.renderRepeaterItem(item,this.props.pItem,this.props.mapIndex) }
                                // renderItem={(item) => {
                                // 2024-05-16 (JJ): Fix for Repeater Children which weren't rendering correctly and stopping form load in release build (now loads all fields in batch)
                                // initialNumToRender={this.props.item.length}
                                // maxToRenderPerBatch={this.props.item.length}
                                renderItem={(item, index) => {
                                    // item.index = index; // 2023-07-31 (JJ): NFE - Removed repeater Item Index in FlatList as this is handled when the form is started.
                                    // 2023-08-27 (JJ): NFE - Repeater now supports style width, colour and font colour properties
                                    return (
                                        <div
                                            style={{
                                                ...styles.repeaterContainer,

                                                ...{
                                                    borderColor: this.props.pItem.stylecolour ? this.props.pItem.stylecolour : this.props.brandData.primarycolour
                                                }
                                            }}
                                        >
                                            <div style={{ display: 'flex' }}>
                                                <div
                                                    style={{ ...styles.repeaterContainerLeftBar, ...(this.props.pItem.stylecolour ? { borderColor: this.props.pItem.stylecolour } : null) }}
                                                    //style={{ ...styles.repeaterContainerLeftBar, ...(this.props.pItem.stylecolour ? { borderColor: this.props.pItem.stylecolour } : null) }}
                                                ></div>
                                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                                    <CulverdocsFieldRender
                                                        brandData={this.props.brandData}
                                                        item={item}
                                                        pItem={this.props.pItem}
                                                        rIndex={this.props.rIndex}
                                                        formFields={this.props.formFields}
                                                        returnPlaceholderCaption={this.props.returnPlaceholderCaption}
                                                        returnFieldVisibility={this.props.returnFieldVisibility}
                                                        returnFieldEditable={this.props.returnFieldEditable}
                                                        downloadMediaFromStorage={this.props.downloadMediaFromStorage}
                                                        onFieldChanged={this.onFieldChanged}
                                                        onRepeaterFieldChanged={this.props.onFieldChanged}
                                                        onFieldNewChild={this.props.onFieldNewChild}
                                                        onFieldDeleteChild={this.props.onFieldDeleteChild}
                                                        returnFieldFromID={this.props.returnFieldFromID}
                                                        returnFieldValueFromID={this.props.returnFieldValueFromID}
                                                        returnListValues={this.props.returnListValues}
                                                        checkGPSPermissions={this.props.checkGPSPermissions}
                                                        onFieldUpdateValueIndex={this.props.onFieldUpdateValueIndex} // 2024-01-09 (JJ): Global update onFieldSoftDeletePhoto() to onFieldUpdateValueIndex() as this is used for Files, Photo Updates, etc.
                                                        toggleFieldLock={this.props.toggleFieldLock} // 2023-07-09 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Short Answer, Number, Choice, Select)
                                                        setFieldDataFromID={this.props.setFieldDataFromID}
                                                        forceRepeaterChildUpdate={this.forceRepeaterChildUpdate} // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                                                        returnValueFromDataname={this.props.returnValueFromDataname} // 2023-08-01 (JJ): NFE - Update calculation returnValueFromDataname to support repeaters
                                                        getKeyForCurrentRecord={this.props.getKeyForCurrentRecord} // 2024-01-10 (JJ): Query updates to support repeaters & forceUpdates after populateFromResponse
                                                        recordId={this.props.recordId} // 2024-05-14 (JJ): Implementing documentviewerembededfileid - embedded file IDs which are record specifc not account (unlike documentviewerfileid) or the base64 option (documentviewerbase64)
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }}
                            ></FlatList>
                        </>
                    )}

                    {/* Dont allow Add New if in view mode */}
                    <div style={styles.addRepeaterSection}>
                        {/* // 2023-02-26 (JJ): Adding editable support to repeaters, for pre-populated data from API & Requests */}
                        {this.props.showAddRepeater && (
                            <div style={styles.addRepeaterButton}>
                                <div
                                    onClick={() => {
                                        // call addRepeater without copyFieldsFromPrevious
                                        this.props.addRepeater(this.props.pItem, this.props.item, false);
                                    }}
                                    style={styles.fieldContainer}
                                >
                                    {/* // 2023-08-27 (JJ): NFE - Repeater now supports style width, colour and font colour properties */}
                                    <FontAwesomeIcon
                                        icon={['fas', 'circle-plus']}
                                        style={{ width: 40, height: 40, marginTop: -5, cursor: 'pointer' }}
                                        color={this.props.pItem.stylecolour ? this.props.pItem.stylecolour : this.props.brandData?.primarycolour}
                                    />
                                </div>
                            </div>
                        )}

                        {/* // 2023-06-09 (JJ): Support for Copy From Previous Repeater / repeatercopyfields */}
                        {this.props.pItem.repeatercopyfields && this.props.showAddRepeater && (
                            <div style={styles.addRepeaterButtonCopy}>
                                {/* // 2024-01-18 (JJ): Styling update on Repeaters with Copy/Duplicate button - line between button now uses style colour if defined */}
                                <div
                                    style={{
                                        ...styles.addRepeaterButtonCopyLine,
                                        ...{
                                            backgroundColor: this.props.pItem.stylecolour ? this.props.pItem.stylecolour : this.props.brandData.primarycolour
                                        }
                                    }}
                                />
                                <div
                                    onClick={() => {
                                        // call addRepeater with copyFieldsFromPrevious - this is defined in the repeatercopyfields array.
                                        this.props.addRepeater(this.props.pItem, this.props.item, true);
                                    }}
                                    style={styles.fieldContainer}
                                >
                                    {/* // 2023-08-27 (JJ): NFE - Repeater now supports style width, colour and font colour properties */}
                                    <FontAwesomeIcon
                                        icon={['fad', 'circle-arrow-down']}
                                        style={{ width: 30, height: 30, cursor: 'pointer' }}
                                        color={this.props.pItem.stylecolour ? this.props.pItem.stylecolour : this.props.brandData?.primarycolour}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    {/* // 2022-10-02 (JJ): Fix to Generated Repeaters with a single entry, now correctly show based on generated flag. */}
                    {this.props.pItem.generatefromlist && !this.props.pItem.generated && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                width: '100%',
                                alignItems: 'center',
                                paddingBottom: 10
                            }}
                        >
                            <CulverdocsButton
                                title={this.state.generatingrepeater ? translate('Please wait...') : translate(this.props.pItem.generatecaption)}
                                icon={'angle-right'}
                                backgroundColor={this.props.brandData?.primarycolour}
                                onClick={() => {
                                    this.setState({
                                        generatingrepeater: true
                                    });
                                    this.props.generateRepeater(this.props.pItem, this.props.item).then(() => {
                                        this.setState({
                                            generatingrepeater: false
                                        });
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    onFieldChanged = async (item, pItem, rIndex) => {
        this.props.onFieldChanged(item, pItem, rIndex); //Pass the update up to CreateFormScreen.
        this.forceUpdate(); //Force Update on the current child.
    };

    // 2023-08-01 (JJ): NFE - Adding forceRepeaterChildUpdate() to RepeaterChild to force update when a field is updated inside the repeater
    forceRepeaterChildUpdate = () => {
        this.forceUpdate();
    };
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        // 2023-08-27 (JJ): NFE - Repeater now supports style width, colour and font colour properties
        repeaterSubHeadingContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignSelf: 'center',
            width: '90%'
        },
        // Repeater
        repeaterHeadingView: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            height: 60,
            minHeight: 40,
            alignItems: 'center'
        },
        repeaterHeadingTitle: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignSelf: 'center',
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: brandData?.primarycolour,
            height: 60
        },
        repeaterHeadingDelete: {
            display: 'flex',
            flex: 0.1,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            alignSelf: 'center',
            backgroundColor: brandData?.primarycolour,
            height: 60,
            paddingRight: 20
        },
        repeaterContainer: {
            marginLeft: 15,
            marginRight: 10,
            borderLeftWidth: 6,
            borderColor: brandData?.primarycolour
        },
        repeaterContainerLeftBar: {
            //  marginLeft: 15,
            // marginRight: 10,
            //borderLeftWidth: 6,
            //  borderColor: brandData?.primarycolour
            border: '6px solid' + brandData?.primarycolour
        },
        repeaterHeadingText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.large,
            color: Colours.WHITE,
            padding: 5,
            paddingTop: 0,
            margin: 0
        },
        addRepeaterButton: {
            left: 3,
            top: -1,
            paddingBottom: 15
        },

        // 2023-06-09 (JJ): Support for Copy From Previous Repeater / repeatercopyfields
        addRepeaterButtonCopy: {
            paddingLeft: 20,
            top: -1
        },
        addRepeaterButtonCopyLine: {
            position: 'absolute',
            backgroundColor: brandData?.primarycolour,
            width: 19,
            height: 20,
            top: 12,
            left: 2
        },
        addRepeaterSection: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row'
        }
    });
};
