import logo from './logo.svg';

import './App.scss';

import { useEffect } from 'react';
import { Router } from 'react-router-dom';
import MainStack from './Navigation/MainStack';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons'; // 2023-01-02 (JJ): Adding font awesome solid, duotone and regular v5
import { fad } from '@fortawesome/pro-duotone-svg-icons'; // 2023-01-02 (JJ): Adding font awesome solid, duotone and regular v5
import { far } from '@fortawesome/pro-regular-svg-icons';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import RouteScreen from './Navigation/Routes';
library.add(fal, fas, fad, far); // 2023-01-02 (JJ): Adding font awesome solid, duotone and regular v5
function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* <MainStack /> */}
            <RouteScreen />
        </LocalizationProvider>
    );
}

export default App;
