import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import Colours from '../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CulverdocsButton = ({ loader = false, title, variant = 'primary', type = 'submit', onClick, disabled, extraStyle = {backgroundColor: Colours.THEME,borderColor: Colours.THEME}, icon = null, iconType = 'fad', iconsize = 30, titleExtraStyle = {fontSize: 20,color: 'white'}, iconLeftType, iconLeft = null }) => {
    return (
        <Button
            variant={variant}
            type={type}
            className="w-100 mt-2"
            disabled={disabled}
            onClick={onClick}
            style={extraStyle}
        >
            <Container>
                <Row>
                    <Col
                        xs={icon ? 10 : 12}
                        style={titleExtraStyle}
                    >
                        {iconLeft && (
                            <FontAwesomeIcon
                                icon={[iconLeftType, iconLeft]}
                                color={Colours.WHITE}
                                style={{
                                    marginRight: 10,
                                    height: iconsize,
                                    width: iconsize
                                }}
                            />
                        )}
                        {loader ? (
                            <Spinner
                                brandData={Colours.WHITE}
                                type={'FadingCircleAlt'}
                            />
                        ) : (
                            title
                        )}
                    </Col>
                    {icon && (
                        <Col xs={2}>
                            <FontAwesomeIcon
                                icon={[iconType, icon]}
                                color={Colours.WHITE}
                                style={{
                                    marginRight: 10,
                                    height: iconsize,
                                    width: iconsize
                                }}
                            />
                        </Col>
                    )}
                </Row>
            </Container>
        </Button>
    );
};

