import React, { Component } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colours from '../../../Constant/colour';
import Font from '../../../Constant/font';
import FontAwesomeSpin from '../../../Common/FontAwesomeSpin';

export default class HomeScreenRecord extends Component {
    render() {
        const { item, handlePress, brandData } = this.props;
        var imageData = this.returnImageFromState(item);

        return (
            <div
                style={{
                    cursor: 'pointer',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: Colours.TILE,
                    justifyContent: 'center'
                }}
            >
                {imageData && (
                    <div
                        onClick={() => {
                            handlePress(item);
                        }}
                        // v1.1.6 - disable the edit button if the record isnt in an editable state (pre-defined list of statuses)
                    >
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingVertical: 5,
                                alignItems: 'center',
                                border: '1px solid' + brandData.primarycolour,
                                padding: 5,
                                paddingLeft: 10,
                                borderRadius:5,
                                marginBottom:5
                            }}
                        >
                            <div
                                style={{
                                    width: 60,
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                {imageData['rotate'] ? (
                                    <FontAwesomeSpin
                                        icon={['fad', imageData['image']]}
                                        size={imageData['size']}
                                        color={imageData['tintColor']}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={['fad', imageData['image']]}
                                        style={{
                                            height: imageData['size'],
                                            width: imageData['size']
                                        }}
                                        color={imageData['tintColor']}
                                    />
                                )}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                    justifyContent: 'center'
                                }}
                            >
                                <p
                                    numberoflines={1}
                                    style={{
                                        color: Colours.BLACK,
                                        fontSize: Font.size.medium,
                                        fontFamily: Font.family.regular,
                                        margin: 0
                                    }}
                                >
                                    {item?.name}
                                </p>
                                {/* // 2023-07-09 (JJ): Minor updates to RecordComponent styling for Title fields - use small font size and remove numberoflines as this was wrapping text which needs to be shown for longer requests and titles. */}
                                <p
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        color: Colours.BLACK,
                                        fontSize: Font.size.small,
                                        fontFamily: Font.family.regular,
                                        flex: 1,
                                        textAlignVertical: 'center',
                                        minHeight: 18,
                                        marginTop: 0,
                                        marginBottom: 0,
                                        margin: 0
                                    }}
                                >
                                    {this.returnTitleFromState(item)}
                                </p>
                                <p
                                    style={{
                                        color: Colours.GREY,
                                        fontSize: Font.size.small,
                                        fontFamily: Font.family.light,
                                        margin: 0
                                    }}
                                >
                                    {moment(item?.timestamp, 'x').format('Do MMMM YYYY HH:mm')}, {moment(item?.timestamp, 'x').fromNow()}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
    returnTitleFromState = (item) => {
        if (item.state === 'saving') {
            return 'Saving as Draft';
        }
        if (item.state === 'draft') {
            return item.title ? 'Saved as Draft' + ': ' + item.title : 'Saved as Draft';
        }

        // 2023-06-24 (JJ): NFE updates to create the initial record as "started" to avoid draft creation/processing
        if (item.state === 'started') {
            return 'Recovered - Unsaved form';
        }
        if (item.state === 'deleting') {
            return 'Deleting';
        }
        if (item.state === 'uploading') {
            return 'Uploading';
        }
        // if (item.state === "processing") { return "Processing"; }
        if (item.state === 'processing') {
            return item.title ? item.title : 'Processing';
        } //v1.1.11 - Allow Processing title
        if (item.state === 'rejected') {
            return item.title ? 'Rejected' + ': ' + item.title : 'Rejected';
        }

        if (item.state === 'requested') {
            // 2023-01-31 (JJ): Implementing configurable requested states - requestcategory, requesticon, requesticoncolour
            return (item.requestcategory ? item.requestcategory : 'Requested') + (item.title ? `: ${item.title}` : '');
        }

        //v1.1.11 - new sending, sent and returned status
        if (item.state === 'sending') {
            return 'Sending to' + ': ' + item.sendto;
        }
        if (item.state === 'sent') {
            return 'Sent to' + ': ' + item.sendto;
        }
        if (item.state === 'returned') {
            return 'Returned from' + ': ' + item.sendto;
        }

        return item.title; //Catch-all
    };
    returnImageFromState(item) {
        if (item) {
            // 2022-01-29 (JJ): Implementing spinner for processing - Processing states - all show the new animated icon & changes to status colours/fonts etc.
            let processingState = {
                image: 'circle-notch',
                size: 40,
                tintColor: this.props.brandData.primarycolour,
                rotate: true
            };
            if (item.state === 'saving') {
                return processingState;
            }
            if (item.state === 'deleting') {
                return processingState;
            }
            if (item.state === 'uploading') {
                return processingState;
            }
            if (item.state === 'processing') {
                return processingState;
            }
            if (item.state === 'sending') {
                return processingState;
            }

            // Waiting state - draft or sent to user awaiting response, show in orange
            if (item.state === 'draft') {
                return {
                    image: 'pause-circle',
                    size: 40,
                    tintColor: this.props.brandData.primarycolour
                };
            }

            // 2023-06-24 (JJ): NFE updates to create the initial record as "started" to avoid draft creation/processing
            if (item.state === 'started') {
                return {
                    image: 'circle-play',
                    size: 40,
                    tintColor: this.props.brandData.primarycolour
                };
            }
            if (item.state === 'sent') {
                return {
                    image: 'comment-alt-dots',
                    size: 40,
                    tintColor: this.props.brandData.primarycolour
                };
            } //v1.1.11 - new sending, sent and returned status

            // To do state - rejected from admin, requested or returned from the user
            if (item.state === 'rejected') {
                return {
                    image: 'comment-alt-dots',
                    size: 40,
                    tintColor: this.props.brandData.primarycolour
                };
            }
            if (item.state === 'requested') {
                // 2023-01-31 (JJ): Implementing configurable requested states - requestcategory, requesticon, requesticoncolour
                return {
                    image: item.requesticon ? item.requesticon : 'question-circle',
                    size: 40,
                    tintColor: item.requesticoncolour ? item.requesticoncolour : Colours.GREEN
                };
            }
            if (item.state === 'returned') {
                return {
                    image: 'comment-alt-check',
                    size: 40,
                    tintColor: Colours.GREEN
                };
            } //v1.1.11 - new sending, sent and returned status

            return {
                image: 'check-circle',
                size: 40,
                tintColor: this.props.brandData.primarycolour
            }; //catch-all, "complete"
        }
    }
}
