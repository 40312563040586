// 2024-01-10 (JJ): New field type "query"
import React, { Component } from 'react';
// import { Text, div, TouchableOpacity, Image, StyleSheet, ActivityIndicator } from 'react-native';
// import auth from '@react-native-firebase/auth'; //Firebase
// import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
// import CulverdocsTile from '@src/Component/CulverdocsTile';
// import { Colours, Font } from '@src/Constant';
// import { connect } from 'react-redux';
// import CryptoJS from 'crypto-js';
// import { translate } from '@src/Helper/CulverdocsTranslate';
import Font from '../../../Constant/font';
import Size from '../../../Constant/size';

import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { translate } from '../../../Common/CulverdocsTranslate';
import FlatList from 'flatlist-react';
import CulverdocsTile from '../../../Component/CulverdocsTile';

class CulverdocsQuery extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        return this.props.visible !== prevProps.visible || this.props.itemValue !== prevProps.itemValue || this.state !== prevState;
    }

    state = {
        processing: false,
        error: null,

        // 2024-01-18 (JJ): Query update: remove buttonProperties support, handle previous state when loading draft/completed with a value & set button to disabled in view mode.
        buttonCaption: this.props.item.value ? this.props.item.value : this.props.item.buttoncaption ? translate(this.props.item.buttoncaption) : translate('Execute Query'),
        buttonIcon: this.props.item.value ? 'circle-check' : this.props.item.buttonicon ? this.props.item.buttonicon : 'arrow-right-arrow-left',
        buttonColour: this.props.item.value ? 'green' : this.props.item.buttoncolour ? this.props.item.buttoncolour : null
    };

    render() {
        if (!this.props.visible) {
            return null;
        }
        const styles = getStyle(this.props.brandData);
        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CulverdocsFieldCaption
                        brandData={this.props.brandData}
                        caption={this.props.item.caption}
                        userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                        mandatory={this.props.item.mandatory}
                        editable={this.props.editable} // 2024-01-18 (JJ): Query update: remove buttonProperties support, handle previous state when loading draft/completed with a value & set button to disabled in view mode.
                        hasValue={this.props.item.value ? true : false}
                    />
                </div>
                <div>
                    <CulverdocsTile
                        brandData={this.props.brandData}
                        caption={this.state.buttonCaption}
                        iconName={this.state.buttonIcon}
                        colour={this.state.buttonColour}
                        processing={this.state.processing}
                        disabled={!this.props.editable} // 2024-01-18 (JJ): Query update: remove buttonProperties support, handle previous state when loading draft/completed with a value & set button to disabled in view mode.
                        buttonStyle={{
                            marginLeft: 0,
                            marginRight: 0,
                            marginTop: 0,
                            height: 45,
                            marginBottom: 10,
                            backgroundColor: Colours.WHITE
                        }}
                        textStyle={{
                            fontFamily: Font.family.regular,
                            fontSize: Font.size.medium
                        }}
                        onPress={() => {
                            // 2024-01-18 (JJ): Query update: remove buttonProperties support, handle previous state when loading draft/completed with a value & set button to disabled in view mode.
                            if (!this.state.processing && this.props.editable) {
                                this.performHttpPostWithTimeout();
                            }
                        }}
                    />
                </div>
            </div>
        );
    }

    performHttpPostWithTimeout = async () => {
        // Key fields for request, url and timeout based on query settings.
        // const url = "https://io.culverdocs.co.uk/v1/app/query";
        // const url = "https://httpstat.us/200?sleep=5000";
        // const url = "https://httpstat.us/500";
        // const url = this.props.item.devendpoint
        //     ? this.props.item.devendpoint
        //     : "https://io.culverdocs.co.uk/v1/app/query";
        // const timeout = this.props.item.timeout
        //     ? this.props.item.timeout * 1000
        //     : 5000; // Timeout is passed in seconds in the field, otherwise default to 5s.
        // // Controller for request and timeout
        // const controller = new AbortController();
        // const timeoutId = setTimeout(() => controller.abort(), timeout);
        // // Set processing state before await auth
        // this.setState({ processing: true });
        // // Get user and payload values
        // const user = auth().currentUser;
        // const token = await user.getIdTokenResult();
        // const recordKey = await this.props.getKeyForCurrentRecord();
        // const hmac = await CryptoJS.HmacSHA256(
        //     user.uid,
        //     "m1uh4dDvF0hAJ2u93cUjb3X7RsukanTgUI4KBm21cx2BjyFOBd"
        // ).toString();
        // // Build the payload
        // const payload = {
        //     source: this.props.item.type,
        //     hmac: hmac,
        //     user: {
        //         accountId: this.props.userProfile.accountid,
        //         uid: user.uid,
        //         email: user.email,
        //         token: token,
        //     },
        //     record: {
        //         recordId: recordKey,
        //         fieldId: this.props.item.id,
        //         parentItemId: this.props.pItem ? this.props.pItem.id : null,
        //         parentItemIndex: this.props.rIndex ? this.props.rIndex : null,
        //     },
        // };
        // // Ready, send POST request.
        // try {
        //     const response = await fetch(url, {
        //         method: "POST",
        //         headers: { "Content-Type": "application/json" },
        //         body: JSON.stringify(payload),
        //         signal: controller.signal,
        //     });
        //     // Guard on unsuccessful request, throw response to catch.
        //     if (!response.ok) {
        //         throw response;
        //     }
        //     // Get the data json response
        //     const data = await response.json();
        //     // Update the field values
        //     this.props.item.value = data.value;
        //     this.props.item.timestamp = new Date().getTime();
        //     if (data.responseData) {
        //         this.props.item.response = data.responseData;
        //     }
        //     this.props.item.http = {
        //         success: response.ok,
        //         status: response.status,
        //         statusText: response.statusText,
        //     };
        //     // 2024-01-18 (JJ): Query update: remove buttonProperties support, handle previous state when loading draft/completed with a value & set button to disabled in view mode.
        //     this.setState({
        //         buttonCaption: data.value,
        //         buttonIcon: "circle-check",
        //         buttonColour: "green",
        //     });
        //     // Push field update for the query field
        //     this.props.onFieldChanged(
        //         this.props.item,
        //         this.props.pItem,
        //         this.props.rIndex
        //     );
        //     // Support the response including populateFromResponse key containing k/v of Field ID and Value
        //     if (data.populateFromResponse) {
        //         for (const [key, value] of Object.entries(
        //             data.populateFromResponse
        //         )) {
        //             // key = field id
        //             // value = new value
        //             if (key && value) {
        //                 await this.props.setFieldDataFromID(
        //                     key,
        //                     this.props.pItem,
        //                     this.props.rIndex,
        //                     { value: value }
        //                 );
        //             }
        //         }
        //         if (this.props.pItem) {
        //             this.props.forceRepeaterChildUpdate();
        //         }
        //     }
        //     this.setState({ processing: false, error: false });
        // } catch (error) {
        //     // Handle other errors, including timeouts
        //     this.props.item.value = null;
        //     this.props.item.response = null;
        //     this.props.item.http = { success: false, status: error.message };
        //     this.props.onFieldChanged(
        //         this.props.item,
        //         this.props.pItem,
        //         this.props.rIndex
        //     );
        //     this.setState({
        //         processing: false,
        //         error: true,
        //         buttonCaption: translate(
        //             "An error has occured, please try again."
        //         ),
        //         buttonIcon: "circle-xmark",
        //         buttonColour: "red",
        //     });
        // } finally {
        //     clearTimeout(timeoutId);
        // }
    };
}

export default CulverdocsQuery;
const getStyle = (brandData) => {
    return Stylesheet.create({
        fieldPhotoTextContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center'
        }
    });
};
