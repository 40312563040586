// 2023-12-30 (JJ): New field "youtube" for playing embedded YT videos (react-native-youtube-iframe & react-native-webview) with AppState to restrict background playing.
import React, { Component } from 'react';
import Font from '../../../Constant/font';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import YouTube from 'react-youtube';
import Size from '../../../Constant/size';

export default class CulverdocsYouTube extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        return this.props.itemValue !== prevProps.itemValue || this.state !== prevState || this.props.visible !== prevProps.visible || this.props.item.value !== prevProps.item.value || this.props.caption !== prevProps.caption;
    }

    state = {
        height: 0,
        playing: false,
        showLoader: true
    };

    playerRef = null;
    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    _onUpdate(event) {
        // access to player in all event handlers via event.target
        // event.target.pauseVideo();
        console.log('updated');
    }
    componentDidMount() {
        let currentWidth = window.innerWidth - Size.doubleBaseMargin;
        let height = parseInt(currentWidth / 1.78);
        this.setState({ height: height });

        // AppState.addEventListener("change", (AppState) => {
        //     if (AppState !== "active") {
        //         this.stopPlaying();
        //     }
        // });
    }

    render() {
        const styles = getStyle(this.props.brandData, this.state.height);
        const videoOptions = {
            width: '100%',
            playerVars: {
                autoplay: 1
            }
        };
        if (!this.props.visible) {
            return null;
        }
        const opts = {
            height: this.state.height,
            width: '100%',
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 1
            }
        };
        return (
            <div>
                <CulverdocsFieldCaption
                    brandData={this.props.brandData}
                    caption={this.props.item.caption}
                    userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                    mandatory={this.props.item.mandatory}
                    editable={this.props.editable} // 2024-01-18 (JJ): YouTube update: non-editable support for completed forms, disable pointer events & firebase update.
                    hasValue={this.props.item.value ? true : false}
                />

                {/* // 2024-01-18 (JJ): YouTube update: non-editable support for completed forms, disable pointer events & firebase update. */}
                <div
                    style={styles.fieldYouTubeContainer}
                    onLayout={this.onLayout}
                    pointerEvents={this.props.editable ? null : 'none'}
                >
                    {/* <ActivityIndicator
                        animating={this.state.showLoader}
                        size="large"
                        color={this.props.brandData?.primarycolour}
                        style={styles.videoLoading}
                    /> */}

                    {/* <YoutubePlayer
                        ref={this.playerRef}
                        webViewStyle={{ opacity: 0.99 }}
                        height={this.state.height}
                        width={'100%'}
                        play={this.state.playing}
                        videoId={this.props.item.youtubevideoid}
                        onChangeState={(onStateChange) => {
                            if (onStateChange == 'playing') {
                                this.setState({ playing: true, showLoader: false });

                                // 2024-01-18 (JJ): YouTube update: non-editable support for completed forms, disable pointer events & firebase update.
                                if (this.props.editable) {
                                    getYoutubeMeta(this.props.item.youtubevideoid).then((meta) => {
                                        this.props.item.value = meta.title;
                                        this.props.item.timestamp = new Date().getTime();
                                        this.props.item.videometa = meta;

                                        this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                                        this.forceUpdate();
                                    });
                                }
                            }
                        }}
                    /> */}
                    <YouTube
                        ref={this.playerRef}
                        videoId={this.props.item.youtubevideoid ? this.props.item.youtubevideoid : ''} // defaults -> ''
                        //opts={opts}
                        style={{ display: 'flex', flex: 1, alignSelf: 'center' }}
                        play={this.state.playing}
                        onReady={this._onReady}
                        opts={videoOptions}
                        onChangeState={(onStateChange) => {
                            if (onStateChange == 'playing') {
                                this.setState({
                                    playing: true,
                                    showLoader: false
                                });
                                // 2024-01-18 (JJ): YouTube update: non-editable support for completed forms, disable pointer events & firebase update.
                                if (this.props.editable) {
                                    // getYoutubeMeta(
                                    //     this.props.item.youtubevideoid
                                    // ).then((meta) => {
                                    //     this.props.item.value = meta.title;
                                    //     this.props.item.timestamp =
                                    //         new Date().getTime();
                                    //     this.props.item.videometa = meta;
                                    //     this.props.onFieldChanged(
                                    //         this.props.item,
                                    //         this.props.pItem,
                                    //         this.props.rIndex
                                    //     );
                                    //     this.forceUpdate();
                                    // });
                                }
                            }
                        }}
                    />
                </div>
            </div>
        );
    }

    onLayout = (event) => {
        let height = parseInt(event.nativeEvent.layout.width / 1.78);
        this.setState({ height: height });
    };

    stopPlaying = () => {
        this.setState({ playing: false });
    };
}

const getStyle = (brandData, height) => {
    return Stylesheet.create({
        fieldYouTubeContainer: {
            // height: height,
            backgroundColor: Colours.WHITE,
            borderColor: brandData?.primarycolour,
            borderRadius: 5,
            // flex: 1,
            overflow: 'hidden',
            marginBottom: 15,
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            borderRadius: 5,
            elevation: 5,

            // height:200,
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center'
        },
        videoLoading: {
            position: 'absolute'
        }
    });
};
