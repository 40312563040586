import React, { Component } from 'react';
import Font from '../../../Constant/font';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import moment from 'moment/moment';
import DatePicker from 'react-datepicker';
import { DateTimePicker } from '@mui/x-date-pickers';

export default class CulverdocsDate extends Component {
    state = {
        isDateTimePickerVisible: false,
        currentTime: new Date() // 2024-04-29 (RB): current time is not updating once modal is open
    };

    shouldComponentUpdate(prevProps, prevState) {
        // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
        return this.props.itemValue !== prevProps.itemValue || this.state.isDateTimePickerVisible !== prevState.isDateTimePickerVisible || this.props.visible !== prevProps.visible || this.props.item.value !== prevProps.item.value || this.props.caption !== prevProps.caption;
    }

    render() {
        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption}`);

        if (!this.props.visible) {
            return null;
        }
        const styles = getStyle(this.props.brandData);
        //v1.1.6 - New property for "Time" and "DateTime"
        var datetimeformat = this.props.item.datetimeformat ? this.props.item.datetimeformat : 'date'; //Use the format if it is set, otherwise default to date.
        var friendlydate = null;
        var storagedate = null;
        var displayval = null;

        //Date
        if (datetimeformat == 'date') {
            friendlydate = 'Do MMMM YYYY';
            storagedate = 'YYYY-MM-DD';
        }

        //Time
        if (datetimeformat == 'time') {
            friendlydate = 'HH:mm';
            storagedate = 'HH:mm';
        }

        //Date & Time
        if (datetimeformat == 'datetime') {
            friendlydate = 'Do MMMM YYYY HH:mm';
            storagedate = 'YYYY-MM-DD HH:mm';
        }

        //Showing/formatting date
        if (this.props.item.value) {
            if (datetimeformat == 'time') {
                displayval = this.props.item.value; //Dont use moment as we havent got a date, just return HH:MM
            } else {
                displayval = moment(this.props.item.value).format(friendlydate);
            }
        }

        return (
            <div>
                <CulverdocsFieldCaption
                    brandData={this.props.brandData}
                    caption={this.props.item.caption}
                    userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                    mandatory={this.props.item.mandatory}
                    editable={this.props.editable}
                    hasValue={this.props.item.value ? true : false}
                />

                {/* Editable */}
                {this.props.editable && (
                    // <div
                    //     style={styles.fieldDate}
                    //     onClick={() => this.setState({ isDateTimePickerVisible: true })}
                    // >
                    //     <p style={styles.fieldDateText}>{displayval}</p>
                    //     {this.state.isDateTimePickerVisible && (
                    <Form.Control
                        //ref={(ref) => (this.state.dateRef = ref)}
                        type={datetimeformat}
                        style={styles.fieldDate}
                        //hidden={true}
                        //  id="gallery"
                        // accept="image/*"
                        defaultValue={this.props.item.value}
                        onChange={(e) => {
                            this.props.item.value = moment(e.target.value).format(storagedate); //Set the date from selection then hide, call setState to update value.
                            this.setState({
                                isDateTimePickerVisible: false
                                //currentTime: new Date()
                            }); // 2024-04-29 (RB): current time is not updating once modal is open
                            this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                            this.forceUpdate();
                        }}
                    />
                )}

                {/* Non-editable */}
                {!this.props.editable && (
                    <div style={styles.fieldDate}>
                        <div style={styles.fieldNonEditable}>{displayval}</div>
                    </div>
                )}
            </div>
        );
    }
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        fieldNonEditable: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.GREY
        },
        // 2023-09-03 (JJ): Update Date styling to standardise button and text css as per other button controls
        fieldDate: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 5,
            backgroundColor: Colours.WHITE,
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            borderRadius: 5,
            elevation: 5,
            height: 45,
            marginBottom: 10 // 2023-09-03 (JJ): Standardising marginBottom:10 on all components which were 15 as there were some inconsistencies with styling
        },
        fieldDateText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            flex: 0.9,
            color: Colours.BACKGROUND
        }
    });
};
