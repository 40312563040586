import { DRAWER } from '../constants';
const initialState = true;
const DrawerReducer = (state = initialState, action) => {
    switch (action.type) {
        case DRAWER:
            return {
                ...state,
                key: action.payload
            };
        default:
            return state;
    }
};
export default DrawerReducer;
