import React, { Component } from 'react';
import Font from '../../../Constant/font';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';

export default class CulverdocsTelephone extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
        return this.props.itemValue !== prevProps.itemValue || this.props.visible !== prevProps.visible || this.props.item.value !== prevProps.item.value || this.props.caption !== prevProps.caption;
    }

    render() {

        if (!this.props.visible) {
            return null;
        }
        const styles = getStyle(this.props.brandData);
        return (
            <div>
                <CulverdocsFieldCaption
                    brandData={this.props.brandData}
                    caption={this.props.item.caption}
                    userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                    mandatory={this.props.item.mandatory}
                    editable={this.props.editable}
                    hasValue={this.props.item.value ? true : false}
                />

                {/* Editable - with keyboard type (bug - https://github.com/facebook/react-native/issues/8932) */}
                {this.props.editable && (
                    // <TextInput
                    //     ref={(ref) => {
                    //         this.thisRef = ref;
                    //     }} // 2023-11-06 (JJ): Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                    //     style={styles.fieldText}
                    //     defaultValue={this.props.item.value}
                    //     keyboardType={'phone-pad'}
                    //     autoCompleteType={'tel'}
                    //     returnKeyType={'done'} //v1.1.6 - changed to "Done" field
                    //     onChangeText={(text) => {
                    //         this.props.item.value = text;
                    //         if (text === '') {
                    //             this.props.item.value = null;
                    //         }
                    //         this.forceUpdate();

                    //         // 2023-11-06 (JJ): Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                    //         // If onChangeText is called without focus, the field has been updated externally (i.e. S Pen) and needs to write to the DB by calling onEndEditing
                    //         if (!this.thisRef.isFocused()) {
                    //             clearTimeout(this.thisRef.timeoutToUpdate);
                    //             this.thisRef.timeoutToUpdate = setTimeout(() => {
                    //                 this.thisRef.props.onEndEditing();
                    //             }, 1000);
                    //         }
                    //     }}
                    //     onEndEditing={() => {
                    //         this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                    //         this.forceUpdate();
                    //     }}
                    // />
                    <Form.Control
                        type="number"
                        style={styles.fieldText}
                        value={this.props.item.value}
                        onChangeText={(text) => {
                            this.props.item.value = text.target.value;
                            if (text.target.value === '') {
                                this.props.item.value = null;
                            }
                            this.forceUpdate();

                            // 2023-11-06 (JJ): Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                            // If onChangeText is called without focus, the field has been updated externally (i.e. S Pen) and needs to write to the DB by calling onEndEditing
                            // if (!this.thisRef.isFocused()) {
                            //     clearTimeout(this.thisRef.timeoutToUpdate);
                            //     this.thisRef.timeoutToUpdate = setTimeout(
                            //         () => {
                            //             this.thisRef.props.onEndEditing();
                            //         },
                            //         1000
                            //     );
                            // }
                        }}
                        onBlur={() => {
                            this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex);
                            this.forceUpdate();
                        }}
                    />
                )}

                {/* Non-editable */}
                {!this.props.editable && (
                    <div style={styles.fieldText}>
                        <p style={styles.fieldNonEditable}>{this.props.item.value}</p>
                    </div>
                )}
            </div>
        );
    }
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        fieldNonEditable: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            // backgroundColor: Colours.MIDGREY,
            color: Colours.GREY
        },
        // fieldNonEditableView : {
        //     backgroundColor: Colours.MIDGREY,
        //     marginBottom: 10,
        //     paddingTop:10,
        //     paddingBottom:10,
        //     paddingLeft:5,

        //     height:45,
        //     borderWidth: 0.5,
        //     borderColor: brandData?.primarycolour,
        //     borderRadius: 5,
        //     elevation:5,
        // },

        // Field Specific
        fieldText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
            // borderBottomWidth: 1,
            // borderColor: brandData?.primarycolour,
            backgroundColor: Colours.LIGHTGREY,
            backgroundColor: Colours.WHITE,
            marginBottom: 10,
            paddingTop: 10, //for iOS
            paddingBottom: 10, //for iOS
            paddingLeft: 5, //for iOS

            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            borderRadius: 5,
            elevation: 5,
            height: 45
        }
    });
};
