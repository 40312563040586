import React, { Component } from 'react';
import Stylesheet from 'reactjs-stylesheet';
import DashedLine from '../../../Component/DashedLine';

export default class CulverdocsSeparator extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        return this.props.visible !== prevProps.visible;
    }

    render() {
        const styles = getStyle(this.props.brandData);
        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption} -- Colour: ${this.props.item.stylecolour}`);
        if (!this.props.visible) {
            return null;
        }

        var headingcustomstyle = [];
        if (this.props.item.stylewidth) {
            var perc = (this.props.item.stylewidth / 12) * 95;
            headingcustomstyle.push({ width: `${perc}%` });
        }

        if (this.props.item.stylepadding) {
            headingcustomstyle.push({
                paddingBottom: parseInt(this.props.item.stylepadding)
            });
        }

        var dashLength;
        var dashGap;
        var dashStyle;
        var dashColor = this.props.item.stylecolour ? this.props.item.stylecolour : this.props.brandData?.primarycolour;

        switch (this.props.item.styletype) {
            case 'dashed':
                dashLength = 10;
                dashGap = 8;
                break;

            case 'dotted':
                dashLength = parseInt(this.props.item.styleheight);
                dashGap = 4;
                dashStyle = { borderRadius: 5 };
                break;

            default: //Solid
                dashLength = 10;
                dashGap = 0;
                break;
        }

        return (
            <div style={styles.separatorView}>
                <DashedLine
                    //dashLength={300}
                    dashThickness={parseInt(this.props.item.styleheight)}
                    dashGap={dashGap}
                    dashColor={dashColor}
                    dashStyle={this.props.item.styletype}
                />
            </div>
        );
    }
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        separatorView: {
            justifyContent: 'center',
            alignSelf: 'center',
            paddingRight: 5
        }
    });
};
