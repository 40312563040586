import React, { memo, useEffect, useState } from 'react';
import Font from '../../../Constant/font';
import Size from '../../../Constant/size';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { translate } from '../../../Common/CulverdocsTranslate';
import FlatList from 'flatlist-react';

const FilterModal = ({ navigation, visible, hideDialog, applyFilter, typeData, formFilterData, length, titleFilter, selectedTypesFilter, selectedFormsFilter, brandData, showFilterModal }) => {
    const styles = getStyles(brandData);
    const [title, setTitle] = useState(titleFilter ? titleFilter : '');
    const [selectedTypes, setSelectedTypes] = useState(selectedTypesFilter ? selectedTypesFilter : []);
    const [selectedForms, setSelectedForms] = useState(selectedFormsFilter ? selectedFormsFilter : []);
    return (
        // <Modal
        //     centered
        //     show={showFilterModal}
        // >
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                width: '100%',
                //height: '100%',
                //minHeight: '60%',
                alignSelf: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: Colours.MODALBG
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    width: '100%',
                    // height: 'auto',
                    maxWidth: 600,
                    maxHeight: length,
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: brandData?.primarycolour,
                    backgroundColor: Colours.WHITE
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: 50,
                            // alignItems: 'center',
                            alignContent: 'center',
                            borderTopRightRadius: 5,
                            borderTopLeftRadius: 5,
                            backgroundColor: brandData?.primarycolour,
                            borderBottomColor: brandData?.primarycolour,
                            borderBottomWidth: 2,
                            paddingTop: 10
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1
                            }}
                        >
                            <p style={{ ...styles.renderOptionModalHeaderText, ...{ fontSize: Font.size.large, marginLeft: 10 } }}>{translate('Filter')}</p>
                        </div>

                        <div>
                            <div
                                style={styles.backIconStyle}
                                hitslop={{
                                    left: Size.CountScale(10),
                                    right: Size.CountScale(10),
                                    bottom: Size.CountScale(10),
                                    top: Size.CountScale(10)
                                }}
                                onClick={() => {
                                    setTitle('');
                                    setSelectedForms([]);
                                    setSelectedTypes([]);
                                    applyFilter('', [], []);
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={['fad', 'trash-alt']}
                                    style={{
                                        width: 20,
                                        height: 20,
                                        alignSelf: 'flex-end',
                                        cursor: 'pointer'
                                    }}
                                    color={Colours.WHITE}
                                />
                            </div>
                        </div>
                    </div>

                    {/* // 2023-07-09 (JJ): Implementing p Search on Titles through filter modal and updating filter modal to use ScrollView */}
                    <div style={styles.filterHeaderText}>
                        <p style={styles.renderOptionModalHeaderText}>
                            <FontAwesomeIcon
                                icon={['fad', 'input-text']}
                                style={{ width: 15, height: 15, marginRight: 10 }}
                                color={Colours.WHITE}
                            />
                            {translate('Title Search')}
                        </p>
                    </div>
                    <Form.Control
                        style={{
                            border: '1px solid' + brandData.primarycolour,
                            width: '95%',
                            margin: 5,

                            alignSelf: 'center'
                        }}
                        placeholder={translate('search')}
                        onChange={(e) => {
                            setTitle(e.target.value);
                        }}
                    />
                    <div style={styles.filterHeaderText}>
                        <p style={styles.renderOptionModalHeaderText}>
                            <FontAwesomeIcon
                                icon={['fad', 'object-group']}
                                style={{ width: 15, height: 15, marginRight: 10 }}
                                color={Colours.WHITE}
                            />
                            {translate('Types')}
                        </p>
                    </div>
                    {typeData?.map((item, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => {
                                    setSelectedTypes((currData) => {
                                        const index = currData.indexOf(item);
                                        if (index === -1) {
                                            return [...currData, item];
                                        } else {
                                            return currData.filter((type) => type !== item);
                                        }
                                    });
                                }}
                                style={{
                                    display: 'flex',
                                    marginLeft: 5,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: 10
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 0.1,
                                        alignItems: 'center'
                                    }}
                                >
                                    {selectedTypes.includes(item) ? (
                                        <FontAwesomeIcon
                                            icon={['fad', 'check-circle']}
                                            style={{
                                                width: 20,
                                                height: 20,
                                                cursor: 'pointer'
                                            }}
                                            color={brandData?.primarycolour}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={['fal', 'circle']}
                                            style={{
                                                width: 20,
                                                height: 20,
                                                cursor: 'pointer'
                                            }}
                                            color={brandData?.primarycolour}
                                        />
                                    )}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 1.5
                                    }}
                                >
                                    <p style={styles.renderOptionModalListFilterTypeText}>{item}</p>
                                </div>
                            </div>
                        );
                    })}

                    <div style={styles.filterHeaderText}>
                        <p style={styles.renderOptionModalHeaderText}>
                            <FontAwesomeIcon
                                icon={['fad', 'ballot-check']}
                                style={{ width: 15, height: 15, marginRight: 10 }}
                                color={Colours.WHITE}
                            />
                            {translate('Forms')}
                        </p>
                    </div>
                    <div style={{ height: '40%', margin: 0 }}>
                        {/* scroll view here */}
                        {formFilterData &&
                            formFilterData?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            setSelectedForms((currData) => {
                                                const index = currData.indexOf(item?.formid);
                                                if (index === -1) {
                                                    return [...currData, item?.formid];
                                                } else {
                                                    return currData.filter((type) => type !== item?.formid);
                                                }
                                            });
                                        }}
                                        style={{
                                            display: 'flex',
                                            marginLeft: 5,
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 10
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flex: 0.1,
                                                alignItems: 'center'
                                            }}
                                        >
                                            {selectedForms.includes(item?.formid) ? (
                                                <FontAwesomeIcon
                                                    icon={['fad', 'check-circle']}
                                                    style={{ height: 20, width: 20, cursor: 'pointer' }}
                                                    color={brandData?.primarycolour}
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={['fal', 'circle']}
                                                    style={{ height: 20, width: 20, cursor: 'pointer' }}
                                                    color={brandData?.primarycolour}
                                                />
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flex: 1.5,
                                                alignItems: 'center'
                                            }}
                                        >
                                            <p style={styles.renderOptionModalListText}>{item.name}</p>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>

                    <div
                        onClick={() => {
                            applyFilter(title, selectedTypes, selectedForms);
                            setTitle('');
                        }}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            height: 50,
                            flexDirection: 'row',
                            alignContent: 'center',
                            justifyContent: 'center',

                            backgroundColor: brandData?.primarycolour,
                            borderBottomLeftRadius: 5,
                            borderBottomRightRadius: 5,
                            paddingTop: 10
                        }}
                    >
                        <div>
                            <FontAwesomeIcon
                                icon={['fad', 'filter']}
                                style={{
                                    width: 15,
                                    height: 15,
                                    marginRight: 5,
                                    marginLeft: 5
                                }}
                                color={Colours.WHITE}
                            />
                        </div>
                        <div>
                            <p style={styles.renderOptionModalButtonText}>{translate('Apply')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // </Modal>
    );
};
export default FilterModal;
const getStyles = (brandData) => {
    return Stylesheet.create({
        container: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1
        },
        contentContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginVertical: Size.sectionMargin,
            marginRight: 15,
            marginLeft: 15,
            flex: 1,
            padding: 10
        },

        renderOptionModalHeaderText: {
            cursor: 'default',
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.WHITE,
            margin: 0
        },
        renderOptionModalClearText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.WHITE,
            textAlign: 'right',
            marginRight: 10
        },
        renderOptionModalSearchText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            width: '100%',
            height: 40,
            paddingLeft: 10
        },
        renderOptionModalListText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK,
            margin: 0
        },
        renderOptionModalButtonText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.large,
            color: Colours.WHITE
        },
        renderOptionModalListFilterTypeText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK,
            textTransform: 'capitalize',
            margin: 0
        },
        renderRecoveryModalOptionText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: brandData?.primarycolour,
            textAlign: 'center'
        },
        filterHeaderText: {
            display: 'flex',
            borderLeftWidth: 3,
            borderColor: brandData?.primarycolour,
            backgroundColor: brandData?.primarycolour,
            color: Colours.WHITE,
            borderRadius: 5,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: 5,
            marginRight: Size.baseMargin,
            marginLeft: Size.baseMargin,
            marginRight: 10,
            marginLeft: 10,
            marginTop: 10,
            padding: 10
        },
        // 2023-07-09 (JJ): Implementing p Search on Titles through filter modal and updating filter modal to use ScrollView
        fieldText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
            backgroundColor: Colours.WHITE,
            margin: 10,
            borderWidth: 0.5,
            borderColor: brandData?.primarycolour,
            borderRadius: 5,
            elevation: 5
        },
        backIconStyle: {
            marginRight: 10,
            marginLeft: 10
        }
    });
};
