import React, { Component } from 'react';
import Font from '../../../Constant/font';
import Colours from '../../../Constant/colour';
import { translate } from '../../../Common/CulverdocsTranslate';
import Stylesheet from 'reactjs-stylesheet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
class CulverdocsFieldCaption extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        // 2023-07-11 (JJ): Fix for Smart Lock - set field to non-editable and update caption to locked icon as per non-editable fields
        return this.props?.caption !== prevProps.caption || this.props?.badge !== prevProps.badge || this.props?.hasValue !== prevProps.hasValue || this.props?.editable !== prevProps.editable || this.props.theme !== prevProps.theme; // 2024-04-23 (RB): this.props.theme !== prevProps.theme for updating the field whenever mode switch
    }

    render() {
        const styles = getStyle(this.props?.brandData); // 2024-04-22 (RB): Dark Theme Implementation
        // console.log(`render() - caption - ${this.props?.caption}`);

        // 2023-06-25 (JJ): FieldCaption support icon prop when a static icon is required for calculation field
        if (this.props?.icon) {
            // We have provided a static icon - mainly for calculation
            var icon = this.props?.icon;
        } else {
            // No icon provided, calculate based on editable, value & mandatory.
            if (!this.props?.editable) {
                var icon = { name: 'lock', colour: Colours.GREY };
            } else if (this.props?.hasValue) {
                var icon = { name: 'circle-check', colour: Colours.GREEN };
            } else {
                if (this.props?.mandatory && !this.props?.hasValue) {
                    var icon = {
                        name: 'circle-exclamation',
                        colour: Colours.RED
                    };
                }
            }
        }
        let stylesData = [
            styles.captionBadge,
            {
                backgroundColor: this.props?.badgeColour ? this.props?.badgeColour : lightenDarkenColor(this.props?.brandData?.primarycolour, 50)
            }
        ];
        return (
            // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
            <div>
                <div
                    style={{
                        cursor: 'default',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: 5
                    }}
                >
                    {/* { this.props?.mandatory && !this.props?.hasValue && this.props?.editable && <FontAwesomeIcon icon={['fad', 'circle-exclamation']} size={11} color={Colours.RED} style={styles.mandatoryFontAwesome}/> } */}
                    {/* { !this.props?.editable && !this.props?.hasValue && <FontAwesomeIcon icon={['fad', 'lock']} size={11} color={Colours.GREY} style={styles.mandatoryFontAwesome}/> } */}
                    {/* { this.props?.hasValue && <FontAwesomeIcon icon={['fad', 'circle-check']} size={11} color={Colours.GREEN} style={styles.mandatoryFontAwesome}/> } */}
                    {icon && (
                        <FontAwesomeIcon
                            icon={['fad', icon.name]}
                            // size={11}
                            color={icon.colour}
                            style={styles.mandatoryFontAwesome}
                        />
                    )}

                    <p style={styles.captionText}>{translate(this.props?.caption)}</p>

                    {this.props?.badge && (
                        <p
                            style={{
                                ...styles.captionBadge,
                                ...{
                                    backgroundColor: this.props?.badgeColour ? this.props?.badgeColour : lightenDarkenColor(this.props?.brandData?.primarycolour, 50)
                                }
                            }}
                            // style={stylesData}
                        >
                            {this.props?.badge}
                        </p>
                    )}
                </div>
                {this.props?.userGuidance && (
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row'
                        }}
                    >
                        <p style={styles.guidanceText}>{this.props?.userGuidance}</p>
                    </div>
                )}
            </div>
        );
    }
}
export default CulverdocsFieldCaption; // 2024-04-22 (RB): Dark Theme Implementation
const getStyle = (brandData) => {
    return Stylesheet.create({
        mandatoryFontAwesome: {
            width: 13,
            height: 13,
            alignContent: 'center',
            alignSelf: 'auto',
            alignItems: 'flex-start',
            top: 5,
            marginRight: 5,
            marginLeft: 0
        },
        captionText: {
            fontFamily: Font.family.bold,
            fontSize: Font.size.medium,
            color: Colours.TEXTCOLOUR, // 2024-04-22 (RB): Dark Theme Implementation
            margin: 0,
            paddingRight: 5 // 2023-08-02 (JJ): Applying paddingRight:5 to FieldCaption as long captions were over the field width
        },
        captionBadge: {
            display: 'flex',
            fontFamily: Font.family.light,
            fontSize: Font.size.small,
            color: Colours.WHITE,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: lightenDarkenColor(brandData?.primarycolour, 50),
            borderRadius: 5,
            bottom: 0,
            paddingLeft: 5,
            paddingRight: 5,
            height: 17,
            maxWidth: 150,
            margin: 0,
            marginLeft: 10 // 2022-10-26 (JJ): Set maxWidth on fieldcaptions to limit content length (postcode changes)
        },
        guidanceText: {
            // flex:1,
            // flexDirection:'column',
            fontFamily: Font.family.light,
            fontSize: Font.size.small,
            color: Colours.GREY,
            paddingBottom: 3,
            top: -5
        },
        guidanceTextIcon: {
            alignContent: 'center',
            alignSelf: 'auto',
            alignItems: 'flex-start',
            top: 3,
            marginRight: 3,
            marginLeft: 0
        }
    });
};
