import { useState } from 'react';
import Colours from '../Constant/colour';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom'; 
import { useSelector } from 'react-redux';
import { getFriendlyLoadedLanguage, translate } from '../Common/CulverdocsTranslate';
import Modal from 'react-bootstrap/Modal';
import Size from '../Constant/size';
import Font from '../Constant/font';
import { CulverdocsButton } from './CulverdocsButton';
import { auth, database } from '../firebase';
import { useEffect } from 'react';
import { removeAllLocalStorage } from '../Helper/localStorage';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';

import { lightenDarkenColor } from '../Helper/lightenDarkenColor';
import { releaseRTDeviceID } from '../Firebase/releaseRTDeviceID';

import './Styles/CulverdocsDrawer.scss';

export const CulverdocsDrawer = ({ show, handleClose, brandData, userProfile, userid, collapsed }) => {
    const drawerStatus = useSelector((state) => state.drawerStatus.key);
    let navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const [showSignOutModal, setShowSignOutModal] = useState(false);
    const [signouttextinprogress, setSignouttextinprogress] = useState(translate('Logging Out...'));
    const [signouttext, setSignoutText] = useState(translate('Logout'));

    //const dispatch = useDispatch();
    const handleLogouthide = () => {
        setShowSignOutModal(false);
    };
    const signOut = async () => {
        // v1.3.0 - We need to release the ID before signing out.
        setSignoutText(signouttextinprogress);

        await releaseRTDeviceID();

        removeAllLocalStorage();
        setShowSignOutModal(false);
        auth.signOut().then((res) => navigate('/')); //Close the modal after clicking yes.
    };
    function renderSignOutModal() {
        // 2023-03-02 (JJ): Reworked discard modal and sign out modal to adjust height based on content to support ML captions which are longer
        return (
            <Modal
                centered
                show={showSignOutModal}
            >
                <div
                    className="modal-wrapper"
                    behavior="height"
                    enabled
                    style={{ evelation: 4 }}
                >
                    <div style={{ width: '100%', height: '100%', borderRadius: 10, borderWidth: 1, borderColor: brandData.primarycolour, backgroundColor: Colours.WHITE }}>
                        <div style={{ height: 50, display: 'flex', flexDirection: 'row', alignContent: 'center', paddingTop: 5, borderTopRightRadius: 5, borderTopLeftRadius: 5, backgroundColor: brandData.primarycolour, borderBottomColor: brandData.primarycolour, borderBottomWidth: 2 }}>
                            <div
                                hitslop={{ left: Size.CountScale(10), right: Size.CountScale(10), bottom: Size.CountScale(10), top: Size.CountScale(10) }}
                                onClick={() => handleLogouthide()}
                            >
                                <FontAwesomeIcon
                                    icon={['fad', 'circle-chevron-left']}
                                    color={Colours.WHITE}
                                    style={{ marginLeft: 10, width: 30, height: 30, cursor: 'pointer' }}
                                />
                            </div>
                            <p
                                style={{
                                    fontFamily: Font.family.bold,
                                    fontSize: Font.size.large,
                                    color: Colours.WHITE,
                                    marginLeft: 5
                                }}
                                numberoflines={1}
                            >
                                {translate('Logout')}
                            </p>
                        </div>

                        <div style={{ padding: 10, justifyContent: 'center', alignItems: 'center' }}>
                            <p style={{ color: Colours.BLACK, fontSize: Font.size.medium, textAlign: 'center', fontFamily: Font.family.regular, padding: 7 }}>{translate('You will no longer be able to access completed or new forms. Are you sure?')}</p>
                            {/* Yes Button */}
                            <CulverdocsButton
                                title={signouttext}
                                icon={'sign-out'}
                                extraStyle={{
                                    backgroundColor: Colours.RED,
                                    borderColor: Colours.RED
                                }}
                                marginHorizontal={10}
                                onClick={() => {
                                    if (signouttext !== signouttextinprogress) {
                                        signOut();
                                    }
                                }}
                            />

                            {/* No Button */}
                            <CulverdocsButton
                                title={translate('No')}
                                icon={'times'}
                                extraStyle={{
                                    backgroundColor: Colours.GREY,
                                    borderColor: Colours.GREY
                                }}
                                marginHorizontal={10}
                                onClick={() => {
                                    handleLogouthide();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
    useEffect(() => {
        console.log('userData', userProfile);
    }, []);

    return (
        <div style={{ height: Size.screenHeight }}>
            <Sidebar
                collapsed={drawerStatus}
                collapsedWidth={0}
                backgroundColor={brandData.primarycolour}
                style={{ paddingLeft: 0, paddingRight: 0, marginLeft: 20, marginRight: 0, marginTop: 30, border: '5px solid ' + brandData.primarycolour, borderRadius: '5px' }}
            >
                <div
                    style={{
                        backgroundColor: brandData.primarycolour,
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        alignItems: 'center',
                        paddingRight: 10,
                        paddingLeft: 10,
                        marginBottom: 10
                    }}
                >
                    {brandData.logo ? (
                        <Image
                            src={`data:image/png;base64,${brandData.logo}`}
                            style={{
                                resizeMode: 'contain',
                                width: 50,
                                height: 50,
                                margin: 20,
                                marginBottom: 0
                            }}
                        />
                    ) : (
                        <Image
                            src={require('../Assets/Images/logo_whitebg.png')}
                            style={{
                                resizeMode: 'contain',
                                width: 50,
                                height: 50,
                                margin: 20,
                                marginBottom: 0
                            }}
                        />
                    )}

                    <p
                        style={{
                            color: Colours.WHITE,
                            fontSize: 18
                        }}
                    >
                        {/* TODO */}
                        {userProfile?.name}
                    </p>
                    <p
                        style={{
                            color: Colours.WHITE,
                            fontSize: 13
                        }}
                    >
                        {/* TODO */}
                        {userProfile?.accountname}
                    </p>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <FontAwesomeIcon
                            icon={['fa-duotone', 'fa-user']}
                            color={'white'}
                            style={{ marginRight: 5, width: 15, height: 15 }}
                        />
                        <p
                            style={{
                                color: Colours.WHITE,
                                fontSize: 10,
                                margin: 0
                            }}
                        >
                            {userid}
                        </p>
                    </div>
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            alignSelf: 'center',
                            marginTop: 10
                        }}
                    >
                        <FontAwesomeIcon
                            icon={['fa-duotone', 'fa-language']}
                            style={{ width: 20, height: 20, marginRight: 10 }}
                            color={'white'}
                        />

                        <p
                            style={{
                                color: Colours.WHITE,
                                fontSize: 15,
                                marginBottom: 4
                            }}
                        >
                            {getFriendlyLoadedLanguage()}
                        </p>
                    </div>
                </div>
                <Menu
                    menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            // only apply styles on first level elements of the tree
                            if (level === 0)
                                return {
                                    color: active ? brandData?.primarycolour : Colours.WHITE,
                                    backgroundColor: active ? Colours.WHITE : undefined,
                                    borderStartStartRadius: 30,
                                    borderEndStartRadius: 30,
                                    marginLeft: 20,

                                    '&:hover': {
                                        color: Colours.WHITE,
                                        backgroundColor: lightenDarkenColor(brandData?.primarycolour, 50) // Adjust the hover background color as needed
                                    },
                                    '&:active': {
                                        color: brandData.primarycolour,
                                        backgroundColor: Colours.WHITE // Adjust the active background color as needed
                                    }
                                };
                        }
                    }}
                >
                    <MenuItem
                        active={currentPath == '/home' && !showSignOutModal ? true : false}
                        component={<Link to="/home" />}
                        icon={
                            <FontAwesomeIcon
                                icon={['fad', 'house-chimney-user']}
                                // color={brandData.primarycolour}
                                style={{
                                    marginRight: 5,
                                    marginLeft: 2,
                                    height: 25,
                                    width: 25
                                }}
                            />
                        }
                    >
                        {translate('Home')}
                    </MenuItem>

                    <MenuItem
                        active={currentPath == '/form' && !showSignOutModal ? true : false}
                        component={<Link to="/form" />}
                        icon={
                            <FontAwesomeIcon
                                icon={['fad', 'file-signature']}
                                style={{
                                    marginRight: 5,
                                    marginLeft: 2,
                                    height: 25,
                                    width: 25
                                }}
                            />
                        }
                    >
                        {translate('Forms')}
                    </MenuItem>

                    <MenuItem
                        active={currentPath == '/documents' && !showSignOutModal ? true : false}
                        component={<Link to="/documents" />}
                        icon={
                            <FontAwesomeIcon
                                icon={['fad', 'folder-open']}
                                style={{
                                    marginRight: 5,
                                    marginLeft: 2,
                                    height: 25,
                                    width: 25
                                }}
                            />
                        }
                    >
                        {translate('Documents')}
                    </MenuItem>
                    <MenuItem
                        active={showSignOutModal ? true : false}
                        //  component={<Link to="/documents" />}
                        onClick={() => setShowSignOutModal(true)}
                        icon={
                            <FontAwesomeIcon
                                icon={['fa-duotone', 'person-to-door']}
                                style={{
                                    marginRight: 5,
                                    marginLeft: 2,
                                    height: 25,
                                    width: 25
                                }}
                            />
                        }
                    >
                        {translate('Logout')}
                    </MenuItem>
                </Menu>
            </Sidebar>
            {renderSignOutModal()}
        </div>
    );
};
