const Font = {
    size: {
        xsmall: 8,
        small: 11,
        medium: 13,
        large: 16,
        xlarge: 18,
        xxlarge: 28,

        bold: '500'
    },
    family: {
        light: 'OpenSans-Light',
        regular: 'OpenSans-Regular',
        bold: 'OpenSans-Bold'
    },
    fontBold: 'bold'
};

export default Font;
