import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colours from '../Constant/colour';
import Font from '../Constant/font';

class CulverdocsTile extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        // 2023-11-08 (JJ): Improved support for refreshing Task Tiles on change of this.props and if the filters are changed the tile is reloaded.
        // return this.props !== prevProps || this.state !== prevState;
        // 2024-04-14 (JJ): Fix for NewFormScreen re-rendering all tiles when a form is updated. Removed componentShouldUpdate for fields and using firebase .once to retrieve latest data.
        return (
            // this.props.this.props.brandData !== prevProps.this.props.brandData ||
            // this.props.name !== prevProps.name ||
            // this.props.processing !== prevProps.processing ||
            this.props.caption !== prevProps.caption ||
            // this.props.colour !== prevProps.colour ||
            // this.props.countBadge !== prevProps.countBadge ||
            // this.props.iconType !== prevProps.iconType ||
            // this.props.iconSize !== prevProps.iconSize ||
            // this.props.iconName !== prevProps.iconName ||
            this.state !== prevState
        );
    }

    render() {
        return (
            <div
                key={this.props.index}
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    cursor: 'pointer',
                    backgroundColor: Colours.TILE,
                    borderRadius: 5,
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 5,
                    marginBottom: 5,
                    minHeight: 45,
                    border: '1px solid' + this.props.brandData.primarycolour,
                    borderRadius: 4,
                    elevation: 3,
                    ...this.props.buttonStyle
                }}
                onClick={this.props.onPress}
                disabled={this.props.disabled}
            >
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: 60,
                            borderTopLeftRadius: 3,
                            borderBottomLeftRadius: 3,
                            backgroundColor: 'transparent',

                            borderLeftColor: this.props.colour,
                            borderLeftWidth: '5px solid' + this.props.brandData.primarycolour,

                            backgroundColor: this.props.backgroundColour
                        }}
                    >
                        <div>
                            {/* // 2024-01-10 (JJ): CulverdocsButton - added processing prop to show ActivityIndicator */}
                            {/* {!this.props.processing ? ( */}
                            <FontAwesomeIcon
                                icon={[this.props.iconType, this.props.iconName]}
                                // size={this.props.iconSize}
                                style={{
                                    height: this.props.iconSize,
                                    width: this.props.iconSize
                                }}
                                color={this.props.colour}
                            />
                            {/* ) : (
                                    <ActivityIndicator
                                        size={"large"}
                                        color={
                                            this.props.this.props.brandData?.primarycolour
                                        }  
                                    />     
                                )} */}
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignContent: 'center',
                            marginLeft: 10,

                            ...this.props.containerRightStyle
                        }}
                    >
                        <p
                            style={{
                                fontFamily: Font.family.regular,
                                fontSize: Font.size.medium,
                                padding: 5,
                                color: Colours.TEXTCOLOUR,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: '250px',
                                margin: 0,
                                ...this.props.textStyle
                            }}
                        >
                            {this.props.caption}
                        </p>
                        {this.props.countBadge && (
                            <p
                                style={{
                                    textAlign: 'center',
                                    color: 'white',
                                    fontFamily: Font.family.regular,
                                    fontSize: 15,
                                    backgroundColor: this.props.brandData?.primarycolour,
                                    border: '1px solid' + this.props.brandData.primarycolour,
                                    minWidth: 20,
                                    height: 20,
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    borderRadius: 1000,
                                    margin: 0
                                }}
                                numberoflines={1}
                            >
                                {this.props.countBadge}
                            </p>
                        )}
                    </div>
                    {this.props.showRightIcon && (
                        <FontAwesomeIcon
                            icon={['fa-solid', 'fa-caret-down']}
                            size={this.props.iconSize}
                            color={Colours.GREY}
                            style={{ marginRight: 20, marginTop: 5 }}
                        />
                    )}
                </div>
            </div>
        );
    }
}
//<i class="fa-solid fa-caret-down"></i>
CulverdocsTile.defaultProps = {
    key: 0,
    backgroundColour: 'lightgrey',
    iconType: 'fad',
    iconName: 'file-lines',
    iconSize: 30,
    showRightIcon: false,
    fields: [],
    categoryForm: []
};

export default CulverdocsTile;
