import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Colours from '../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const CulverdocsScreenHeader2 = ({ LeftIconType, LeftIcon, LeftIconSize, LeftIconColour, title, RightIconType, RightIcon, RightIconsize, RightIconColour, ScreenName, onLeftIconClick, onRightIconClick, brandData }) => {
    return (
        <Container
            fluid
            style={{
                background: brandData.primarycolour,
                alignItems: 'center'
            }}
            className="custom-container"
        >
            <Row
                style={{
                    background: brandData.primarycolour,
                    display: 'flex',
                    alignItems: 'center',
                    height: 60

                    // borderTopRightRadius: 20,
                    // borderTopLeftRadius: 20
                }}
                className="custom-container"
            >
                <Col
                    md={4}
                    sm={4}
                    xs={4}
                    lg={4}
                    className="pointer"
                >
                    <FontAwesomeIcon
                        icon={[LeftIconType ? LeftIconType : 'fad', LeftIcon]}
                        color={Colours.WHITE}
                        style={{ height: 30, width: 30 }}
                        onClick={onLeftIconClick}
                    />
                </Col>
                <Col
                    md={4}
                    sm={4}
                    xs={4}
                    lg={4}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <p
                        style={{
                            fontSize: 20,
                            color: 'white',
                            textAlign: 'center',
                            cursor: 'default',
                            margin: 0
                        }}
                        className="max-lines"
                    >
                        {title}
                    </p>
                </Col>
                {RightIcon && (
                    <Col
                        className="pointer"
                        md={4}
                        sm={4}
                        xs={4}
                        lg={4}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <FontAwesomeIcon
                            icon={[RightIconType ? RightIconType : 'fad', RightIcon]}
                            color={Colours.WHITE}
                            style={{ height: 30, width: 30 }}
                            onClick={onRightIconClick}
                        />
                    </Col>
                )}
            </Row>
        </Container>
    );
};
