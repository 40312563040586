import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HomeScreen from '../Screens/Home/HomeScreen';
import Login from '../Screens/Auth/Login';
import NewFormScreen from '../Screens/Form/NewFormScreen';
import CreateFormScreen from '../Screens/CreateFormScreen/CreateFormScreen';
import Documents from '../Screens/Document/Documents';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, database } from '../firebase';
import { getLocalStorage } from '../Helper/localStorage';
import { CulverdocsDrawer } from '../Component/CulverdocsDrawer';
import { CulverdocsScreenHeader } from '../Component/CulverdocsScreenHeader';
import Colours from '../Constant/colour';
import { child, get, ref } from 'firebase/database';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setDrawerAction } from '../Redux/actions/setDrawerAction';
import { useDispatch } from 'react-redux';
import NotFound from '../Screens/Auth/NotFound';
import { startFirebaseListeners } from '../Firebase/FirebaseListenerControl';

const RouteScreen = () => {
    const dispatch = useDispatch();
    const [uidState, setUidState] = useState('');
    const [userData, setUserData] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    const [brandData, setBrandData] = useState();
    const [userProfile, setUserProfile] = useState({});
    const [tempBrand, setTempBrand] = useState({});
    const [tempProfile, setTempProfile] = useState({});
    const [showLogout, setShowLogout] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const formRef = useRef();

    useLayoutEffect(() => {
        let sub = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    setUidState(user.uid);
                    let brandData = getLocalStorage('brandData');
                    let userProfiles = getLocalStorage('userProfiles');
                    setUserData(user);
                    setBrandData(brandData);
                    setUserProfile(userProfiles);
                    let profile = await get(child(ref(database), `/users/${user.uid}/profile`)).then((snapshot) => snapshot.val());
                    let brand = await get(child(ref(database), `/accounts/${profile.accountid}/branding/`)).then((snapshot) => snapshot.val());
                    setTempProfile(profile);
                    setTempBrand(brand);
                    startFirebaseListeners();
                } catch (error) {
                    console.log(error);
                }
            } else {
                setUserData(null);
                setTempBrand({});
                setTempProfile({});
                setUidState('');
                console.log('user is logged out');
            }
        });
        return sub;
    }, []);

    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setCollapsed(true);
            dispatch(setDrawerAction(true));
        } else {
            setCollapsed(false);
            dispatch(setDrawerAction(false));
        }
    };

    useEffect(() => {
        handleResize(); // Check initial screen size
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={<Login />}
                />
                <Route
                    path="/login"
                    element={<Login />}
                />
                <Route
                    path="/kiosk"
                    element={<CreateFormScreen />}
                />
            </Routes>
            {userData && tempBrand.primarycolour && !tempProfile.kioskformid && (
                <div style={{ display: 'flex', flex: 1, backgroundColor: '#e0e0e0', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flex: 0.2 }}>
                        {
                            <CulverdocsDrawer
                                collapsed={collapsed}
                                brandData={brandData ? brandData : tempBrand}
                                userProfile={userProfile}
                                userid={uidState}
                            />
                        }
                    </div>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <Routes>
                            <Route
                                exact
                                path="/home"
                                element={<HomeScreen />}
                            />
                            <Route
                                path="/form"
                                element={<NewFormScreen />}
                            />
                            <Route
                                path="/createform"
                                element={<CreateFormScreen ref={formRef} />}
                            />
                            <Route
                                path="/documents"
                                element={<Documents />}
                            />
                        </Routes>
                    </div>
                </div>
            )}
            <Routes>
                <Route
                    path="*"
                    element={<NotFound />}
                />
            </Routes>
        </Router>
    );
};

export default RouteScreen;
