import React, { Component } from 'react';
// import { p, div, div, AppState, TextInput, Modal, KeyboardAwareScrollView, Dimensions, Alert, StyleSheet } from 'react-native';
// import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
// import ReactNativePinView from 'react-native-pin-view';
// import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
// import { lightenDarkenColor } from '@src/Helper/lightenDarkenColor';
// import { Colours, Font, Size } from '@src/Constant';
// import { translate } from '@src/Helper/CulverdocsTranslate';
import Size from '../../../Constant/size';
import PinInput from 'react-pin-input';

import Font from '../../../Constant/font';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { translate } from '../../../Common/CulverdocsTranslate';
import Pincode from '../../../Component/Pincode';

export default class CulverdocsPINCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            renderPINCodeModal: false,
            pinCode: ''
        };
        this.pinView = React.createRef();
        this.handleDigitClick = this.handleDigitClick.bind(this);
        this.handleBackspaceClick = this.handleBackspaceClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.handleCheckClick = this.handleCheckClick.bind(this);
    }
    shouldComponentUpdate(prevProps, prevState) {
        // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
        return this.state !== prevState || this.props.visible !== prevProps.visible || this.props.itemValue !== prevProps.itemValue || this.props.caption !== prevProps.caption;
    }
    handleDigitClick(digit) {
        const newPin = this.state.pinCode + digit;
        if (newPin.length > this.props.item.pincodelength) {
            return;
        }
        this.setState({ pinCode: newPin }, () => {
            // Use the ref to trigger the onChange externally
            this.pinView.current.triggerChange(newPin);
        });
    }

    handleBackspaceClick() {
        const newPin = this.state.pinCode.slice(0, -1);
        this.setState({ pinCode: newPin }, () => {
            this.pinView.current.triggerChange(newPin);
        });
    }

    handleClearClick() {
        this.setState({ pinCode: '' }, () => {
            this.pinView.current.triggerChange('');
        });
    }

    handleCheckClick() {
        console.log('Submit PIN:', this.state.pinCode);
        this.pinView.current.triggerComplete(this.state.pinCode);
        // Implement your submit logic here
    }

    render() {
        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption} - ${this.props.item.value}`);
        const styles = getStyle(this.props.brandData);

        if (!this.props.visible) {
            return null;
        }

        // const pinView = {};

        return (
            <div>
                <CulverdocsFieldCaption
                    caption={this.props.item.caption}
                    userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                    mandatory={this.props.item.mandatory}
                    editable={this.props.editable}
                    hasValue={this.props.item.value ? true : false}
                />

                {this.props.editable && (
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1
                            }}
                        >
                            <div
                                onClick={() => {
                                    this.setState({ renderPINCodeModal: true });
                                }}
                                style={styles.fieldContainer}
                                value={this.props.item.value}
                            >
                                <p style={styles.fieldText}>{this.props.item.value}</p>

                                <div
                                    style={{
                                        display: 'flex',
                                        marginRight: 10
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={['fad', 'grid']}
                                        color={lightenDarkenColor(this.props.brandData?.primarycolour, 50)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {!this.props.editable && (
                    <div style={styles.fieldContainer}>
                        <p style={styles.fieldNonEditableText}>{this.props.item.value}</p>
                    </div>
                )}

                <Modal
                    centered
                    show={this.state.renderPINCodeModal}
                >
                    <div
                        behavior="height"
                        enabled
                        style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: Colours.MODALBG, evelation: 4, justifyContent: 'center', alignItems: 'center' }}
                    >
                        <div style={{ height: 400, width: '100%', borderRadius: 10, borderWidth: 1, borderColor: this.props.brandData?.primarycolour, backgroundColor: 'white' }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                <div style={{ display: 'flex', height: 50, flexDirection: 'row', alignItems: 'center', borderTopRightRadius: 5, borderTopLeftRadius: 5, backgroundColor: this.props.brandData?.primarycolour, borderBottomColor: this.props.brandData?.primarycolour, borderBottomWidth: 2 }}>
                                    <FontAwesomeIcon
                                        onClick={() => this.setState({ renderPINCodeModal: false })}
                                        icon={['fad', 'circle-chevron-left']}
                                        color={Colours.WHITE}
                                        style={{ marginLeft: 10, marginRight: 10, width: 30, height: 30, cursor: 'pointer' }}
                                    />

                                    <div style={{ display: 'flex', flexDirection: 'flex', flex: 1, marginLeft: 10, alignItems: 'center' }}>
                                        <p style={styles.renderOptionModalHeaderText}>{translate(this.props.item.caption)}</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <Pincode
                                        ref={this.pinView}
                                        length={parseInt(this.props.item.pincodelength)}
                                        //secret
                                        secretDelay={100}
                                        value={this.state.pinCode}
                                        onChange={(value, index) => {
                                            console.log(value);
                                            // setCode(value);
                                            this.state.pinCode = value;
                                        }}
                                        // type="numeric"
                                        // inputMode="number"
                                        style={{ display: 'flex', flexDirection: 'row', flex: 1, padding: '10px', alignItems: 'center', justifyContent: 'center' }}
                                        inputStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, borderRadius: 50, marginLeft: 10, marginRight: 10, borderColor: lightenDarkenColor(this.props.brandData?.primarycolour, 50), color: this.props.brandData?.primarycolour, fontSize: Font.size.xlarge, height: 50, width: 50, paddingLeft: 15 }}
                                        inputFocusStyle={{ borderColor: this.props.brandData?.primarycolour }}
                                        onComplete={(value, index) => {
                                            this.renderPINCodeModalCheckPin(this.pinView);
                                        }}
                                        autoSelect={true}
                                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <div
                                        onClick={() => this.handleDigitClick('1')}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 48, width: 70, borderRadius: 5, backgroundColor: this.props.brandData.primarycolour, marginRight: 10, marginLeft: 10, marginTop: 10 }}
                                    >
                                        <p style={{ fontSize: Font.size.large, fontFamily: Font.family.bold, margin: 0, color: Colours.WHITE }}>1</p>
                                    </div>
                                    <div
                                        onClick={() => this.handleDigitClick('2')}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 48, width: 70, borderRadius: 5, backgroundColor: this.props.brandData.primarycolour, marginRight: 10, marginLeft: 10, marginTop: 10 }}
                                    >
                                        <p style={{ fontSize: Font.size.large, fontFamily: Font.family.bold, margin: 0, color: Colours.WHITE }}>2</p>
                                    </div>
                                    <div
                                        onClick={() => this.handleDigitClick('3')}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 48, width: 70, borderRadius: 5, backgroundColor: this.props.brandData.primarycolour, marginRight: 10, marginLeft: 10, marginTop: 10 }}
                                    >
                                        <p style={{ fontSize: Font.size.large, fontFamily: Font.family.bold, margin: 0, color: Colours.WHITE }}>3</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <div
                                        onClick={() => this.handleDigitClick('4')}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 48, width: 70, borderRadius: 5, backgroundColor: this.props.brandData.primarycolour, marginRight: 10, marginLeft: 10, marginTop: 10 }}
                                    >
                                        <p style={{ fontSize: Font.size.large, fontFamily: Font.family.bold, margin: 0, color: Colours.WHITE }}>4</p>
                                    </div>
                                    <div
                                        onClick={() => this.handleDigitClick('5')}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 48, width: 70, borderRadius: 5, backgroundColor: this.props.brandData.primarycolour, marginRight: 10, marginLeft: 10, marginTop: 10 }}
                                    >
                                        <p style={{ fontSize: Font.size.large, fontFamily: Font.family.bold, margin: 0, color: Colours.WHITE }}>5</p>
                                    </div>
                                    <div
                                        onClick={() => this.handleDigitClick('6')}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 48, width: 70, borderRadius: 5, backgroundColor: this.props.brandData.primarycolour, marginRight: 10, marginLeft: 10, marginTop: 10 }}
                                    >
                                        <p style={{ fontSize: Font.size.large, fontFamily: Font.family.bold, margin: 0, color: Colours.WHITE }}>6</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <div
                                        onClick={() => this.handleDigitClick('7')}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 48, width: 70, borderRadius: 5, backgroundColor: this.props.brandData.primarycolour, marginRight: 10, marginLeft: 10, marginTop: 10 }}
                                    >
                                        <p style={{ fontSize: Font.size.large, fontFamily: Font.family.bold, margin: 0, color: Colours.WHITE }}>7</p>
                                    </div>
                                    <div
                                        onClick={() => this.handleDigitClick('8')}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 48, width: 70, borderRadius: 5, backgroundColor: this.props.brandData.primarycolour, marginRight: 10, marginLeft: 10, marginTop: 10 }}
                                    >
                                        <p style={{ fontSize: Font.size.large, fontFamily: Font.family.bold, margin: 0, color: Colours.WHITE }}>8</p>
                                    </div>
                                    <div
                                        onClick={() => this.handleDigitClick('9')}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 48, width: 70, borderRadius: 5, backgroundColor: this.props.brandData.primarycolour, marginRight: 10, marginLeft: 10, marginTop: 10 }}
                                    >
                                        <p style={{ fontSize: Font.size.large, fontFamily: Font.family.bold, margin: 0, color: Colours.WHITE }}>9</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <FontAwesomeIcon
                                        onClick={() => {
                                            this.handleBackspaceClick();
                                        }}
                                        icon={['fad', 'backspace']}
                                        style={{ width: 48, height: 48, marginTop: 10, alignSelf: 'flex-start' }}
                                        color={this.props.brandData?.primarycolour}
                                    />
                                    <div
                                        onClick={() => this.handleDigitClick('0')}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 48, width: 70, borderRadius: 5, backgroundColor: this.props.brandData.primarycolour, marginRight: 30, marginLeft: 30, marginTop: 10, padding: 10 }}
                                    >
                                        <p style={{ fontSize: Font.size.large, fontFamily: Font.family.bold, margin: 0, color: Colours.WHITE }}>0</p>
                                    </div>
                                    <FontAwesomeIcon
                                        onClick={() => this.handleCheckClick()}
                                        icon={['fad', 'check-circle']}
                                        style={{ width: 48, height: 48, marginTop: 10 }}
                                        color={this.props.brandData?.primarycolour}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    renderPINCodeModalCheckPin = async (pinView) => {
        if (this.props.item.listname) {
            // Append the PIN Code Filter to the existing List Filters and pass into returnListValues with additional filter parameter.
            var listFilter = this.props.item.listfilter ? JSON.parse(JSON.stringify(this.props.item.listfilter)) : [];
            listFilter.push({
                Name: this.props.item.pincodevalidatefield,
                Type: 'text',
                Value: this.state.pinCode
            });

            this.props.returnListValues(this.props.item, this.props.pItem, this.props.rIndex, listFilter).then((listResult) => {
                // Always take the first list option, this also stops duplicate entries if its unfiltered.
                if (listResult.options && listResult.options.length > 0) {
                    listResult.options = listResult.options[0];
                    this.props.item.value = listResult.options.value;
                    this.props.item.selected = listResult.options;

                    if (this.props.item.listtofieldvalue) {
                        this.props.item.listtofieldvalue.forEach((val, index) => {
                            //val.Name = the List Field Name (e.g. Hair Colour) || val.Value = the Field ID that needs setting.
                            if (val.Name && val.Value) {
                                var nameValue = this.props.item.selected[val.Name];
                                if (nameValue) {
                                    this.props.setFieldDataFromID(val.Value, this.props.pItem, this.props.rIndex, { value: nameValue });
                                }
                            }
                        });
                    }
                    this.setState({ renderPINCodeModal: false });
                    this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex).then(() => {
                        // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                        if (this.props.pItem) {
                            this.props.forceRepeaterChildUpdate();
                        }
                    });
                } else {
                    if (this.props.item.pincodevalidatefielderrortext && this.props.item.pincodevalidatefielderrortext !== '') {
                        alert(translate('Incorrect PIN Code'), translate(this.props.item.pincodevalidatefielderrortext));
                    }
                    this.pinView.current.clearAll();
                }
            });
        } else {
            // If there is no List Validation enabled, just accept the input value.
            this.props.item.value = this.state.pinCode;
            this.setState({ renderPINCodeModal: false });
            this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex).then(() => {
                // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                if (this.props.pItem) {
                    this.props.forceRepeaterChildUpdate();
                }
            });
        }
    };
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        fieldContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK,
            backgroundColor: Colours.WHITE,
            marginBottom: 10,
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 5,
            height: 45,
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            borderRadius: 5,
            elevation: 5,
            justifyContent: 'space-between'
        },
        fieldText: {
            display: 'flex',
            flexDirection: 'column',
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BACKGROUND,
            flex: 0.9,
            margin: 0
        },
        // fieldNonEditableContainer : {
        //     backgroundColor: Colours.MIDGREY,
        //     marginBottom: 10,
        //     paddingTop:10,
        //     paddingBottom:10,
        //     paddingLeft:5,

        //     height: 45,
        //     borderWidth: 0.5,
        //     borderColor: this.props.brandData?.primarycolour,
        //     borderRadius: 5,
        //     elevation:5,
        // },
        fieldNonEditableText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.GREY,
            margin: 0
        },

        // Modal
        modalBackground: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.6)'
        },
        modalContainer: {
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            elevation: 5,
            margin: 20,
            width: '90%',
            maxWidth: 300,
            maxHeight: 400.3
        },
        renderOptionModalHeaderText: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.large,
            color: Colours.WHITE,
            margin: 0
        },
        modalHeader: {
            display: 'flex',
            height: 50,
            alignSelf: 'stretch',
            backgroundColor: brandData?.primarycolour,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row'
        },
        modalHeaderText: {
            fontFamily: Font.family.bold,
            fontSize: Font.size.medium,
            color: Colours.WHITE
        },
        modalContent: {
            // padding: 20,
            backgroundColor: Colours.WHITE,
            // maxWidth:300,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5
        }
    });
};
