import React, { Component } from 'react';
import Font from '../../../Constant/font';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';
import Size from '../../../Constant/size';

export default class CulverdocsCheckbox extends Component {
    dimensions = {}; // 2023-12-19 (JJ): NFE - Support for AutoScroll property on Choice, Checkbox & Select.

    shouldComponentUpdate(prevProps, prevState) {
        // 2023-12-28 (JJ): Adding new Designer Support to listen for form changes whilst the form is open to live-refresh + itemValue changes to support refresh.
        return this.props.visible !== prevProps.visible || this.props.itemValue !== prevProps.itemValue || this.props.caption !== prevProps.caption;
    }

    render() {
        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption}`);
        const styles = getStyle(this.props.brandData);
        if (!this.props.visible) {
            return null;
        }
        return (
            <div
                style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}
                onLayout={(event) => {
                    this.dimensions = event.nativeEvent.layout;
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'center'
                    }}
                >
                    <div
                        onClick={this.toggleCheck}
                        style={styles.fieldContainer}
                        disabled={!this.props.editable}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',

                                justifyContent: 'center'
                            }}
                        >
                            {!this.props.editable && (
                                <FontAwesomeIcon
                                    icon={['fad', 'lock']}
                                    color={Colours.MIDGREY}
                                    style={styles.checkboxMandatory}
                                />
                            )}

                            {this.props.item.mandatory && (!this.props.item.value || this.props.item.value == this.props.item.optionvalue[0]) && (
                                <FontAwesomeIcon
                                    icon={['fad', 'circle-exclamation']}
                                    color={Colours.RED}
                                    style={styles.checkboxMandatory}
                                />
                            )}

                            {(!this.props.item.value || this.props.item.value == this.props.item.optionvalue[0]) && (
                                <FontAwesomeIcon
                                    icon={['fal', 'square']}
                                    color={this.props.brandData?.primarycolour}
                                    style={{
                                        marginLeft: 20,
                                        marginRight: 20,
                                        alignSelf: 'flex-start',
                                        width: Size.icons.small,
                                        height: Size.icons.small
                                    }}
                                />
                            )}

                            {this.props.item.value == this.props.item.optionvalue[1] && (
                                <FontAwesomeIcon
                                    icon={['fad', 'square-check']}
                                    color={this.props.brandData?.primarycolour}
                                    style={{
                                        marginLeft: 20,
                                        marginRight: 20,
                                        alignSelf: 'flex-start',
                                        width: Size.icons.small,
                                        height: Size.icons.small
                                    }}
                                />
                            )}
                        </div>
                        <div style={styles.fieldText}>
                            <p style={styles.fieldText}>
                                <p style={this.props.editable ? styles.checkboxCaption : styles.checkboxCaptionNE}>{this.props.item.caption}</p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    toggleCheck = () => {
        if (this.props.item.value == this.props.item.optionvalue[1]) {
            this.props.item.value = this.props.item.optionvalue[0]; //Set to false [0] index
        } else {
            this.props.item.value = this.props.item.optionvalue[1]; //Set to true [1] index
        }

        // 2023-12-19 (JJ): NFE - Support for AutoScroll property on Choice, Checkbox & Select.
        this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex, this.props.item.autoscroll ? this.dimensions.height : null);
        this.forceUpdate();
    };
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        fieldContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK,
            backgroundColor: Colours.WHITE,
            marginBottom: 10, // 2023-09-03 (JJ): Standardising marginBottom:10 on all components which were 15 as there were some inconsistencies with styling
            marginTop: 5, //To allow for some more spacing as there isn't a caption above
            alignItems: 'center',

            minHeight: 45,
            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            cursor: 'pointer',
            borderRadius: 5,
            elevation: 5
        },
        fieldText: {
            display: 'flex',
            flexDirection: 'column',
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            //  position: 'absolute',
            color: Colours.BACKGROUND,
            flex: 1,

            margin: 0
            // paddingTop: 0,
        },

        checkboxMandatory: {
            width: Size.icons.tiny,
            height: Size.icons.tiny,
            position: 'absolute',
            top: 5,
            left: 5,
            alignContent: 'center',
            alignSelf: 'center',
            margin: 0
        },
        checkboxCaption: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK,
            margin: 0
        },
        checkboxCaptionNE: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK,
            margin: 0
        }
    });
};
