import React, { Component } from 'react';
import Font from '../../../Constant/font';
import Colours from '../../../Constant/colour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightenDarkenColor } from '../../../Helper/lightenDarkenColor';
import CulverdocsFieldCaption from './_CulverdocsFieldCaption';
import Stylesheet from 'reactjs-stylesheet';
import { Form } from 'react-bootstrap';

export default class CulverdocsNumber extends Component {
    shouldComponentUpdate(prevProps, prevState) {
        // 2023-08-01 (JJ): NFE - Implementing new itemValue check in shouldComponentUpdate() for Populate from List: Short Answer, Long Answer, Number, Choice, Telephone, Postcode
        return this.props.itemValue !== prevProps.itemValue || this.props.visible !== prevProps.visible || this.props.item.value !== prevProps.item.value || this.props.caption !== prevProps.caption;
    }
    onFieldChanged = () => {
        // 2022-07-27 (JJ): Adding Min/Max Support for Integer fields using badge
        if (this.props.item && this.props.item.value && this.props.item.minvalue > 0 && parseFloat(this.props.item.value) < parseFloat(this.props.item.minvalue) && this.props.item.value !== this.props.item.minvalue) {
            this.props.item.value = this.props.item.minvalue;
        }

        if (this.props.item && this.props.item.value && this.props.item.maxvalue > 0 && parseFloat(this.props.item.value) > parseFloat(this.props.item.maxvalue) && this.props.item.value !== this.props.item.maxvalue) {
            this.props.item.value = this.props.item.maxvalue;
        }

        // 2022-07-06 (JJ): Applying integerdecimalplaces to input for Intger fields, auto applies decimal rounding.
        if (this.props.item && this.props.item.value) {
            if (this.props.item.integerdecimalplaces && this.props.item.integerdecimalplaces > 0) {
                this.props.item.value = parseFloat(this.props.item.value).toFixed(this.props.item.integerdecimalplaces).toString();
            }
        }

        this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex).then(() => {
            // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
            if (this.props.pItem) {
                this.props.forceRepeaterChildUpdate();
            }

            this.forceUpdate();
        });
    };
    render() {
        // console.log(`render() - ${this.props.item.type} - ${this.props.item.caption}`);

        if (!this.props.visible) {
            return null;
        }
        const styles = getStyle(this.props.brandData);

        // 2022-07-27 (JJ): Adding Min/Max Support for Integer fields using badge
        var minmaxrule;
        if (this.props.item && (this.props.item.minvalue || this.props.item.maxvalue)) {
            switch (true) {
                case this.props.item.minvalue > 0 && this.props.item.maxvalue == 0:
                    minmaxrule = `Min: ${this.props.item.minvalue}`;
                    break;
                case this.props.item.minvalue == 0 && this.props.item.maxvalue > 0:
                    minmaxrule = `Max: ${this.props.item.maxvalue}`;
                    break;
                case this.props.item.minvalue > 0 && this.props.item.maxvalue > 0:
                    minmaxrule = `${this.props.item.minvalue} - ${this.props.item.maxvalue}`;
                    break;
            }
        }
        // let hasValueProps = this.props.item.value ? true : false;
        return (
            <div style={{ position: 'relative' }}>
                <CulverdocsFieldCaption
                    brandData={this.props.brandData}
                    caption={this.props.item.caption}
                    userGuidance={this.props.item.userguidance} // 2024-01-15 (JJ): _CulverdocsFieldCaption support for userGuidance
                    // badge={minmaxrule}
                    mandatory={this.props.item.mandatory}
                    editable={this.props.editable}
                    hasValue={this.props.item.value ? true : false}
                />

                {/* // 2023-07-09 (JJ): NFE ONLY - Implementing Lock Fields (Smart Lock) for NFE forms (Short Answer, Number, Choice, Select) */}
                {this.props.item.lockfieldtype && (
                    <div style={{ position: 'absolute', right: 0, top: 0 }}>
                        <div
                            hitslop={{
                                left: 10,
                                right: 10,
                                bottom: 10,
                                top: 10
                            }}
                            onClick={() => {
                                this.props.toggleFieldLock(this.props.item, !this.props.item.locked).then(() => {
                                    // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                                    if (this.props.pItem) {
                                        this.props.forceRepeaterChildUpdate();
                                    }

                                    this.forceUpdate();
                                });
                            }}
                        >
                            <FontAwesomeIcon
                                icon={['fad', this.props.item.locked ? (this.props.item.lockfieldtype == 'value' ? 'square-a-lock' : 'hashtag-lock') : 'lock-keyhole-open']}
                                color={this.props.item.locked ? Colours.GREEN : Colours.GREY}
                            />
                        </div>
                    </div>
                )}

                {/* Editable */}
                {this.props.editable && (
                    // <TextInput
                    //     ref={(ref) => {
                    //         this.thisRef = ref;
                    //     }} // 2023-11-06 (JJ): Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                    //     // placeholderTextColor={Colours.BACKGROUND}
                    //     defaultValue={this.props.item.value}
                    //     style={styles.fieldInteger}
                    //     onChangeText={(text) => {
                    //         this.props.item.value = text;
                    //         this.forceUpdate();
                    //         // 2023-11-06 (JJ): Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                    //         // If onChangeText is called without focus, the field has been updated externally (i.e. S Pen) and needs to write to the DB by calling onEndEditing
                    //         if (!this.thisRef.isFocused()) {
                    //             clearTimeout(this.thisRef.timeoutToUpdate);
                    //             this.thisRef.timeoutToUpdate = setTimeout(() => {
                    //                 this.thisRef.props.onEndEditing();
                    //             }, 1000);
                    //         }
                    //     }}
                    //     // 2022-07-07 (JJ): Handle updates in onEndEditing to support decimal updates after editing, otherwise was inconsistent in onChangeText.
                    //     onEndEditing={() => {
                    //         // 2022-07-27 (JJ): Adding Min/Max Support for Integer fields using badge
                    //         if (this.props.item && this.props.item.value && this.props.item.minvalue > 0 && parseFloat(this.props.item.value) < parseFloat(this.props.item.minvalue) && this.props.item.value !== this.props.item.minvalue) {
                    //             this.props.item.value = this.props.item.minvalue;
                    //         }

                    //         if (this.props.item && this.props.item.value && this.props.item.maxvalue > 0 && parseFloat(this.props.item.value) > parseFloat(this.props.item.maxvalue) && this.props.item.value !== this.props.item.maxvalue) {
                    //             this.props.item.value = this.props.item.maxvalue;
                    //         }

                    //         // 2022-07-06 (JJ): Applying integerdecimalplaces to input for Intger fields, auto applies decimal rounding.
                    //         if (this.props.item && this.props.item.value) {
                    //             if (this.props.item.integerdecimalplaces && this.props.item.integerdecimalplaces > 0) {
                    //                 this.props.item.value = parseFloat(this.props.item.value).toFixed(this.props.item.integerdecimalplaces).toString();
                    //             }
                    //         }

                    //         this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex).then(() => {
                    //             // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                    //             if (this.props.pItem) {
                    //                 this.props.forceRepeaterChildUpdate();
                    //             }

                    //             this.forceUpdate();
                    //         });
                    //     }}
                    //     keyboardType={'decimal-pad'} // 2021-05-31 (JJ): applied returnKeyType for renderInteger() and changed to numeric
                    //     returnKeyType="done" // 2021-05-31 (JJ): applied returnKeyType for renderInteger() and changed to numeric
                    //     autoCapitalize="none" //Fix for Android not showing decimal- TODO: Test with latest release
                    // />

                    <Form.Control
                        type="number"
                        style={styles.fieldInteger}
                        value={this.props.item.value}
                        onChange={(text) => {
                            this.props.item.value = text.target.value;
                            this.forceUpdate();

                            // 2023-11-06 (JJ): Support for Samsung S Pen - force onEndEditing if onChangeText is triggered without focus.
                            // If onChangeText is called without focus, the field has been updated externally (i.e. S Pen) and needs to write to the DB by calling onEndEditing
                            // if (!this.thisRef.isFocused()) {
                            //     clearTimeout(this.thisRef.timeoutToUpdate);
                            //     this.thisRef.timeoutToUpdate = setTimeout(
                            //         () => {
                            //             this.thisRef.props.onEndEditing();
                            //         },
                            //         1000
                            //     );
                            // }
                        }}
                        onBlur={() => {
                            this.onFieldChanged(); // 2024-06-13 (JJ): Fix for S Pen writing with onEndEditing through ref, now calls function in component
                        }}
                        // onBlur={() => {
                        //     //console.log("DATA IS HERE");
                        //     // 2022-07-27 (JJ): Adding Min/Max Support for Integer fields using badge
                        //     if (this.props.item && this.props.item.value && this.props.item.minvalue > 0 && parseFloat(this.props.item.value) < parseFloat(this.props.item.minvalue) && this.props.item.value !== this.props.item.minvalue) {
                        //         this.props.item.value = this.props.item.minvalue;
                        //     }

                        //     if (this.props.item && this.props.item.value && this.props.item.maxvalue > 0 && parseFloat(this.props.item.value) > parseFloat(this.props.item.maxvalue) && this.props.item.value !== this.props.item.maxvalue) {
                        //         this.props.item.value = this.props.item.maxvalue;
                        //     }

                        //     // 2022-07-06 (JJ): Applying integerdecimalplaces to input for Intger fields, auto applies decimal rounding.
                        //     if (this.props.item && this.props.item.value) {
                        //         if (this.props.item.integerdecimalplaces && this.props.item.integerdecimalplaces > 0) {
                        //             this.props.item.value = parseFloat(this.props.item.value).toFixed(this.props.item.integerdecimalplaces).toString();
                        //         }
                        //     }

                        //     this.props.onFieldChanged(this.props.item, this.props.pItem, this.props.rIndex).then(() => {
                        //         // 2023-08-01 (JJ): NFE - Added forceRepeaterChildUpdate to update parent when field is updated or toggleFieldLock from inside a repeater
                        //         if (this.props.pItem) {
                        //             this.props.forceRepeaterChildUpdate();
                        //         }

                        //         this.forceUpdate();
                        //     });
                        // }}
                    />
                )}

                {/* NonEditable */}
                {!this.props.editable && (
                    <div style={styles.fieldInteger}>
                        <p style={styles.fieldNonEditable}>{this.props.item.value}</p>
                    </div>
                )}
            </div>
        );
    }
}

const getStyle = (brandData) => {
    return Stylesheet.create({
        fieldNonEditable: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            // backgroundColor: Colours.MIDGREY,
            color: Colours.GREY,
            margin: 0
        },
        // fieldNonEditableView : {
        //     backgroundColor: Colours.MIDGREY,
        //     marginBottom: 10,
        //     paddingTop:10,
        //     paddingBottom:10,
        //     paddingLeft:5,

        //     height:45,
        //     borderWidth: 0.5,
        //     borderColor: brandData?.primarycolour,
        //     borderRadius: 5,
        //     elevation:5,
        // },

        // Field Specific
        fieldInteger: {
            fontFamily: Font.family.regular,
            fontSize: Font.size.medium,
            color: Colours.BLACK, //v1.1.9 - iOS Dark Mode fix
            backgroundColor: Colours.WHITE,
            // borderBottomWidth: 1,
            // borderColor: brandData?.primarycolour,
            marginBottom: 10, // 2023-07-29 (JJ): NFE - Reducing Number field to marginBottom 10 to standardise padding
            paddingTop: 10, //for iOS
            paddingBottom: 10, //for iOS
            paddingLeft: 5, //for iOS

            boxShadow: '2px 3px 1px #d2d2d2',
            border: '0.5px solid' + lightenDarkenColor(brandData?.primarycolour, 50),
            backgroundColor: Colours.TILE,
            borderRadius: 4,
            elevation: 3,
            height: 45
        }
    });
};
