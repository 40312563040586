// 2024-04-19 (JJ): Improvements to Select / List Filters - listFilterContainsAdvancedFilter to support: OR conditions, >, <, .., DATE(), !== etc.
import React from 'react';
import { calculateDateFromDays } from './calculateDateFromDays';

export function listFilterTextConditions(filterString, listItemValue, returnValueFromDataname, item, pItem, rIndex, internalPlaceholders) {
    // First, we're going to split the filterValue by "," to support OR conditions
    var filterString = filterString.split(','); //Split the condition values in case there's an OR filter.
    let showEntry = false;

    // Loop through each of the conditions to see if any match (or conditions) - if so, showEntry is set to true if any conditions match and will stop checking.
    filterString.forEach((conditionValue) => {
        if (!showEntry) {
            // Parse any values from inside placeholders
            if (conditionValue.includes('[')) {
                var fieldname = conditionValue.split('[')[1].split(']')[0]; //Opted for this approach to keep simple instead of regex, only 1 field value per filter.
                var fieldvalue = (() => {
                    // Check if 'User.' is part of the fieldname and return accordingly
                    return fieldname.includes('User.')
                        ? internalPlaceholders[fieldname] // Use internal placeholder if 'User.' is found
                        : returnValueFromDataname(fieldname, item, pItem, rIndex); // Otherwise, get the value from data
                })();
                if (fieldvalue) {
                    conditionValue = conditionValue.replace(`[${fieldname}]`, fieldvalue);
                }
            }

            // Parse any DATE(n) occurrences with the calculated date
            // 2024-05-01 (JJ): Support DATE() without number to return equiv to today (0)
            const dateRegex = /DATE\((-?\d*)\)/g;
            conditionValue = conditionValue.replace(dateRegex, (match, days) => {
                days = days || 0;
                const calculatedDate = calculateDateFromDays(parseInt(days));
                return calculatedDate; // Here, we're directly replacing DATE(n) with the calculated date
            });

            // Exact Match
            if (conditionValue == listItemValue) {
                showEntry = true;
            }

            // Wildcard - * = any value in the field
            if (conditionValue == '*' && listItemValue) {
                showEntry = true; //Condition is * so there should be a value.
            }

            // 2021-05-31 (JJ): Support for blank filter condition ''.
            if (conditionValue == "''" && !listItemValue) {
                showEntry = true; //Condition is '' so there should not be a value.
            }

            // 2021-05-31 (JJ): Support for range x..y in filters.
            if (conditionValue.includes('..')) {
                var condvalarr = conditionValue.split('..');

                // 2024-05-01 (JJ): Fix for Select List Filters with range on DATE() calculations - only parseFloat if it doesn't match date format.
                var fieldval = !/^\d{4}-\d{2}-\d{2}$/.test(listItemValue) ? parseFloat(listItemValue) : listItemValue;
                var min = !/^\d{4}-\d{2}-\d{2}$/.test(condvalarr[0]) ? parseFloat(condvalarr[0]) : condvalarr[0];
                var max = !/^\d{4}-\d{2}-\d{2}$/.test(condvalarr[1]) ? parseFloat(condvalarr[1]) : condvalarr[1];

                if (listItemValue && fieldval >= min && fieldval <= max) {
                    showEntry = true;
                }
            }

            // 2021-05-30 (JJ): Fix for conditional visibility to include < and > conditions.
            var firstchar = conditionValue.substring(0, 1);
            if (firstchar == '<' || firstchar == '>') {
                // Get the values as-is
                var fieldval = listItemValue;
                var checkcond = conditionValue.substring(1);

                // If it's not a YYYY-MM-DD date format, parse them to avoid issues with text for example.
                if (!/^\d{4}-\d{2}-\d{2}$/.test(checkcond)) {
                    fieldval = parseFloat(fieldval);
                    checkcond = parseFloat(checkcond);
                }

                if (firstchar == '>' && listItemValue && fieldval > checkcond) {
                    showEntry = true;
                }
                if (firstchar == '<' && listItemValue && fieldval < checkcond) {
                    showEntry = true;
                }
            }

            // 2022-07-27 (JJ): Support for not equals condition !=
            if (conditionValue.substring(0, 2) == '!=') {
                var checkcond = conditionValue.substring(2);
                if (listItemValue && listItemValue !== checkcond) {
                    showEntry = true;
                }
            }
        }
    });

    return showEntry; //We haven't returned true by this point, fallback with false to hide it.
}
